import { useState, useRef, useEffect, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../core/components/Text';
import styled from 'styled-components';
import ConfirmQuestionModal from './ConfirmQuestionModal';
import ConfirmAnswerModal from './ConfirmAnswerModal';
import Row from '../../core/components/Row';
import Button from '../../core/components/Button';
import MultiTextInput from '../../core/components/MultiTextInput';
import Message from '../../core/components/Message';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import ACTIONS from '../../core/constants/actions';
import { createJobQuestion, getJobQuestions, updateJobQuestion, createJobAnswer, updateJobAnswer } from '../apis/job';
import { JobAnswer, JobQATranslation, JobQuestion } from '../types/api';
import moment from 'moment';
import { decodeHTMLEntities } from '../../core/utils';

type QASectionProp = {
    jobPostId?: number | string,
}

type UserTextInputProp = {
    clickEvents: {
        onCancel?: () => void,
        onSubmit: (text: string) => void
    }, 
    onChange?: (text: string) => void,
    placeholder?: string, 
    defaultValue?: string,
    submitBtnText?: string,
    textAreaRef?: RefObject<HTMLTextAreaElement>
}

type NewQuestionBlockProp = {
    jobPostId: number | string,
    onPost: (newQuestion: JobQuestion) => void
}

type QuestionBlockProp = {
    question: JobQuestion,
    onUpdate: (updatedQuestion: JobQuestion) => void
}

type AnswerBlockProp = {
    questionId: number | string,
    answer: JobAnswer | null,
    onUpdate: (updatedAnswer: JobAnswer) => void
}


/********** STYLED-CSS RULES **********/
const AnswerSecWrapper = styled.div`
    width: 100%;
    padding-left: 20px;  
`;

const UserInputFieldWrapper = styled.div`
    width: 100%;
`;

const TextArea = styled(MultiTextInput)`
    width: 100%;
    padding: 10px;
    resize: vertical;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    gap: 10px;
    justify-content: flex-end;
`;

const InputUIRow = styled(Row)`
    justify-content: space-between;
    align-items: center;
`;

const QABlockContainer = styled.div`
    margin-bottom: 20px;
    width: 100%;
`;

const QuestionPanelContainer = styled.div`
    width: 100%;
    background-color: transparent;
    padding: 0 10px 10px 0;
    border-radius: 5px;
`;

const AnswerPanelContainer = styled.section`
    width: 100%;
    background-color: #F6F6F6;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
`;

const ShowOriginalText = styled(Text)`
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #767676;

    &:hover{
        text-decoration: underline;
        color: #999999
    }
`;

const NoQuestionBlock = styled(Text)`
    margin-bottom: 20px;   
`;

const MoreQuestionBlock = styled.div`
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
`;

const MoreQuestionButton = styled(Button)`
    display: inline;  
`;

const OriginalText = styled(Text)`
    color: #767676;
    font-size: 14px;
`;

const EditText = styled(Text)`
    cursor: pointer;
    color: #767676;

    &:hover{
        text-decoration: underline;
        color: #999999
    }
`;

const CancelButton = styled(Button)`
    width: 80px;
`;

const SubmitButton = styled(Button)`
    width: 100px;
`;

const MetaText = styled(Text)`
    font-size: 12px;
    color: #999999;
`;

const WarningText = styled(Text)`
    color: #E93232;   
`;

const LocalSection = styled.section`
    margin-top: 10px;
    width: 100%;
`;

const LocalRow = styled(Row)`
    margin-top: 5px;
    margin-left: 20px;
    gap: 10px;
    align-items: flex-end;
    flex-wrap: wrap;
`;

const QAHeader = styled.span`
    width: 20px;
    display: inline-block;
`;

const QAText = styled(Text)`
    display: inline;
`;

const EditingTextAreaContainer = styled.div`
    margin-bottom: 10px;
`;

const GoogleTranslateImgWrapper = styled.span`
    max-width: 118px;
`;

const GOOGLE_TRANSLATE_URL = 'http://translate.google.com';


//Input string: e.g. 2022-10-24T10:07:56.055+09:00
const formatTime = (rawTimeStr: string) => {
    return moment(rawTimeStr).format('YYYY/MM/DD HH:mm');
}


const getTranslatedText = (translations: JobQATranslation[], lang_code: string) => {
    return translations.find(translation => translation.language.code === lang_code)?.text
}

 
export const JobQandASection = (props: QASectionProp) => {
    const { t } = useTranslation();
    const { jobPostId } = props;
    const { state } = useReducerContext();
    const [ jobQuestions, setJobQuestions ] = useState<JobQuestion[]>([]);
    const QUESTIONS_PER_LOADING = 10;
    const [ loadedBy, setLoadedBy ] = useState(QUESTIONS_PER_LOADING);
    const loadNextQuestions = () => { setLoadedBy(loadedBy + QUESTIONS_PER_LOADING); }
    const onQuestionUpdate = (updatedQuestion: JobQuestion) => {
        setJobQuestions(jobQuestions.map(question => {
            return question.id === updatedQuestion.id? updatedQuestion : question;
        }));
    };
    const onAnswerUpdate = (updatedAnswer: JobAnswer) => {
        setJobQuestions(jobQuestions.map(question => {
            if (question.id === updatedAnswer.jobPostQuestionId) {
                question.answer = updatedAnswer;
            }

            return question;
        }));
    };
    const onQuestionPost = (newQuestion: JobQuestion) => {
        setJobQuestions([...jobQuestions, newQuestion]);
    };

    useEffect(() => {
        if (!jobPostId)
            return;

        (async () => {       
            try {
                setJobQuestions(await getJobQuestions(jobPostId))
            } catch (e) {
            }
        })()
    }, [jobPostId])

    return (
        <LocalSection>  
            { 
                jobQuestions.slice(0, loadedBy).map((question,i) => 
                    <QABlockContainer key={`QA-${i}`}>
                        <QuestionBlock question={question} onUpdate={onQuestionUpdate}/>
                        <AnswerSecWrapper>
                            <AnswerBlock answer={question.answer} onUpdate={onAnswerUpdate} questionId={question.id}/>     
                        </AnswerSecWrapper>
                    </QABlockContainer>)
            }
            {   
                //Show "Show more questions" button if all questions doen't appear yet
                loadedBy < jobQuestions.length && 
                <MoreQuestionBlock>
                        <MoreQuestionButton variant="quarternary-subtle" onClick={loadNextQuestions}>
                        {t('job.job_question_show_more')}
                    </MoreQuestionButton>
                </MoreQuestionBlock> 
            }
            { 
                jobQuestions.length === 0 && 
                <NoQuestionBlock>{t('job.job_question_no_question')}</NoQuestionBlock>
            }
            { 
                (state.isPartner || state.isCandidate) && jobPostId && 
                <NewQuestionBlock jobPostId={jobPostId} onPost={onQuestionPost}/> 
            }
        </LocalSection>
    )
}


const AnswerBlock = (props: AnswerBlockProp) => {
    const { t, i18n } = useTranslation();
    const { answer, onUpdate, questionId } = props;
    const { state, dispatch } = useReducerContext();
    const { isEmployer } = state;
    const [answerText, setAnswerText] = useState<string>(answer?.originalText ?? "");
    const [isEditing, setIsEditing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const placeholder = t('job.job_answer_textarea_placeholder');
    const originalText = answer?.originalText ?? "";
    const translatedText = getTranslatedText(answer?.translations ?? [], i18n.language);
    const showShowOriginalText = translatedText !== originalText;
    const [showOriginal, setShowOriginal] = useState(false);
    const onClickEdit = () => { setIsEditing(true); };
    const onCancelEdit = () => { setIsEditing(false); };
    const onCancelSubmit = () => { setShowModal(false); };
    const onClickSubmit = () => {  setShowModal(true); };
    const onClickShowOriginal = () => { setShowOriginal(!showOriginal) };
    const msgUpdateSuccess = t('job.job_qa_snackbar_update_success');
    const msgUpdateWarning = t('job.job_qa_snackbar_update_failed');
    const msgPostSuccess = t('job.job_answer_snackbar_success');
    const msgPostWarning = t('job.job_answer_snackbar_failed');
    const showSpinner = () => dispatch({ type: ACTIONS.START_LOADING });
    const hideSpinner = () => dispatch({ type: ACTIONS.STOP_LOADING });
    const showSuccessMsg = (message: string) => dispatch({ type: ACTIONS.SET_PROMPT, payload: { type: 'success', message }});
    const showFailedMsg = (message: string) => dispatch({ type: ACTIONS.SET_PROMPT, payload: { type: 'warning', message }});
    const onClickSaveEdit = (newText: string) => {
        //Called when the user finishes editing an answer and click "Save"
        (async () => {
            if (!answer || newText === "") {
                setIsEditing(false);
                return;
            }

            try {
                const params = { originalText: newText };
                showSpinner();                
                onUpdate(await updateJobAnswer(answer.id, params))
                showSuccessMsg(msgUpdateSuccess);
                setIsEditing(false);
            } catch (e) {
                showFailedMsg(msgUpdateWarning);
            }

            hideSpinner();    
        })()
    };
    const onConfirmSubmit = () => {
        //Called when the user clicks "Confirm" on a confirmation modal
        (async () => {
            try {
                const params = { jobPostQuestionId: questionId, originalText: answerText};
                showSpinner();
                onUpdate(await createJobAnswer(params));
                showSuccessMsg(msgPostSuccess);
            } catch (e) {
                showFailedMsg(msgPostWarning);
            } finally {
                hideSpinner();
            }
        })()
    }

    //Show a text area to let the employer answer
    if (isEmployer && !answer)
        return (
            <>
                <UserTextInput 
                    clickEvents={{ onSubmit: onClickSubmit }}
                    onChange={setAnswerText}
                    placeholder={placeholder}
                    submitBtnText={t('core.submit')} />
                {
                    showModal &&
                    <ConfirmAnswerModal onConfirm={onConfirmSubmit} onClose={onCancelSubmit} />
                } 
            </>
        )
    
    //Show "Waiting for the answer" message for partners/anonymous users if no answer is given
    if (!answer)
        return (
            <Message style={{ color: "#444444" }}>
                {t('job.job_qa_text_waiting_answer')}
            </Message>
        )

    if (isEditing)
        return (    
            <UserTextInput 
                clickEvents={{ onCancel: onCancelEdit, onSubmit: onClickSaveEdit }}
                placeholder={placeholder}
                defaultValue={answer.originalText}
                submitBtnText={t('core.save')} />
        )

    return (
        <AnswerPanelContainer>
            <QAText><QAHeader>A.</QAHeader>{ decodeHTMLEntities(translatedText) }</QAText>
            { showShowOriginalText && showOriginal && 
                <LocalRow>
                    <OriginalText>{ originalText }</OriginalText>
                </LocalRow>
            }
            <LocalRow>
                <MetaText>{ formatTime(answer.createdAt) }</MetaText>
                {
                    !!answer.editedAt &&
                    <MetaText>{ t('job.job_qa_edited') }</MetaText>
                }
                { 
                    isEmployer && 
                    <EditText onClick={onClickEdit}>
                        {t('job.job_answer_edit')}
                    </EditText> 
                }
                {
                    showShowOriginalText  &&
                    <>
                        <ShowOriginalText onClick={onClickShowOriginal}>
                            { showOriginal? t('job.job_qa_hide_original') : t('job.job_qa_show_original')}
                        </ShowOriginalText>
                        <GoogleTranslateLogo/>
                    </>
                }
            </LocalRow>    
        </AnswerPanelContainer>
    )    

}


const QuestionBlock = (props: QuestionBlockProp) => {
    const { t, i18n } = useTranslation();
    const { question, onUpdate } = props;
    const { state, dispatch } = useReducerContext();
    const { isPartner, isCandidate } = state;
    const originalText = question.originalText ?? "";
    const [isEditing, setIsEditing] = useState(false);
    const placeholder = t('job.job_question_textarea_placeholder');
    const translatedText = getTranslatedText(question.translations, i18n.language);
    const showShowOriginalText = translatedText !== originalText;
    const [showOriginal, setShowOriginal] = useState(false);
    const onClickEdit = () => { setIsEditing(true); };
    const onClickCancel = () => { setIsEditing(false); };
    const onClickShowOriginal = () => { setShowOriginal(!showOriginal) };
    const showSpinner = () => dispatch({ type: ACTIONS.START_LOADING });
    const hideSpinner = () => dispatch({ type: ACTIONS.STOP_LOADING });
    const msgUpdateSuccess = t('job.job_qa_snackbar_update_success');
    const msgUpdateWarning = t('job.job_qa_snackbar_update_failed');
    const showSuccessMsg = (message: string) => dispatch({ type: ACTIONS.SET_PROMPT, payload: { type: 'success', message }});
    const showWarningMsg = (message: string) => dispatch({ type: ACTIONS.SET_PROMPT, payload: { type: 'warning', message }});
    const onClickSave = (newText: string) => {
        //Called when the user click "Save" button to complete editing
        if (newText === '') {
            setIsEditing(false);
            return;
        }

        (async () => {
            try {
                const params = { originalText: newText};
                showSpinner();
                onUpdate(await updateJobQuestion(question.id, params));
                setIsEditing(false);
                showSuccessMsg(msgUpdateSuccess);
            } catch (e) {
                showWarningMsg(msgUpdateWarning);
            }
            hideSpinner();
        })()
    }

    if (isEditing)
        return (    
            <EditingTextAreaContainer>
                <UserTextInput 
                    clickEvents={{ onCancel: onClickCancel, onSubmit: onClickSave }}
                    placeholder={placeholder}
                    defaultValue={originalText}
                    submitBtnText={t('core.save')} />
            </EditingTextAreaContainer>
        )

    return (
        <QuestionPanelContainer>
            <QAText><QAHeader>Q.</QAHeader>{ decodeHTMLEntities(translatedText) }</QAText>
            { showShowOriginalText && showOriginal && 
                <LocalRow>
                    <OriginalText>{ originalText }</OriginalText>
                </LocalRow>
            }
            <LocalRow>
                <MetaText>{ formatTime(question.createdAt) }</MetaText>
                {
                    !!question.editedAt &&
                    <MetaText>{ t('job.job_qa_edited') }</MetaText>
                }
                { 
                    ((isPartner && question.partnerId === state.company?.partner?.id) ||
                     (isCandidate && question.candidateId === state.candidate?.id)) 
                    &&
                    <EditText onClick={onClickEdit}>{t('job.job_answer_edit')}</EditText> 
                }
                {
                    showShowOriginalText &&
                    <>
                        <ShowOriginalText onClick={onClickShowOriginal}>
                            { showOriginal? t('job.job_qa_hide_original') : t('job.job_qa_show_original')}
                        </ShowOriginalText>
                        <GoogleTranslateLogo/>
                    </>
                }
            </LocalRow>    
        </QuestionPanelContainer>
    )    

}


//Block to show a text area to let a partner enter his/her question
const NewQuestionBlock = (props: NewQuestionBlockProp) => {
    const [question, setQuestion] = useState("");
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const { jobPostId, onPost } = props;
    const { dispatch } = useReducerContext();
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const msgPostSuccess = t('job.job_question_snackbar_success');
    const msgPostWarning = t('job.job_question_snackbar_failed');
    const showSpinner = () => dispatch({ type: ACTIONS.START_LOADING });
    const hideSpinner = () => dispatch({ type: ACTIONS.STOP_LOADING });
    const showSuccessMsg = (message: string) => dispatch({ type: ACTIONS.SET_PROMPT, payload: { type: 'success', message }});
    const showWarningMsg = (message: string) => dispatch({ type: ACTIONS.SET_PROMPT, payload: { type: 'warning', message }});
    const onSubmitQuestion = (userText: string) => {
        setQuestion(userText);
        setShowModal(true);
    };
    const onSubmitModal = () => {
        (async () => {
            try {
                showSpinner();
                onPost(await createJobQuestion({ jobPostId, originalText: question }));
                setQuestion("");
                showSuccessMsg(msgPostSuccess);

                if (textAreaRef?.current)
                    textAreaRef.current.value = "";

            } catch (e) {
                showWarningMsg(msgPostWarning);
            } finally {
                hideSpinner();
                setShowModal(false);
            }
        })()
    };

    return (
        <>
            <UserTextInput 
                clickEvents={{ onSubmit: onSubmitQuestion }}
                onChange={setQuestion}
                textAreaRef={textAreaRef}
                placeholder={t('job.job_question_textarea_placeholder')}
            />  
            { showModal && 
                <ConfirmQuestionModal 
                    onConfirm={onSubmitModal}
                    onClose={() => { setShowModal(false); }}
                /> 
            }
        </>
    )
}


//Textarea + button(s) : Show an UI to enter a question/answer
const UserTextInput = (props: UserTextInputProp) => {
    const { t } = useTranslation();
    const MAX_LEN = 500;
    const placeholder = props.placeholder ?? "Type here";
    const defaultValue = props.defaultValue ?? "";
    const submitBtnText = props.submitBtnText ?? t('core.submit');
    const { onChange, textAreaRef } = props;
    const { onCancel, onSubmit } = props.clickEvents;
    const [overWordLimit, setOverWordLimit] = useState(false);
    const [textInput, setTextInput] = useState("");
    const [canSubmit, setCanSubmit] = useState(textInput.length > 0);
    const onClickSubmit = () => { onSubmit(textInput); };
    const warningStyle = { borderColor: "#E93232", backgroundColor: "#FFF0F0"};
    const onTextChange = (text: string) => {
        const len = text.length;
        setCanSubmit(len > 0 && len <= MAX_LEN);
        setOverWordLimit(len > MAX_LEN);
        onChange && onChange(text);
        setTextInput(text);
    }

    return (
        <UserInputFieldWrapper>
            <TextArea 
                placeholder={placeholder} 
                textAreaRef={textAreaRef} 
                defaultValue={defaultValue}
                onTextChange={onTextChange}
                style={overWordLimit ? warningStyle : {}}
                autoFocus>
            </TextArea>
            <InputUIRow>
                { overWordLimit && <WarningText>{t('job.job_qa_text_over_limit')}</WarningText>}
                <ButtonWrapper>
                    { onCancel && 
                    <CancelButton variant="quarternary-subtle" onClick={onCancel}>{t('core.not_submit')}</CancelButton> }
                    <SubmitButton variant="quart" onClick={onClickSubmit} disabled={!canSubmit}>{submitBtnText}</SubmitButton>
                </ButtonWrapper>
            </InputUIRow>
        </UserInputFieldWrapper>
    )
}


const GoogleTranslateLogo = () => {
    return (
        <GoogleTranslateImgWrapper>
            <a href={GOOGLE_TRANSLATE_URL}>
                <img alt="google-translate-logo" src="/images/googleTranslate.svg" />
            </a>
        </GoogleTranslateImgWrapper>
    )
}

