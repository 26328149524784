import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../core/components/Modal';
import Button from '../../../core/components/Button';
import Text from '../../../core/components/Text';
import Column from '../../../core/components/Column';
import ToggleButton from '../../../core/components/ToggleButton';
import Row from '../../../core/components/Row';
import { ReactComponent as AlertIcon } from '../../../assets/icon-alert.svg';
import { ReactComponent as FileSubmittingIcon} from '../../../assets/icon-file-with-right-arrow.svg';
import { ReactComponent as OpenIcon } from '../../../assets/icon-open-in-new.svg';
import { updateCompany } from '../../../core/apis/company';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import ACTIONS from '../../../core/constants/actions';


interface HiringConfirmationModalProps {
    open: boolean;
    onHire: () => void;
    onClose: () => void;
    companyId: string | number;
    proposalId: string | number;
    isAutoSendOn: boolean;
    isEmployerAddressFullyAvailable: boolean;
}

interface FeatureExplanationContainerProps {
  isAutoSubmitOn?: boolean;
}


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 5px;
  gap: 20px;
`;

const MainHeader = styled.h2`
  font-size: 26px;
  font-weight: 500;
  line-height: 37px;
  margin: 0;
`;

const DescriptionContainer = styled.div`
`;

const FeatureExplanationContainer = styled.div<FeatureExplanationContainerProps>`
  background-color: ${({ isAutoSubmitOn }) => isAutoSubmitOn ? "#FFF6F0" : "#F7F7F7"};
  border: 2px solid ${({ isAutoSubmitOn }) => isAutoSubmitOn ? "#FF730D" : "#ACACAC"};
  border-radius: 5px;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
  transition: 0.3s ease-in-out;

`;

const ExplanationColumn = styled(Column)`
  gap: 10px;
`;

const ExplanationHeading = styled(Text)`
  display: grid;
  font-weight: 700;
  grid-template-columns: 24px auto;
  gap: 10px;
`;

const ToggleButtonColumn = styled(Column)`
  align-items: center;
  justify-content: center;
`;

const LocalRow = styled(Row)`
  gap: 20px;
`;

const LinkText = styled.a`
  cursor: pointer;
  color: #017698;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;

const AlertMessagesContainer = styled(Column)`
  gap: 10px;
`;

const AlertContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  padding: 10px 20px 10px 20px;
  background-color: #FFE5E5;
  border-radius: 2px;
  gap: 20px;
`;

const AlertIconContainer = styled(Column)`
  align-items: center;
  justify-content: center;
`;

const AlertMessageContainer = styled(Column)`
  gap: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%; 
  gap: 10px;
  margin-top: 20px;
`;

const HireButton = styled(Button)`
  width: 150px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const CancelButton = styled(Button)`
  width: 120px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const HiringConfirmationModal: FunctionComponent<HiringConfirmationModalProps> = ({
  open,
  onHire,
  onClose,
  companyId,
  proposalId,
  isAutoSendOn,
  isEmployerAddressFullyAvailable,
}) => { 
    const { dispatch } = useReducerContext();
    const [isAutoSendEnabled, setIsAutoSendEnabled] = useState<boolean>(false);

    const onModalClose = () => {
        if (onClose)
          onClose();
    }

    const onToggle = async () => {
      try {
        setIsAutoSendEnabled(!isAutoSendEnabled);
        const updatedCompany = await updateCompany(companyId, { 
          autoSendEmploymentOffer: !isAutoSendOn
        });
        dispatch({ type: ACTIONS.UPDATE_COMPANY, payload: {
          company: updatedCompany
        }});
      } catch {
        setIsAutoSendEnabled(isAutoSendEnabled);
      }
    }

    useEffect(() => {
      setIsAutoSendEnabled(
        isAutoSendOn &&
        isEmployerAddressFullyAvailable
      );
    }, [
      isAutoSendOn,
      isEmployerAddressFullyAvailable, 
    ]);

    return (
        <Modal onClose={onClose} open={open}>
            <ContentWrapper>
                <MainHeader>候補者を採用</MainHeader>

                <DescriptionContainer>
                    <Text>採用を行なうと、候補者の連絡先情報を確認したり、ファイルを共有することができます。</Text>
                </DescriptionContainer>
                
                <FeatureExplanationContainer isAutoSubmitOn={isAutoSendEnabled}>
                  <ExplanationColumn>
                    <ExplanationHeading>
                      <FileSubmittingIcon />
                      候補者へ内定証明書を送付する
                    </ExplanationHeading>
                    <Text>候補者の名前等を記載した内定証明書を自動的に作成し、候補者の登録メールアドレスへ送付します</Text>
                    <LocalRow>

                      { isAutoSendOn && isEmployerAddressFullyAvailable &&
                        <LinkText 
                          href={`/applications/${proposalId}/documents/employment_offer`} 
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => onClose()}
                        >
                          プレビューを見る <OpenIcon />
                        </LinkText>
                      }
                    
                      <LinkText 
                        href={`/settings/documents/employment_offer`} 
                        target='_blank'
                        rel="noopener noreferrer"
                        onClick={() => onClose()}
                      >
                        設定を開く <OpenIcon />
                      </LinkText>
                    </LocalRow>
                    <AlertMessagesContainer>
                      { !isEmployerAddressFullyAvailable &&
                        <AlertContainer>
                          <AlertIconContainer>
                            <AlertIcon />
                          </AlertIconContainer>
                          <AlertMessageContainer>
                            <Text>内定証明書に記載される自社の「都道府県」「市・区」「町名」「番地」項目が未登録です。</Text>
                            <Text>
                              <LinkText 
                                href={`/settings/organization`}
                                target='_blank'
                                rel="noopener noreferrer"
                                onClick={() => onClose()}
                                style={{ marginRight: 5 }}
                              >
                                組織設定 <OpenIcon/>
                              </LinkText>
                              より登録を行ってください。
                            </Text>
                          </AlertMessageContainer>
                        </AlertContainer>
                      }
                    </AlertMessagesContainer>
                  </ExplanationColumn>
                  <ToggleButtonColumn>
                    <ToggleButton 
                      toggled={isAutoSendEnabled}
                      onToggle={onToggle}
                      disabled={!isEmployerAddressFullyAvailable}
                    />
                  </ToggleButtonColumn>
                </FeatureExplanationContainer>

                <AlertContainer>
                  <AlertIconContainer>
                    <AlertIcon />
                  </AlertIconContainer>
                  <AlertMessageContainer>
                    <Text>この操作は取り消しができません</Text>
                  </AlertMessageContainer>
               </AlertContainer>
            
                <ButtonsContainer>
                    <CancelButton variant="secondary" onClick={onModalClose} style={{ border: 'none' }}>
                        キャンセル
                    </CancelButton>
                    <HireButton onClick={onHire}>
                        採用
                    </HireButton>
                </ButtonsContainer>
            </ContentWrapper>
        </Modal>
    );
}

export default HiringConfirmationModal;