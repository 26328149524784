//性別
export enum Gender {
  male = 1,
  female = 2,
}
//国籍
export enum Nationality {
  other = "13",
}
//住居の確保状況
export enum HousingEnsureSituation  {
  Already = "already",
  Will = "will",
}