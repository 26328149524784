export enum JobContractPeriodFromAI {
  Indefinite,
  FixedTerm,
}

export enum JobContractRenewalFromAI {
  Automatic,
  Possible,
  No,
}

export enum JobSalaryTypeFromAI {
  Monthly,
  Daily,
  Hourly,
}

export enum JobWorkStyleFromAI {
  FixedHours,
  ShiftSystem,
}

export enum JobDormTypeFromAI {
  Apartment,
  SingleHouse, 
}

export enum JobCommuteMethodFromAI {
  Walk,
  Bycycle,
  PublicTransport,
  Automobile
}

export enum JobJaConversationSkillFromAI {
  CanSpeakBasicPhrases,
  CanCarryOnEverydayConversation,
  Fluent,
}

export enum JobJlptLevelFromAI {
  N1 = 1,
  N2 = 2,
  N3 = 3,
  N4 = 4,
  N5 = 5,
}

export enum JobPreferredGenderFromAI {
  NotAny,
  Male,
  Female
}

export enum JobInterviewTypeFromAI {
  Online,
  Offline,
}