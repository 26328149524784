import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import BannerButton from './BannerButton';
import Button from '../../core/components/Button';
import Label from "../../core/components/Label";
import Message from '../../core/components/Message';
import Modal from '../../core/components/Modal';
import MultiTextInput from '../../core/components/MultiTextInput';
import PageHeading from '../../core/components/PageHeading';
import Row from '../../core/components/Row';
import Select from '../../core/components/Select';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import {MOBILE_DEVICE} from "../../core/constants/styles";

type Option = {
    label: string;
    value: string;
};

type CancelInterviewModalProps = {
    open?: boolean;
    options?: Option[];
    reason?: string;
    placeholder?: string;
    comment?: string;
    onReasonChange?: (reason: string) => void;
    onCommentChange?: (comment: string) => void;
    onWithdraw?: () => void;
    onDecline?: () => void;
    onConfirm?: () => void;
    onCancel?: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 600px;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 5px;
  
  @media ${MOBILE_DEVICE} {
    width: 100vw;
    height: 100vh;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const Prompt = styled.div`
  margin-left: 8px;
  font-family: "Hiragino Kaku Gothic ProN", sans-serif;
  font-size: 18px;
  color: #999999;
  zoom: 0.5;
`

const LocalPageHeading = styled(PageHeading)`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
`;

const CancelButton = styled(Button)`
  border: none;
`;

const CancelInterviewModal: FunctionComponent<CancelInterviewModalProps> = ({
    open,
    options,
    reason,
    placeholder,
    comment,
    onReasonChange,
    onCommentChange,
    onWithdraw,
    onDecline,
    onConfirm,
    onCancel,
}) => {
    const { state, dispatch } = useReducerContext();
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onCancel}>
            <Container>
                <LocalPageHeading>
                    {t('proposal.cancel_interview')}
                </LocalPageHeading>
                {(state.isPartner || state.isCandidate) && (
                  <BannerButton onClick={onWithdraw}>
                      {t('proposal.cancel_interview_warning')}
                  </BannerButton>
                )}
                {state.isEmployer && (
                    <BannerButton onClick={onDecline}>
                        {t('proposal.cancel_interview_warning_employer')}
                    </BannerButton>
                )}
                <Message withIcon>
                    {t('proposal.cancel_interview_prompt')}
                </Message>
                <Form>
                    <Field>
                        <Label required reversed style={{ fontWeight: 400 }}>
                            {t('proposal.reason')}
                        </Label>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('core.select')}
                          options={options}
                          value={reason}
                          onValueChange={onReasonChange}
                        />
                    </Field>
                    <Field>
                        <Label>
                            {t('proposal.comment')}
                        </Label>
                        <MultiTextInput
                          style={{ width: '100%', height: '80px', resize: 'vertical' }}
                          placeholder={placeholder}
                          value={comment}
                          onTextChange={onCommentChange}
                        />
                    </Field>
                </Form>
                <Row style={{ alignSelf: 'flex-end', gap: '10px', marginTop: '20px' }}>
                    <CancelButton
                        variant="secondary"
                        onClick={onCancel}
                    >
                        {t('core.not_submit')}
                    </CancelButton>
                    <Button disabled={!reason} onClick={() => onConfirm && onConfirm()}>
                        {t('core.submit')}
                    </Button>
                </Row>
            </Container>
        </Modal>
    );
};

export default CancelInterviewModal;