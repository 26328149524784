import styled from "styled-components";
import Column from "../../../../../core/components/Column";

export const Section = styled.section`
  padding: 20px 10px;
  border: 1px solid black;

  &:not(:first-child) {
    border-top: none;
  }
`;

export const BlockNoteContainer = styled.div`
  width: 100%;
  text-align: right;
`;

export const SectionHeadingContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px;
  margin-bottom: 10px;
`;

export const ListBlocksContainer = styled(Column)`
  gap: 10px;
`;

export const ListBlock = styled.div`
  width: 100%; 
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
`;

export const ListBlockIndex = styled.div`
`;

export const ListBlockContent = styled(Column)`
  gap: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

export const Indent = styled.div`
  margin-left: 15px;
`;
