import { FunctionComponent } from "react";
import {
  Column5, CustomLabel,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell,
  LocalPostCodeInput,
  LocalTextInputExtend, Note,
  SectionHeadingLarge
} from "../commonStyle"
import { isEmpty } from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";
import {SupportPlanFormProps} from "../types";
import {ItemTextInput} from "../../EmploymentConditionsFormPage/commonStyles";
import {LocalTextInput, NoteInputWrapper} from "../../../../job/pages/JobFormPage/commonStyle";
interface OrganizationSectionProps extends SupportPlanFormProps {
}

export const OrganizationSection: FunctionComponent<OrganizationSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
}) => {
  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>Ⅱ. 特定技能所属機関</SectionHeadingLarge>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 氏名又は名称 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>氏名又は名称</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="サンプル株式会社"
                value={supportPlan?.organizationOfAffiliationName ?? ""}
                error={isEmpty(supportPlan?.organizationOfAffiliationName)}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({
                    organizationOfAffiliationName: val,
                    supportSignatureAffiliationName: val
                  });
                }}
              />
              {isEmpty(supportPlan?.organizationOfAffiliationName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 氏名又は名称ふりがな */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>氏名又は名称ふりがな</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="さんぷるかぶしきがいしゃ"
                value={supportPlan?.organizationOfAffiliationNameFurigana ?? ""}
                error={isEmpty(supportPlan?.organizationOfAffiliationNameFurigana)}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({organizationOfAffiliationNameFurigana: val});
                }}
              />
              {isEmpty(supportPlan?.organizationOfAffiliationNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
        {/* 郵便番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>郵便番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>ハイフンを除いた半角数字で入力してください</Note>
              <LocalPostCodeInput
                value={supportPlan?.organizationOfAffiliationPostalCode ?? ""}
                onTextChange={(val) => {
                  onChangeSupportPlan?.({ organizationOfAffiliationPostalCode: val });
                }}
                onPostalCodeAddressIdChange={(val) =>
                  onChangeSupportPlan?.({ organizationOfAffiliationPostalCodeAddressId: val })
                }
                onPrefectureChange={(val) => {
                  onChangeSupportPlan?.(
                    { organizationOfAffiliationPrefecture: val }
                  );
                }}
                onCityChange={(val) => {
                  onChangeSupportPlan?.(
                    { organizationOfAffiliationCityWard: val }
                  );
                }}
                onTownChange={(val) => {
                  onChangeSupportPlan?.(
                    { organizationOfAffiliationTown: val }
                  );
                }}
                error={isEmpty(supportPlan?.organizationOfAffiliationPostalCode)}
                suppressErrorMessage
              />
              {isEmpty(supportPlan?.organizationOfAffiliationPostalCode) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 都道府県 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>都道府県</CustomLabel>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>郵便番号から自動で入力されます</Note>
              <LocalTextInput
                readOnly
                disabled
                value={
                  supportPlan?.organizationOfAffiliationPrefecture ?? ""
                }
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 市区 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>市区</CustomLabel>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>郵便番号から自動で入力されます</Note>
              <LocalTextInput
                readOnly
                disabled
                value={
                  supportPlan?.organizationOfAffiliationCityWard ?? ""
                }
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 町村 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>町村</CustomLabel>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>郵便番号から自動で入力されます</Note>
              <LocalTextInput
                readOnly
                disabled
                value={
                  supportPlan?.organizationOfAffiliationTown ?? ""
                }
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 番地 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>番地</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="1-2-3"
                value={supportPlan?.organizationOfAffiliationAddressNumber ?? ""}
                onTextChange={val => onChangeSupportPlan?.({ organizationOfAffiliationAddressNumber: val })}
                error={isEmpty(supportPlan?.organizationOfAffiliationAddressNumber)}
              />
              {isEmpty(supportPlan?.organizationOfAffiliationAddressNumber) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 建物名等 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>建物名等</CustomLabel>
          </LabelCell>
          <FieldCell>
            <ItemTextInput
              placeholder="サンプルビル 10F"
              value={supportPlan?.organizationOfAffiliationBuilding ?? ""}
              onTextChange={val => onChangeSupportPlan?.({ organizationOfAffiliationBuilding: val })}
            />
          </FieldCell>
        </GridRow>

        {/* 電話番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>電話番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="03-1234-5678"
                value={supportPlan?.organizationOfAffiliationPhoneNumber ?? ""}
                onTextChange={val => onChangeSupportPlan?.({ organizationOfAffiliationPhoneNumber: val })}
                error={isEmpty(supportPlan?.organizationOfAffiliationPhoneNumber)}
              />
              {isEmpty(supportPlan?.organizationOfAffiliationPhoneNumber) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default OrganizationSection;