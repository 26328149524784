import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Page from '../../components/Page';
import PersonalSettings from './PersonalSettings';

type PersonalSettingsPageProps = {
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  background: #FAF6F4;
`;

const LocalPage = styled(Page)`
  padding-top: 10px;
`;


const PersonalSettingsPage: FunctionComponent<PersonalSettingsPageProps> = ({
}) => {
    return (
      <LocalPage>
        <Container>
          <PersonalSettings/>                
        </Container>
      </LocalPage>
    );
};

export default PersonalSettingsPage;