import { FunctionComponent, InputHTMLAttributes, useEffect, useState } from "react";
import TextInput from "./TextInput";
import styled from "styled-components";
import Row from "./Row";

//Spec:
//https://www.figma.com/file/UwZXypeCGS6TGrveckqOOU/tokuty?type=design&node-id=3809-77770&mode=design&t=113laKbAFV2BEylV-4
interface CurrencyFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value?: number) => void;
  error?: boolean;
  allowNegative?: boolean;
} 

const Container = styled(Row)`
  align-items: center;
  gap: 5px;
`;

const LocalTextInput = styled(TextInput)`
  text-align: right;
  width: 73px;

`;

const CurrencyField: FunctionComponent<CurrencyFieldProps> = ({
  value,
  placeholder,
  onValueChange,
  error,
  allowNegative,
  ...props
}) => {
  const formatCurrency = (value: string) => {
    const regex = allowNegative? /[^0-9.-]/g : /[^0-9.]/g;
    const cleanedValue = parseFloat(value.replace(regex, ''));
    return new Intl.NumberFormat().format(cleanedValue);
  };
  const [displayedValue, setDisplayedValue] = useState('');

  const handleChange = (newValue: string) => {;
    if (!!!newValue) {
      setDisplayedValue('');
      onValueChange?.(undefined);
      return;
    }

    const regex = allowNegative ? /^(-?\d{1,7})|(-)$/ : /^\d{1,7}$/;

    if (regex.test(newValue.replace(/,/g, ''))) {

      if (newValue === '-') {
        setDisplayedValue(newValue);
        onValueChange?.(0);
        return;
      }

      const numericValue = newValue.replace(/,/g, '');
      const newDisplayedValue = formatCurrency(numericValue);

      setDisplayedValue(newDisplayedValue);
      onValueChange?.(Number(numericValue));
    } 
  };

  useEffect(() => {
    if (value == null) 
      return;

    setDisplayedValue(value ? formatCurrency(String(value)) : '');
  }, [value])

  return (
      <Container>
        <LocalTextInput 
          value={displayedValue}
          onTextChange={handleChange}
          placeholder={placeholder}
          error={error}
          {...props}
        />
      </Container>
  )
}

export default CurrencyField;