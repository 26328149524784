import { JobCommuteMethod, JobJlptLevel } from "../enums/job";

export const COMMUTE_METHOD_ITEMS = [
    {
        label: 'job.walk',
        value: JobCommuteMethod.Walk,
    },
    {
        label: 'job.bicycle',
        value: JobCommuteMethod.Bicycle,
    },
    {
        label: 'job.public_transport',
        value: JobCommuteMethod.PublicTransport,
    },
    {
        label: 'job.automobile',
        value: JobCommuteMethod.Automobile,
    },
];


export const CONVERSATION_SKILLS_OPTIONS = [
    {
        label: 'job.basic_conversation_skills',
        value: '0',
    },
    {
        label: 'job.daily_conversation_skills',
        value: '1',
    },
    {
        label: 'job.fluent_conversation_skills',
        value: '2',
    },
];

export const GENDER_ITEMS = [
    {
        label: 'core.male',
        value: 'male',
    },
    {
        label: 'core.female',
        value: 'female',
    }
];

export const LANGUAGE_LEVEL_ITEMS = [
    {
        label: 'core.jlpt_levels.n1',
        value: JobJlptLevel.N1,
    },
    {
        label: 'core.jlpt_levels.n2',
        value: JobJlptLevel.N2,
    },
    {
        label: 'core.jlpt_levels.n3',
        value: JobJlptLevel.N3,
    },
    {
        label: 'core.jlpt_levels.n4',
        value: JobJlptLevel.N4,
    },
    {
        label: 'core.jlpt_levels.n5',
        value: JobJlptLevel.N5,
    },
]

export const MONTH_ITEMS = [
    {
        label: 'calendar.month.january',
        value: 'january',
    },
    {
        label: 'calendar.month.february',
        value: 'february',
    },
    {
        label: 'calendar.month.march',
        value: 'march',
    },
    {
        label: 'calendar.month.april',
        value: 'april',
    },
    {
        label: 'calendar.month.may',
        value: 'may',
    },
    {
        label: 'calendar.month.june',
        value: 'june',
    },
    {
        label: 'calendar.month.july',
        value: 'july',
    },
    {
        label: 'calendar.month.august',
        value: 'august',
    },
    {
        label: 'calendar.month.september',
        value: 'september',
    },
    {
        label: 'calendar.month.october',
        value: 'october',
    },
    {
        label: 'calendar.month.november',
        value: 'november',
    },
    {
        label: 'calendar.month.december',
        value: 'december',
    },
];

export const NATIONALITY_ITEMS = [
        {
            label: 'country.VN',
            value: 'VN',
        },
        {
            label: 'country.NP',
            value: 'NP',
        },
        {
            label: 'country.ID',
            value: 'ID',
        },
        {
            label: 'country.PH',
            value: 'PH',
        },
        {
            label: 'country.KH',
            value: 'KH',
        },
        {
            label: 'country.MM',
            value: 'MM',
        },
        {
            label: 'country.TH',
            value: 'TH',
        },
        {
            label: 'country.MN',
            value: 'MN',
        },
        {
            label: 'country.BD',
            value: 'BD',
        },
        {
            label: 'country.PK',
            value: 'PK',
        },
        {
            label: 'country.LK',
            value: 'LK',
        },
        {
            label: 'country.UZ',
            value: 'UZ',
        },
        {
            label: 'country.other',
            value: 'other',
        },
    ]
;

export const DAY_OF_MONTH_ITEMS = [
    {
        label: '',
        value: ''
    },
    {
        label: 'job.day_of_month.1',
        value: '1',
    },
    {
        label: 'job.day_of_month.2',
        value: '2',
    },
    {
        label: 'job.day_of_month.3',
        value: '3',
    },
    {
        label: 'job.day_of_month.4',
        value: '4',
    },
    {
        label: 'job.day_of_month.5',
        value: '5',
    },
    {
        label: 'job.day_of_month.6',
        value: '6',
    },
    {
        label: 'job.day_of_month.7',
        value: '7',
    },
    {
        label: 'job.day_of_month.8',
        value: '8',
    },
    {
        label: 'job.day_of_month.9',
        value: '9',
    },
    {
        label: 'job.day_of_month.10',
        value: '10',
    },
    {
        label: 'job.day_of_month.11',
        value: '11',
    },
    {
        label: 'job.day_of_month.12',
        value: '12',
    },
    {
        label: 'job.day_of_month.13',
        value: '13',
    },
    {
        label: 'job.day_of_month.14',
        value: '14',
    },
    {
        label: 'job.day_of_month.15',
        value: '15',
    },
    {
        label: 'job.day_of_month.16',
        value: '16',
    },
    {
        label: 'job.day_of_month.17',
        value: '17',
    },
    {
        label: 'job.day_of_month.18',
        value: '18',
    },
    {
        label: 'job.day_of_month.19',
        value: '19',
    },
    {
        label: 'job.day_of_month.20',
        value: '20',
    },
    {
        label: 'job.day_of_month.21',
        value: '21',
    },
    {
        label: 'job.day_of_month.22',
        value: '22',
    },
    {
        label: 'job.day_of_month.23',
        value: '23',
    },
    {
        label: 'job.day_of_month.24',
        value: '24',
    },
    {
        label: 'job.day_of_month.25',
        value: '25',
    },
    {
        label: 'job.day_of_month.26',
        value: '26',
    },
    {
        label: 'job.day_of_month.27',
        value: '27',
    },
    {
        label: 'job.day_of_month.28',
        value: '28',
    },
    {
        label: 'job.day_of_month.end',
        value: 'end',
    }
];

export const SOCIAL_INSURACE_COVERAGE_ITEMS = [
    {
        label: "job.social_insurance_coverage.corporate_pension",
        value: "corporate_pension",
    },
    {
        label: "job.social_insurance_coverage.health_insurance",
        value: "health_insurance",
    },
    {
        label: "job.social_insurance_coverage.employment_insurance",
        value: "employment_insurance",
    },
    {
        label: "job.social_insurance_coverage.workers_accident_insurance",
        value: "workers_accident_insurance",
    },
    {
        label: "job.social_insurance_coverage.national_pension",
        value: "national_pension",
    },
    {
        label: "job.social_insurance_coverage.national_health_insurance",
        value: "national_health_insurance",
    },
    {
        label: "job.social_insurance_coverage.other",
        value: "other",
    }
]