import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { MOBILE_DEVICE } from '../constants/styles';

type ActionBarProps = HTMLAttributes<HTMLDivElement> & {
};

const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 60px;
  padding: 0 100px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  z-index: 5;
  
  > *:not(:first-child) {
    margin-left: 20px;
  }
  
  @media ${MOBILE_DEVICE} {
    position: unset;
    flex-direction: column;
    height: unset;
    padding: 20px;
    background: none;
    border: none;

    > *:not(:first-child) {
      margin-top: 20px;
      margin-left: unset;
    }
  }
`;

const ActionBar: FunctionComponent<ActionBarProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default ActionBar;