import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import moment from 'moment';

import { Job } from '../job/types/api';

import { LanguageCode } from './enums/language';
import { Month } from './types/api';
import { SelectOption } from './components/Select';
import { JobSalaryType } from '../job/enums/job';

export const apiUtil = applyCaseMiddleware(
    axios.create({
        baseURL: process.env.REACT_APP_API_HOST,
        withCredentials: true,
    }),
    {
        ignoreHeaders: true,
        preservedKeys: [
            '_destroy',
        ],
    },
);

export const wait = (seconds: number) => new Promise<void>(
    (resolve) => {
        setTimeout(() => {
            resolve();
        }, seconds * 1000);
    },
);

export const isEmptyObject = (obj: object) => Object.keys(obj).length === 0;

export const isValidPostalCode = (code: string) => /^\d{7}$/.test(code);

export const isValidEmailAddress = (email?: string) => {
    if (!email) 
        return false;
    
    return /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
}

export const getJobLocation = (job: Job, langCode: string): string => {
    return (
        langCode === LanguageCode.Japanese
        ? `${job.jaPrefecture} ${job.jaCity} ${job.jaTown}` 
        : `${job.enPrefecture} ${job.enCity} ${job.enTown}`
    )
}

export const convertMonthsToOptions = (months?: Month[]): SelectOption[] => {
    if (!months || months.length === 0) 
        return [];

    return months.map(month => ({
        label: `core.months.${month.tkey}`,
        value: String(month.id),
    }));
}

export const getSalary = (job: Job): string => {
    const isMontly = job.salaryType === JobSalaryType.Monthly;
    const salary = isMontly? job.grossSalary : job.hourlyDailySalary;
    const upperLimit = isMontly? job.grossSalaryUpperLimit : job.hourlyDailySalaryUpperLimit;
    return upperLimit ? `${salary?.toLocaleString()} ~ ${upperLimit.toLocaleString()}` : salary?.toString() ?? ""
};

export const formatTime = (time: Date | string) => {
    return moment(time).format('YYYY/MM/DD HH:mm');
};

export const formatDate = (date?: Date | string) => {
    return date ? moment(date).format('YYYY/MM/DD') : '';
}

export const formatAmount = (amount?: number | string) => {
    if (!amount || isNaN(Number(amount))) return "";
  
    return Number(amount).toLocaleString();
  };

export const convertBytesToMB = (bytes: number, decimals = 1): string => {
    const MB = bytes / (1024 * 1024);
    return MB.toFixed(decimals);
  }

export const decodeHTMLEntities = (text: string | undefined): string | undefined => {
    if (!text)
        return;

    const txtArea = document.createElement("textarea");
    txtArea.innerHTML = text;
    return txtArea.value;
}

export const getLanguageIdFromCode = (languageCode: string | undefined): number | undefined => {
    switch (languageCode) {
        case LanguageCode.Japanese: return 1;
        case LanguageCode.English: return 2;
        case LanguageCode.Vietnamese: return 3;
        case LanguageCode.Indonesian: return 4;
        case LanguageCode.Myanmar: return 5;
        case LanguageCode.Nepali: return 6;
        default: return undefined;
    }
};

export const getLanguageCodeFromId = (languageId: number | undefined): string | undefined => {
    switch (languageId) {
        case 1: return LanguageCode.Japanese;
        case 2: return LanguageCode.English;
        case 3: return LanguageCode.Vietnamese;
        case 4: return LanguageCode.Indonesian;
        case 5: return LanguageCode.Myanmar;
        case 6: return LanguageCode.Nepali;
        default: return undefined;
    }
};

export const compareDate = (date1: Date, date2: Date) => {
    const time1 = date1.getTime();
    const time2 = date2.getTime();
    if (time1 < time2) {
        return -1;
    } else if (time1 > time2) {
        return 1;
    }
    return 0;
}

export const isEmpty = (value: any): boolean => {
    if (value == null)
        return true;

    //If an object, check if it has any keys
    if (value && typeof value === 'object') {
        return Object.keys(value).length === 0;
    }

    //If an array, check if it has any items
    if (value && Array.isArray(value)) {
        return value.length === 0;
    }

    //0 is not considered empty here
    if (value === 0)
        return false;

    return !!!value;
}

export const getDefaultThumbnailURL = (jobTypeId?: number) => {
    return jobTypeId ? `/images/job-type-thumbnails/${jobTypeId}.webp` : "";
}

//If the number is a decimal but the fractional part is 0, return the number as an integer
//e.g. 1.0 -> 1
export const normalizeDecimalNumber = (num?: number | string | null): string => {
    if (!num || Number.isNaN(Number(num))) 
        return "";

    if (Number(num) % 1 === 0) 
      return Number(num).toFixed(0); 

    return String(num);
  }

  export const isValidDateString = (dateString?: string | null): boolean => {
    if (!dateString) 
        return false;

    const date = new Date(dateString);   
    return !isNaN(date.getTime());
}

export const pad0 = (num: number | null | undefined, digits = 2): string => {
    if (num == null) 
        return '';
    
    return num.toString().padStart(digits, '0');
}

export const safeGet = <T extends object>(obj: T, key: keyof any | null | undefined): any => {
    if (isKeyOf(obj, key)) 
        return obj[key];

    return null;
}


export const isKeyOf = <T extends object>(obj: T, key: keyof any | null | undefined): key is keyof T => {
if (!key) return false;

return key in obj;
};
