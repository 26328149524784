import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MOBILE_DEVICE } from '../constants/styles';

type FilterButtonProps = HTMLAttributes<HTMLLabelElement> & {
    checked?: boolean;
    onToggle?: () => void;
};

const Input = styled.input`
    display: none !important;
`;

const Button = styled.button`
  height: 40px;
  padding: 0 9px;
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;

  &:hover {
    color: #FF730D;
    background: #FFDFD1;
  }

  &:active {
    color: #FF730D;
    background: #FFDFD1;
    border: 3px solid #FFC194;
  }

  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const Container = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  ${Input}:checked ~ ${Button} {
    border: 3px solid #FFC194;
  }

  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const FilterButton: FunctionComponent<FilterButtonProps> = ({
    checked,
    onToggle,
    ...props
}) => {
    const { t } = useTranslation();

    const handleClick = () => {
        onToggle && onToggle();
    };

    return (
        <Container {...props}>
            <Input
                type="checkbox"
                checked={checked}
            />
            <Button onClick={handleClick}>{t('core.filter')}</Button>
        </Container>
    );
};

export default FilterButton;