import { FunctionComponent } from "react";
import {
  Column5,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell,
  LocalPostCodeInput,
  LocalTextInput,
  Note,
  NoteInputWrapper,
  SectionHeadingLarge,
} from "../commonStyle";
import Label from "../../../../core/components/Label";
import { JobFormSectionProps } from "../type";
import { useTranslation } from "react-i18next";
import { LanguageCode } from "../../../../core/enums/language";
import { isEmpty } from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";

interface PlaceOfEmoploymentSectionProps extends JobFormSectionProps {}

const PlaceOfEmoploymentSection: FunctionComponent<
  PlaceOfEmoploymentSectionProps
> = ({ job, onChange }) => {
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });
  const { t : tCore } = useTranslation('translation', { keyPrefix: 'core' });
  const { i18n } = useTranslation();
  const isLangJapanese = i18n.language === LanguageCode.Japanese;

  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>{tJob('work_location')}</SectionHeadingLarge>
      </HeadingContainer>

      <GridRowsContainer>

        {/* 郵便番号 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore('postal_code')}</Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>{tCore('input_prompt.halfwidth_number_without_hyphen')}</Note>
              <LocalPostCodeInput
                value={job?.postalCode ?? ""}
                onTextChange={(val) => {
                  onChange?.({ postalCode: val });
                }}
                onPostalCodeAddressIdChange={(val) =>
                  onChange?.({ postalCodeAddressId: val })
                }
                onPrefectureChange={(val) => {
                  onChange?.(
                    isLangJapanese
                      ? { jaPrefecture: val }
                      : { enPrefecture: val }
                  );
                }}
                onCityChange={(val) => {
                  onChange?.(
                    isLangJapanese ? { jaCity: val } : { enCity: val }
                  );
                }}
                onTownChange={(val) => {
                  onChange?.(
                    isLangJapanese ? { jaTown: val } : { enTown: val }
                  );
                }}
                error={isEmpty(job?.postalCode)}
                suppressErrorMessage
              />
              { isEmpty(job?.postalCode) &&
                <ErrorMessage>{tCore('input_prompt.text_field')}</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 都道府県 */}
        <GridRow>
          <LabelCell>
            <Label>{tCore('prefecture')}</Label>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>{tJob('automatically_entered_from_postal_code')}</Note>
              <LocalTextInput
                readOnly
                disabled
                value={
                  isLangJapanese
                    ? job?.jaPrefecture ?? ""
                    : job?.enPrefecture ?? ""
                }
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 市区 */}
        <GridRow>
          <LabelCell>
            <Label>{tCore('city')}</Label>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>{tJob('automatically_entered_from_postal_code')}</Note>
              <LocalTextInput
                readOnly
                disabled
                value={isLangJapanese ? job?.jaCity ?? "" : job?.enCity ?? ""}
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 町村 */}
        <GridRow>
          <LabelCell>
            <Label>{tCore('town_name')}</Label>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>{tJob('automatically_entered_from_postal_code')}</Note>
              <LocalTextInput
                readOnly
                disabled
                value={isLangJapanese ? job?.jaTown ?? "" : job?.enTown ?? ""}
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 番地 */}
        <GridRow>
          <LabelCell>
            <Label>{tCore('locality')}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInput
              placeholder="1-15-23"
              value={job?.addressNumber ?? ""}
              onTextChange={(val) => onChange?.({ 
                addressNumber: val,
                ecEmployerAddressNumber: val,
              })}
            />
          </FieldCell>
        </GridRow>

        {/* 建物名等 */}
        <GridRow>
          <LabelCell>
            <Label>{tCore('building_etc')}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInput 
              placeholder="トクティービル3F" 
              value={job?.buildingName ?? ""}
              onTextChange={(val) => onChange?.({ 
                buildingName: val,
                ecEmployerBuilding: val,
              })}
            />
          </FieldCell>
        </GridRow>

        {/* 事務所名（日本語） */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('business_name_in_ja')}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInput 
              placeholder="川崎工場" 
              value={job?.ecPlaceOfEmploymentJaOfficeName ?? ""}
              onTextChange={(val) => onChange?.({ ecPlaceOfEmploymentJaOfficeName: val })}
            />
          </FieldCell>
        </GridRow>

        {/* 事務所名（英語） */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('business_name_in_en')}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInput 
              placeholder="Kawasaki Koujou" 
              value={job?.ecPlaceOfEmploymentEnOfficeName ?? ""}
              onTextChange={(val) => onChange?.({ ecPlaceOfEmploymentEnOfficeName: val })}
            />
          </FieldCell>
        </GridRow>

        {/* 電話番号 */}
        <GridRow>
          <LabelCell>
            <Label>{tCore('phone_number')}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInput 
              placeholder="03-1234-5678" 
              value={job?.ecPlaceOfEmploymentPhoneNumber ?? ""}
              onTextChange={(val) => onChange?.({ ecPlaceOfEmploymentPhoneNumber: val })}
            />
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default PlaceOfEmoploymentSection;
