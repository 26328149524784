import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import InterviewCalendar from './InterviewCalendar';
import Button from '../../core/components/Button';
import Modal from '../../core/components/Modal';
import Text from '../../core/components/Text';

interface InterviewCalendarModalProps {
    open?: boolean;
    readonly?: boolean;
    creationMode?: boolean;
    defaultValue?: Date[];
    onClose?: () => void;
    onConfirm?: (interviewTimes: Date[]) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #FFFFFF;
  overflow: auto;
  
  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 24px;
  
  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
`;

const InterviewCalendarModal: FunctionComponent<InterviewCalendarModalProps> = ({
    open,
    readonly,
    creationMode,
    defaultValue,
    onClose,
    onConfirm,
}) => {
    const [interviewTimes, setInterviewTimes] = useState<Date[]>(defaultValue || []);
    const [interviewTime, setInterviewTime] = useState<Date|null>(null);
    const { t } = useTranslation();

    const handleToggle = (date: Date) => {
        if (!readonly) {
            const index = interviewTimes.findIndex(time => time.getTime() === date.getTime())
            if (index !== -1) {
                const times = [...interviewTimes]
                times.splice(index, 1)
                setInterviewTimes(times)
            } else if (interviewTimes.length < 20) {
                setInterviewTimes([...interviewTimes, date])
            }
        }
    };

    const handlePick = (date: Date) => {
        if (readonly) {
            setInterviewTime(date);
        }
    };

    const handleConfirm = () => {
        if (onConfirm) {
            if (readonly) {
                interviewTime && onConfirm([interviewTime]);
            } else {
                onConfirm(interviewTimes);
            }
        }
    };

    return (
        <Modal open={open}>
            <Container>
                <CloseContainer>
                    <img
                        alt="close"
                        src="/images/icon-close.svg"
                        onClick={onClose}
                    />
                </CloseContainer>
                <Text>
                    {t(creationMode ? 'proposal.suggested_interview_dates_description' : 'proposal.suggest_dates')}
                </Text>
                <InterviewCalendar
                    readonly={readonly}
                    value={interviewTimes}
                    onToggle={handleToggle}
                    onPick={handlePick}
                />
                <Button
                    style={{ minHeight: 40, marginTop: 30 }}
                    disabled={readonly
                        ? !interviewTime
                        : interviewTimes.length < (creationMode ? 3 : 1)}
                    onClick={handleConfirm}
                >
                    {t('core.confirm')}
                </Button>
            </Container>
        </Modal>
    );
};

export default InterviewCalendarModal;