import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Section, SectionContent, SectionIndex } from "./styles";
import { Column5, InputValue, Row, Item10, Segment, Item5 } from "../../commonStyles";
import { TemplateSectionProps } from "../../types";
import { formatAmount } from "../../util";
import { Trans, useTranslation } from "react-i18next";


interface BenefitsSectionProps extends TemplateSectionProps {
}

const BenefitsSection: FunctionComponent<BenefitsSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang }
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string, obj?: object): string => tFixedToTransLang(key, obj).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;

  const [benefit1Name, setBenefit1Name] = useState<string>("");
  const [benefit1NameTrans, setBenefit1NameTrans] = useState<string>("");
  const [benefit1Amount, setBenefit1Amount] = useState<string>("");
  const [benefit1CalcMethod, setBenefit1CalcMethod] = useState<string>("");
  const [benefit1CalcMethodTrans, setBenefit1CalcMethodTrans] = useState<string>("");
  const [benefit2Name, setBenefit2Name] = useState<string>("");
  const [benefit2NameTrans, setBenefit2NameTrans] = useState<string>("");
  const [benefit2Amount, setBenefit2Amount] = useState<string>("");
  const [benefit2CalcMethod, setBenefit2CalcMethod] = useState<string>("");
  const [benefit2CalcMethodTrans, setBenefit2CalcMethodTrans] = useState<string>("");
  const [benefit3Name, setBenefit3Name] = useState<string>("");
  const [benefit3NameTrans, setBenefit3NameTrans] = useState<string>("");
  const [benefit3Amount, setBenefit3Amount] = useState<string>("");
  const [benefit3CalcMethod, setBenefit3CalcMethod] = useState<string>("");
  const [benefit3CalcMethodTrans, setBenefit3CalcMethodTrans] = useState<string>("");
  const [benefit4Name, setBenefit4Name] = useState<string>("");
  const [benefit4NameTrans, setBenefit4NameTrans] = useState<string>("");
  const [benefit4Amount, setBenefit4Amount] = useState<string>("");
  const [benefit4CalcMethod, setBenefit4CalcMethod] = useState<string>("");
  const [benefit4CalcMethodTrans, setBenefit4CalcMethodTrans] = useState<string>("");
  const [fixedOvertimeAllowanceName, setFixedOvertimeAllowanceName] = useState<string>("");
  const [fixedOvertimeAllowanceNameTrans, setFixedOverTimeAllowanceNameTrans] = useState<string>("");
  const [fixedOvertimeAllowanceAmount, setFixedOvertimeAllowanceAmount] = useState<string>("");
  const [fixedOvertimeAllowancePaymentReq1, setFixedOvertimeAllowancePaymentReq1] = useState<string>("");
  const [fixedOvertimeAllowancePaymentReq2, setFixedOvertimeAllowancePaymentReq2] = useState<string>("");
  

  const BenefitAmount = ({ children }: { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>;

  const BenefitCalcMethod = ({ children }: { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>;

  const FixedOvertimeAllowanceAmount = ({ children }: { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>;

  const FixedOvertimeAllowancePaymentReq = ({ children }: { children?: ReactNode }) =>
    <Item5><InputValue>{children}</InputValue></Item5>;

  useEffect(() => {
    if (!ec) 
      return;

    setBenefit1Name(ec.benefitsBenefit_1Name);
    setBenefit1NameTrans(transHandler.getTranslation("benefitsBenefit_1Name"));
    setBenefit1Amount(formatAmount(ec.benefitsBenefit_1Amount));
    setBenefit1CalcMethod(ec.benefitsBenefit_1CalculationMethod);
    setBenefit1CalcMethodTrans(transHandler.getTranslation("benefitsBenefit_1CalculationMethod"));
    setBenefit2Name(ec.benefitsBenefit_2Name);
    setBenefit2NameTrans(transHandler.getTranslation("benefitsBenefit_2Name"));
    setBenefit2Amount(formatAmount(ec.benefitsBenefit_2Amount));
    setBenefit2CalcMethod(ec.benefitsBenefit_2CalculationMethod);
    setBenefit2CalcMethodTrans(transHandler.getTranslation("benefitsBenefit_2CalculationMethod"));
    setBenefit3Name(ec.benefitsBenefit_3Name);
    setBenefit3NameTrans(transHandler.getTranslation("benefitsBenefit_3Name"));
    setBenefit3Amount(formatAmount(ec.benefitsBenefit_3Amount));
    setBenefit3CalcMethod(ec.benefitsBenefit_3CalculationMethod);
    setBenefit3CalcMethodTrans(transHandler.getTranslation("benefitsBenefit_3CalculationMethod"));
    setBenefit4Name(ec.benefitsBenefit_4Name);
    setBenefit4NameTrans(transHandler.getTranslation("benefitsBenefit_4Name"));
    setBenefit4Amount(formatAmount(ec.benefitsBenefit_4Amount));
    setBenefit4CalcMethod(ec.benefitsBenefit_4CalculationMethod);
    setBenefit4CalcMethodTrans(transHandler.getTranslation("benefitsBenefit_4CalculationMethod"));
    setFixedOvertimeAllowanceName(ec.benefitsFixedOvertimePayName);
    setFixedOverTimeAllowanceNameTrans(transHandler.getTranslation("benefitsFixedOvertimePayName"));
    setFixedOvertimeAllowanceAmount(formatAmount(ec.benefitsFixedOvertimePayAmount));
    setFixedOvertimeAllowancePaymentReq1(ec.benefitsFixedOvertimePayPaymentRequirement_1?.toString() ?? "");
    setFixedOvertimeAllowancePaymentReq2(ec.benefitsFixedOvertimePayPaymentRequirement_2?.toString() ?? "");

  }, [ec]);

  return (
    <Section>
      <SectionIndex>2.</SectionIndex>
      <SectionContent>
        <Segment>
          <span>諸手当の額及び計算方法（時間外労働の割増賃金は除く。）</span><br/>
          <span>{tFixed("benefits_excl_overtime")}</span>
        </Segment>
        <Segment>
          <Column5>
            <Row>
              <Item10>(a)（</Item10>
              <Item10>
                <InputValue>{benefit1Name}</InputValue>
              </Item10>
              <Item10>
                <InputValue>{benefit1Amount}</InputValue>
              </Item10>
              <Item10>円／計算方法：</Item10>
              <Item10>
                <InputValue>{benefit1CalcMethod}</InputValue>
              </Item10>
              <Item10>）</Item10>
            </Row>
            <Row>
              <Item10>(b)（</Item10>
              <Item10>
                <InputValue>{benefit2Name}</InputValue>
              </Item10>
              <Item10>
                <InputValue>{benefit2Amount}</InputValue>
              </Item10>
              <Item10>円／計算方法：</Item10>
              <Item10>
                <InputValue>{benefit2CalcMethod}</InputValue>
              </Item10>
              <Item10>）</Item10>
            </Row>
            <Row>
              <Item10>(c)（</Item10>
              <Item10>
                <InputValue>{benefit3Name}</InputValue>
              </Item10>
              <Item10>
                <InputValue>{benefit3Amount}</InputValue>
              </Item10>
              <Item10>円／計算方法：</Item10>
              <Item10>
                <InputValue>{benefit3CalcMethod}</InputValue>
              </Item10>
              <Item10>）</Item10>
            </Row>
            <Row>
              <Item10>(d)（</Item10>
              <Item10>
                <InputValue>{benefit4Name}</InputValue>
              </Item10>
              <Item10>
                <InputValue>{benefit4Amount}</InputValue>
              </Item10>
              <Item10>円／計算方法：</Item10>
              <Item10>
                <InputValue>{benefit4CalcMethod}</InputValue>
              </Item10>
              <Item10>）</Item10>
            </Row>
          </Column5>
        </Segment>
        <Segment>
        <Column5>
          <Row>
            <Item10>(a) (</Item10>
            <Item10>
              <InputValue>{benefit1NameTrans}</InputValue>
            </Item10>
            <Trans
              i18nKey={getTKey("benefit_amount")}
              tOptions={tOptions}
              values={{
                amount: benefit1Amount,
              }}
              components={{
                seg: <Item10 />,
                amount: <BenefitAmount />,
              }}
            />
            <span>/</span>
            <Trans
              i18nKey={getTKey("benefit_calc_method")}
              tOptions={tOptions}
              values={{
                method: benefit1CalcMethodTrans
              }}
              components={{
                seg: <Item10 />,
                method: <BenefitCalcMethod />,
              }}
            />
            <Item10>)</Item10>
          </Row>
          <Row>
            <Item10>(b) (</Item10>
            <Item10>
              <InputValue>{benefit2NameTrans}</InputValue>
            </Item10>
            <Trans
              i18nKey={getTKey("benefit_amount")}
              tOptions={tOptions}
              values={{
                amount: benefit2Amount,
              }}
              components={{
                seg: <Item10 />,
                amount: <BenefitAmount />,
              }}
            />
            <span>/</span>
            <Trans
              i18nKey={getTKey("benefit_calc_method")}
              tOptions={tOptions}
              values={{
                method: benefit2CalcMethodTrans
              }}
              components={{
                seg: <Item10 />,
                method: <BenefitCalcMethod />,
              }}
            />
            <Item10>)</Item10>
          </Row>
          <Row>
            <Item10>(c) (</Item10>
            <Item10>
              <InputValue>{benefit3NameTrans}</InputValue>
            </Item10>
            <Trans
              i18nKey={getTKey("benefit_amount")}
              tOptions={tOptions}
              values={{
                amount: benefit3Amount,
              }}
              components={{
                seg: <Item10 />,
                amount: <BenefitAmount />,
              }}
            />
            <span>/</span>
            <Trans
              i18nKey={getTKey("benefit_calc_method")}
              tOptions={tOptions}
              values={{
                method: benefit3CalcMethodTrans
              }}
              components={{
                seg: <Item10 />,
                method: <BenefitCalcMethod />,
              }}
            />
            <Item10>)</Item10>
          </Row>
          <Row>
            <Item10>(d) (</Item10>
            <Item10>
              <InputValue>{benefit4NameTrans}</InputValue>
            </Item10>
            <Trans
              i18nKey={getTKey("benefit_amount")}
              tOptions={tOptions}
              values={{
                amount: benefit4Amount,
              }}
              components={{
                seg: <Item10 />,
                amount: <BenefitAmount />,
              }}
            />
            <span>/</span>
            <Trans
              i18nKey={getTKey("benefit_calc_method")}
              tOptions={{ lng: transLang}}
              values={{
                method: benefit4CalcMethodTrans
              }}
              components={{
                seg: <Item10 />,
                method: <BenefitCalcMethod />,
              }}
            />
            <Item10>)</Item10>
          </Row>
        </Column5>
        </Segment>
        <Segment>
          <span>【固定残業代がある場合】</span>
        </Segment>
        <Segment>
           <Column5>           
            <Row>
              <Item10>(e) (</Item10>
              <Item10>
                <InputValue>{fixedOvertimeAllowanceName}</InputValue>
                <span>手当</span>
              </Item10>
              <Item10>
                <InputValue>{fixedOvertimeAllowanceAmount}</InputValue>
              </Item10>
              <Item10>円</Item10>
              <Item10>)</Item10>
            </Row>
            <span>
              <Item5>支給要件：時間外労働の有無にかかわらず、</Item5>
              <Item5>{fixedOvertimeAllowancePaymentReq1}</Item5>
              <Item5>時間分の時間外手当として支給。</Item5>
              <Item5>{fixedOvertimeAllowancePaymentReq2}</Item5>
              <Item5>時間を超える時間外労働分についての割増賃金は追加で支給。</Item5>
            </span>
          </Column5>
        </Segment>
        <Segment>
          <span>{tFixed("fixed_overtime_pay")}</span>
        </Segment>
        <Segment>
           <Column5>           
            <Row>
              <Item10>(e) (</Item10>
              <Item10>{tFixed("allowance")}</Item10>
              <Item10>
                <InputValue>{fixedOvertimeAllowanceNameTrans}</InputValue>
              </Item10>
              <Trans
                i18nKey={getTKey("fixed_overtime_pay_amount")}
                tOptions={tOptions}
                values={{
                  amount: fixedOvertimeAllowanceAmount
                }}
                components={{
                  seg: <Item10 />,
                  amount: <FixedOvertimeAllowanceAmount />,
                }}
              />
              <Item10>)</Item10>
            </Row>
            <span>
              <Trans
                i18nKey={getTKey("fixed_overtime_pay_requirements")}
                tOptions={tOptions}
                values={{
                  hour1: fixedOvertimeAllowancePaymentReq1,
                  hour2: fixedOvertimeAllowancePaymentReq2
                }}
                components={{
                  seg: <Item5 />,
                  hour: <FixedOvertimeAllowancePaymentReq />,
                }}
              />
            </span> 
          </Column5>
        </Segment>
        
      </SectionContent>
    </Section>
  )
}

export default BenefitsSection;