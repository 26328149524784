import { Job } from "../../../../job/types/api";
import {EmploymentConditionExtended, SupportPlan} from "../../../types";
import {Candidate} from "../../../../candidate/types/api";
import {Gender, HousingEnsureSituation} from "../../../../core/enums/supportPlans";
import {Company} from "../../../../core/types/api";
import {
  APPROPRIATE, APPROPRIATE_AFTER_CONTRACT,
  APPROPRIATE_INVOLUNTARILY_LEAVE,
  NON_TITLE,
  NOT_OWN_HOUSING, QUARTERLY
} from "../../../../core/constants/defaultValue";

type FormInitConfig = {
  company: Company;
  job: Job;
  candidate: Candidate;
  ec?: EmploymentConditionExtended;
};

export const prepareInitializedSpFormData = ({
  company,
  job,
  candidate,
  ec,
}: FormInitConfig): Partial<SupportPlan> => {
  const initVals: Partial<SupportPlan> = {};

  /*** Target section (支援対象者) ***/
  //氏名（名前）
  initVals.targetFirstName= candidate?.enFirstName ?? "";

  //氏名（名字）
  initVals.targetLastName = candidate?.enLastName ?? "";

  //支援対象者人数
  initVals.targetNumberOfPeople = 0;

  //性別
  if (candidate?.gender === "male") initVals.targetGender = Gender.male;
  if (candidate?.gender === "female") initVals.targetGender = Gender.female;

  //国籍・地域
  initVals.targetNationalityId = candidate?.nationalityId ?? null;

  /*** Organization section (特定技能所属機関) ***/
  //氏名又は名称
  initVals.organizationOfAffiliationName = job.spOrganizationOfAffiliationName ?? company.name ?? "";

  //郵便番号
  initVals.organizationOfAffiliationPostalCode = job.spOrganizationOfAffiliationPostalCode ?? company.postalCode ?? "";

  //都道府県
  initVals.organizationOfAffiliationPrefecture =job.spOrganizationOfAffiliationPrefecture ?? company.prefecture ?? "";

  //市区
  initVals.organizationOfAffiliationCityWard = job.spOrganizationOfAffiliationCityWard ?? company.cityWard ?? "";

  //町村
  initVals.organizationOfAffiliationTown = job.spOrganizationOfAffiliationTown ?? company.town ?? "";

  //番地
  initVals.organizationOfAffiliationAddressNumber = job.spOrganizationOfAffiliationAddressNumber ?? company.addressNumber ?? "";

  //建物名等
  initVals.organizationOfAffiliationBuilding = job.spOrganizationOfAffiliationBuilding ?? company.buildingName ?? "";

  //電話番号
  initVals.organizationOfAffiliationPhoneNumber = job.spOrganizationOfAffiliationPhoneNumber ?? company.phoneNumber ?? "";

  /*** Support Organization Base Section (登録支援機関（基本情報）) ***/
  //登録番号
  initVals.supportOrganizationBaseRegistrationNumberPrefix = job.spSupportOrganizationBaseRegistrationNumberPrefix ?? "23";
  initVals.supportOrganizationBaseRegistrationNumberSuffix = job.spSupportOrganizationBaseRegistrationNumberSuffix ?? "008552";

  //登録年月日
  initVals.supportOrganizationBaseRegistrationDate = job.spSupportOrganizationBaseRegistrationDate ?? "2023-07-07";

  //支援業務を開始する予定年月日
  initVals.supportOrganizationBaseScheduledStartDate = job.spSupportOrganizationBaseScheduledStartDate ?? "2023-04-15";

  //氏名又は名称
  initVals.supportOrganizationBaseName = job.spSupportOrganizationBaseName ?? "トクティー株式会社";

  //氏名又は名称ふりがな
  initVals.supportOrganizationBaseNameFurigana = job.spSupportOrganizationBaseNameFurigana ?? "とくてぃーぶしきがいしゃ";

  //郵便番号
  initVals.supportOrganizationBasePostalCode = job.spSupportOrganizationBasePostalCode ?? "1050022";

  //都道府県
  initVals.supportOrganizationBasePrefecture = job.spSupportOrganizationBasePrefecture ?? "東京都";

  //市区
  initVals.supportOrganizationBaseCityWard = job.spSupportOrganizationBaseCityWard ?? "港区";

  //町村
  initVals.supportOrganizationBaseTown = job.spSupportOrganizationBaseTown ?? "海岸";

  //番地
  initVals.supportOrganizationBaseAddressNumber = job.spSupportOrganizationBaseAddressNumber ?? "1丁目9番18号";

  //建物名等
  initVals.supportOrganizationBaseBuilding = job.spSupportOrganizationBaseBuilding ?? "国際浜松町ビル2F";

  //電話番号
  initVals.supportOrganizationBasePhoneNumber = job.spSupportOrganizationBasePhoneNumber ?? "03-5843-8925";

  //代表者の名字
  initVals.supportOrganizationBaseRepresentativeLastName = job.spSupportOrganizationBaseRepresentativeLastName ?? "杉原";

  //代表者の名前
  initVals.supportOrganizationBaseRepresentativeFirstName = job.spSupportOrganizationBaseRepresentativeFirstName ?? "尚輔";

  //代表者の名字ふりがな
  initVals.supportOrganizationBaseRepresentativeLastNameFurigana = job.spSupportOrganizationBaseRepresentativeLastNameFurigana ?? "すぎはら";

  //代表者の名前ふりがな
  initVals.supportOrganizationBaseRepresentativeFirstNameFurigana = job.spSupportOrganizationBaseRepresentativeFirstNameFurigana ?? "なおすけ";

  /*** Support Organization Support section (登録支援機関（支援情報）) ***/
  //支援先事務所の郵便番号
  initVals.supportOrganizationSupportOfficePostalCode = ec?.placeOfEmploymentPostalCode ?? "";

  //支援先事務所の都道府県
  initVals.supportOrganizationSupportOfficePrefecture = ec?.placeOfEmploymentPrefecture ?? "";

  //支援先事務所の市区
  initVals.supportOrganizationSupportOfficeCityWard = ec?.placeOfEmploymentCityWard ?? "";

  //支援先事務所の町村
  initVals.supportOrganizationSupportOfficeTown = ec?.placeOfEmploymentTown ?? "";

  //支援先事務所の番地
  initVals.supportOrganizationSupportOfficeAddressNumber = ec?.placeOfEmploymentAddressNumber ?? "";

  //支援先事務所の建物名等
  initVals.supportOrganizationSupportOfficeBuilding = ec?.placeOfEmploymentBuilding ?? "";

  //支援先事務所の電話番号
  initVals.supportOrganizationSupportOfficePhoneNumber = ec?.placeOfEmploymentPhoneNumber ?? "";

  //支援責任者の名字
  initVals.supportOrganizationSupportManagerLastName = job.spSupportOrganizationSupportManagerLastName ?? "田村";

  //支援責任者の名前
  initVals.supportOrganizationSupportManagerFirstName = job.spSupportOrganizationSupportManagerFirstName ?? "秀介";

  //支援責任者の名字ふりがな
  initVals.supportOrganizationSupportManagerLastNameFurigana = job.spSupportOrganizationSupportManagerLastNameFurigana ?? "たむら";

  //支援責任者の名前ふりがな
  initVals.supportOrganizationSupportManagerFirstNameFurigana = job.spSupportOrganizationSupportManagerFirstNameFurigana ?? "しゅうすけ";

  //役職
  initVals.supportOrganizationSupportManagerTitle = job.spSupportOrganizationSupportManagerTitle ?? NON_TITLE;

  //支援を行っている1号特定技能外国人数
  initVals.supportOrganizationSupportSpecificSkilledWorkerCount = job.spSupportOrganizationSupportSpecificSkilledWorkerCount ?? 0;

  //支援担当者数
  initVals.supportOrganizationSupportPersonCount = job.spSupportOrganizationSupportPersonCount ?? 0;

  //支援の適正性を担保していることの有無
  initVals.supportOrganizationSupportEnsureProperty = job.spSupportOrganizationSupportEnsureProperty ?? true;

  /***Support Signature section (署名欄と共通情報) ***/
  //特定技能所属機関の氏名又は名称
  initVals.supportSignatureAffiliationName = job.spSupportSignatureAffiliationName ?? company.name ?? "";

  //「Ⅳ. 支援内容」の実施言語と担当者情報に、全て以下の情報を使用する
  initVals.supportSignatureUseCommonInformation = job.spSupportSignatureUseCommonInformation ?? true;

  //担当者の氏名（名字）
  initVals.supportSignaturePersonLastName = job.spSupportSignaturePersonLastName ?? "";

  //担当者の氏名（名前）
  initVals.supportSignaturePersonFirstName = job.spSupportSignaturePersonFirstName ?? "";

  /***Guidance section (Ⅳ. 支援内容（1. 事前ガイダンスの提供）) ***/
  //ア. 情報提供内容等
  //a. 従事する業務の内容、報酬の額その他の労働条件に関する事項
  //実施予定の有無
  initVals.guidanceWorkingConditionsSchedule = job.spGuidanceWorkingConditionsSchedule ?? true;

  //委託の有無
  initVals.guidanceWorkingConditionsEntrusting = job.spGuidanceWorkingConditionsEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.guidanceWorkingConditionsEntrustingPersonLastName = job.spGuidanceWorkingConditionsEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.guidanceWorkingConditionsEntrustingPersonFirstName = job.spGuidanceWorkingConditionsEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.guidanceWorkingConditionsEntrustingPersonTitle = job.spGuidanceWorkingConditionsEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.guidanceWorkingConditionsEntrustingMethodVideoCall = job.spGuidanceWorkingConditionsEntrustingMethodVideoCall ?? true;

  //b. 本邦において行うことができる活動の内容
  //実施予定の有無
  initVals.guidanceActivitySchedule = job.spGuidanceActivitySchedule ?? true;

  //委託の有無
  initVals.guidanceActivityEntrusting = job.spGuidanceActivityEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.guidanceActivityEntrustingPersonLastName = job.spGuidanceActivityEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.guidanceActivityEntrustingPersonFirstName = job.spGuidanceActivityEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.guidanceActivityEntrustingPersonTitle = job.spGuidanceActivityEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.guidanceActivityEntrustingMethodVideoCall = job.spGuidanceActivityEntrustingMethodVideoCall ?? true;

  //c. 入国に当たっての手続きに関する事項
  //実施予定の有無
  initVals.guidanceProceduresSchedule = job.spGuidanceProceduresSchedule ?? true;

  //委託の有無
  initVals.guidanceProceduresEntrusting = job.spGuidanceProceduresEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.guidanceProceduresEntrustingPersonLastName = job.spGuidanceProceduresEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.guidanceProceduresEntrustingPersonFirstName = job.spGuidanceProceduresEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.guidanceProceduresEntrustingPersonTitle = job.spGuidanceProceduresEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.guidanceProceduresEntrustingMethodVideoCall = job.spGuidanceProceduresEntrustingMethodVideoCall ?? true;

  //d. 保証金の徴収、契約の不履行についての違約金契約などの締結の禁止
  //実施予定の有無
  initVals.guidanceProhibitionsSchedule = job.spGuidanceProhibitionsSchedule ?? true;

  //委託の有無
  initVals.guidanceProhibitionsEntrusting = job.spGuidanceProhibitionsEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.guidanceProhibitionsEntrustingPersonLastName = job.spGuidanceProhibitionsEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.guidanceProhibitionsEntrustingPersonFirstName = job.spGuidanceProhibitionsEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.guidanceProhibitionsEntrustingPersonTitle = job.spGuidanceProhibitionsEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.guidanceProhibitionsEntrustingMethodVideoCall = job.spGuidanceProhibitionsEntrustingMethodVideoCall ?? true;

  //e. 入国の準備に関し外国の機関に支払った費用について、当該費用の額及び内訳を十分に理解して支払わせなければならないこと
  //実施予定の有無
  initVals.guidanceUnderstandingCostsSchedule = job.spGuidanceUnderstandingCostsSchedule ?? true;

  //委託の有無
  initVals.guidanceUnderstandingCostsEntrusting = job.spGuidanceUnderstandingCostsEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.guidanceUnderstandingCostsEntrustingPersonLastName = job.spGuidanceUnderstandingCostsEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.guidanceUnderstandingCostsEntrustingPersonFirstName = job.spGuidanceUnderstandingCostsEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.guidanceUnderstandingCostsEntrustingPersonTitle = job.spGuidanceUnderstandingCostsEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.guidanceUnderstandingCostsEntrustingMethodVideoCall = job.spGuidanceUnderstandingCostsEntrustingMethodVideoCall ?? true;

  //f. 支援に関する費用を負担させないこととしていること
  //実施予定の有無
  initVals.guidanceNoBearingCostsSchedule = job.spGuidanceNoBearingCostsSchedule ?? true;

  //委託の有無
  initVals.guidanceNoBearingCostsEntrusting = job.spGuidanceNoBearingCostsEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.guidanceNoBearingCostsEntrustingPersonLastName = job.spGuidanceNoBearingCostsEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.guidanceNoBearingCostsEntrustingPersonFirstName = job.spGuidanceNoBearingCostsEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.guidanceNoBearingCostsEntrustingPersonTitle = job.spGuidanceNoBearingCostsEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.guidanceNoBearingCostsEntrustingMethodVideoCall = job.spGuidanceNoBearingCostsEntrustingMethodVideoCall ?? true;

  //g. 入国する際の送迎に関する支援の内容
  //実施予定の有無
  initVals.guidancePickUpDropOffSchedule = job.spGuidancePickUpDropOffSchedule ?? true;

  //委託の有無
  initVals.guidancePickUpDropOffEntrusting = job.spGuidancePickUpDropOffEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.guidancePickUpDropOffEntrustingPersonLastName = job.spGuidancePickUpDropOffEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.guidancePickUpDropOffEntrustingPersonFirstName = job.spGuidancePickUpDropOffEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.guidancePickUpDropOffEntrustingPersonTitle = job.spGuidancePickUpDropOffEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.guidancePickUpDropOffEntrustingMethodVideoCall = job.spGuidancePickUpDropOffEntrustingMethodVideoCall ?? true;

  //h. 住居の確保に関する支援の内容
  //実施予定の有無
  initVals.guidanceHousingSchedule = job.spGuidanceHousingSchedule ?? true;

  //委託の有無
  initVals.guidanceHousingEntrusting = job.spGuidanceHousingEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.guidanceHousingEntrustingPersonLastName = job.spGuidanceHousingEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.guidanceHousingEntrustingPersonFirstName = job.spGuidanceHousingEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.guidanceHousingEntrustingPersonTitle = job.spGuidanceHousingEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.guidanceHousingEntrustingMethodVideoCall = job.spGuidanceHousingEntrustingMethodVideoCall ?? true;

  //i. 相談・苦情の対応に関する内容
  //実施予定の有無
  initVals.guidanceRequestsSchedule = job.spGuidanceRequestsSchedule ?? true;

  //委託の有無
  initVals.guidanceRequestsEntrusting = job.spGuidanceRequestsEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.guidanceRequestsEntrustingPersonLastName = job.spGuidanceRequestsEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.guidanceRequestsEntrustingPersonFirstName = job.spGuidanceRequestsEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.guidanceRequestsEntrustingPersonTitle = job.spGuidanceRequestsEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.guidanceRequestsEntrustingMethodVideoCall = job.spGuidanceRequestsEntrustingMethodVideoCall ?? true;

  //j. 特定技能所属機関等の支援担当者指名及び連絡先
  //実施予定の有無
  initVals.guidancePersonContactSchedule = job.spGuidancePersonContactSchedule ?? true;

  //委託の有無
  initVals.guidancePersonContactEntrusting = job.spGuidancePersonContactEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.guidancePersonContactEntrustingPersonLastName = job.spGuidancePersonContactEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.guidancePersonContactEntrustingPersonFirstName = job.spGuidancePersonContactEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.guidancePersonContactEntrustingPersonTitle = job.spGuidancePersonContactEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.guidancePersonContactEntrustingMethodVideoCall = job.spGuidancePersonContactEntrustingMethodVideoCall ?? true;

  //イ. 実施言語
  initVals.guidanceScheduleTime = job.spGuidanceScheduleTime ?? 3;

  /***Pick Up and Drop Off section section (Ⅳ. 支援内容（2. 出入国する際の送迎）) ***/
  //a. 到着空港等での出迎え及び特定技能所属機関又は住居までの送迎
  //実施予定の有無
  initVals.pickUpDropOffArrivalSchedule = job.spPickUpDropOffArrivalSchedule ?? true;

  //実施予定時期
  initVals.pickUpDropOffArrivalScheduleDateContent = job.spPickUpDropOffArrivalScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.pickUpDropOffArrivalEntrusting = job.spPickUpDropOffArrivalEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.pickUpDropOffArrivalEntrustingPersonLastName = job.spPickUpDropOffArrivalEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.pickUpDropOffArrivalEntrustingPersonFirstName = job.spPickUpDropOffArrivalEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.pickUpDropOffArrivalEntrustingPersonTitle = job.spPickUpDropOffArrivalEntrustingPersonTitle ?? NON_TITLE;

  //出迎え空港等
  initVals.pickUpDropOffArrivalAirport = job.spPickUpDropOffArrivalAirport ?? true;

  //送迎方法
  initVals.pickUpDropOffArrivalMethod = job.spPickUpDropOffArrivalMethod ?? true;

  //b. 出国予定空港等までの送迎及び保安検査場入場までの出国手続の補助
  //実施予定の有無
  initVals.pickUpDropOffDepartureSchedule = job.spPickUpDropOffDepartureSchedule ?? true;

  //実施予定時期
  initVals.pickUpDropOffDepartureScheduleDateContent = job.spPickUpDropOffDepartureScheduleDateContent ?? APPROPRIATE_AFTER_CONTRACT;

  //委託の有無
  initVals.pickUpDropOffDepartureEntrusting = job.spPickUpDropOffDepartureEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.pickUpDropOffDepartureEntrustingPersonLastName = job.spPickUpDropOffDepartureEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.pickUpDropOffDepartureEntrustingPersonFirstName = job.spPickUpDropOffDepartureEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.pickUpDropOffDepartureEntrustingPersonTitle = job.spPickUpDropOffDepartureEntrustingPersonTitle ?? NON_TITLE;

  //出迎え空港等
  initVals.pickUpDropOffDepartureAirport = job.spPickUpDropOffDepartureAirport ?? true;

  //未定
  initVals.pickUpDropOffDepartureAirportNameUndecided = job.spPickUpDropOffDepartureAirportNameUndecided ?? true

  //送迎方法
  initVals.pickUpDropOffDepartureMethod = job.spPickUpDropOffDepartureMethod ?? true;

  /***Life section (Ⅳ. 支援内容（3. 適切な住居の確保に係る支援・生活に必要な契約に係る支援）) ***/
  //ア. 適切な住居の確保に係る支援
  //a. 特定技能外国人が賃貸借契約を行う場合
  //実施予定の有無
  initVals.lifeForeignerHousingSchedule = job.spLifeForeignerHousingSchedule ?? true;

  //実施予定時期
  initVals.lifeForeignerHousingScheduleDateContent = job.spLifeForeignerHousingScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.lifeForeignerHousingEntrusting = job.spLifeForeignerHousingEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.lifeForeignerHousingEntrustingPersonLastName = job.spLifeForeignerHousingEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.lifeForeignerHousingEntrustingPersonFirstName = job.spLifeForeignerHousingEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.lifeForeignerHousingEntrustingPersonTitle = job.spLifeForeignerHousingEntrustingPersonTitle ?? NON_TITLE;

  //b. 特定技能所属機関が物件を借り上げる場合
  //実施予定の有無
  initVals.lifeRentalHousingSchedule = job.spLifeRentalHousingSchedule ?? true;

  //実施予定時期
  initVals.lifeRentalHousingScheduleDateContent = job.spLifeRentalHousingScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.lifeRentalHousingEntrusting = job.spLifeRentalHousingEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.lifeRentalHousingEntrustingPersonLastName = job.spLifeRentalHousingEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.lifeRentalHousingEntrustingPersonFirstName = job.spLifeRentalHousingEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.lifeRentalHousingEntrustingPersonTitle = job.spLifeRentalHousingEntrustingPersonTitle ?? NON_TITLE;

  //c. 特定技能所属機関の自己所有物件の場合
  //実施予定の有無
  initVals.lifeOwnHousingSchedule = job.spLifeOwnHousingSchedule ?? false;

  //実施予定時期
  initVals.lifeOwnHousingScheduleDateContent = job.spLifeOwnHousingScheduleDateContent ?? NOT_OWN_HOUSING;

  //委託の有無
  initVals.lifeOwnHousingEntrusting = job.spLifeOwnHousingEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.lifeOwnHousingEntrustingPersonLastName = job.spLifeOwnHousingEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.lifeOwnHousingEntrustingPersonFirstName = job.spLifeOwnHousingEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.lifeOwnHousingEntrustingPersonTitle = job.spLifeOwnHousingEntrustingPersonTitle ?? NON_TITLE;

  //d. 情報提供する又は住居とし て提供する住居の概要（確保予定の場合を含む）
  //住居の確保状況
  initVals.lifeHousingOverviewEnsureSituation = job.spLifeHousingOverviewEnsureSituation ?? HousingEnsureSituation.Already;

  //同居人数計
  initVals.lifeHousingOverviewLivingTogetherNumberOfPeople = job.spLifeHousingOverviewLivingTogetherNumberOfPeople ?? 0;

  //居間の広さ
  initVals.lifeHousingOverviewLivingRoomSize_7_5OrMore = job.spLifeHousingOverviewLivingRoomSize_7_5OrMore ?? true;

  //寝室の広さ
  initVals.lifeHousingOverviewBedroomSize_4_5OrMore = job.spLifeHousingOverviewBedroomSize_4_5OrMore ?? true;

  //イ. 生活に必要な契約に係る支援
  //a. 銀行その他の金融機関における預金口座又は貯金口座の開設の手続の補助
  //実施予定の有無
  initVals.lifeAccountOpeningSchedule = job.spLifeAccountOpeningSchedule ?? true;

  //実施予定時期
  initVals.lifeAccountOpeningScheduleDateContent = job.spLifeAccountOpeningScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.lifeAccountOpeningEntrusting = job.spLifeAccountOpeningEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.lifeAccountOpeningEntrustingPersonLastName = job.spLifeAccountOpeningEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.lifeAccountOpeningEntrustingPersonFirstName = job.spLifeAccountOpeningEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.lifeAccountOpeningEntrustingPersonTitle = job.spLifeAccountOpeningEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.lifeAccountOpeningEntrustingMethodProviding = job.spLifeAccountOpeningEntrustingMethodProviding ?? true;
  initVals.lifeAccountOpeningEntrustingMethodAccompanying = job.spLifeAccountOpeningEntrustingMethodAccompanying ?? true;

  //b. 携帯電話の利用に関する契約の手続の補助
  //実施予定の有無
  initVals.lifeMobilePhoneSchedule = job.spLifeMobilePhoneSchedule ?? true;

  //実施予定時期
  initVals.lifeMobilePhoneScheduleDateContent = job.spLifeMobilePhoneScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.lifeMobilePhoneEntrusting = job.spLifeMobilePhoneEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.lifeMobilePhoneEntrustingPersonLastName = job.spLifeMobilePhoneEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.lifeMobilePhoneEntrustingPersonFirstName = job.spLifeMobilePhoneEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.lifeMobilePhoneEntrustingPersonTitle = job.spLifeMobilePhoneEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.lifeMobilePhoneEntrustingMethodProviding = job.spLifeMobilePhoneEntrustingMethodProviding ?? true;
  initVals.lifeMobilePhoneEntrustingMethodAccompanying = job.spLifeMobilePhoneEntrustingMethodAccompanying ?? true;

  //c. 電気・水道・ガス等のライフラインに関する手続の補助
  //実施予定の有無
  initVals.lifeLifelineSchedule = job.spLifeLifelineSchedule ?? true;

  //実施予定時期
  initVals.lifeLifelineScheduleDateContent = job.spLifeLifelineScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.lifeLifelineEntrusting = job.spLifeLifelineEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.lifeLifelineEntrustingPersonLastName = job.spLifeLifelineEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.lifeLifelineEntrustingPersonFirstName = job.spLifeLifelineEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.lifeLifelineEntrustingPersonTitle = job.spLifeLifelineEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.lifeLifelineEntrustingMethodProviding = job.spLifeLifelineEntrustingMethodProviding ?? true;
  initVals.lifeLifelineEntrustingMethodAccompanying = job.spLifeLifelineEntrustingMethodAccompanying ?? true;

  /***Orientation section (Ⅳ. 支援内容（4. 生活オリエンテーションの実施）) ***/
  //ア. 情報提供内容等
  //a. 本邦での生活一般に関する事項
  //実施予定の有無
  initVals.orientationGeneralLifeSchedule = job.spOrientationGeneralLifeSchedule ?? true;

  //委託の有無
  initVals.orientationGeneralLifeEntrusting = job.spOrientationGeneralLifeEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.orientationGeneralLifeEntrustingPersonLastName = job.spOrientationGeneralLifeEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.orientationGeneralLifeEntrustingPersonFirstName = job.spOrientationGeneralLifeEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.orientationGeneralLifeEntrustingPersonTitle = job.spOrientationGeneralLifeEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.orientationGeneralLifeEntrustingMethodInPerson = job.spOrientationGeneralLifeEntrustingMethodInPerson ?? true;
  initVals.orientationGeneralLifeEntrustingMethodVideoCall = job.spOrientationGeneralLifeEntrustingMethodVideoCall ?? true;

  //b. 法令の規定により外国人が履行しなければならない国又は地方公共団体の機関に対する届出その他の手続に関する事項及び必要に応じて同行し手続を補助すること
  //実施予定の有無
  initVals.orientationProcedureSchedule = job.spOrientationProcedureSchedule ?? true;

  //委託の有無
  initVals.orientationProcedureEntrusting = job.spOrientationProcedureEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.orientationProcedureEntrustingPersonLastName = job.spOrientationProcedureEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.orientationProcedureEntrustingPersonFirstName = job.spOrientationProcedureEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.orientationProcedureEntrustingPersonTitle = job.spOrientationProcedureEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.orientationProcedureEntrustingMethodInPerson = job.spOrientationProcedureEntrustingMethodInPerson ?? true;
  initVals.orientationProcedureEntrustingMethodVideoCall = job.spOrientationProcedureEntrustingMethodVideoCall ?? true;

  //c. 相談・苦情の連絡先、申出をすべき国又は地方公共団体の機関の連絡先
  //実施予定の有無
  initVals.orientationRequestsSchedule = job.spOrientationRequestsSchedule ?? true;

  //委託の有無
  initVals.orientationRequestsEntrusting = job.spOrientationRequestsEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.orientationRequestsEntrustingPersonLastName = job.spOrientationRequestsEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.orientationRequestsEntrustingPersonFirstName = job.spOrientationRequestsEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.orientationRequestsEntrustingPersonTitle = job.spOrientationRequestsEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.orientationRequestsEntrustingMethodInPerson = job.spOrientationRequestsEntrustingMethodInPerson ?? true;
  initVals.orientationRequestsEntrustingMethodVideoCall = job.spOrientationRequestsEntrustingMethodVideoCall ?? true;

  //d. 十分に理解することができる言語により医療を受けることができる医療機関に関する事項
  //実施予定の有無
  initVals.orientationMedicalSchedule = job.spOrientationMedicalSchedule ?? true;

  //委託の有無
  initVals.orientationMedicalEntrusting = job.spOrientationMedicalEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.orientationMedicalEntrustingPersonLastName = job.spOrientationMedicalEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.orientationMedicalEntrustingPersonFirstName = job.spOrientationMedicalEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.orientationMedicalEntrustingPersonTitle = job.spOrientationMedicalEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.orientationMedicalEntrustingMethodInPerson = job.spOrientationMedicalEntrustingMethodInPerson ?? true;
  initVals.orientationMedicalEntrustingMethodVideoCall = job.spOrientationMedicalEntrustingMethodVideoCall ?? true;

  //e. 防災・防犯に関する事項、急病その他の緊急時における対応に必要な事項
  //実施予定の有無
  initVals.orientationEmergencySchedule = job.spOrientationEmergencySchedule ?? true;

  //委託の有無
  initVals.orientationEmergencyEntrusting = job.spOrientationEmergencyEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.orientationEmergencyEntrustingPersonLastName = job.spOrientationEmergencyEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.orientationEmergencyEntrustingPersonFirstName = job.spOrientationEmergencyEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.orientationEmergencyEntrustingPersonTitle = job.spOrientationEmergencyEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.orientationEmergencyEntrustingMethodInPerson = job.spOrientationEmergencyEntrustingMethodInPerson ?? true;
  initVals.orientationEmergencyEntrustingMethodVideoCall = job.spOrientationEmergencyEntrustingMethodVideoCall ?? true;

  //f. 出入国又は労働に関する法令規定の違反を知ったときの対応方法その他当該外国人の法的保護に必要な事項
  //実施予定の有無
  initVals.orientationViolationSchedule = job.spOrientationViolationSchedule ?? true;

  //委託の有無
  initVals.orientationViolationEntrusting = job.spOrientationViolationEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.orientationViolationEntrustingPersonLastName = job.spOrientationViolationEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.orientationViolationEntrustingPersonFirstName = job.spOrientationViolationEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.orientationViolationEntrustingPersonTitle = job.spOrientationViolationEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.orientationViolationEntrustingMethodInPerson = job.spOrientationViolationEntrustingMethodInPerson ?? true;
  initVals.orientationViolationEntrustingMethodVideoCall = job.spOrientationViolationEntrustingMethodVideoCall ?? true;

  //ウ. 実施予定時間
  initVals.orientationScheduleTime = job.spOrientationScheduleTime ?? 8;

  /***Learning Opportunities section (Ⅳ. 支援内容（5. 日本語学習機会の提供）) ***/
  //a. 日本語教室や日本語教育機関に関する入学案内の情報を提供し、必要に応じて同行して入学の手続の補助を行う
  //実施予定の有無
  initVals.opportunitiesAdmissionSchedule = job.spOpportunitiesAdmissionSchedule ?? true;

  //実施予定時期
  initVals.opportunitiesAdmissionScheduleDateContent = job.spOpportunitiesAdmissionScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.opportunitiesAdmissionEntrusting = job.spOpportunitiesAdmissionEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.opportunitiesAdmissionEntrustingPersonLastName = job.spOpportunitiesAdmissionEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.opportunitiesAdmissionEntrustingPersonFirstName = job.spOpportunitiesAdmissionEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.opportunitiesAdmissionEntrustingPersonTitle = job.spOpportunitiesAdmissionEntrustingPersonTitle ?? NON_TITLE;

  //b. 自主学習のための日本語学習教材やオンラインの日本語講座に関する情報の提供し、必要に応じて日本語学習教材の入手やオンラインの日本語講座の利用契約手続の補助を行う
  //実施予定の有無
  initVals.opportunitiesMaterialsSchedule = job.spOpportunitiesMaterialsSchedule ?? true;

  //実施予定時期
  initVals.opportunitiesMaterialsScheduleDateContent = job.spOpportunitiesMaterialsScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.opportunitiesMaterialsEntrusting = job.spOpportunitiesMaterialsEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.opportunitiesMaterialsEntrustingPersonLastName = job.spOpportunitiesMaterialsEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.opportunitiesMaterialsEntrustingPersonFirstName = job.spOpportunitiesMaterialsEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.opportunitiesMaterialsEntrustingPersonTitle = job.spOpportunitiesMaterialsEntrustingPersonTitle ?? NON_TITLE;

  //c. １号特定技能外国人との合意の下、日本語教師と契約して１号特定技能外国人に日本語の講習の機会を提供する
  //実施予定の有無
  initVals.opportunitiesTeachersSchedule = job.spOpportunitiesTeachersSchedule ?? true;

  //実施予定時期
  initVals.opportunitiesTeachersScheduleDateContent = job.spOpportunitiesTeachersScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.opportunitiesTeachersEntrusting = job.spOpportunitiesTeachersEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.opportunitiesTeachersEntrustingPersonLastName = job.spOpportunitiesTeachersEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.opportunitiesTeachersEntrustingPersonFirstName = job.spOpportunitiesTeachersEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.opportunitiesTeachersEntrustingPersonTitle = job.spOpportunitiesTeachersEntrustingPersonTitle ?? NON_TITLE;

  /***Requests section (Ⅳ. 支援内容（6. 相談又は苦情への対応））) ***/
  //ア. 対応内容等
  //a. 相談又は苦情に対し、遅滞なく十分に理解できる言語により適切に対応し、必要な助言及び指導を行う
  //実施予定の有無
  initVals.requestsProperlyResponsesSchedule = job.spRequestsProperlyResponsesSchedule ?? true;

  //実施予定時期
  initVals.requestsProperlyResponsesScheduleDateContent = job.spRequestsProperlyResponsesScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.requestsProperlyResponsesEntrusting = job.spRequestsProperlyResponsesEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.requestsProperlyResponsesEntrustingPersonLastName = job.spRequestsProperlyResponsesEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.requestsProperlyResponsesEntrustingPersonFirstName = job.spRequestsProperlyResponsesEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.requestsProperlyResponsesEntrustingPersonTitle = job.spRequestsProperlyResponsesEntrustingPersonTitle ?? NON_TITLE;

  //b. 必要に応じ、相談内容に対応する関係行政機関を案内し、同行する等必要な手続の補助を行う
  initVals.requestsAdministrativeOrgansSchedule = job.spRequestsAdministrativeOrgansSchedule ?? true;

  //実施予定時期
  initVals.requestsAdministrativeOrgansScheduleDateContent = job.spRequestsAdministrativeOrgansScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.requestsAdministrativeOrgansEntrusting = job.spRequestsAdministrativeOrgansEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.requestsAdministrativeOrgansEntrustingPersonLastName = job.spRequestsAdministrativeOrgansEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.requestsAdministrativeOrgansEntrustingPersonFirstName = job.spRequestsAdministrativeOrgansEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.requestsAdministrativeOrgansEntrustingPersonTitle = job.spRequestsAdministrativeOrgansEntrustingPersonTitle ?? NON_TITLE;

  //イ. 実施方法
  //対応時間
  initVals.requestsResponseHoursMonday = job.spRequestsResponseHoursMonday ?? true;
  initVals.requestsResponseHoursMondayStartTime = job.spRequestsResponseHoursMondayStartTime ?? "09:00";
  initVals.requestsResponseHoursMondayEndTime = job.spRequestsResponseHoursMondayEndTime ?? "18:00";
  initVals.requestsResponseHoursTuesday = job.spRequestsResponseHoursTuesday ?? true;
  initVals.requestsResponseHoursTuesdayStartTime = job.spRequestsResponseHoursTuesdayStartTime ?? "09:00";
  initVals.requestsResponseHoursTuesdayEndTime = job.spRequestsResponseHoursTuesdayEndTime ?? "18:00";
  initVals.requestsResponseHoursWednesday = job.spRequestsResponseHoursWednesday ?? true;
  initVals.requestsResponseHoursWednesdayStartTime = job.spRequestsResponseHoursWednesdayStartTime ?? "09:00";
  initVals.requestsResponseHoursWednesdayEndTime = job.spRequestsResponseHoursWednesdayEndTime ?? "18:00";
  initVals.requestsResponseHoursThursday = job.spRequestsResponseHoursThursday ?? true;
  initVals.requestsResponseHoursThursdayStartTime = job.spRequestsResponseHoursThursdayStartTime ?? "09:00";
  initVals.requestsResponseHoursThursdayEndTime = job.spRequestsResponseHoursThursdayEndTime ?? "18:00";
  initVals.requestsResponseHoursFriday = job.spRequestsResponseHoursFriday ?? true;
  initVals.requestsResponseHoursFridayStartTime = job.spRequestsResponseHoursFridayStartTime ?? "09:00";
  initVals.requestsResponseHoursFridayEndTime = job.spRequestsResponseHoursFridayEndTime ?? "18:00";
  initVals.requestsResponseHoursSaturday = job.spRequestsResponseHoursSaturday ?? true;
  initVals.requestsResponseHoursSaturdayStartTime = job.spRequestsResponseHoursSaturdayStartTime ?? "09:00";
  initVals.requestsResponseHoursSaturdayEndTime = job.spRequestsResponseHoursSaturdayEndTime ?? "17:00";
  initVals.requestsResponseHoursSunday = job.spRequestsResponseHoursSunday ?? true;
  initVals.requestsResponseHoursSundayStartTime = job.spRequestsResponseHoursSundayStartTime ?? "09:00";
  initVals.requestsResponseHoursSundayEndTime = job.spRequestsResponseHoursSundayEndTime ?? "17:00";
  initVals.requestsResponseHoursHoliday = job.spRequestsResponseHoursHoliday ?? true;
  initVals.requestsResponseHoursHolidayStartTime = job.spRequestsResponseHoursHolidayStartTime ?? "09:00";
  initVals.requestsResponseHoursHolidayEndTime = job.spRequestsResponseHoursHolidayEndTime ?? "17:00";

  //相談方法
  initVals.requestsMethodPhone = job.spRequestsMethodPhone ?? true;
  initVals.requestsMethodPhoneNumber = job.spRequestsMethodPhoneNumber ?? "03-5843-8925";
  initVals.requestsMethodEmail = job.spRequestsMethodEmail ?? true;
  initVals.requestsMethodEmailAddress = job.spRequestsMethodEmailAddress ?? "candidates-team@tokuty.jp";

  //緊急対応時
  initVals.requestsEmergencyMethodPhone = job.spRequestsEmergencyMethodPhone ?? true;
  initVals.requestsEmergencyMethodPhoneNumber = job.spRequestsEmergencyMethodPhoneNumber ?? "080-7805-8010";
  initVals.requestsEmergencyMethodEmail = job.spRequestsEmergencyMethodEmail ?? true;
  initVals.requestsEmergencyMethodEmailAddress = job.spRequestsEmergencyMethodEmailAddress ?? "tamura@tokuty.jp";

  /***Interactions section (Ⅳ. 支援内容（7. 日本人との交流促進に係る支援）) ***/
  //a. 必要に応じ、地方公共団体やボランティア団体等が主催する地域住民との交流の場に関する情報の提供や地域の自治会等の案内を行い、各行事等への参加の手続の補助を行うほか、必要に応じて同行して各行事の注意事項や実施方法を説明するなどの補助を行う
  //実施予定の有無
  initVals.interactionsProvidingSchedule = job.spInteractionsProvidingSchedule ?? true;

  //実施予定時期
  initVals.interactionsProvidingScheduleDateContent = job.spInteractionsProvidingScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.interactionsProvidingEntrusting = job.spInteractionsProvidingEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.interactionsProvidingEntrustingPersonLastName = job.spInteractionsProvidingEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.interactionsProvidingEntrustingPersonFirstName = job.spInteractionsProvidingEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.interactionsProvidingEntrustingPersonTitle = job.spInteractionsProvidingEntrustingPersonTitle ?? NON_TITLE;

  //b. 日本の文化を理解するために必要な情報として、就労又は生活する地域の行事に関する案内を行うほか、必要に応じて同行し現地で説明するなどの補助を行う
  //実施予定の有無
  initVals.interactionsCulturalSchedule = job.spInteractionsCulturalSchedule ?? true;

  //実施予定時期
  initVals.interactionsCulturalScheduleDateContent = job.spInteractionsCulturalScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.interactionsCulturalEntrusting = job.spInteractionsCulturalEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.interactionsCulturalEntrustingPersonLastName = job.spInteractionsCulturalEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.interactionsCulturalEntrustingPersonFirstName = job.spInteractionsCulturalEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.interactionsCulturalEntrustingPersonTitle = job.spInteractionsCulturalEntrustingPersonTitle ?? NON_TITLE;

  /***Job Change section (Ⅳ. 支援内容（8. 非自発的離職時の転職支援）) ***/
  //a. 所属する業界団体や関連企業等を通じて次の受入れ先に関する情報を入手し提供する
  //実施予定の有無
  initVals.jobChangeAcceptanceSchedule = job.spJobChangeAcceptanceSchedule ?? true;

  //実施予定時期
  initVals.jobChangeAcceptanceScheduleDateContent = job.spJobChangeAcceptanceScheduleDateContent ?? APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.jobChangeAcceptanceEntrusting = job.spJobChangeAcceptanceEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.jobChangeAcceptanceEntrustingPersonLastName = job.spJobChangeAcceptanceEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.jobChangeAcceptanceEntrustingPersonFirstName = job.spJobChangeAcceptanceEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.jobChangeAcceptanceEntrustingPersonTitle = job.spJobChangeAcceptanceEntrustingPersonTitle ?? NON_TITLE;

  //b. 公共職業安定所、その他の職業安定機関等を案内し、必要に応じて支援対象者に同行して次の受入れ先を探す補助を行う
  //実施予定の有無
  initVals.jobChangeIntroductionSchedule = job.spJobChangeIntroductionSchedule ?? true;

  //実施予定時期
  initVals.jobChangeIntroductionScheduleDateContent = job.spJobChangeIntroductionScheduleDateContent ?? APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.jobChangeIntroductionEntrusting = job.spJobChangeIntroductionEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.jobChangeIntroductionEntrustingPersonLastName = job.spJobChangeIntroductionEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.jobChangeIntroductionEntrustingPersonFirstName = job.spJobChangeIntroductionEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.jobChangeIntroductionEntrustingPersonTitle = job.spJobChangeIntroductionEntrustingPersonTitle ?? NON_TITLE;

  //c. １号特定技能外国人の希望条件、技能水準、日本語能力等を踏まえ、適切に職業相談・職業紹介が受けられるよう又は円滑に就職活動が行えるよう推薦状を作成する
  //実施予定の有無
  initVals.jobChangeRecommendationSchedule = job.spJobChangeRecommendationSchedule ?? true;

  //実施予定時期
  initVals.jobChangeRecommendationScheduleDateContent = job.spJobChangeRecommendationScheduleDateContent ?? APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.jobChangeRecommendationEntrusting = job.spJobChangeRecommendationEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.jobChangeRecommendationEntrustingPersonLastName = job.spJobChangeRecommendationEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.jobChangeRecommendationEntrustingPersonFirstName = job.spJobChangeRecommendationEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.jobChangeRecommendationEntrustingPersonTitle = job.spJobChangeRecommendationEntrustingPersonTitle ?? NON_TITLE;

  //d. 職業紹介事業の許可又は届出を受けて職業紹介を行うことができる場合は、就職先の紹介あっせんを行う
  //実施予定の有無
  initVals.jobChangeMediationSchedule = job.spJobChangeMediationSchedule ?? true;

  //実施予定時期
  initVals.jobChangeMediationScheduleDateContent = job.spJobChangeMediationScheduleDateContent ?? APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.jobChangeMediationEntrusting = job.spJobChangeMediationEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.jobChangeMediationEntrustingPersonLastName = job.spJobChangeMediationEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.jobChangeMediationEntrustingPersonFirstName = job.spJobChangeMediationEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.jobChangeMediationEntrustingPersonTitle = job.spJobChangeMediationEntrustingPersonTitle ?? NON_TITLE;

  //e. １号特定技能外国人が求職活動をするために必要な有給休暇を付与する
  //実施予定の有無
  initVals.jobChangePaidHolidaysSchedule = job.spJobChangePaidHolidaysSchedule ?? true;

  //実施予定時期
  initVals.jobChangePaidHolidaysScheduleDateContent = job.spJobChangePaidHolidaysScheduleDateContent ?? APPROPRIATE_INVOLUNTARILY_LEAVE;

  //f. 離職時に必要な行政手続について情報を提供する
  //実施予定の有無
  initVals.jobChangeProceduresSchedule = job.spJobChangeProceduresSchedule ?? true;

  //実施予定時期
  initVals.jobChangeProceduresScheduleDateContent = job.spJobChangeProceduresScheduleDateContent ?? APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.jobChangeProceduresEntrusting = job.spJobChangeProceduresEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.jobChangeProceduresEntrustingPersonLastName = job.spJobChangeProceduresEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.jobChangeProceduresEntrustingPersonFirstName = job.spJobChangeProceduresEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.jobChangeProceduresEntrustingPersonTitle = job.spJobChangeProceduresEntrustingPersonTitle ?? NON_TITLE;

  //g. 倒産等により、転職のための支援が適切に実施できなくなることが見込まれるときは、それに備え、当該機関に代わって支援を行う者を確保する
  //実施予定の有無
  initVals.jobChangeBankruptcySchedule = job.spJobChangeBankruptcySchedule ?? true;

  //実施予定時期
  initVals.jobChangeBankruptcyScheduleDateContent = job.spJobChangeBankruptcyScheduleDateContent ?? APPROPRIATE_INVOLUNTARILY_LEAVE;

  //委託の有無
  initVals.jobChangeBankruptcyEntrusting = job.spJobChangeBankruptcyEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.jobChangeBankruptcyEntrustingPersonLastName = job.spJobChangeBankruptcyEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.jobChangeBankruptcyEntrustingPersonFirstName = job.spJobChangeBankruptcyEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.jobChangeBankruptcyEntrustingPersonTitle = job.spJobChangeBankruptcyEntrustingPersonTitle ?? NON_TITLE;

  /***Interviews section (Ⅳ. 支援内容（9. 定期的な面談の実施・行政機関への通報）) ***/
  //ア. 面談内容等
  //a. １号特定技能外国人の労働状況や生活状況を確認するため、当該外国人及びその監督をする立場にある者それぞれと定期的な面談（3か月に1回以上）を実施する
  //実施予定の有無
  initVals.interviewsLaborSituationSchedule = job.spInterviewsLaborSituationSchedule ?? true;

  //実施予定時期
  initVals.interviewsLaborSituationScheduleDateContent = job.spInterviewsLaborSituationScheduleDateContent ?? QUARTERLY;

  //委託の有無
  initVals.interviewsLaborSituationEntrusting = job.spInterviewsLaborSituationEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.interviewsLaborSituationEntrustingPersonLastName = job.spInterviewsLaborSituationEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.interviewsLaborSituationEntrustingPersonFirstName = job.spInterviewsLaborSituationEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.interviewsLaborSituationEntrustingPersonTitle = job.spInterviewsLaborSituationEntrustingPersonTitle ?? NON_TITLE;

  //実施方法
  initVals.interviewsLaborSituationEntrustingMethodInPerson = job.spInterviewsLaborSituationEntrustingMethodInPerson ?? true;

  //b. 再確認のため、生活オリエンテーションにおいて提供した情報について、改めて提供する
  //実施予定の有無
  initVals.interviewsReconfirmationSchedule = job.spInterviewsReconfirmationSchedule ?? true;

  //実施予定時期
  initVals.interviewsReconfirmationScheduleDateContent = job.spInterviewsReconfirmationScheduleDateContent ?? QUARTERLY;

  //委託の有無
  initVals.interviewsReconfirmationEntrusting = job.spInterviewsReconfirmationEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.interviewsReconfirmationEntrustingPersonLastName = job.spInterviewsReconfirmationEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.interviewsReconfirmationEntrustingPersonFirstName = job.spInterviewsReconfirmationEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.interviewsReconfirmationEntrustingPersonTitle = job.spInterviewsReconfirmationEntrustingPersonTitle ?? NON_TITLE;

  //c. 労働基準法その他の労働に関する法令の規定に違反していることを知ったときは、労働基準監督署その他の関係行政機関へ通報する
  //実施予定の有無
  initVals.interviewsLegalViolationSchedule = job.spInterviewsLegalViolationEntrusting ?? true;

  //実施予定時期
  initVals.interviewsLegalViolationScheduleDateContent = job.spInterviewsLegalViolationScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.interviewsLegalViolationEntrusting = job.spInterviewsLegalViolationEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.interviewsLegalViolationEntrustingPersonLastName = job.spInterviewsLegalViolationEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.interviewsLegalViolationEntrustingPersonFirstName = job.spInterviewsLegalViolationEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.interviewsLegalViolationEntrustingPersonTitle = job.spInterviewsLegalViolationEntrustingPersonTitle ?? NON_TITLE;

  //d. 資格外活動等の入管法違反又は旅券及び在留カードの取上げ等その他の問題の発生を知ったときは、その旨を地方出入国在留管理局に通報する
  //実施予定の有無
  initVals.interviewsOtherProblemsSchedule = job.spInterviewsOtherProblemsSchedule ?? true;

  //実施予定時期
  initVals.interviewsOtherProblemsScheduleDateContent = job.spInterviewsOtherProblemsScheduleDateContent ?? APPROPRIATE;

  //委託の有無
  initVals.interviewsOtherProblemsEntrusting = job.spInterviewsOtherProblemsEntrusting ?? false;

  //委託を受けた実施担当者の名字
  initVals.interviewsOtherProblemsEntrustingPersonLastName = job.spInterviewsOtherProblemsEntrustingPersonLastName ?? "";

  //委託を受けた実施担当者の名前
  initVals.interviewsOtherProblemsEntrustingPersonFirstName = job.spInterviewsOtherProblemsEntrustingPersonFirstName ?? "";

  //委託を受けた実施担当者の役職名
  initVals.interviewsOtherProblemsEntrustingPersonTitle = job.spInterviewsOtherProblemsEntrustingPersonTitle ?? NON_TITLE;

  return initVals;
};
