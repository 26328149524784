import { FunctionComponent } from "react";
import {
  CategoryContainer, Column16,
  Column5,
  CustomLabel,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingMediumContainer,
  LabelCell, LocalCheckBox, LocalTextInput,
  Row0, Row10, SectionHeadingLarge,
  SectionHeadingMedium, UnitText,
} from "../commonStyle"
import { SelectOption } from "../../../../core/components/Select";
import ErrorMessage from "../components/ErrorMessage";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import * as React from "react";
import CheckBoxWithTextInput from "../components/CheckBoxWithTextInput";
import ScheduleRow from "../components/ScheduleRow";
import ScheduleDateReadOnlyRow from "../components/ScheduleDateReadOnlyRow";
import EntrustingRow from "../components/EntrustingRow";
import EntrustingPersonLastNameRow from "../components/EntrustingPersonLastNameRow";
import EntrustingPersonFirstNameRow from "../components/EntrustingPersonFirstNameRow";
import EntrustingPersonTitleRow from "../components/EntrustingPersonTitleRow";
import LanguageCategory from "../components/LanguageCategory";
import {isEmpty} from "../../../../core/utils";

interface RequestsSectionProps extends SupportPlanFormProps {
  nationalityOptions?: SelectOption[];
}

export const RequestsSection: FunctionComponent<RequestsSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
  }) => {

  interface HoursInputProps {
    checkPropertyName: SupportPlanExtractType<boolean>;
    startTimePropertyName: SupportPlanExtractType<string | null>;
    endTimePropertyName: SupportPlanExtractType<string | null>;
    label: string;
  }

  const HoursInput: FunctionComponent<HoursInputProps> = (
    {
      checkPropertyName,
      startTimePropertyName,
      endTimePropertyName,
      label,
    }) => {
    return (
      <Row0>
        <LocalCheckBox
          checked={!!supportPlan?.[checkPropertyName]}
          onValueChange={(val) => {
            onChangeSupportPlan?.({[checkPropertyName]: val});
          }}
        >
          {label}
        </LocalCheckBox>
        <Row10>
          <UnitText>
            （
          </UnitText>
          <LocalTextInput
            placeholder="09:00"
            value={supportPlan?.[startTimePropertyName] ?? ""}
            onTextChange={(val: string) => {
              onChangeSupportPlan?.({[startTimePropertyName]: val});
            }}
          />
          <UnitText>
            〜
          </UnitText>
          <LocalTextInput
            placeholder="18:00"
            value={supportPlan?.[endTimePropertyName] ?? ""}
            onTextChange={(val: string) => {
              onChangeSupportPlan?.({[endTimePropertyName]: val});
            }}
          />
          <UnitText>
            ）
          </UnitText>
        </Row10>
      </Row0>
    )
  }

  return (
    <>
      <CategoryContainer>
        <SectionHeadingLarge>ア. 対応内容等</SectionHeadingLarge>
      </CategoryContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>a. 相談又は苦情に対し、遅滞なく十分に理解できる言語により適切に対応し、必要な助言及び指導を行う</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"requestsProperlyResponsesSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"requestsProperlyResponsesScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"requestsProperlyResponsesEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"requestsProperlyResponsesEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"requestsProperlyResponsesEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"requestsProperlyResponsesEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>b. 必要に応じ、相談内容に対応する関係行政機関を案内し、同行する等必要な手続の補助を行う</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"requestsAdministrativeOrgansSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"requestsAdministrativeOrgansScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"requestsAdministrativeOrgansEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"requestsAdministrativeOrgansEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"requestsAdministrativeOrgansEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"requestsAdministrativeOrgansEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <CategoryContainer>
        <SectionHeadingLarge>イ. 実施方法</SectionHeadingLarge>
      </CategoryContainer>

      <GridRowsContainer>
        {/* 対応時間 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>対応時間</CustomLabel>
          </LabelCell>

          <FieldCell>
            <Column5>
              <Column16>
                <HoursInput
                  checkPropertyName="requestsResponseHoursMonday"
                  startTimePropertyName="requestsResponseHoursMondayStartTime"
                  endTimePropertyName="requestsResponseHoursMondayEndTime"
                  label="月曜日"
                />
                <HoursInput
                  checkPropertyName="requestsResponseHoursTuesday"
                  startTimePropertyName="requestsResponseHoursTuesdayStartTime"
                  endTimePropertyName="requestsResponseHoursTuesdayEndTime"
                  label="火曜日"
                />
                <HoursInput
                  checkPropertyName="requestsResponseHoursWednesday"
                  startTimePropertyName="requestsResponseHoursWednesdayStartTime"
                  endTimePropertyName="requestsResponseHoursWednesdayEndTime"
                  label="水曜日"
                />
                <HoursInput
                  checkPropertyName="requestsResponseHoursThursday"
                  startTimePropertyName="requestsResponseHoursThursdayStartTime"
                  endTimePropertyName="requestsResponseHoursThursdayEndTime"
                  label="木曜日"
                />
                <HoursInput
                  checkPropertyName="requestsResponseHoursFriday"
                  startTimePropertyName="requestsResponseHoursFridayStartTime"
                  endTimePropertyName="requestsResponseHoursFridayEndTime"
                  label="金曜日"
                />
                <HoursInput
                  checkPropertyName="requestsResponseHoursSaturday"
                  startTimePropertyName="requestsResponseHoursSaturdayStartTime"
                  endTimePropertyName="requestsResponseHoursSaturdayEndTime"
                  label="土曜日"
                />
                <HoursInput
                  checkPropertyName="requestsResponseHoursSunday"
                  startTimePropertyName="requestsResponseHoursSundayStartTime"
                  endTimePropertyName="requestsResponseHoursSundayEndTime"
                  label="日曜日"
                />
                <HoursInput
                  checkPropertyName="requestsResponseHoursHoliday"
                  startTimePropertyName="requestsResponseHoursHolidayStartTime"
                  endTimePropertyName="requestsResponseHoursHolidayEndTime"
                  label="祝祭日"
                />
              </Column16>
              {(
                  isEmpty(supportPlan?.requestsResponseHoursMonday) &&
                  isEmpty(supportPlan?.requestsResponseHoursTuesday) &&
                  isEmpty(supportPlan?.requestsResponseHoursWednesday) &&
                  isEmpty(supportPlan?.requestsResponseHoursThursday) &&
                  isEmpty(supportPlan?.requestsResponseHoursFriday) &&
                  isEmpty(supportPlan?.requestsResponseHoursSaturday) &&
                  isEmpty(supportPlan?.requestsResponseHoursSunday) &&
                  isEmpty(supportPlan?.requestsResponseHoursHoliday)
                ) &&
                  <ErrorMessage>必ず選択してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 相談方法 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>相談方法</CustomLabel>
          </LabelCell>

          <FieldCell>
            <Column5>
              <Column16>
                <LocalCheckBox
                  checked={!!supportPlan?.requestsMethodDirect}
                  onValueChange={(val) => {
                    onChangeSupportPlan?.({requestsMethodDirect: val});
                  }}
                >
                  直接面談
                </LocalCheckBox>
                <CheckBoxWithTextInput
                  supportPlan={supportPlan}
                  onChangeSupportPlan={onChangeSupportPlan}
                  checkPropertyName="requestsMethodPhone"
                  textPropertyName="requestsMethodPhoneNumber"
                  label="電　話"
                  placeholder="03-1234-5678"
                />
                <CheckBoxWithTextInput
                  supportPlan={supportPlan}
                  onChangeSupportPlan={onChangeSupportPlan}
                  checkPropertyName="requestsMethodEmail"
                  textPropertyName="requestsMethodEmailAddress"
                  label="メール"
                />
                <CheckBoxWithTextInput
                  supportPlan={supportPlan}
                  onChangeSupportPlan={onChangeSupportPlan}
                  checkPropertyName="requestsMethodOther"
                  textPropertyName="requestsMethodOtherContent"
                  label="その他"
                  placeholder="相談方法を入力"
                />
              </Column16>
              {(
                  isEmpty(supportPlan?.requestsMethodDirect) &&
                  isEmpty(supportPlan?.requestsMethodPhone) &&
                  isEmpty(supportPlan?.requestsMethodEmail) &&
                  isEmpty(supportPlan?.requestsMethodOther)
                ) &&
                  <ErrorMessage>必ず選択してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 緊急対応時 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>緊急対応時</CustomLabel>
          </LabelCell>

          <FieldCell>
            <Column5>
              <Column16>
                <LocalCheckBox
                  checked={!!supportPlan?.requestsEmergencyMethodDirect}
                  onValueChange={(val) => {
                    onChangeSupportPlan?.({requestsEmergencyMethodDirect: val});
                  }}
                >
                  直接面談
                </LocalCheckBox>
                <CheckBoxWithTextInput
                  supportPlan={supportPlan}
                  onChangeSupportPlan={onChangeSupportPlan}
                  checkPropertyName="requestsEmergencyMethodPhone"
                  textPropertyName="requestsEmergencyMethodPhoneNumber"
                  label="電　話"
                  placeholder="03-1234-5678"
                />
                <CheckBoxWithTextInput
                  supportPlan={supportPlan}
                  onChangeSupportPlan={onChangeSupportPlan}
                  checkPropertyName="requestsEmergencyMethodEmail"
                  textPropertyName="requestsEmergencyMethodEmailAddress"
                  label="メール"
                />
                <CheckBoxWithTextInput
                  supportPlan={supportPlan}
                  onChangeSupportPlan={onChangeSupportPlan}
                  checkPropertyName="requestsEmergencyMethodOther"
                  textPropertyName="requestsEmergencyMethodOtherContent"
                  label="その他"
                  placeholder="相談方法を入力"
                />
              </Column16>
              {(
                  isEmpty(supportPlan?.requestsEmergencyMethodDirect) &&
                  isEmpty(supportPlan?.requestsEmergencyMethodPhone) &&
                  isEmpty(supportPlan?.requestsEmergencyMethodEmail) &&
                  isEmpty(supportPlan?.requestsEmergencyMethodOther)
                ) &&
                  <ErrorMessage>必ず選択してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>

      <LanguageCategory
        categoryName={"ウ. 実施言語"}
        languageIdPropertyName={"requestsLanguageId"}
        languageOtherLastNamePropertyName={"requestsLanguageOtherLastName"}
        languageOtherFirstNamePropertyName={"requestsLanguageOtherFirstName"}
        languageOtherAffiliationPropertyName={"requestsLanguageOtherAffiliation"}
        supportPlan={supportPlan}
        onChangeSupportPlan={onChangeSupportPlan}
      />
    </>
  );
};

export default RequestsSection;