import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type PageHeadingProps = HTMLAttributes<HTMLHeadingElement> & {
    children?: ReactNode;
};

const Container = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;
  color: #444444;
`;

const PageHeading: FunctionComponent<PageHeadingProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default PageHeading;