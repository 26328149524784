import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Section, SectionContent, SectionIndex } from "./styles";
import { FlexRowColumn, Row, Item5, Item10, Item20, Segment, InputValue } from "../../commonStyles";
import { EcWagePaymentType } from "../../../../../core/enums/employmentConditions";
import { TemplateSectionProps } from "../../types";
import { formatAmount } from "../../util";
import CheckMark from "../../components/CheckMark";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

interface BaseWageSectionProps extends TemplateSectionProps {
}

export const RowContent = styled(Row)`
  width: auto;
  margin-right: 20px;

  &:last-child {
    margin-right: 0px;
  }
`;

export const InputValueFieldInJa = styled.div`
  word-break: break-all;
  min-width: 47px;
`;

export const InputValueField = styled.div`
  word-break: break-all;
  min-width: 43px;
`;

const BaseWageSection: FunctionComponent<BaseWageSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang };
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;
  const [baseWagePaymentType, setBaseWagePaymentType] = useState<string>("");
  const [baseWageAmount, setBaseWageAmount] = useState<string>("");
  const [amountPerHourForMonthlyOrDaily, setAmountPerHourForMonthlyOrDaily] = useState<string>("");
  const [amountPerMonthForDailyOrHourly, setAmountPerMonthForDailyOrHourly] = useState<string>("");

  const WageAmount = ({ children }: { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>

  useEffect(() => {
    if (!ec) 
      return;

    setBaseWagePaymentType(ec.baseWageType);
    setBaseWageAmount(formatAmount(ec.baseWageAmount));
    setAmountPerHourForMonthlyOrDaily(formatAmount(ec.baseWageAmountPerHour));
    setAmountPerMonthForDailyOrHourly(formatAmount(ec.baseWageAmountPerMonth));
  }, [ec]);

  return (
    <Section>
      <SectionIndex>I.</SectionIndex> 
        <SectionContent>
          <Segment>
            <span>基本賃金</span><br/>
            <span>{tFixed("base_wage")}</span>
          </Segment>
          <Segment>
            <FlexRowColumn>
              <RowContent>
                <CheckMark
                  checked={baseWagePaymentType === EcWagePaymentType.Monthly}
                >
                  月給
                </CheckMark>
                <span>
                  <Row>
                    <Item5>（</Item5>
                    <Item5>
                      <InputValueFieldInJa>
                        {
                          baseWagePaymentType === EcWagePaymentType.Monthly 
                          ? baseWageAmount
                          : ''
                        }
                      </InputValueFieldInJa>
                    </Item5>
                    <Item5>円）</Item5>
                  </Row>
                </span>
              </RowContent>
              <RowContent>
                <CheckMark
                  checked={baseWagePaymentType === EcWagePaymentType.Daily}
                >
                  日給
                </CheckMark>
                <span>
                  <Row>
                    <Item5>（</Item5>
                    <Item5>
                      <InputValueFieldInJa>
                        {
                          baseWagePaymentType === EcWagePaymentType.Daily 
                          ? baseWageAmount
                          : ''
                        }
                      </InputValueFieldInJa>
                    </Item5>
                    <Item5>円）</Item5>
                  </Row>
                </span>
              </RowContent>
              <RowContent>
                <CheckMark
                  checked={baseWagePaymentType === EcWagePaymentType.Hourly}
                >
                  時間給
                </CheckMark>
                <span>
                  <Row>
                    <Item5>（</Item5>
                    <Item5>
                      <InputValueFieldInJa>
                        {
                          baseWagePaymentType === EcWagePaymentType.Hourly 
                          ? baseWageAmount
                          : ''
                        }
                      </InputValueFieldInJa>
                    </Item5>
                    <Item5>円）</Item5>
                  </Row>
                </span>      
              </RowContent>
            </FlexRowColumn>
            <FlexRowColumn>
              <Item20>※月給・日給の場合の１時間当たりの金額</Item20>
              <div>
                <Item10>（</Item10>
                <Item10><InputValue>{amountPerHourForMonthlyOrDaily}</InputValue></Item10>
                <Item10>円）</Item10>
              </div>
            </FlexRowColumn>
            <FlexRowColumn>
              <Item20>※日給・時給の場合の１か月当たりの金額</Item20>
              <div>
                <Item10>（</Item10>
                <Item10><InputValue>{amountPerMonthForDailyOrHourly}</InputValue></Item10>
                <Item10>円）</Item10>
              </div>
            </FlexRowColumn>
          </Segment>
          <Segment>
            <FlexRowColumn>
              <RowContent>
                <Item5>
                  <CheckMark
                    checked={baseWagePaymentType === EcWagePaymentType.Monthly}
                  >
                    {tFixed("monthly_wage")}
                  </CheckMark>
                </Item5>
                <span>
                  <Row>
                    <Trans
                      i18nKey={getTKey("wage_amount")}
                      tOptions={tOptions}
                      values={{
                        amount: baseWagePaymentType === EcWagePaymentType.Monthly
                        ? baseWageAmount
                        : ''
                      }}
                      components={{
                        seg: <Item5/>,
                        amount: <WageAmount/>
                      }}
                    />
                  </Row>
                </span> 
              </RowContent>
              <RowContent>
                <Item5>
                  <CheckMark
                    checked={baseWagePaymentType === EcWagePaymentType.Daily}
                  >
                    {tFixed("daily_wage")}
                  </CheckMark>
                </Item5>
                <span>
                  <Row>
                    <Trans
                      i18nKey={getTKey("wage_amount")}
                      tOptions={tOptions}
                      values={{
                        amount: baseWagePaymentType === EcWagePaymentType.Daily
                        ? baseWageAmount
                        : ''
                      }}
                      components={{
                        seg: <Item5/>,
                        amount: <WageAmount/>
                      }}
                    />
                  </Row>
                </span> 
              </RowContent>
              <RowContent>
                <Item5>
                  <CheckMark
                    checked={baseWagePaymentType === EcWagePaymentType.Hourly}
                  >
                    {tFixed("hourly_wage")}
                  </CheckMark>              
                </Item5>
                <span>
                  <Row>
                    <Trans
                      i18nKey={getTKey("wage_amount")}
                      tOptions={tOptions}
                      values={{
                        amount: baseWagePaymentType === EcWagePaymentType.Hourly
                        ? baseWageAmount
                        : ''
                      }}
                      components={{
                        seg: <Item5/>,
                        amount: <WageAmount/>
                      }}
                    />
                  </Row>
                </span> 
              </RowContent>
            </FlexRowColumn>
            <FlexRowColumn>
              <Item20>{tFixed("hourly_rate_from_monthly")}</Item20>
              <div>
                <Trans
                  i18nKey={getTKey("wage_amount")}
                  tOptions={tOptions}
                  values={{
                    amount: amountPerHourForMonthlyOrDaily
                  }}
                  components={{
                    seg: <Item10/>,
                    amount: <WageAmount/>
                  }}
                />
              </div>
            </FlexRowColumn>
            <FlexRowColumn>
              <Item20>{tFixed("monthly_rate_from_daily_hourly")}</Item20>
              <div>
                <Trans
                  i18nKey={getTKey("wage_amount")}
                  tOptions={tOptions}
                  values={{
                    amount: amountPerMonthForDailyOrHourly
                  }}
                  components={{
                    seg: <Item10/>,
                    amount: <WageAmount/>
                  }}
                />
              </div>
            </FlexRowColumn>
          </Segment>
        </SectionContent>
      </Section>
  )
}

export default BaseWageSection;