import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import StepContent from '../../components/StepContent';
import Message from '../../../core/components/Message';
import StepLabel from '../../components/StepLabel';

interface ResultParagraphProps {
    active?: boolean;
    number?: number;
    comment?: string;
    state?: string;
}

const ResultParagraph: FunctionComponent<ResultParagraphProps> = ({
    active,
    number,
    comment,
    state,
}) => {
    const { t } = useTranslation();

    return (
        <>
            {state !== 'declined' && (
                <>
                    <StepLabel
                        active={active && !(state === 'hired' || state === 'rejected')}
                        completed={state === 'hired' || state === 'rejected'}
                        number={number}
                        comment={comment}
                    >
                        {t('proposal.status_result')}
                    </StepLabel>
                    {(state === 'hired' || state === 'rejected') && (
                        <StepContent active>
                            <Message>
                                {t(`proposal.status_${state}`)}
                            </Message>
                        </StepContent>
                    )}
                </>
            )}
        </>
    );
};

export default ResultParagraph;