import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

//import { discardUserInvitation, getCompanyUsers, sendUserInvitation } from '../../apis';
import Button from '../../components/Button';
import DiscardUserInvitationModal from '../../components/DiscardUserInvitationModal';
import Row from '../../components/Row';
import SectionHeading from '../../components/SectionHeading';
import UserInvitationModal from '../../components/UserInvitationModal';
import UserListTable from '../../components/UserListTable';
import ACTIONS from '../../constants/actions';
import { useReducerContext } from '../../contexts/ReducerContext';
import { User } from '../../types/api';
import { getUsers, inviteUser } from '../../apis/user';

interface UserManagementProps {
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
  gap: 10px;
`;

const LocalSectionHeading = styled(SectionHeading)`
   font-weight: 500;
   font-size: 32px;
   line-height: 46px;
   height: auto;
`;


const UserManagement: FunctionComponent<UserManagementProps> = ({
}) => {
    const { dispatch } = useReducerContext();
    const [users, setUsers] = useState<User[]>([]);
    const [invitations, setInvitations] = useState<User[]>([]);
    const [invitationModal, setInvitationModal] = useState(false);
    const [discardModal, setDiscardModal] = useState(false);
    const [discardIndex, setDiscardIndex] = useState(-1);
    const { t } = useTranslation();

    const fetchUsers = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: 'Fetching users...',
            },
        });
        try {
            const users = await getUsers();
            setUsers(users)
        } catch (e) {
            // TODO: error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const sendInvitation = async (role: string, email: string) => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: 'Sending invitation...',
            },
        });
        try {
            await inviteUser(email);
            await fetchUsers();
        } catch (e) {
            // TODO: error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const discardInvitation = async (role: string, email: string) => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: 'Discarding invitation...',
            },
        });
        try {
            //await discardUserInvitation(state.companyId, email, role === 'Admin');
            await fetchUsers();
        } catch (e) {
            // TODO: error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleInvitationModalSubmit = async (role: string, email: string) => {
        handleInvitationModalClose();
        await sendInvitation(role, email);
    };

    const handleInvitationModalClose = () => {
        setInvitationModal(false);
    };

    const handleInvitationResend = async (index: number) => {
        const invitation = invitations[index];
        //await sendInvitation(invitation.role, invitation.email);
    };

    const handleInvitationDiscard = (index: number) => {
        setDiscardModal(true);
        setDiscardIndex(index);
    }

    const handleDiscardModalSubmit = async () => {
        handleDiscardModalClose();
        const invitation = invitations[discardIndex];
        //await discardInvitation(invitation.role, invitation.email);
    };

    const handleDiscardModalClose = () => {
        setDiscardModal(false);
    };

    useEffect(() => {
        (async () => {
            try {
                await fetchUsers();
            } catch (e) {
            }
        })();
    }, []);

    return (
        <Container>
            <Row center style={{ width: '100%', justifyContent: 'space-between' }}>
                <LocalSectionHeading>{t("core.settings.manage_users.title")}</LocalSectionHeading>
                <Button variant="quart-add" onClick={() => setInvitationModal(true)}>
                    {t('core.invite_user')}
                </Button>
            </Row>
            <UserListTable
                style={{ marginTop: 10 }}
                users={users}
                invitations={invitations}
                onInvitationResend={handleInvitationResend}
                onInvitationDiscard={handleInvitationDiscard}
            />
            {invitationModal && (
                <UserInvitationModal
                    onSubmit={handleInvitationModalSubmit}
                    onClose={handleInvitationModalClose}
                />
            )}
            {discardModal && (
                <DiscardUserInvitationModal
                    onSubmit={handleDiscardModalSubmit}
                    onClose={handleDiscardModalClose}
                />
            )}
        </Container>
    );
};

export default UserManagement;