import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Text from '../../../components/Text';
import Section from '../../../components/Section';
import SectionHeading from '../../../components/SectionHeading';
import { useTranslation } from 'react-i18next';
import Row from '../../../components/Row';
import { ReactComponent as ArrowIcon } from '../../../../assets/icon-arrow-right.svg';
import { useNavigate } from 'react-router-dom';

interface DocumentsSettingsProps {
}

interface MenuItemProps {
    name: string;
    onClick: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
  gap: 20px;
`;

const LocalSectionHeading = styled(SectionHeading)`
   font-weight: 500;
   font-size: 32px;
   line-height: 46px;
   height: auto;
`;

const LocalSection = styled(Section)`
    padding: 0;
    width: 100%;
`;

const LocalRow = styled(Row)`
    width: 100%;
    min-height: 50px;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: #FFF0F0;
    }

    &:active {
        background-color: #FFDFD1;
    }
`;

const MenuItem: FunctionComponent<MenuItemProps> = ({ 
    name, onClick 
}) => {
    return (
        <LocalRow onClick={onClick}>
            <Text>{name}</Text>
            <ArrowIcon />
        </LocalRow>
    )
}


const DocumentsSettings: FunctionComponent<DocumentsSettingsProps> = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'core.settings' });
    const navigate = useNavigate();
    const getPath = (path: string) => `/settings/documents/${path}`;

    return (
        <Container>
            <LocalSectionHeading>{t("documents.title")}</LocalSectionHeading>
            <LocalSection>
                <MenuItem name={"内定証明書"} onClick={() => navigate(getPath("employment_offer"))} />
            </LocalSection>
        </Container>
    );
};

export default DocumentsSettings;