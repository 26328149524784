import { Fragment, FunctionComponent, HTMLAttributes, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DESKTOP_DEVICE, MOBILE_DEVICE } from '../../core/constants/styles';
import { useMediaQuery } from 'react-responsive';

const WEEKDAYS = [
    'proposal.sunday',
    'proposal.monday',
    'proposal.tuesday',
    'proposal.wednesday',
    'proposal.thursday',
    'proposal.friday',
    'proposal.saturday',
]

const TIME_SLOTS = [...Object.keys(new Array(13).fill(0)).map(key => Number(key))].map(item => item + 9)

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 41px 49px 25px;
  grid-auto-rows: minmax(25px, auto);
  width: 700px;
  border: 1px solid #D5D5D5;
  
  @media ${MOBILE_DEVICE} {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 50px 49px 25px;
    width: 100%;
  }
`

interface CellProps {
    leading?: boolean;
    horizontal?: boolean;
    vertical?: boolean;
}

const Cell = styled.div<CellProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 24px;
  color: #999999;
  background: #FFFFFF;
  border-top: ${(props: CellProps) => props.leading && props.vertical ? 'none' : '1px solid #D5D5D5'};
  border-left: ${(props: CellProps) => props.leading && props.horizontal ? 'none' : '1px solid #D5D5D5'};
`

const Range = styled(Cell)`
  position: absolute;
  top: 11px;
  left: 5px;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  
  @media ${MOBILE_DEVICE} {
    display: none;
  }
`

const Timezone = styled(Cell)`
  grid-row: 1 / 2;
  grid-column: 1 / 17;
  justify-content: flex-end;
  padding: 0 14.5px;
  transform: scale(0.5);
  transform-origin: right;
  
  @media ${MOBILE_DEVICE} {
    grid-column: 1 / 9;
    justify-content: space-between;
    padding: 0 10px;
  }
`

const Navigator = styled(Cell)`
  grid-row: span 1;
  grid-column: span 1;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #E5E5E5;
  border: none;
  border-radius: 5px;
  outline: none;
  
  @media ${MOBILE_DEVICE} {
    zoom: 2;
    
    &:disabled {
      opacity: 0;
    }
  }
`

const Arrow = styled.img`
  width: 32px;
  height: 32px;
`
const Weekday = styled(Cell)`
  grid-row: span 1;
  grid-column: span 2;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    transform: scale(0.5);
  }
  
  @media ${MOBILE_DEVICE} {
    grid-column: span 1;
  }
`

const TimeSharp = styled(Cell)`
  grid-row: span 1;
  grid-column: span 1;
  font-weight: bold;
  
  > span {
    transform: scale(0.5);
  }
`

const TimeHalf = styled(Cell)`
  grid-row: span 1;
  grid-column: span 1;
  border-top: 1px dashed #D5D5D5;
`

type SlotProps = {
    disabled?: boolean;
};

const SlotSharp = styled(Cell)<SlotProps>`
  position: relative;
  grid-row: span 1;
  grid-column: span 2;
  background: ${(props: SlotProps) => props.disabled ? '#E5E5E5' : '#FFFFFF'};
  cursor: ${(props: SlotProps) => props.disabled ? 'auto' : 'pointer'};

  @media ${MOBILE_DEVICE} {
    grid-column: span 1;
  }
`

const SlotHalf = styled(Cell)<SlotProps>`
  position: relative;
  grid-row: span 1;
  grid-column: span 2;
  background: ${(props: SlotProps) => props.disabled ? '#E5E5E5' : '#FFFFFF'};
  border-top: 1px dashed #D5D5D5;
  cursor: ${(props: SlotProps) => props.disabled ? 'auto' : 'pointer'};

  @media ${MOBILE_DEVICE} {
    grid-column: span 1;
  }
`

interface BookingBlockProps {
    focused?: boolean;
}

const BookingBlock = styled.div<BookingBlockProps>`
  position: absolute;
  top: 2px;
  right: 2px;
  left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  padding: 10px 7px;
  border-radius: 5px;
  background-color: ${(props: BookingBlockProps) => props.focused ? '#CC5700' : '#FFC194'};
  cursor: pointer;
  z-index: 2;
  
  > div {
    font-size: 24px;
    line-height: 24px;
    color: #444444;
    transform: scale(0.5);
  }
`

const DeleteButton = styled.img`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
`;

const getStartDate = () => {
    const today = moment(new Date()).utcOffset('+09:00');
    return today.weekday() < 6
        ? today.startOf('isoWeek').subtract(1, 'day')
        : today.clone().add(1, 'week').startOf('isoWeek').subtract(1, 'day')
}

type InterviewCalendarProps = HTMLAttributes<HTMLDivElement> & {
    readonly?: boolean;
    value: Date[];
    onToggle?: (date: Date) => void;
    onPick?: (date: Date) => void;
};

const InterviewCalendar: FunctionComponent<InterviewCalendarProps> = ({
    readonly,
    value,
    onToggle,
    onPick,
    ...props
}) => {
    const [today, setToday] = useState(moment(new Date()).utcOffset('+09:00'));
    const [startDate, setStartDate] = useState(getStartDate())
    const [currentStartDate, setCurrentStartDate] = useState(startDate)
    const [selectedTimeSlots, setSelectedTimeSlots] = useState<string[]>([])
    const [focusedTimeSlot, setFocusedTimeSlot] = useState('')
    const { t } = useTranslation();
    const currentEndDate = moment(currentStartDate).add(6, 'days');
    const isMobileDevice = useMediaQuery({
        query: MOBILE_DEVICE,
    });
    const isDesktopDevice = useMediaQuery({
        query: DESKTOP_DEVICE,
    });

    const toggleSelectedTimeSlot = (date: Moment) => {
        !readonly && date > today && onToggle && onToggle(date.toDate())
    }

    const focusSelectedTimeSlot = (date: Moment) => {
        setFocusedTimeSlot(date.format('MM/DD HH:mm'))
        !!readonly && onPick && onPick(date.toDate())
    }

    const isBooked = (date: Moment) => {
        const value = `${date.format('MM/DD HH:mm')}`
        return selectedTimeSlots.indexOf(value) !== -1
    }

    const isFocused = (date: Moment) => {
        return date.format('MM/DD HH:mm') === focusedTimeSlot
    }

    useEffect(() => {
        const timeSlots = value.map(date => moment(date).utcOffset('+09:00').format('MM/DD HH:mm'))
        setSelectedTimeSlots(timeSlots)
    }, [value])

    return (
        <Container {...props}>
            <Range>
                {currentStartDate.format('MMMM YYYY')}
                {currentEndDate.month() !== currentStartDate.month()
                    ? currentEndDate.format(' - MMMM YYYY')
                    : ''
                }
            </Range>
            <Timezone leading vertical horizontal>
                {isMobileDevice && (
                    <Button
                        disabled={currentStartDate <= startDate}
                        onClick={() => setCurrentStartDate(currentStartDate.clone().subtract(1, 'week'))}
                    >
                        <Arrow src="/images/icon-calendar-previous.svg" />
                    </Button>
                )}
                Timezone : (UTC+9:00) {t('timezone.jst')}
                {isMobileDevice && (
                    <Button onClick={() => setCurrentStartDate(currentStartDate.clone().add(1, 'week'))}>
                        <Arrow src="/images/icon-calendar-next.svg" />
                    </Button>
                )}
            </Timezone>
            {isDesktopDevice && (
                <Navigator leading horizontal>
                    {currentStartDate > startDate && (
                        <Button onClick={() => setCurrentStartDate(currentStartDate.clone().subtract(1, 'week'))}>
                            <Arrow src="/images/icon-calendar-previous.svg" />
                        </Button>
                    )}
                </Navigator>
            )}
            {isMobileDevice && (
                <Weekday />
            )}
            {WEEKDAYS.map((weekday, index) => {
                const date = currentStartDate.clone().add(index, 'days')
                return (
                    <Weekday key={date.toISOString()}>
                        <div>
                            <span>{t(weekday)}</span>
                            <span style={{ color: '#444444', fontSize: '28px' }}>
                                {date.format('D')}
                            </span>
                        </div>
                    </Weekday>
                )
            })}
            {isDesktopDevice && (
                <Navigator>
                    <Button onClick={() => setCurrentStartDate(currentStartDate.clone().add(1, 'week'))}>
                        <Arrow src="/images/icon-calendar-next.svg" />
                    </Button>
                </Navigator>
            )}
            {TIME_SLOTS.map(time => (
                <Fragment key={time}>
                    <TimeSharp leading horizontal>
                        <span>{time}:00</span>
                    </TimeSharp>
                    {WEEKDAYS.map((weekday, index) => {
                        const value = currentStartDate
                            .clone()
                            .add(index, 'day')
                            .set('hour', time);
                        return (
                            <SlotSharp
                                key={value.toISOString()}
                                disabled={value < today}
                                onClick={() => {
                                    toggleSelectedTimeSlot(value)
                                }}
                            >
                                {isBooked(value) && (
                                    <BookingBlock
                                        focused={isFocused(value)}
                                        onClick={() => {
                                            focusSelectedTimeSlot(value)
                                        }}
                                    >
                                        {isMobileDevice
                                            ? (!readonly && (
                                                <DeleteButton
                                                    alt="delete"
                                                    src="/images/icon-delete.svg"
                                                />
                                            ))
                                            : (
                                                <div>
                                                    {time}:00 -<br />
                                                    {time + 1}:00
                                                </div>
                                            )
                                        }
                                    </BookingBlock>
                                )}
                            </SlotSharp>
                        );
                    })}
                    {isDesktopDevice && (
                        <TimeSharp>
                            <span>{time}:00</span>
                        </TimeSharp>
                    )}
                    <TimeHalf leading horizontal />
                    {WEEKDAYS.map((weekday, index) => {
                        const value = currentStartDate
                            .clone()
                            .add(index, 'day')
                            .set({
                                hour: time,
                                minute: 30,
                            });
                        return (
                            <SlotHalf
                                key={value.toISOString()}
                                disabled={value < today}
                                onClick={() => {
                                    toggleSelectedTimeSlot(value)
                                }}
                            >
                                {isBooked(value) && (
                                    <BookingBlock
                                        focused={isFocused(value)}
                                        onClick={() => {
                                            focusSelectedTimeSlot(value)
                                        }}
                                    >
                                        {isMobileDevice
                                            ? (!readonly && (
                                                <DeleteButton
                                                    alt="delete"
                                                    src="/images/icon-delete.svg"
                                                />
                                            ))
                                            : (
                                                <div>
                                                    {time}:30 -<br />
                                                    {time + 1}:30
                                                </div>
                                            )
                                        }
                                    </BookingBlock>
                                )}
                            </SlotHalf>
                        );
                    })}
                    {isDesktopDevice && (
                        <TimeHalf />
                    )}
                </Fragment>
            ))}
        </Container>
    )
}

export default InterviewCalendar