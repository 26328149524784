import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '../../core/components/Button';
import Modal from '../../core/components/Modal';
import PageHeading from '../../core/components/PageHeading';
import Text from '../../core/components/Text';
import { MOBILE_DEVICE } from '../../core/constants/styles';

type ConfirmAnswerModalProps = {
    onConfirm: () => void;
    onClose?: () => void;
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 5px;
`;

const MainHeader = styled(PageHeading)`
  font-size: 20px;
`;

const WarningSection = styled.section`
  background-color: #FFF0F0;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  gap: 15px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%; 
  gap: 10px;
  margin-top: 20px;
`;

const OkButton = styled(Button)`
  width: 150px;

  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const CancelButton = styled(Button)`
  width: 120px;

  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const ConfirmAnswerModal: FunctionComponent<ConfirmAnswerModalProps> = (props: ConfirmAnswerModalProps) => {
    const { onConfirm, onClose } = props;
    const [isOpen, setIsOpen] = useState(true);
    const { t } = useTranslation();
    const onModalClose = () => {
        if (onClose) onClose();
        setIsOpen(false);
    }

    return (
        <Modal onClose={onClose} open={isOpen}>
            <ContentWrapper>
                <MainHeader>
                    {t('job.job_answer_modal_header')}
                </MainHeader>

                <WarningSection>
                    <img alt="cross" src="/images/icon-alert.svg" />
                    <Text>{t('job.job_answer_modal_warning')}</Text>
                </WarningSection>

                <ButtonWrapper>
                    <OkButton onClick={() => onConfirm && onConfirm()}>
                        {t('job.job_answer_modal_ok')}
                    </OkButton>
                    <CancelButton variant="secondary" onClick={onModalClose}>
                        {t('job.job_answer_modal_cancel')}
                    </CancelButton>
                </ButtonWrapper>
            </ContentWrapper>
        </Modal>
    );
}

export default ConfirmAnswerModal;