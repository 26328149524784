import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../components/Button';
import Field from '../components/Field';
import Label from '../components/Label';
import MultiTextInput from '../components/MultiTextInput';
import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import Text from '../components/Text';
import TextInput from '../components/TextInput';
import ACTIONS from '../constants/actions';
import { useReducerContext } from '../contexts/ReducerContext';
import { sendInquiry } from '../apis/misc';

interface ContactPageProps {
}

const LocalPage = styled(Page)`
    > * {
      align-items: center;
      gap: 10px;
      width: 500px;
    }
`;

const LocalField = styled(Field)`
  width: 100%;
  margin-top: 25px;
`;

const LocalTextInput = styled(TextInput)`
  width: 100%;
`;

const LocalMultiTextInput = styled(MultiTextInput)`
  width: 100%;
  height: 120px;
  resize: vertical;
`;

const LocalButton = styled(Button)`
  width: 200px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
`;

const Prompt = styled(Text)`
  margin-top: 50px;
  font-size: 12px;
  line-height: 17px;
  color: #FF730D;
`;

const Checked = styled.img`
  align-self: center;
  width: 30px;
  height: 30px;
  margin-bottom: 16px;
  object-fit: cover;
`;

const ContactPage: FunctionComponent<ContactPageProps> = () => {
    const { dispatch } = useReducerContext();
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [inquiryDetails, setInquiryDetails] = useState('');
    const [sent, setSent] = useState(false);

    const handleSend = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('Sending inquiry...'),
            },
        });
        try {
            await sendInquiry(name, email, phoneNumber, companyName, inquiryDetails);
            setSent(true);
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    return (
        <LocalPage>
            <Text>{t('core.recruitment_agencies')}</Text>
            <PageHeading>{t('core.contact')}</PageHeading>
            <Description>
                {sent && (
                    <Checked alt="sent" src="/images/icon-check.svg" />
                )}
                {t(sent ? 'core.sent_description' : 'core.contact_description')
                    .split('\n')
                    .map((item, index) => <Text key={index}>{item}</Text>)
                }
            </Description>
            {!sent && (
                <>
                    <LocalField>
                        <Label required reversed>
                            {t('core.name')}
                        </Label>
                        <LocalTextInput
                            placeholder="グエン　ダオ"
                            value={name}
                            onTextChange={setName}
                        />
                    </LocalField>
                    <LocalField>
                        <Label required reversed>
                            {t('core.email')}
                        </Label>
                        <LocalTextInput
                            placeholder="nguyen.dao@tokuty.com"
                            value={email}
                            onTextChange={setEmail}
                        />
                    </LocalField>
                    <LocalField>
                        <Label required reversed>
                            {t('core.phone_number')}
                        </Label>
                        <LocalTextInput
                            placeholder="+84-166-422-3299"
                            value={phoneNumber}
                            onTextChange={setPhoneNumber}
                        />
                    </LocalField>
                    <LocalField>
                        <Label required reversed>
                            {t('core.company_name')}
                        </Label>
                        <LocalTextInput
                            placeholder="グローバル・リクルート・エージェンシー"
                            value={companyName}
                            onTextChange={setCompanyName}
                        />
                    </LocalField>
                    <LocalField>
                        <Label required reversed>
                            {t('core.inquiry_details')}
                        </Label>
                        <LocalMultiTextInput
                            value={inquiryDetails}
                            onTextChange={setInquiryDetails}
                        />
                    </LocalField>
                    <Prompt>
                        {t('core.required_prompt')}
                    </Prompt>
                    <LocalButton
                        disabled={!(name && email && phoneNumber && companyName && inquiryDetails)}
                        onClick={handleSend}
                    >
                        {t('core.send')}
                    </LocalButton>
                </>
            )}
        </LocalPage>
    );
};

export default ContactPage;