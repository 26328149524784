import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Modal from './Modal';
import { ReactComponent as IconClose } from '../../assets/icon-close.svg';


type LanguageSwitchModalProps = HTMLAttributes<HTMLDivElement> & {
    open?: boolean;
    options: LanguageOption[];
    currentLanguage?: string;
    onLanguageChange: (value: string) => void;
    onClose: () => void;
}

interface LanguageOption {
    label: string;
    value: string;
    region: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 320px;
  height: 364px;
  border-radius: 16px;
  padding: 16px;
  background: #FFFFFF;
  gap: 8px;
`;

const ModalTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

const LanguageListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const LanguageRow = styled.div<{isApplied: boolean}>`
    display: flex;
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    gap: 4px;
    background-color: ${({ isApplied }) => isApplied ? '#FF720D' : '#FFFFFF'};
    border: 0px;
    font-size: 14px;
    font-weight: ${({ isApplied }) => isApplied ? '700' : '400'};
    color: ${({ isApplied }) => isApplied ? '#FFFFFF' : '#000000CC'};
    line-height: 14px;
    cursor: pointer;

    &:hover {
        background-color: #FFFFFF;
        font-weight: 700;
        color: #FFA211;
    }
`;

const IconField = styled.div`
  svg > path {
    fill: #00000099;
    ;
  }
`;

const SEPARATOR = '-';


const LanguageSwitchModal: FunctionComponent<LanguageSwitchModalProps> = ({
    open,
    options,
    currentLanguage,
    onLanguageChange,
    onClose,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Modal open={open}>
            <Container>
                <IconField>
                    <IconClose onClick={onClose} />
                </IconField>
                <ModalTitle>{t('core.choose_language_region')}</ModalTitle>
                <LanguageListContainer>
                    {
                        options.map((option) => 
                          option.value === currentLanguage ? 
                          (
                            <LanguageRow isApplied key={option.value} onClick={() => onLanguageChange(option.value)}>
                                <span>{option.label}</span>
                                <span>{SEPARATOR}</span>
                                <span>{option.region}</span>
                            </LanguageRow>
                          ) : 
                          (
                            <LanguageRow isApplied={false} key={option.value} onClick={() => onLanguageChange(option.value)}>
                                <span>{option.label}</span>
                                <span>{SEPARATOR}</span>
                                <span>{option.region}</span>
                            </LanguageRow>
                          )
                        )
                    }
                </LanguageListContainer>
            </Container>
        </Modal>
    );
};

export default LanguageSwitchModal;