import { ButtonHTMLAttributes, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface ActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'normal'|'inline';
    color?: 'normal'|'red';
    children?: ReactNode;
}

const Container = styled.button<ActionButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${(props: ActionButtonProps) => props.variant === 'inline' ? '24px' : '30px'};
  padding: ${(props: ActionButtonProps) => props.variant === 'inline' ? '0 10px' : '0 20px'};
  font-size: 14px;
  font-weight: 400;
  color: ${(props: ActionButtonProps) => props.color === 'red'
          ? '#E93232'
          : '#444444'
  };
  background: ${(props: ActionButtonProps) => props.color === 'red'
          ? '#FFF0F0'
          : '#E5E5E5'
  };
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
  white-space: nowrap;

  &:hover {
    color: ${(props: ActionButtonProps) => props.color === 'red'
            ? '#FFFFFF'
            : '#FF730D'
    };
    background: ${(props: ActionButtonProps) => props.color === 'red'
            ? '#F98686'
            : '#FFDFD1'
    };
    border-color: ${(props: ActionButtonProps) => props.color === 'red'
            ? '#F98686'
            : '#D5D5D5'
    };
  }
  
  &:focus {
    color: ${(props: ActionButtonProps) => props.color === 'red'
            ? '#E93232'
            : '#FF730D'
    };
    background: ${(props: ActionButtonProps) => props.color === 'red'
            ? '#F98686'
            : '#FFDFD1'
    };
    border-color: ${(props: ActionButtonProps) => props.color === 'red'
            ? '#F98686'
            : '#FFC194'
    };
  }

  &:disabled {
    color: #999999;
    background: #D5D5D5;
    border-color: #D5D5D5;
  }
`;

const ActionButton: FunctionComponent<ActionButtonProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default ActionButton;