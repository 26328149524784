import { FunctionComponent } from "react";
import {
  FieldCell,
  LabelCell,
  SectionHeadingLarge,
  HeadingContainer,
  GridRowsContainer,
  GridRow,
  LocalTextInputExtend,
} from "../commonStyle";
import Label from "../../../../core/components/Label";
import { useTranslation } from "react-i18next";
import { JobFormSectionProps } from "../type";

interface SupportPersonInformationSectionProps extends JobFormSectionProps {}


export const SupportPersonInformationSection: FunctionComponent<SupportPersonInformationSectionProps> = ({
  job, 
  onChange 
}) => {
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });

  const updatePersonLastName = (val: string | undefined) => {
    onChange?.({
      spSupportSignaturePersonLastName: val,
      spGuidanceWorkingConditionsEntrustingPersonLastName: val,
      spGuidanceActivityEntrustingPersonLastName: val,
      spGuidanceProceduresEntrustingPersonLastName: val,
      spGuidanceProhibitionsEntrustingPersonLastName: val,
      spGuidanceUnderstandingCostsEntrustingPersonLastName: val,
      spGuidanceNoBearingCostsEntrustingPersonLastName: val,
      spGuidancePickUpDropOffEntrustingPersonLastName: val,
      spGuidanceHousingEntrustingPersonLastName: val,
      spGuidanceRequestsEntrustingPersonLastName: val,
      spGuidancePersonContactEntrustingPersonLastName: val,
      spPickUpDropOffArrivalEntrustingPersonLastName: val,
      spPickUpDropOffDepartureEntrustingPersonLastName: val,
      spLifeForeignerHousingEntrustingPersonLastName: val,
      spLifeRentalHousingEntrustingPersonLastName: val,
      spLifeOwnHousingEntrustingPersonLastName: val,
      spLifeAccountOpeningEntrustingPersonLastName: val,
      spLifeMobilePhoneEntrustingPersonLastName: val,
      spLifeLifelineEntrustingPersonLastName: val,
      spOrientationGeneralLifeEntrustingPersonLastName: val,
      spOrientationProcedureEntrustingPersonLastName: val,
      spOrientationRequestsEntrustingPersonLastName: val,
      spOrientationMedicalEntrustingPersonLastName: val,
      spOrientationEmergencyEntrustingPersonLastName: val,
      spOrientationViolationEntrustingPersonLastName: val,
      spOpportunitiesAdmissionEntrustingPersonLastName: val,
      spOpportunitiesMaterialsEntrustingPersonLastName: val,
      spOpportunitiesTeachersEntrustingPersonLastName: val,
      spRequestsProperlyResponsesEntrustingPersonLastName: val,
      spRequestsAdministrativeOrgansEntrustingPersonLastName: val,
      spInteractionsProvidingEntrustingPersonLastName: val,
      spInteractionsCulturalEntrustingPersonLastName: val,
      spJobChangeAcceptanceEntrustingPersonLastName: val,
      spJobChangeIntroductionEntrustingPersonLastName: val,
      spJobChangeRecommendationEntrustingPersonLastName: val,
      spJobChangeMediationEntrustingPersonLastName: val,
      spJobChangeProceduresEntrustingPersonLastName: val,
      spJobChangeBankruptcyEntrustingPersonLastName: val,
      spInterviewsLaborSituationEntrustingPersonLastName: val,
      spInterviewsReconfirmationEntrustingPersonLastName: val,
      spInterviewsLegalViolationEntrustingPersonLastName: val,
      spInterviewsOtherProblemsEntrustingPersonLastName: val,
    });
  }

  const updatePersonFirstName = (val: string | undefined) => {
    onChange?.({
      spSupportSignaturePersonFirstName: val,
      spGuidanceWorkingConditionsEntrustingPersonFirstName: val,
      spGuidanceActivityEntrustingPersonFirstName: val,
      spGuidanceProceduresEntrustingPersonFirstName: val,
      spGuidanceProhibitionsEntrustingPersonFirstName: val,
      spGuidanceUnderstandingCostsEntrustingPersonFirstName: val,
      spGuidanceNoBearingCostsEntrustingPersonFirstName: val,
      spGuidancePickUpDropOffEntrustingPersonFirstName: val,
      spGuidanceHousingEntrustingPersonFirstName: val,
      spGuidanceRequestsEntrustingPersonFirstName: val,
      spGuidancePersonContactEntrustingPersonFirstName: val,
      spPickUpDropOffArrivalEntrustingPersonFirstName: val,
      spPickUpDropOffDepartureEntrustingPersonFirstName: val,
      spLifeForeignerHousingEntrustingPersonFirstName: val,
      spLifeRentalHousingEntrustingPersonFirstName: val,
      spLifeOwnHousingEntrustingPersonFirstName: val,
      spLifeAccountOpeningEntrustingPersonFirstName: val,
      spLifeMobilePhoneEntrustingPersonFirstName: val,
      spLifeLifelineEntrustingPersonFirstName: val,
      spOrientationGeneralLifeEntrustingPersonFirstName: val,
      spOrientationProcedureEntrustingPersonFirstName: val,
      spOrientationRequestsEntrustingPersonFirstName: val,
      spOrientationMedicalEntrustingPersonFirstName: val,
      spOrientationEmergencyEntrustingPersonFirstName: val,
      spOrientationViolationEntrustingPersonFirstName: val,
      spOpportunitiesAdmissionEntrustingPersonFirstName: val,
      spOpportunitiesMaterialsEntrustingPersonFirstName: val,
      spOpportunitiesTeachersEntrustingPersonFirstName: val,
      spRequestsProperlyResponsesEntrustingPersonFirstName: val,
      spRequestsAdministrativeOrgansEntrustingPersonFirstName: val,
      spInteractionsProvidingEntrustingPersonFirstName: val,
      spInteractionsCulturalEntrustingPersonFirstName: val,
      spJobChangeAcceptanceEntrustingPersonFirstName: val,
      spJobChangeIntroductionEntrustingPersonFirstName: val,
      spJobChangeRecommendationEntrustingPersonFirstName: val,
      spJobChangeMediationEntrustingPersonFirstName: val,
      spJobChangeProceduresEntrustingPersonFirstName: val,
      spJobChangeBankruptcyEntrustingPersonFirstName: val,
      spInterviewsLaborSituationEntrustingPersonFirstName: val,
      spInterviewsReconfirmationEntrustingPersonFirstName: val,
      spInterviewsLegalViolationEntrustingPersonFirstName: val,
      spInterviewsOtherProblemsEntrustingPersonFirstName: val,
    });
  }

  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>{tJob("support_person_information")}</SectionHeadingLarge>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 支援責任者の名字 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob("support_manager_last_name")}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              placeholder={tJob("support_manager_last_name_example")}
              value={job?.spSupportOrganizationSupportManagerLastName ?? ""}
              onTextChange={(val) => {
                onChange?.({ spSupportOrganizationSupportManagerLastName: val });
              }}
            />
          </FieldCell>
        </GridRow>

        {/* 支援責任者の名前 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob("support_manager_first_name")}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              placeholder={tJob("support_manager_first_name_example")}
              value={job?.spSupportOrganizationSupportManagerFirstName ?? ""}
              onTextChange={(val) => {
                onChange?.({ spSupportOrganizationSupportManagerFirstName: val });
              }}
            />
          </FieldCell>
        </GridRow>

        {/* 支援責任者の名字ふりがな */}
        <GridRow>
          <LabelCell>
            <Label>{tJob("support_manager_last_name_furigana")}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              placeholder={tJob("support_manager_last_name_furigana_example")}
              value={job?.spSupportOrganizationSupportManagerLastNameFurigana ?? ""}
              onTextChange={(val) => {
                onChange?.({ spSupportOrganizationSupportManagerLastNameFurigana: val });
              }}
            />
          </FieldCell>
        </GridRow>

        {/* 支援責任者の名前ふりがな */}
        <GridRow>
          <LabelCell>
            <Label>{tJob("support_manager_first_name_furigana")}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              placeholder={tJob("support_manager_first_name_furigana_example")}
              value={job?.spSupportOrganizationSupportManagerFirstNameFurigana ?? ""}
              onTextChange={(val) => {
                onChange?.({ spSupportOrganizationSupportManagerFirstNameFurigana: val });
              }}
            />
          </FieldCell>
        </GridRow>

        {/* 支援担当者の氏名（名字） */}
        <GridRow>
          <LabelCell>
            <Label>{tJob("support_person_last_name")}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              placeholder={tJob("support_person_last_name_example")}
              value={job?.spSupportSignaturePersonLastName ?? ""}
              onTextChange={(val) =>
                updatePersonLastName(val)
              }
            />
          </FieldCell>
        </GridRow>

        {/* 支援担当者の氏名（名前） */}
        <GridRow>
          <LabelCell>
            <Label>{tJob("support_person_first_name")}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInputExtend
              placeholder={tJob("support_person_first_name_example")}
              value={job?.spSupportSignaturePersonFirstName ?? ""}
              onTextChange={(val) =>
                updatePersonFirstName(val)
              }
            />
          </FieldCell>
        </GridRow>

      </GridRowsContainer>
    </>
  );
};

export default SupportPersonInformationSection;