import {apiUtil} from "../../core/utils";
import {SupportPlan} from "../types";

export const createProposalSupportPlans = async (proposalId: string | number, sp?: Partial<SupportPlan>) => {
  const response = await apiUtil.post(
      '/proposal_support_plans',
      { 
        proposalId,
        ...sp
      },
  );
  if (response.status >= 200 && response.status < 300) {
      return response.data as SupportPlan;
  }
  throw new Error('API Error');
};

export const updateProposalSupportPlans = async (spId: string | number, sp: Partial<SupportPlan>) => {
  const {
    //Unnecessary fields
    id,
    createdAt,
    updatedAt,
    proposalId,
    //Extract only the necessary fields
    ...targetUpdateData
  } = sp;

  const response = await apiUtil.put(
      `/proposal_support_plans/${spId}`,
      targetUpdateData,
  );
  if (response.status >= 200 && response.status < 300) {
      return response.data as SupportPlan;
  }
  throw new Error('API Error');
}