import { FunctionComponent, InputHTMLAttributes, useEffect, useState } from "react";
import TextInput from "./TextInput";
import styled from "styled-components";
import Row from "./Row";
import { useTranslation } from "react-i18next";
import Text from "./Text";
import { isEmpty } from "../utils";

//Spec:
//https://www.figma.com/file/UwZXypeCGS6TGrveckqOOU/tokuty?type=design&node-id=4958-109257&mode=design&t=113laKbAFV2BEylV-4
interface AmountOfTimeFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  hours?: number | null;
  minutes?: number | null;
  maxHourDigits?: number;
  upTo24Hours?: boolean;
  placeholderForHours?: string;
  placeholderForMinutes?: string;
  onHoursChange?: (value: number | null) => void;
  onMinutesChange?: (value: number | null) => void;
} 

interface HourTextInputProps {
  upTo24Hours?: boolean;
}


const HourTextInput = styled(TextInput)<HourTextInputProps>`
  text-align: left;
  width: ${({ upTo24Hours }) => upTo24Hours ? '36px' : '52px'};
  text-indent: 5px;
`;

const MinuteTextInput = styled(TextInput)`
  text-align: center;
  width: 36px;

  &::placeholder { 
    text-align: center;
  }
`;

const Container = styled(Row)`
  align-items: center;
  gap: 5px;
`;

const AmountOfTimeField: FunctionComponent<AmountOfTimeFieldProps> = ({
  hours,
  minutes,
  upTo24Hours = false,
  placeholderForHours,
  placeholderForMinutes,
  onHoursChange,
  onMinutesChange,
  ...props
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'core.units'});
  const hourRegex = 
    upTo24Hours 
      ? /^(0|([1-9]|1[0-9]|2[0-3]))$/ 
      : /^(0|([1-9][0-9]{0,3}))$/;
  const minuteRegex = /^([0-5]?[0-9])$/;
  const pad0 = (val?: number | null) => val != null ? val.toString().padStart(2, '0') : "";

  //These are to handle empty values because the given value is a number
  const [displayHours, setDisplayHours] = useState<string>("");
  const [displayMinutes, setDisplayMinutes] = useState<string>("");
  const [hoursUpdated, setHoursUpdated] = useState(false);
  const [minutesUpdated, setMinutesUpdated] = useState(false);

  const handleHoursChange = (newValue: string) => {
    if (hourRegex.test(newValue) || isEmpty(newValue)) {
      onHoursChange?.(
        isEmpty(newValue) 
          ? null 
          : Number(newValue)
      );
      setDisplayHours(newValue);
      setHoursUpdated(true);
    }
  };

  const handleMinutesChange = (newValue: string) => {
    if (minuteRegex.test(newValue) || isEmpty(newValue)) 
      onMinutesChange?.(
        isEmpty(newValue) 
          ? null 
          : Number(newValue)
      );
      setDisplayMinutes(newValue);
      setMinutesUpdated(true);
  }

  useEffect(() => {
    if (isEmpty(hours) || hoursUpdated)
      return;

    setDisplayHours(String(hours));
    setHoursUpdated(true);
  }, [hours]);

  useEffect(() => {
    if (isEmpty(minutes) || minutesUpdated)
      return;

    setDisplayMinutes(pad0(minutes));
    setMinutesUpdated(true);
  }, [minutes]);

  return (
      <Container>
        <HourTextInput 
          value={displayHours}
          placeholder={placeholderForHours ?? "40"}
          onTextChange={handleHoursChange}
          upTo24Hours={upTo24Hours}
          {...props}
        />
        <Text>{t("hours")}</Text>
        <MinuteTextInput
          value={displayMinutes} 
          placeholder={placeholderForMinutes ?? "0"}
          onTextChange={handleMinutesChange}
          onBlur={() => {
            setDisplayMinutes(pad0(minutes));
          }}
          {...props}
          />
        <Text>{t("minutes")}</Text>
      </Container>
  )
}

export default AmountOfTimeField;