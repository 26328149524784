import { Month, Nationality } from '../types/api';
import { apiUtil } from '../utils';

export const getMonths = async () => {
    const response = await apiUtil.get('/months');
    if (response.status >= 200 && response.status < 300) {
        return response.data as Month[];
    }
    return [];
};

export const getNationalities = async () => {
    const response = await apiUtil.get('/nationalities');
    if (response.status >= 200 && response.status < 300) {
        return response.data as Nationality[];
    }
    return [];
};