import React, {FunctionComponent} from "react";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import {
  CustomLabel, DatePickerWrapper, FieldCell, GridRow,
  LabelCell, LocalTextInput,
} from "../commonStyle";
import DatePicker from "../../../../core/components/DatePicker";
import {isEmpty} from "../../../../core/utils";
import ErrorMessage from "./ErrorMessage";

interface ScheduleDateRowProps extends SupportPlanFormProps {
  propertyName: SupportPlanExtractType<string>;
}

const ScheduleDateRow: FunctionComponent<ScheduleDateRowProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
    propertyName,
}) => {
  return (
    <GridRow>
      <LabelCell>
        <CustomLabel required>実施予定時期</CustomLabel>
      </LabelCell>
      <FieldCell>
        <DatePickerWrapper>
          <DatePicker
            placeholder="2000/01/23"
            selected={
              supportPlan?.[propertyName]
                ? new Date(supportPlan?.[propertyName] as string)
                : undefined
            }
            onChange={(val) =>
              onChangeSupportPlan?.({[propertyName]: val.toISOString()})
            }
            customInput={<LocalTextInput/>}
            error={isEmpty(supportPlan?.[propertyName])}
          />
        </DatePickerWrapper>
        {isEmpty(supportPlan?.[propertyName]) &&
            <ErrorMessage>入力してください</ErrorMessage>
        }
      </FieldCell>
    </GridRow>
  );
};

export default ScheduleDateRow;