import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type MenuItemProps = HTMLAttributes<HTMLDivElement> & {
    children?: ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 150px;
  height: 40px;
  padding: 10px;
  color: #444444;
  white-space: nowrap;
  
  &:hover {
    background: #FFF0F0;
  }
`;

const MenuItem: FunctionComponent<MenuItemProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default MenuItem;