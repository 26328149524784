import { CandidateVisaStatus } from "../enums/candidate";

export const CONVERSATION_SKILLS_OPTIONS = [
    {
        label: 'tokuty.basic_conversation_skills',
        value: '0',
    },
    {
        label: 'tokuty.daily_conversation_skills',
        value: '1',
    },
    {
        label: 'tokuty.fluent_conversation_skills',
        value: '2',
    },
];

export const GENDER_ITEMS = [
    {
        label: 'tokuty.male',
        value: 'male',
    },
    {
        label: 'tokuty.female',
        value: 'female',
    }
];

export const LANGUAGE_LEVEL_ITEMS = [
    {
        label: 'ui.language_level_1',
        value: 1,
    },
    {
        label: 'ui.language_level_2',
        value: 2,
    },
    {
        label: 'ui.language_level_3',
        value: 3,
    },
    {
        label: 'ui.language_level_4',
        value: 4,
    },
    {
        label: 'ui.language_level_5',
        value: 5,
    },
];

export const NATIONALITY_ITEMS = [
    {
        label: 'country.VN',
        value: 'VN',
    },
    {
        label: 'country.NP',
        value: 'NP',
    },
    {
        label: 'country.ID',
        value: 'ID',
    },
    {
        label: 'country.PH',
        value: 'PH',
    },
    {
        label: 'country.KH',
        value: 'KH',
    },
    {
        label: 'country.MM',
        value: 'MM',
    },
    {
        label: 'country.TH',
        value: 'TH',
    },
    {
        label: 'country.MN',
        value: 'MN',
    },
    {
        label: 'country.BD',
        value: 'BD',
    },
    {
        label: 'country.PK',
        value: 'PK',
    },
    {
        label: 'country.LK',
        value: 'LK',
    },
    {
        label: 'country.UZ',
        value: 'UZ',
    },
    {
        label: 'country.other',
        value: 'other',
    },
];

export const RESUME_UPLOAD_TYPE_MAPPING = {
    resume: 'resume',
    jLPTFile: 'jlpt',
    primaryTokuteiGinoExamFile: 'primary_test_upload_file',
    secondaryTokuteiGinoExamFile: 'secondary_test_upload_file',
    primaryTokuteiGinoTrainingFile: 'primary_training_upload_file',
    secondaryTokuteiGinoTrainingFile: 'secondary_training_upload_file',
    residentCardFront: 'residence_card_front',
    residentCardBack: 'residence_card_back',
}

export const UPLOAD_TYPE_ITEMS = [
    {
        label: 'tokuty.resume_upload',
        value: 'resume',
    },
    {
        label: 'tokuty.jlpt_upload',
        value: 'jlpt',
    },
    {
        label: 'tokuty.gino_test_upload',
        value: 'primary_test_upload_file',
    },
    {
        label: 'tokuty.gento_test_upload',
        value: 'secondary_test_upload_file',
    },
    {
        label: 'tokuty.zuiji3kyu_training_upload',
        value: 'primary_training_upload_file',
    },
    {
        label: 'tokuty.training_evaluation_sheet_upload',
        value: 'secondary_training_upload_file',
    },
    {
        label: 'tokuty.residence_card_front_upload',
        value: 'residence_card_front',
    },
    {
        label: 'tokuty.residence_card_back_upload',
        value: 'residence_card_back',
    },
    {
        label: 'ui.other',
        value: 'other',
    },
];

export const VISA_STATUS_ITEMS = [
    {
        label: 'candidate.student',
        value: CandidateVisaStatus.Student,
    },
    {
        label: 'candidate.specified_skilled_worker_1',
        value: CandidateVisaStatus.SpecifiedSkilledWorker1,
    },
    {
        label: 'candidate.specified_skilled_worker_2',
        value: CandidateVisaStatus.SpecifiedSkilledWorker2,
    },
    {
        label: 'candidate.trainee',
        value: CandidateVisaStatus.Trainee,
    },
    {
        label: 'candidate.designated_activities',
        value: CandidateVisaStatus.DesignatedActivities,
    },
    {
        label: 'candidate.living_overseas',
        value: CandidateVisaStatus.LivingOverseas,
    },
    {
        label: 'candidate.other',
        value: CandidateVisaStatus.Other,
    },
];