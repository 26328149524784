import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../../core/components/Button';
import MultiSelect from '../../../core/components/MultiSelect';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { CONVERSATION_SKILLS_OPTIONS, GENDER_ITEMS } from '../../../job/constants/options';
import Text from '../../../core/components/Text';
import { useJobPostOptions } from '../../../core/hooks';

interface SearchPanelProps {
    onSubmit?: (filters: { [key: string]: any }) => void;
    onClear?: () => void;
    targetIndustryIds?: number [];
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  
  > *:not(:first-child) {
    margin-left: 10px;
  }
`;

const FilterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 10px;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  
  img {
    width: 18px;
    object-fit: cover;
  }
`;

const ApplyButton = styled(Button)`
  height: 30px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const TextButton = styled(Text)`
  cursor: pointer;
`;

const SearchPanel: FunctionComponent<SearchPanelProps> = ({
    onSubmit,
    onClear,
    targetIndustryIds
}) => {
    const { state } = useReducerContext();
    const { t } = useTranslation();
    const { jobTypeOptionsDic } = useJobPostOptions();
    const [jobTypes, setJobTypes] = useState<string[]>([]);
    const [nationalities, setNationalities] = useState<string[]>([]);
    const [genders, setGenders] = useState<string[]>([]);
    const [conversationSkills, setConversationSkills] = useState<string[]>([]);
    const mergedJobTypeOptions = useMemo(() => (
            targetIndustryIds?.map(
                targetIndustryId => jobTypeOptionsDic[targetIndustryId] ?? []
            ).flat() ?? []
    ), [jobTypeOptionsDic, targetIndustryIds]);
    const nationalityOptions = Object.entries(state.nationalities)
        .map(([key, value]) => ({
            label: value.name,
            value: key,
        }));

    const handleSubmit = () => {
        const filters: { [key: string]: any } = {};
        if (jobTypes.length) {
            filters['jobTypeIds'] = jobTypes.map((jobType) => Number(jobType));
        }
        if (nationalities.length) {
            filters['nationalityIds'] = nationalities.map((nationality) => Number(nationality));
        }
        if (genders.length) {
            filters['genders'] = genders;
        }
        if (conversationSkills.length) {
            filters['japaneseConversationSkills'] = conversationSkills;
        }
        if (targetIndustryIds && targetIndustryIds.length > 0) {
            filters['industryIds'] = targetIndustryIds; 
        }

        onSubmit?.(filters);
    };

    const handleClear = () => {
        setJobTypes([]);
        setNationalities([]);
        setGenders([]);
        setConversationSkills([]);

        onClear?.();
    };

    return (
        <Container>
            <FilterContainer>
                <Icon>
                    <img alt="filter" src="/images/icon-filter.svg" />
                </Icon>
                <MultiSelect
                    placeholder={t('job.job_type')}
                    options={mergedJobTypeOptions}
                    value={jobTypes}
                    onValueChange={setJobTypes}
                />
                <MultiSelect
                    placeholder={t('candidate.nationality')}
                    options={nationalityOptions}
                    value={nationalities}
                    onValueChange={setNationalities}
                />
                <MultiSelect
                    placeholder={t('candidate.gender')}
                    options={GENDER_ITEMS}
                    value={genders}
                    onValueChange={setGenders}
                />
                <MultiSelect
                    placeholder={t('candidate.japanese_conversation_skills')}
                    options={CONVERSATION_SKILLS_OPTIONS}
                    value={conversationSkills}
                    onValueChange={setConversationSkills}
                />
                <ApplyButton
                    variant="tertiary"
                    onClick={handleSubmit}
                >
                    {t('core.apply')}
                </ApplyButton>
                <TextButton onClick={handleClear}>
                    {t('scout.reset')}
                </TextButton>
            </FilterContainer>
        </Container>
    );
};

export default SearchPanel;