import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../core/components/Button';
import Column from '../../core/components/Column';
import Modal from '../../core/components/Modal';
import MultiTextInput from '../../core/components/MultiTextInput';
import PageHeading from '../../core/components/PageHeading';
import Text from '../../core/components/Text';

interface QuestionPostModalProps {
    type: string;
    onSubmit?: (question: string) => void;
    onClose?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 5px;
  
  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: inside;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  > *:not(:first-child) {
    margin-left: 10px;
  }
`;

const QuestionPostModal: FunctionComponent<QuestionPostModalProps> = ({
    type,
    onSubmit,
    onClose,
}) => {
    const [question, setQuestion] = useState('');
    const [invalid, setInvalid] = useState(false);
    const { t } = useTranslation();

    return (
        <Modal onClose={onClose}>
            <Container>
                <PageHeading>
                    {t(`tokuty.${type}_question_title`)}
                </PageHeading>

                <Text style={{ width: '100%' }}>
                    <List>
                        {t(`tokuty.${type}_question_details`)
                            .split('\\n')
                            .map(item => (
                                <li>{item}</li>
                            ))
                        }
                    </List>
                </Text>

                {type === 'job' && (
                    <Text style={{ width: '100%', color: '#E93232' }}>
                        <List style={{ listStyle: 'none' }}>
                            {t(`tokuty.job_question_warnings`)
                                .split('\\n')
                                .map(item => (
                                    <li>{item}</li>
                                ))
                            }
                        </List>
                    </Text>
                )}

                <Column style={{ width: '100%' }}>
                    <MultiTextInput
                        invalid={invalid}
                        style={{ width: '100%', height: 90 }}
                        placeholder={t(`tokuty.${type}_question_example`)}
                        value={question}
                        onTextChange={setQuestion}
                        onFocus={() => setInvalid(false)}
                        onBlur={() => setInvalid(question.length > 500)}
                    />
                    <Text style={{ marginTop: 2, color: invalid ? '#E93232' : '#999999' }}>
                        {/*t('tokuty.text_count', question.length, 500)} {question.length > 500 && t('tokuty.text_too_long_warning', 500)*/}
                    </Text>
                </Column>

                <Actions style={{ marginTop: 30 }}>
                    <Button
                        disabled={question.length < 1 || question.length > 500}
                        onClick={() => onSubmit && onSubmit(question)}
                    >
                        {t('ui.submit')}
                    </Button>
                    <Button variant="secondary" onClick={onClose}>
                        {t('ui.cancel')}
                    </Button>
                </Actions>
            </Container>
        </Modal>
    );
}

export default QuestionPostModal;