import { FunctionComponent } from "react";
import styled from "styled-components";
import BaseWageSection from "./compensations/BaseWageSection";
import BenefitsSection from "./compensations/BenefitsSection";
import DeductionsSection from "./compensations/DeductionsSection";
import { FormBlockProps } from "../types";

interface CompensationsBlockProps extends FormBlockProps {}

const FormContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 0px 0px 30px 0px;
  border-radius: 5px;
  border: 0.5px solid #e5d5d5;
`;

const CompensationsBlock: FunctionComponent<CompensationsBlockProps> = ({
  ...props
}) => {
  return (
    <FormContainer>
      <BaseWageSection {...props}/>
      <BenefitsSection {...props}/>
      <DeductionsSection {...props}/> 
    </FormContainer> 
  );
};

export default CompensationsBlock;
