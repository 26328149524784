import { HTMLAttributes, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../../core/components/Button';
import Field from '../../../core/components/Field';
import Label from '../../../core/components/Label';
import MultiSelect from '../../../core/components/MultiSelect';
import SearchBar from '../../../core/components/SearchBar';
import Select, { SelectOption } from '../../../core/components/Select';
import {
    CONVERSATION_SKILLS_OPTIONS,
    LANGUAGE_LEVEL_ITEMS
} from '../../constants/options';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import { useJobPostOptions } from '../../../core/hooks';

type SearchPanelProps = HTMLAttributes<HTMLDivElement> & {
    detailed?: boolean;
    keyword?: string;
    locationOptions?: SelectOption[];
    locations?: string[];
    industry?: string;
    jobTypes?: string[];
    gender?: string;
    conversationSkill?: string;
    jlptLevel?: string;
    onDetailedChange?: (detailed: boolean) => void;
    onKeywordChange?: (keyword: string) => void;
    onLocationsChange?: (locations: string[]) => void;
    onIndustryChange?: (industry: string) => void;
    onJobTypesChange?: (jobType: string[]) => void;
    onGenderChange?: (gender: string) => void;
    onConversationSkillChange?: (conversationSkill: string) => void;
    onJlptLevelChange?: (jlptLevel: string) => void;
    onSearch?: () => void;
    onApplyFilter?: () => void;
    onClearFilter?: () => void;
    onResize?: () => void;
};

type ToggleButtonProps = {
    active?: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  background: #FFFFFF;
  border-bottom: 1px solid #E5E5E5;
  
  @media ${MOBILE_DEVICE} {
    padding: 20px;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  > *:not(:first-child) {
    margin-left: 36px;
  }

  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    
    > *:not(:first-child) {
      margin-top: 10px;
      margin-left: 0;
    }
  }
`;

const ToggleContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 0 20px;
  align-items: flex-end;
  margin-top: 20px;

  > *:not(:first-child) {
    margin-left: 20px;
  }
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;

    > *:not(:first-child) {
      margin-top: 10px;
      margin-left: 0;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  
  > *:not(:first-child) {
    margin-left: 60px;
  }
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;

    > *:not(:first-child) {
      margin-top: 10px;
      margin-left: 0;
    }
  }
`;

const ToggleButton = styled.button<ToggleButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 10px;
  font-size: 14px;
  color: ${(props: ToggleButtonProps) => props.active ? '#FF730D' : '#444444'};
  background: ${(props: ToggleButtonProps) => props.active ? '#FFDFD1' : '#FFFFFF'};
  border: ${(props: ToggleButtonProps) => props.active ? '2px solid #FFC194' : '1px solid #D5D5D5'};
  
  &:hover {
    color: #FF730D;
    background: #FFDFD1;
  }
  
  @media ${MOBILE_DEVICE} {
    display: none;
  }
`;

const LocalSearchBar = styled(SearchBar)`
  width: 400px;

  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;



const SearchPanel = forwardRef<HTMLDivElement, SearchPanelProps>(({
    detailed,
    keyword,
    locationOptions,
    locations,
    industry,
    jobTypes,
    gender,
    conversationSkill,
    jlptLevel,
    onDetailedChange,
    onKeywordChange,
    onLocationsChange,
    onIndustryChange,
    onJobTypesChange,
    onGenderChange,
    onConversationSkillChange,
    onJlptLevelChange,
    onSearch,
    onApplyFilter,
    onClearFilter,
    onResize,
    ...props
}, ref) => {
    const { t } = useTranslation();
    const { industryOptions, jobTypeOptionsDic } = useJobPostOptions();
    const jobTypeOptions = industry ? jobTypeOptionsDic[industry] : [];

    const clearFilters = () => {
        onLocationsChange && onLocationsChange([]);
        onIndustryChange && onIndustryChange('');
        onJobTypesChange && onJobTypesChange([]);
        onGenderChange && onGenderChange('');
        onConversationSkillChange && onConversationSkillChange('');
        onJlptLevelChange && onJlptLevelChange('');
        if (onClearFilter) onClearFilter();
    };

    return (
        <Container ref={ref} {...props}>
            <SearchContainer>
                <ToggleContainer>
                    <ToggleButton
                        active={detailed}
                        style={{ borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}
                        onClick={() => onDetailedChange && onDetailedChange(true)}
                    >
                        {t('job.detailed_view')}
                    </ToggleButton>
                    <ToggleButton
                        active={!detailed}
                        style={{ borderTopRightRadius: 15, borderBottomRightRadius: 15 }}
                        onClick={() => onDetailedChange && onDetailedChange(false)}
                    >
                        {t('job.list_view')}
                    </ToggleButton>
                </ToggleContainer>
                <LocalSearchBar onChange={onKeywordChange} onSearch={onSearch} />
            </SearchContainer>
            <FilterContainer>
                <Field>
                    <Label>{t('job.location')}</Label>
                    <MultiSelect
                        placeholder="core.select"
                        options={locationOptions}
                        value={locations}
                        onValueChange={onLocationsChange}
                    />
                </Field>
                <Field>
                    <Label>{t('job.industry')}</Label>
                    <Select
                        placeholder="core.select"
                        options={industryOptions}
                        value={industry}
                        onValueChange={onIndustryChange}
                    />
                </Field>
                <Field>
                    <Label>{t('job.job_type')}</Label>
                    <MultiSelect
                        placeholder="core.select"
                        disabled={!industry}
                        options={jobTypeOptions}
                        value={jobTypes}
                        onValueChange={onJobTypesChange}
                    />
                </Field>
                <Field>
                    <Label>{t('job.japanese_conversation_skills')}</Label>
                    <Select
                        placeholder="core.select"
                        options={CONVERSATION_SKILLS_OPTIONS}
                        value={conversationSkill}
                        onValueChange={onConversationSkillChange}
                    />
                </Field>
                <Field>
                    <Label>{t('job.jlpt_level')}</Label>
                    <Select
                        placeholder="core.select"
                        options={LANGUAGE_LEVEL_ITEMS}
                        value={jlptLevel}
                        onValueChange={onJlptLevelChange}
                    />
                </Field>
            </FilterContainer>
            <ActionContainer>
                <Button variant="secondary" onClick={onApplyFilter}>
                    {t('core.apply_filter')}
                </Button>
                <Button variant="tertiary" onClick={clearFilters}>
                    {t('core.clear_filter')}
                </Button>
            </ActionContainer>
        </Container>
    );
});

export default SearchPanel;