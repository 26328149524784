import { FunctionComponent } from "react";
import {
  GridRowsContainer,
  HeadingMediumContainer,
  SectionHeadingMedium,
} from "../commonStyle"
import { SelectOption } from "../../../../core/components/Select";
import {SupportPlanFormProps} from "../types";
import * as React from "react";
import ScheduleRow from "../components/ScheduleRow";
import ScheduleDateReadOnlyRow from "../components/ScheduleDateReadOnlyRow";
import EntrustingRow from "../components/EntrustingRow";
import EntrustingPersonLastNameRow from "../components/EntrustingPersonLastNameRow";
import EntrustingPersonFirstNameRow from "../components/EntrustingPersonFirstNameRow";
import EntrustingPersonTitleRow from "../components/EntrustingPersonTitleRow";

interface JobChangeSectionProps extends SupportPlanFormProps {
  nationalityOptions?: SelectOption[];
}

export const JobChangeSection: FunctionComponent<JobChangeSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
  }) => {
  return (
    <>
      <HeadingMediumContainer>
        <SectionHeadingMedium>
          a. 所属する業界団体や関連企業等を通じて次の受入れ先に関する情報を入手し提供する
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"jobChangeAcceptanceSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"jobChangeAcceptanceScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"jobChangeAcceptanceEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"jobChangeAcceptanceEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"jobChangeAcceptanceEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"jobChangeAcceptanceEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>b. 公共職業安定所、その他の職業安定機関等を案内し、必要に応じて支援対象者に同行して次の受入れ先を探す補助を行う</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"jobChangeIntroductionSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"jobChangeIntroductionScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"jobChangeIntroductionEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"jobChangeIntroductionEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"jobChangeIntroductionEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"jobChangeIntroductionEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          c. １号特定技能外国人の希望条件、技能水準、日本語能力等を踏まえ、
          適切に職業相談・職業紹介が受けられるよう又は円滑に就職活動が行えるよう推薦状を作成する
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"jobChangeRecommendationSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"jobChangeRecommendationScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"jobChangeRecommendationEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"jobChangeRecommendationEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"jobChangeRecommendationEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"jobChangeRecommendationEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          d. 職業紹介事業の許可又は届出を受けて職業紹介を行うことができる場合は、就職先の紹介あっせんを行う
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"jobChangeMediationSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"jobChangeMediationScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"jobChangeMediationEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"jobChangeMediationEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"jobChangeMediationEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"jobChangeMediationEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          e. １号特定技能外国人が求職活動をするために必要な有給休暇を付与する
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"jobChangePaidHolidaysSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"jobChangePaidHolidaysScheduleDateContent"}
          supportPlan={supportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          f. 離職時に必要な行政手続について情報を提供する
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"jobChangeProceduresSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"jobChangeProceduresScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"jobChangeProceduresEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"jobChangeProceduresEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"jobChangeProceduresEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"jobChangeProceduresEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          g. 倒産等により、転職のための支援が適切に実施できなくなることが見込まれるときは、
          それに備え、当該機関に代わって支援を行う者を確保する
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"jobChangeBankruptcySchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"jobChangeBankruptcyScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"jobChangeBankruptcyEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"jobChangeBankruptcyEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"jobChangeBankruptcyEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"jobChangeBankruptcyEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>
    </>
  );
};

export default JobChangeSection;