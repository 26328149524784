import {
  ChangeEvent,
  FunctionComponent,
  HTMLAttributes,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as Icon } from "../../assets/icon-upload.svg";
import Text from "./Text";
import Button from "./Button";
import { ButtonVariant } from "../types/component";

type UploadButtonProps = HTMLAttributes<HTMLButtonElement> & {
  file?: File | string;
  variant?: ButtonVariant;
  buttonText?: string;
  disabled?: boolean;
  accept?: string;
  onFileChange?: (file: File) => void;
};

type ContainerProps = {};

const ButtonText = styled(Text)`
  margin-left: 6px;
`;

const UploadIcon = styled(Icon)``;

const DefaultContainer = styled.button<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 24px;
  min-width: 88px;
  padding: 2px 12px;
  background: "#F2F2F2";
  border: 0.5px solid #d5d5d5;
  border-radius: 5px;
  outline: none;
  white-space: nowrap;

  &:hover {
    background-color: #fff0f0;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #d5d5d5;
  }

  &:hover ${UploadIcon} {
    path {
      fill: #ff730d;
    }
  }

  &:disabled ${UploadIcon} {
    path {
      fill: #999999;
    }
  }

  &:hover ${ButtonText} {
    color: #ff730d;
  }

  &:disabled ${ButtonText} {
    color: #999999;
  }

  &:active ${ButtonText} {
    color: #444444;
  }

  &:active {
    background-color: #d5d5d5;
  }

  &:active ${UploadIcon} {
    path {
      fill: #444444;
    }
  }
`;

const UploadButton: FunctionComponent<UploadButtonProps> = ({
  file,
  variant,
  buttonText,
  disabled,
  accept,
  onFileChange,
  ...props
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();

  const handleClick = () => {
    if (ref.current) {
      (ref.current as HTMLInputElement).click();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      onFileChange && onFileChange(e.target.files[0]);
    }
  };

  return (
    <>
      {variant ? (
        <Button 
          variant={variant} 
          disabled={disabled}
          onClick={handleClick} 
          {...props}
        >
          {buttonText}
          <input
            ref={ref}
            style={{ display: "none" }}
            type="file"
            onChange={handleChange}
            accept={accept}
          />
        </Button>
      ) : (
        <DefaultContainer 
          disabled={disabled} 
          onClick={handleClick} 
          {...props}
        >
          <UploadIcon />
          <ButtonText>
            {buttonText ?? t(`core.${file ? "uploaded" : "upload"}`)}
          </ButtonText>
          <input
            ref={ref}
            style={{ display: "none" }}
            type="file"
            onChange={handleChange}
            accept={accept}
          />
        </DefaultContainer>
      )}
    </>
  );
};

export default UploadButton;
