import { FunctionComponent } from 'react';
import styled from 'styled-components';

import Page from '../components/Page';
import Section from '../components/Section';
import PageHeading from '../components/PageHeading';
import { useReducerContext } from '../contexts/ReducerContext';

interface HomePageProps {
}

const LocalPage = styled(Page)`
  > * {
    width: 500px;
  }
`;

const LocalSection = styled(Section)`
  padding: 50px 75px 67px 75px;
`;

const HomePage: FunctionComponent<HomePageProps> = () => {
    const {state} = useReducerContext();

    return (
        <LocalPage>
            <LocalSection>
                <PageHeading>
                    {state.isLoggedIn ? 'Logged In' : 'Not Logged In'}
                </PageHeading>
            </LocalSection>
        </LocalPage>
    );
};

export default HomePage;