import styled from "styled-components";
import { EmploymentCondition } from "../../types";
import Column from "../../../core/components/Column";
import EmploymentConditionsBlock from "./blocks/EmploymentConditionsBlock";
import CompensationsBlock from "./blocks/CompensationsBlock";
import { FunctionComponent } from "react";
import { Job } from "../../../job/types/api";

interface EmploymentConditionsFormProps {
  employmentCondition?: EmploymentCondition;
  jobPost?: Job;
  onChange?: (ec: Partial<EmploymentCondition>) => void;
}

const BlocksContainer = styled(Column)`
  gap: 20px;
  margin-bottom: 40px;
`;

const Block = styled(Column)`
  width: 100%;
  gap: 5px;
`;

const BlockHeading = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
`;

const BlockHeadingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const EmploymentConditionForm: FunctionComponent<EmploymentConditionsFormProps> = ({ 
  employmentCondition, 
  jobPost,
  onChange 
}) => {
  return (
    <BlocksContainer>
      <Block>
        <BlockHeadingContainer>
          <BlockHeading>雇用条件書</BlockHeading>
        </BlockHeadingContainer>
        <EmploymentConditionsBlock 
          employmentConditions={employmentCondition}
          onChange={onChange}
          jobPost={jobPost}
        />
      </Block>
      <Block>
        <BlockHeadingContainer>
          <BlockHeading>賃金の支払い</BlockHeading>
        </BlockHeadingContainer>
        <CompensationsBlock 
          employmentConditions={employmentCondition}
          onChange={onChange}
          jobPost={jobPost}
        />
      </Block>
    </BlocksContainer>
  )
}

export default EmploymentConditionForm;