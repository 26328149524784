import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Modal from '../../../core/components/Modal';
import Button from '../../../core/components/Button';

interface EmailSubmissionModalProps {
    open: boolean;
    onClose?: () => void;
    onSend?: () => void;
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 10px;
  gap: 40px;
`;

const MainHeader = styled.h2`
  font-size: 26px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;

const DescriptionContainer = styled.div`
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%; 
  gap: 10px;
`;

const SubmitButton = styled(Button)`
  width: 200px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const CancelButton = styled(Button).attrs({
  variant: "secondary",
})`
  width: 120px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  border: none;
`;


const EmailSubmissionModal: FunctionComponent<EmailSubmissionModalProps> = ({
  open,
  onClose,
  onSend,
}) => { 
    const onModalClose = () => onClose?.();

    return (
        <Modal onClose={onClose} open={open}>
           <ContentWrapper>
            <MainHeader>メールを送信します</MainHeader>
            <DescriptionContainer>
              これまで入力した雇用条件書の内容を保存し、閲覧・印刷画面のリンクを記載したメールを候補者のメールアドレスへ送信します。
            </DescriptionContainer>
            <ButtonsContainer>
              <CancelButton onClick={onModalClose}>キャンセル</CancelButton>
              <SubmitButton onClick={onSend}>送信</SubmitButton>
            </ButtonsContainer>
           </ContentWrapper>
        </Modal>
    );
}

export default EmailSubmissionModal;