import { apiUtil } from '../utils';
import { Company, CompanySealFile } from '../types/api';

export const updateCompany = async (companyId: number | string, company: Partial<Company>) => {
    const response = await apiUtil.patch(
        `/companies/${companyId}`,
        {
            ...company,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Company;
    }
    throw new Error('API error');
};

export const uploadCompanySealFile = async (companyId: number | string, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', companyId.toString());
    const response = await apiUtil.post(
        `/company_seal_files`,
        formData,
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as CompanySealFile;
    }
    throw new Error('API error');
}

export const updateCompanySealFile = async (sealFileId: number | string, sealFile: File) => {
    const formData = new FormData();
    formData.append('file', sealFile);
    const response = await apiUtil.patch(
        `/company_seal_files/${sealFileId}`,
        formData,
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as CompanySealFile;
    }
    throw new Error('API error');
}

export const deleteCompanySealFile = async (sealFileId: number | string) => {
    const response = await apiUtil.delete(
        `/company_seal_files/${sealFileId}`,
    );

    if (response.status >= 200 && response.status < 300) {
        return response.data as CompanySealFile;
    }
    throw new Error('API error');
}

