import {FunctionComponent} from "react";
import {
  Column16,
  Column5,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingMediumContainer,
  LabelCell,
  LocalCheckBox,
  Row0,
  SectionHeadingMedium, UnitText, LocalTextInputGrow, Row10WithGrow, WordBreakLabel,
} from "../commonStyle"
import Radio from "../../../../core/components/Radio";
import {isEmpty} from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";
import {SupportPlanFormProps} from "../types";
import {APPROPRIATE, APPROPRIATE_AFTER_CONTRACT} from "../../../../core/constants/defaultValue";
import * as React from "react";
import ScheduleRow from "../components/ScheduleRow";
import ScheduleDateInitializedTextRow from "../components/ScheduleDateInitializedTextRow";
import EntrustingRow from "../components/EntrustingRow";
import EntrustingPersonLastNameRow from "../components/EntrustingPersonLastNameRow";
import EntrustingPersonFirstNameRow from "../components/EntrustingPersonFirstNameRow";
import EntrustingPersonTitleRow from "../components/EntrustingPersonTitleRow";
import CheckBoxWithTextInput from "../components/CheckBoxWithTextInput";

interface pickUpDropOffSectionProps extends SupportPlanFormProps {}

export const pickUpDropOffSection: FunctionComponent<pickUpDropOffSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
}) => {
  return (
    <>
      <HeadingMediumContainer>
        <SectionHeadingMedium>a. 到着空港等での出迎え及び特定技能所属機関又は住居までの送迎</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"pickUpDropOffArrivalSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"pickUpDropOffArrivalScheduleDateContent"}
          initialValue={APPROPRIATE}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"pickUpDropOffArrivalEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"pickUpDropOffArrivalEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"pickUpDropOffArrivalEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"pickUpDropOffArrivalEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>実施方法</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Column16>
                <CheckBoxWithTextInput
                  checkPropertyName={"pickUpDropOffArrivalAirport"}
                  textPropertyName={"pickUpDropOffArrivalAirportName"}
                  label={"出迎え空港等"}
                  placeholder={"空港名を入力"}
                  tailBracket={"空港）"}
                  supportPlan={supportPlan}
                  onChangeSupportPlan={onChangeSupportPlan}
                />
                <CheckBoxWithTextInput
                  checkPropertyName={"pickUpDropOffArrivalMethod"}
                  textPropertyName={"pickUpDropOffArrivalMethodName"}
                  label={"送迎方法"}
                  placeholder={"送迎方法を入力"}
                  supportPlan={supportPlan}
                  onChangeSupportPlan={onChangeSupportPlan}
                />
              </Column16>
              {(
                isEmpty(supportPlan?.pickUpDropOffArrivalAirport) &&
                isEmpty(supportPlan?.pickUpDropOffArrivalMethod)
                ) &&
                  <ErrorMessage>必ず選択してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>b. 出国予定空港等までの送迎及び保安検査場入場までの出国手続の補助</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"pickUpDropOffDepartureSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"pickUpDropOffDepartureScheduleDateContent"}
          initialValue={APPROPRIATE_AFTER_CONTRACT}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"pickUpDropOffDepartureEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"pickUpDropOffDepartureEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"pickUpDropOffDepartureEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"pickUpDropOffDepartureEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>実施方法</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Column16>
                <Row0>
                  <LocalCheckBox
                    checked={!!supportPlan?.pickUpDropOffDepartureAirport}
                    onValueChange={(val) => {
                      onChangeSupportPlan?.({ pickUpDropOffDepartureAirport: val});
                    }}
                  >
                    出国予定空港等
                  </LocalCheckBox>
                  <Row10WithGrow>
                    <UnitText>
                      （
                    </UnitText>
                    <Radio
                      checked={
                        !!supportPlan?.pickUpDropOffDepartureAirportNameUndecided
                      }
                      onValueChange={() => {
                        onChangeSupportPlan?.({pickUpDropOffDepartureAirportNameUndecided: true});
                      }}
                    >
                      未定
                    </Radio>
                    <UnitText>
                      /
                    </UnitText>
                    <Radio
                      checked={
                        !supportPlan?.pickUpDropOffDepartureAirportNameUndecided
                      }
                      onValueChange={() => {
                        onChangeSupportPlan?.({pickUpDropOffDepartureAirportNameUndecided: false});
                      }}
                    >
                      予定
                    </Radio>
                    <LocalTextInputGrow
                      placeholder="空港名を入力"
                      value={supportPlan?.pickUpDropOffDepartureAirportName ?? ""}
                      onTextChange={(val: string) => {
                        onChangeSupportPlan?.({pickUpDropOffDepartureAirportName: val});
                      }}
                    />
                    <UnitText>
                      空港）
                    </UnitText>
                  </Row10WithGrow>
                </Row0>
                <CheckBoxWithTextInput
                  checkPropertyName={"pickUpDropOffDepartureMethod"}
                  textPropertyName={"pickUpDropOffDepartureMethodName"}
                  label={"送迎方法"}
                  placeholder={"送迎方法を入力"}
                  supportPlan={supportPlan}
                  onChangeSupportPlan={onChangeSupportPlan}
                />
              </Column16>
              {(
                  isEmpty(supportPlan?.pickUpDropOffDepartureAirport) &&
                  isEmpty(supportPlan?.pickUpDropOffDepartureMethod)
                ) &&
                  <ErrorMessage>必ず選択してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default pickUpDropOffSection;