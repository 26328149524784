import React, { FunctionComponent } from "react";
import Text from "../../../../core/components/Text";
import styled from "styled-components";


interface ErrorMessageProps {
  children?: React.ReactNode;
}

const LocalText = styled(Text)`
  font-size: 12px;
`;

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
  children
}) => {
  return (
    <LocalText error={true}>
      {children}
    </LocalText>
  );
};

export default ErrorMessage;