import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AdditionalInformationType } from '../../types/api';
import Column from '../../../core/components/Column';
import DownloadButton from '../../../core/components/DownloadButton';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Text from '../../../core/components/Text';
import { MOBILE_DEVICE } from '../../../core/constants/styles';

interface AdditionalInformationSectionProps {
    additionalInformationTypes?: AdditionalInformationType[];
    uploads?: { type: string, file: string }[];
}

const Block = styled(Column)`
  margin-top: 8px;
  
  > *:not(:first-child) {
    margin-top: 10px;
  }

  @media ${MOBILE_DEVICE} {
    > *:not(:first-child) {
      margin-top: 20px;
    }
  }
`;

const Field = styled(Row)`
  > *:first-child {
    width: 233px;
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media ${MOBILE_DEVICE} {
    flex-direction: column;

    > *:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 4px;
      color: #999999;
    }
  }
`;

const AdditionalInformationSection: FunctionComponent<AdditionalInformationSectionProps> = ({
    additionalInformationTypes,
    uploads,
}) => {
    const [types, setTypes] = useState<{ [key: string]: string }>({});
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const types: { [key: string]: string } = {};
        if (additionalInformationTypes) {
            for (const type of additionalInformationTypes) {
                types[String(type.id)] = i18n.exists(`candidate.additional_information_types.${type.tkey}`)
                    ? t(`candidate.additional_information_types.${type.tkey}`)
                    : type.name;
            }
        }
        setTypes(types);
    }, [additionalInformationTypes]);

    return (
        <Section>
            <SectionHeading>{t('candidate.additional_information')}</SectionHeading>
            <Block>
                {uploads && uploads.map((upload, index) => (
                    <Field key={index}>
                        <Text>{types[upload.type]}</Text>
                        <DownloadButton href={upload.file} />
                    </Field>
                ))}
            </Block>
        </Section>
    );
};

export default AdditionalInformationSection;