import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { useComponentVisible } from '../hooks';

type MenuProps = HTMLAttributes<HTMLButtonElement> & {
    title?: string;
    children?: ReactNode;
};

type PopupProps = {
    visible?: boolean;
};

const Arrow = styled.span`
  width: 0;
  height: 0;
  border-left: 6.5px solid transparent;
  border-right: 6.5px solid transparent;
  border-top: 8px solid #444444;
  margin-left: 10px;
`

const Container = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  font-size: 14px;
  color: #444444;
  background: #E5E5E5;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  
  &:hover {
    color: #FF730D;
    background: #FFDFD1;
    
    ${Arrow} {
      border-top-color: #FF730D;
    }
  }
  
  &:focus {
    padding: 5px 10px;
    color: #FF730D;
    background: #FFDFD1;
    border: 1px solid #FFC194;

    ${Arrow} {
      border-top-color: #FF730D;
    }
  }
`;

const Popup = styled.div<PopupProps>`
  position: absolute;
  top: 100%;
  left: 0;
  display: ${(props: PopupProps) => props.visible ? 'flex' : 'none'};
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 1px;
  box-shadow: 0 0 4px #999999;
`;

const Menu: FunctionComponent<MenuProps> = ({
    title,
    children,
    ...props
}) => {
    const { ref, visible, setVisible } = useComponentVisible(false);

    return (
        <Container onClick={() => setVisible(!visible)} {...props}>
            {title}
            <Arrow />
            <Popup ref={ref} visible={visible}>
                {children}
            </Popup>
        </Container>
    );
};

export default Menu;