import { FunctionComponent, ReactNode } from "react";
import { Column20, Column5, FlexRowColumn } from "../../commonStyles";
import { TemplateSectionProps } from "../../types";
import styled from "styled-components";
import { MOBILE_DEVICE } from "../../../../../core/constants/styles";
import { Trans, useTranslation } from "react-i18next";

const LocalSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DateContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-left: auto;
`;

const SignOnDate = styled.div``;

const YearField = styled.span`
  display: inline-block;
  width: 48px;
`;

const MonthField = styled.span`
  display: inline-block;
  width: 24px;
`;

const DayField = styled.span`
  display: inline-block;
  width: 24px;
`;

const LocalFlexRowColumn = styled(FlexRowColumn)`
  justify-content: space-between;
  gap: 40px;

  @media ${MOBILE_DEVICE} {
    gap: 20px;
  }

  @media print {
    gap: 40px;
  }
`;

const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 50%;
  flex-grow: 1;
  word-break: break-all;

  @media ${MOBILE_DEVICE} {
    max-width: 400px;
    width: 100%;
    align-self: flex-end;
  }

  @media print {
    max-width: 50%;
    width: auto;
    align-self: auto;
  }
`;

const Signature = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const EmployeeSignatureField = styled.div`
  border-bottom: 1px solid black;
  min-width: 170px;
  max-width: 100%;
  flex-grow: 1;
`;

const EmployerSignatureField = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
  border-bottom: 1px solid black;
  min-width: 170px;
  max-width: 100%;
  flex-grow: 1;
`;

interface SignatureSectionProps extends TemplateSectionProps {}

const SignatureSection: FunctionComponent<SignatureSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey,
}) => {

  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang }
  const tFixedToTransLang = i18n.getFixedT(transHandler.targetLangCode, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;
  const signerInJa = [
    ec?.employerJaOrganizationName,
    ec?.employerRepresentativeTitle,
    ec?.employerJaRepresentativeName
  ].filter(v => v).join("　");

  const signerInEn = [
    ec?.employerEnOrganizationName,
    transHandler.getTranslation("employerRepresentativeTitle"),
    ec?.employerEnRepresentativeName
  ].filter(v => v).join(" / ");

  const EmployerSignature = ({ children }: {children?: ReactNode}) => 
    <EmployerSignatureField>{children}</EmployerSignatureField>;

  const EmployeeSignature = ({ children }: {children?: ReactNode}) =>
    <EmployeeSignatureField>{children}</EmployeeSignatureField>;
  

  return (
    <LocalSection>
      {/* Signature date */}
      <Column5>
        <DateContainer>
          <SignOnDate>
            <YearField />
            <span>年</span>
            <MonthField />
            <span>月</span>
            <DayField />
            <span>日</span>
          </SignOnDate>
          <span>締結</span>
        </DateContainer>

        <DateContainer>
          <SignOnDate>
            <Trans
              i18nKey={getTKey("signature_date")}
              tOptions={tOptions}
              components={{
                day: <DayField />,
                month: <MonthField />,
                year: <YearField />,
              }}
            />
          </SignOnDate>
        </DateContainer>
      </Column5>

      <Column20>
        {/* Signatures (in Japanese) */}
        <LocalFlexRowColumn>
          {/* By employer */}
          <SignatureContainer>
            <Signature>
              <span>甲</span>
              <EmployerSignatureField>
                <span>{signerInJa}</span>
                <span>㊞</span>
              </EmployerSignatureField>
            </Signature>
            <span>（特定技能所属機関名・代表者役職名・氏名・捺印）</span>
          </SignatureContainer>

          {/* By employee */}
          <SignatureContainer>
            <Signature>
              <span>乙</span>
              <EmployeeSignatureField />
            </Signature>
            <span>（特定技能外国人の署名）</span>
          </SignatureContainer>
        </LocalFlexRowColumn>

        {/* Signatures (in the target foreign language) */}
        <LocalFlexRowColumn>
          {/* By employer */}
          <SignatureContainer>
            <Signature>
              <Trans
                i18nKey={getTKey("employer_signature")}
                tOptions={tOptions}
                values={{
                  signer: signerInEn
                }}
                components={{
                  signed_by: <span/>,
                  signer: <span/>,
                  stamp: <span/>,
                  signature: <EmployerSignature />
                }}
              />
            </Signature>
            <span>
              {tFixed("employer_signature_explanation")}
            </span>
          </SignatureContainer>

          {/* By employee */}
          <SignatureContainer>
            <Signature>
              <Trans
                i18nKey={getTKey("employee_signature")}
                tOptions={tOptions}
                components={{
                  signed_by: <span/>,
                  signature: <EmployeeSignature />
                }}
              />
            </Signature>
            <span>
              {tFixed("employee_signature_explanation")}
            </span>
          </SignatureContainer>
        </LocalFlexRowColumn>
      </Column20>
    </LocalSection>
  );
};

export default SignatureSection;
