import moment from 'moment';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Message from '../../core/components/Message';
import Modal from '../../core/components/Modal';
import PageHeading from '../../core/components/PageHeading';
import InterviewCalendar from './InterviewCalendar';
import Button from '../../core/components/Button';
import Column from '../../core/components/Column';
import Text from '../../core/components/Text';
import { compareDate } from '../../core/utils';

interface EditAvailableDatesModalProps {
  open?: boolean;
  value?: Date[];
  onValueChange?: (value: Date[]) => void;
  onClose?: () => void;
  onSubmit?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 755px;
  max-height: 90vh;
  padding: 30px;
  background: #FFFFFF;
  overflow: hidden;
`;

const CalendarContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  overflow: auto;
`;

const ActionsContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

const LocalPageHeading = styled(PageHeading)`
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
`;

const EditAvailableDatesModal: FunctionComponent<EditAvailableDatesModalProps> = ({
  open,
  value,
  onValueChange,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const handleDateToggle = (date: Date) => {
    if (value && onValueChange) {
      const time = date.getTime();
      const index = value.findIndex(item => item.getTime() === time);
      if (index !== -1) {
        const dates = [...value]
        dates.splice(index, 1)
        onValueChange(dates)
      } else if (value.length < 20) {
        onValueChange([...value, date]);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <LocalPageHeading>
          {t('proposal.edit_available_dates')}
        </LocalPageHeading>
        <Message withIcon>
          {t('proposal.counterparty_will_be_notified')}
        </Message>
        <CalendarContainer>
          <InterviewCalendar
            style={{ width: '100%' }}
            value={value || []}
            onToggle={handleDateToggle}
          />

          <Column style={{ gap: 4 }}>
            <Text>{t('proposal.selected_dates')}</Text>
            <Message style={{ width: '100%' }}>
              {!!value && value.sort(compareDate).map((date) => (
                <div key={date.toISOString()}>
                  {moment(date)
                    .utcOffset('+09:00')
                    .format('YYYY/MM/DD (ddd) H:mm')
                  }
                  {moment(date)
                    .add(1, 'hour')
                    .utcOffset('+09:00')
                    .format(' - H:mm')
                  }
                </div>
              ))}
            </Message>
          </Column>
        </CalendarContainer>
        <ActionsContainer>
          <Button
            variant="secondary"
            onClick={onClose}
          >
            {t('core.not_submit')}
          </Button>
          <Button
            disabled={Number(value?.length) < 1}
            onClick={onSubmit}
          >
            {t('core.submit')}
          </Button>
        </ActionsContainer>
      </Container>
    </Modal>
  );
};

export default EditAvailableDatesModal;