import { StrictMode } from 'react';
import i18next from 'i18next';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import App from './App';
import translations from './core/translations';
import { ReducerContextProvider } from './core/contexts/ReducerContext';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "Noto Sans", "Noto Sans JP", sans-serif;
  }
  * {
    box-sizing: border-box;
  }
`;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

i18next
    .use(initReactI18next)
    .init({
        resources: translations,
        lng: localStorage.getItem('lng') || 'ja',
        fallbackLng: 'ja',
        interpolation: {
            escapeValue: false,
        },
    });

root.render(
    <StrictMode>
        <GlobalStyle />
        <BrowserRouter>
            <ReducerContextProvider>
                <App />
            </ReducerContextProvider>
        </BrowserRouter>
    </StrictMode>
);