import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import styled from 'styled-components';

import { ReactComponent as ThreeDotsIcon } from '../../assets/icon-three-dots.svg';
import {useComponentVisible} from '../hooks';

interface OtherButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
}

interface PopupProps {
  visible?: boolean;
}

const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  padding: 0 4px;
  border: 1px solid #C2C2C2;
  border-radius: 5px;
  background: #EDEDED;
  cursor: pointer;
  
  &:hover, &:focus {
    background: #F7F7F7;
  }
`;

const Popup = styled.div<PopupProps>`
  position: absolute;
  top: 100%;
  right: 0;
  display: ${(props: PopupProps) => props.visible ? 'flex' : 'none'};
  flex-direction: column;
  padding: 10px 0;
  border: 1px solid #EDEDED;
  border-radius: 1px;
  background: #FFFFFF;
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.10);
  z-index: 5;
`;

const OtherMenu: FunctionComponent<OtherButtonProps> = ({
  children,
  ...props
}) => {
  const { ref, visible, setVisible } = useComponentVisible(false);

  return (
    <Button onClick={() => setVisible(!visible)} {...props}>
      <ThreeDotsIcon />
      <Popup ref={ref} visible={visible}>
        {children}
      </Popup>
    </Button>
  );
};

export default OtherMenu;