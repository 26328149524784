import { useTranslation } from "react-i18next";
import { Section, SectionHeading, SectionHeadingContainer, SectionLabel, SectionLabelCell, SectionRow, SectionValue, SectionValueCell, SectionValuesContainer } from "./commonStyle";
import { SectionProps } from "../types/props";
import { FunctionComponent, useEffect, useState } from "react";
import { EMPTY_VAL } from "../constants/defaultValue";

interface ExtraWagesSectionProps extends SectionProps {}

const ExtraWagesSection: FunctionComponent<ExtraWagesSectionProps> = ({
  job
}) => {
  const { t: tJob } = useTranslation('translation', { keyPrefix: 'job' });
  const [exWageRatesOvertimeAboveLegalLte60Hs, setExWageRatesOvertimeAboveLegalLte60Hs] = useState<string>(EMPTY_VAL);
  const [exWageRatesOvertimeAboveLegalGt60Hs, setExWageRatesOvertimeAboveLegalGt60Hs] = useState<string>(EMPTY_VAL);
  const [exWageRatesOvertimeAboveNormal, setExWageRatesOvertimeAboveNormal] = useState<string>(EMPTY_VAL);
  const [exWageRatesHolidayLegal, setExWageRatesHolidayLegal] = useState<string>(EMPTY_VAL);
  const [exWageRatesHolidayNonLegal, setExWageRatesHolidayNonLegal] = useState<string>(EMPTY_VAL);
  const [exWageRatesLateNight, setExWageRatesLateNight] = useState<string>(EMPTY_VAL);

  useEffect(() => {
    if (!job)
      return;

    if (job.ecCompensationExtraWageRatesOvertimeAboveLegalLte_60Hs) 
      setExWageRatesOvertimeAboveLegalLte60Hs(`${job.ecCompensationExtraWageRatesOvertimeAboveLegalLte_60Hs}%`);
    else
      setExWageRatesOvertimeAboveLegalLte60Hs(EMPTY_VAL);

    if (job.ecCompensationExtraWageRatesOvertimeAboveLegalGt_60Hs)
      setExWageRatesOvertimeAboveLegalGt60Hs(`${job.ecCompensationExtraWageRatesOvertimeAboveLegalGt_60Hs}%`);
    else
      setExWageRatesOvertimeAboveLegalGt60Hs(EMPTY_VAL);

    if (job.ecCompensationExtraWageRatesOvertimeAboveNormal)
      setExWageRatesOvertimeAboveNormal(`${job.ecCompensationExtraWageRatesOvertimeAboveNormal}%`);
    else
      setExWageRatesOvertimeAboveNormal(EMPTY_VAL);

    if (job.ecCompensationExtraWageRatesHolidayLegal)
      setExWageRatesHolidayLegal(`${job.ecCompensationExtraWageRatesHolidayLegal}%`);
    else
      setExWageRatesHolidayLegal(EMPTY_VAL);

    if (job.ecCompensationExtraWageRatesHolidayOtherThanLegal)
      setExWageRatesHolidayNonLegal(`${job.ecCompensationExtraWageRatesHolidayOtherThanLegal}%`);
    else
      setExWageRatesHolidayNonLegal(EMPTY_VAL);

    if (job.ecCompensationExtraWageRatesMidnight)
      setExWageRatesLateNight(`${job.ecCompensationExtraWageRatesMidnight}%`);
    else
      setExWageRatesLateNight(EMPTY_VAL);

  }, [job])

  return (
    <Section>
      <SectionHeadingContainer>
        <SectionHeading>
          {tJob('overtime_pay_rate')}
        </SectionHeading>
      </SectionHeadingContainer>

       {/* 所定時間外（法定超月60時間以内） */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('regular_hours_beyond_legal_limits_up_to_60_hours')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValuesContainer>
            <SectionValue>
              {exWageRatesOvertimeAboveLegalLte60Hs}
            </SectionValue>
          </SectionValuesContainer>
        </SectionValueCell>
      </SectionRow>

      {/* 所定時間外（法定超月60時間超） */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('regular_hours_beyond_legal_li9mits_exceeding_60_hours')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {exWageRatesOvertimeAboveLegalGt60Hs}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 所定時間外（所定超） */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>   
            {tJob('regular_hours_beyond_standard_limits')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {exWageRatesOvertimeAboveNormal}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 休日（法定） */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('holiday_work_legal')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {exWageRatesHolidayLegal}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 休日（法定外） */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('holiday_work_non_legal')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {exWageRatesHolidayNonLegal}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 深夜 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('late_night_hours')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {exWageRatesLateNight}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>
    </Section>
  );
};

export default ExtraWagesSection;