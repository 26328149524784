import { FunctionComponent, useEffect, useState } from "react";
import Page from "../../../core/components/Page";
import BasicInformationSection from "../../sections/BasicInformationSection";
import styled from "styled-components";
import SalarySection from "../../sections/SalarySection";
import CandidateConditionsSection from "../../sections/CandidateConditionsSection";
import WorkHoursSection from "../../sections/WorkHoursSection";
import HolidaysVacationSection from "../../sections/HolidaysVacationSection";
import LivingEnvironmentSection from "../../sections/LivingEnvironmentSection";
import ResignationOtherSection from "../../sections/ResignationOtherSection";
import { useReducerContext } from "../../../core/contexts/ReducerContext";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { JobExtended } from "../../types/api";
import ACTIONS from "../../../core/constants/actions";
import { archiveJob, getJob } from "../../apis/job";
import { isCandidateProfileComplete } from "../../utils";
import ProfileMissingModal from "../../components/ProfileMissingModal";
import QuestionAndAnswerSection from "../../sections/QuestionAndAnswerSection";
import ActionBar from "../../../core/components/ActionBar";
import { JobState } from "../../enums/job";
import Button from "../../../core/components/Button";
import { useMediaQuery } from "react-responsive";
import { DESKTOP_DEVICE, MOBILE_DEVICE } from "../../../core/constants/styles";
import Row from "../../../core/components/Row";
import Menu from "../../../core/components/Menu";
import MenuItem from "../../../core/components/MenuItem";
import ActionButton from "../../../core/components/ActionButton";
import NotificationBar from "./components/NotificationBar";
import { LanguageCode } from "../../../core/enums/language";
import { useJobTranslation } from "../../hook";
import ExtraWagesSection from "../../sections/ExtraWagesSection";
import { getDefaultThumbnailURL } from "../../../core/utils";
import Column from "../../../core/components/Column";
import Text from "../../../core/components/Text";
import axios from "axios";

interface JobDetailsPageProps {}

const LocalPage = styled(Page)`
  @media ${MOBILE_DEVICE} {
    padding: 10px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SectionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const ButtonsContainer = styled.div`
  min-height: 20px;
  display: flex;
  gap: 10px;
  margin-left: auto;
`;

const JobPostOverviewContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 350px) 1fr;
  gap: 20px;

  @media ${MOBILE_DEVICE} {
    grid-template-columns: 1fr;
  }
`;

const JobImage = styled.img`
  max-width: 350px;
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const JobInfoActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const JobPostHeaderLocation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const JobPostTitle = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
`;

const Location = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #444444;
`;

const AnonymousActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  border: 1px solid #c2c2c2;
  border-radius: 12px;
  background-color: white;

  @media ${MOBILE_DEVICE} {
    flex-direction: column;
  }
`;

const AnonymousAction = styled(Column)`
  flex: 1;
  gap: 5px;
  overflow: hidden;
`;

const AnonymousActionLabel = styled(Text)`
  color: #999999;
  margin: 0 auto;
`;

const AnonymousActionButton = styled(Button)`
  width: 100%;
`;


const JobDetailsPage: FunctionComponent<JobDetailsPageProps> = () => {
  const { state, dispatch } = useReducerContext();
  const { isEmployer, isPartner, isCandidate, isAdmin, isLoggedIn } = state;
  const isDesktopDevice = useMediaQuery({
    query: DESKTOP_DEVICE,
  });
  const { jobId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { findTranslation } = useJobTranslation();
  const [jobPost, setJobPost] = useState<Partial<JobExtended>>();
  const [jobPostTitle, setJobPostTitle] = useState<string>("");
  const [jobLocation, setJobLocation] = useState<string>("");
  const [profileMissingModal, setProfileMissingModal] = useState(false);
  const commonProps = { job: jobPost };
  const isArchived = jobPost?.state === JobState.Archived;
  const isPublished = jobPost?.state === JobState.Published;
  const isCurLangJapanese = i18n.language === LanguageCode.Japanese;
  const showActionBar =
    !isArchived && !!jobPost?.state && !isAdmin && (isPartner || isCandidate);

  const onClickArchive = async () => {
    dispatch({
      type: ACTIONS.START_CONFIRMATION,
      payload: {
        message: `「${jobPost?.name}」の求人をアーカイブしますか？`,
        callback: async () => {
          dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
              message: t("job.deleting_job"),
            },
          });
          try {
            await archiveJob(jobId!);
            navigate("/jobs");
          } catch {
          } finally {
            dispatch({
              type: ACTIONS.STOP_LOADING,
            });
          }
        },
      },
    });
  };

  const onClickPropose = () => {
    if (isCandidate && !isCandidateProfileComplete(state.candidate!))
      setProfileMissingModal(true);
    else navigate(`/jobs/${jobId}/propose`);
  };

  useEffect(() => {
    if (!jobId) {
      navigate("/");
      return;
    }

    (async () => {
      dispatch({ type: ACTIONS.START_LOADING });
      try {
        const job = await getJob(jobId);
        setJobPost(job);
      } catch (e) {
        if (!axios.isAxiosError(e)) 
          return;
          
        switch (e?.response?.status) {
          case 404:
            navigate("/jobs");
            return;
        }
      } finally {
        dispatch({ type: ACTIONS.STOP_LOADING });
      }
    })();
  }, []);

  useEffect(() => {
    if (!jobPost) return;

    const addrArr = [];
    if (isCurLangJapanese) {
      addrArr.push(jobPost.jaPrefecture);
      addrArr.push(jobPost.jaCity);
      addrArr.push(jobPost.jaTown);
    } else {
      addrArr.push(jobPost.enPrefecture);
      addrArr.push(jobPost.enCity);
      addrArr.push(jobPost.enTown);
    }

    if (addrArr.length > 0) setJobLocation(addrArr.join(" "));

    if (isPublished)
      setJobPostTitle(
        findTranslation(jobPost.translations, "name") || jobPost.name || ""
      );
    else setJobPostTitle(jobPost.name || "");
  }, [jobPost, i18n.language]);

  return (
    <LocalPage>
      <Content>
        {isArchived && (
          <NotificationBar type="warning" text={t("job.job_posting_closed")} />
        )}

        <JobPostOverviewContainer>
          <JobImage
            src={
              jobPost?.images?.[0]?.url ||
              getDefaultThumbnailURL(jobPost?.jobTypeId) 
            }
          />
      
          <JobInfoActionsContainer>
            {jobPost && (
              <JobPostHeaderLocation>
                <JobPostTitle>{jobPostTitle || ""}</JobPostTitle>
                <Location>{jobLocation || ""}</Location>
              </JobPostHeaderLocation>
            )}

            {isDesktopDevice && (
              <ButtonsContainer>
                {isAdmin && (
                  <Button
                    variant="secondary"
                    onClick={() => navigate(`/admin/jobs/${jobId}/edit`)}
                  >
                    {t("core.edit")}
                  </Button>
                )}

                {isEmployer && (
                  <Row center>

                    { !isArchived &&
                      <ActionButton
                        onClick={() => navigate(`/jobs/${jobId}/edit`)}
                      >
                        {t("core.edit")}
                      </ActionButton>
                    }

                    <Menu
                      style={{ marginLeft: 10 }}
                      title={t("core.other_actions")}
                    >
                      <MenuItem
                        onClick={() => navigate(`/jobs/new?from=${jobId}`)}
                      >
                        {t("job.copy_and_create_new")}
                      </MenuItem>

                      { !isArchived &&
                        <MenuItem onClick={onClickArchive}>
                          {t("core.archive")}
                        </MenuItem>
                      }
                    </Menu>
                  </Row>
                )}
              </ButtonsContainer>
            )}
          </JobInfoActionsContainer>
        </JobPostOverviewContainer>

        { !isLoggedIn &&
          <AnonymousActions>
            <AnonymousAction>
              <AnonymousActionLabel>{t("job.job_seekers")}</AnonymousActionLabel>
              <AnonymousActionButton
                onClick={() => navigate("/register")}
              >
                {t("job.register_and_apply")}
              </AnonymousActionButton>
            </AnonymousAction>
            <AnonymousAction>
              <AnonymousActionLabel>
                {t("job.recruitment_agencies")}
              </AnonymousActionLabel>
              <AnonymousActionButton
                variant="tertiary"
                onClick={() => navigate("/contact")}
              >
                {t("job.contact")}
              </AnonymousActionButton>
            </AnonymousAction>
          </AnonymousActions>
        }

        <SectionsContainer>
          <BasicInformationSection {...commonProps} />
          <SalarySection {...commonProps} />
          <ExtraWagesSection {...commonProps} />
          <CandidateConditionsSection {...commonProps} />
          <WorkHoursSection {...commonProps} />
          <HolidaysVacationSection {...commonProps} />
          <LivingEnvironmentSection {...commonProps} />
          <ResignationOtherSection {...commonProps} />
          <QuestionAndAnswerSection {...commonProps} />
        </SectionsContainer>
      </Content>

      {showActionBar && (
        <ActionBar>
          {jobPost.state === JobState.Published && (
            <Button onClick={onClickPropose}>
              {isCandidate ? t("proposal.apply") : t("proposal.propose")}
            </Button>
          )}

          {jobPost.state === JobState.Archived && (
            <Button disabled>{t("proposal.job_closed")}</Button>
          )}
        </ActionBar>
      )}

      <ProfileMissingModal 
        open={profileMissingModal} 
        onClick={() => navigate('/profile/edit')}
      />
    </LocalPage>
  );
};

export default JobDetailsPage;
