import { LanguageCode } from '../enums/language';
import { LANGUAGE_LABELS, LANGUAGE_REGION_LABELS } from './labels';

const SUPPORTED_LANGUAGES = [
    LanguageCode.English,
    LanguageCode.Indonesian,
    LanguageCode.Myanmar,
    LanguageCode.Nepali,
    LanguageCode.Vietnamese,
    LanguageCode.Japanese,
];

export const SUPPORTED_LANGUAGE_OPTIONS = SUPPORTED_LANGUAGES.map((language) => ({
    label: LANGUAGE_LABELS[language],
    region: LANGUAGE_REGION_LABELS[language],
    value: language,
}));