import styled from "styled-components";
import Column from "../../../core/components/Column";
import {FunctionComponent} from "react";
import Accordion from "../../../core/components/Accordion";
import {useNationalities} from "../../../core/hooks";
import TargetSection from "./sections/TargetSection";
import OtherTargetSection from "./sections/OtherTargetSection";
import {SupportPlanFormProps} from "./types";
import OrganizationSection from "./sections/OrganizationSection";
import SupportOrganizationBaseSection from "./sections/SupportOrganizationBaseSection";
import SupportOrganizationSupportSection from "./sections/SupportOrganizationSupportSection";
import SupportSignatureSection from "./sections/SupportSignatureSection";
import GuidanceSection from "./sections/GuidanceSection";
import PickUpDropOffSection from "./sections/PickUpDropOffSection";
import LifeSection from "./sections/LifeSection";
import OrientationSection from "./sections/OrientationSection";
import OpportunitiesSection from "./sections/OpportunitiesSection";
import RequestsSection from "./sections/RequestsSection";
import InteractionsSection from "./sections/InteractionsSection";
import JobChangeSection from "./sections/JobChangeSection";
import InterviewsSection from "./sections/InterviewsSection";


const ContentContainer = styled(Column)`
    width: 100%;
    gap: 40px;
    margin-bottom: 40px;
`;

const Block = styled.div`
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--Grey-40, #c2c2c2);
  background-color: white;
`;

const AccordionBlock = styled(Accordion)`
  & > div:first-child {
    padding: 30px;
    height: 90px;
    border-bottom: none;
  }
  & > div > span > h3 {
    font-weight: 700;
  }
  & > div > span {
    width: 100%;
    justify-content: space-between;
  }
  
`;

const SupportPlansForm: FunctionComponent<SupportPlanFormProps> = ({
  ...props
}) => {
  const { nationalityOptions } = useNationalities();

  return (
    <ContentContainer>
      <Block>
        <TargetSection {...props} nationalityOptions={nationalityOptions} />
      </Block>
      {
        !!props.supportPlan?.targetNumberOfPeople &&
        props.supportPlan.targetNumberOfPeople > 0 && (
          <Block>
            <OtherTargetSection {...props} nationalityOptions={nationalityOptions} />
          </Block>
        )
      }
      <Block>
        <OrganizationSection {...props} />
      </Block>
      <Block>
        <SupportOrganizationBaseSection {...props} />
      </Block>
      <Block>
        <SupportOrganizationSupportSection {...props} />
      </Block>
      <Block>
        <SupportSignatureSection {...props} />
      </Block>
      <AccordionBlock headingText={"Ⅳ. 支援内容（1. 事前ガイダンスの提供）"}>
        <GuidanceSection {...props} />
      </AccordionBlock>
      <AccordionBlock headingText={"Ⅳ. 支援内容（2. 出入国する際の送迎）"}>
        <PickUpDropOffSection {...props} />
      </AccordionBlock>
      <AccordionBlock headingText={"Ⅳ. 支援内容（3. 適切な住居の確保に係る支援・生活に必要な契約に係る支援）"}>
        <LifeSection {...props} />
      </AccordionBlock>
      <AccordionBlock headingText={"Ⅳ. 支援内容（4. 生活オリエンテーションの実施）"}>
        <OrientationSection {...props} />
      </AccordionBlock>
      <AccordionBlock headingText={"Ⅳ. 支援内容（5. 日本語学習機会の提供）"}>
        <OpportunitiesSection {...props} />
      </AccordionBlock>
      <AccordionBlock headingText={"Ⅳ. 支援内容（6. 相談又は苦情への対応）"}>
        <RequestsSection {...props} />
      </AccordionBlock>
      <AccordionBlock headingText={"Ⅳ. 支援内容（7. 日本人との交流促進に係る支援）"}>
        <InteractionsSection {...props} />
      </AccordionBlock>
      <AccordionBlock headingText={"Ⅳ. 支援内容（8. 非自発的離職時の転職支援）"}>
        <JobChangeSection {...props} />
      </AccordionBlock>
      <AccordionBlock headingText={"Ⅳ. 支援内容（9. 定期的な面談の実施・行政機関への通報）"}>
        <InterviewsSection {...props} />
      </AccordionBlock>
    </ContentContainer>
  )
}

export default SupportPlansForm;