import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Column from '../../../core/components/Column';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Text from '../../../core/components/Text';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import { useJobPostCategory } from '../../../core/hooks';

interface SkillsSectionProps {
    jobType?: string;
}

const Block = styled(Column)`
  margin-top: 8px;
  
  > *:not(:first-child) {
    margin-top: 10px;
  }

  @media ${MOBILE_DEVICE} {
    > *:not(:first-child) {
      margin-top: 20px;
    }
  }
`;

const Field = styled(Row)`
  > *:first-child {
    width: 233px;
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
  }

  @media ${MOBILE_DEVICE} {
    flex-direction: column;

    > *:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 4px;
      color: #999999;
    }
  }
`;

const SkillsSection: FunctionComponent<SkillsSectionProps> = ({
    jobType,
}) => {
    const { t } = useTranslation();
    const jobPostCategory = useJobPostCategory();

    return (
        <Section>
            <SectionHeading>{t('candidate.skills')}</SectionHeading>
            <Block style={{ marginTop: 20 }}>
                <Field>
                    <Text>{t('job.industry')}</Text>
                    <Text>
                        {jobPostCategory.getIndustryNameByJobTypeId(jobType) || '-'}
                    </Text>
                </Field>
                <Field>
                    <Text>{t('job.job_type')}</Text>
                    <Text>
                        {jobPostCategory.getJobTypeName(jobType) || '-'}
                    </Text>
                </Field>
            </Block>
        </Section>
    );
};

export default SkillsSection;