import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { MOBILE_DEVICE } from '../constants/styles';

type CardProps = HTMLAttributes<HTMLDivElement> & {
    children?: ReactNode;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 297px;
  padding: 20px 14px 22px 22px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  
  @media ${MOBILE_DEVICE} {
    width: 100%;
    padding: 20px;
    border-radius: 0;
  }
`;

const Card: FunctionComponent<CardProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default Card;