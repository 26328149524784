import { FunctionComponent, HTMLAttributes, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MOBILE_DEVICE } from '../constants/styles';
import { useComponentVisible } from '../hooks';

export type SelectOption = {
    label: string;
    value: any;
};

type SelectProps = HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    placeholder?: string;
    options?: SelectOption[];
    value?: any;
    onValueChange?: (value: any) => void;
};

type ContainerProps = SelectProps & {
    invalid?: boolean;
};

type MenuProps = {
    visible?: boolean;
};

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 165px;
  height: 30px;
  padding-right: 6px;
  padding-left: 7px;
  font-size: 14px;
  color: ${(props: ContainerProps) => props.disabled ? '#999999' : '#444444'};
  background: ${
    (props: ContainerProps) => props.disabled
            ? '#D5D5D5'
            : ((props.invalid || props.error)? '#FFF0F0' : '#FFFFFF')
  };
  border: 1px solid ${(props: ContainerProps) => (props.invalid || props.error) ? '#E93232' : '#999999'};
  border-radius: 5px;
  cursor: pointer;
  pointer-events: ${(props: SelectProps) => props.disabled ? 'none' : 'auto'};
  
  &:hover {
    border-color: ${(props: ContainerProps) => props.invalid ? '#E93232' : '#444444'};
  }
  
  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const Placeholder = styled.span`
  flex: 1;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Text = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Arrow = styled.span`
  width: 0;
  height: 0;
  margin-left: 4px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #999999;
`

const Menu = styled.div<MenuProps>`
  position: absolute;
  top: 100%;
  left: 0;
  width: max-content;
  min-width: 100%;
  max-height: 170px;
  max-width: 500px;
  padding: 4px 0;
  background: white;
  border-radius: 3px;
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.2);
  overflow: auto;
  visibility: ${(props: MenuProps) => props.visible ? 'visible' : 'hidden'};
  z-index: 9;

  @media ${MOBILE_DEVICE} {
    width: 100%;
    max-width: 100%;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 30px;
  padding: 5px 8px;
  
  &:hover {
    color: white;
    background: #FF730D;
  }
`

const Select: FunctionComponent<SelectProps> = ({
    required,
    placeholder,
    options,
    value,
    error,
    onValueChange,
    ...props
}) => {
    const { t } = useTranslation();
    const { ref, visible, setVisible } = useComponentVisible(false);
    const option = options ? options.find(option => option.value === value) : null

    const handleContainerClick = () => {
        setVisible(!visible);
    };

    const handleItemClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();

        //When null is set to data-value, dataset.value becomes an empty string instead of null
        onValueChange && onValueChange((event.target as HTMLDivElement).dataset.value);
        setVisible(false);
    };

    return (
        <Container
            invalid={required
                && (!options || !options.length || options.every(option => option.value !== value))
            }
            error={error}
            onClick={handleContainerClick}
            {...props}
        >
            {option
                ? <Text>{t(option.label)}</Text>
                : (placeholder ? <Placeholder>{t(placeholder)}</Placeholder> : <Text />)
            }
            <Arrow />
            <Menu ref={ref} visible={visible}>
                {options && options.map(option => (
                    <Item
                        key={option.value}
                        onClick={handleItemClick}
                        data-value={option.value}
                    >
                        {t(option.label)}
                    </Item>
                ))}
            </Menu>
        </Container>
    )
};

export default Select;
