import React, {FunctionComponent} from "react";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import {
  CheckboxesRowWrapper,
  Column5,
  FieldCell, GridRow,
  LabelCell, LocalCheckBox, LocalTextInputExtend, WordBreakLabel,
} from "../commonStyle";
import {isEmpty} from "../../../../core/utils";
import ErrorMessage from "./ErrorMessage";

interface MethodWithOtherRowProps extends SupportPlanFormProps {
  firstCheckLabel: string;
  firstCheckPropertyName: SupportPlanExtractType<boolean>;
  secondCheckLabel: string;
  secondCheckPropertyName: SupportPlanExtractType<boolean>;
  otherCheckPropertyName: SupportPlanExtractType<boolean>;
  otherContentPropertyName: SupportPlanExtractType<string | null>;
}

const MethodWithOtherRow: FunctionComponent<MethodWithOtherRowProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
    firstCheckLabel,
    firstCheckPropertyName,
    secondCheckLabel,
    secondCheckPropertyName,
    otherCheckPropertyName,
    otherContentPropertyName,
}) => {
  return (
    <>
      <GridRow>
        <LabelCell>
          <WordBreakLabel required>実施方法</WordBreakLabel>
        </LabelCell>
        <FieldCell>
          <Column5>
            <CheckboxesRowWrapper>
              <LocalCheckBox
                checked={!!supportPlan?.[firstCheckPropertyName]}
                onValueChange={(val) => {
                  onChangeSupportPlan?.({ [firstCheckPropertyName]: val});
                }}
              >
                {firstCheckLabel}
              </LocalCheckBox>
              <LocalCheckBox
                checked={!!supportPlan?.[secondCheckPropertyName]}
                onValueChange={(val) => {
                  onChangeSupportPlan?.({ [secondCheckPropertyName]: val});
                }}
              >
                {secondCheckLabel}
              </LocalCheckBox>
              <LocalCheckBox
                checked={!!supportPlan?.[otherCheckPropertyName]}
                onValueChange={(val) => {
                  onChangeSupportPlan?.(
                    val ?
                      { [otherCheckPropertyName]: val } :
                      { [otherCheckPropertyName]: val,
                        [otherContentPropertyName]: null }
                  );
                }}
              >
                その他
              </LocalCheckBox>
            </CheckboxesRowWrapper>
            {(
                isEmpty(supportPlan?.[firstCheckPropertyName]) &&
                isEmpty(supportPlan?.[secondCheckPropertyName]) &&
                isEmpty(supportPlan?.[otherCheckPropertyName])
              ) &&
                <ErrorMessage>必ず選択してください</ErrorMessage>
            }
          </Column5>
        </FieldCell>
      </GridRow>

      {
        supportPlan?.[otherCheckPropertyName] && (
          <GridRow>
            <LabelCell>
              <WordBreakLabel>その他</WordBreakLabel>
            </LabelCell>
            <FieldCell>
              <LocalTextInputExtend
                placeholder="その他の具体的な内容"
                value={supportPlan?.[otherContentPropertyName] ?? ""}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({[otherContentPropertyName]: val});
                }}
              />
            </FieldCell>
          </GridRow>
        )
      }
    </>
  );
};

export default MethodWithOtherRow;