import { EmploymentOfferLetter } from "../../core/types/api";
import { apiUtil } from "../../core/utils";
import { Document } from '../types';

export const uploadSharedDocuments = async (proposalId: number | string, kind: string, file: File) => {
  const data = new FormData();
  data.set('proposalId', String(proposalId));
  data.set('kind', kind);
  data.set('file', file);
  const response = await apiUtil.post('/proposal_employer_files', data);
  if (response.status >= 200 && response.status < 300) {
      return response.data as Document;
  }
  throw new Error('API error');
};

export const deleteSharedDocuments = async (documentId: number | string) => {
  const response = await apiUtil.delete(`/proposal_employer_files/${documentId}`);
  if (response.status >= 200 && response.status < 300) {
      return response.data as Document;
  }
  throw new Error('API error');
};

export const sendSharedDocuments = async (proposalId: number | string) => {
  const response = await apiUtil.post(`/proposals/${proposalId}/send_employer_files`, {});
  if (response.status >= 200 && response.status < 300) {
      return response.data;
  }
  throw new Error('API error');
};

export const getEmploymentOfferLetter = async (proposalId: string | number) => {
  const response = await apiUtil.get(
      `/proposals/${proposalId}/employment_offer`,
  );
  if (response.status >= 200 && response.status < 300) {
      return response.data as EmploymentOfferLetter;
  }
  throw new Error('API Error');
};
