import {
    ButtonHTMLAttributes,
    FunctionComponent,
} from 'react';
import styled from 'styled-components';

interface DeleteButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
}

interface ContainerProps extends DeleteButtonProps {
}

const Container = styled.button<ContainerProps>`
  padding: 8px 36px;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  background: #E93232;
  border: 1px solid #E93232;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;

  &:hover {
    color: #E93232;
    background: #FFFFFF;
  }
  
  &:focus {
    color: #FFF0F0;
    background: #E93232;
  }
  
  &:disabled {
    color: #F98686;
    background: #E93232;
  }
`;

const DeleteButton: FunctionComponent<DeleteButtonProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default DeleteButton;