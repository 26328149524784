import { useState, useEffect, FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';
import Row from './Row';
import Select, { SelectOption } from './Select';
import Text from './Text';
import { useTranslation } from 'react-i18next';

interface DateFieldProps extends HTMLAttributes<HTMLDivElement> {
  value?: string;
  onlyYearAndMonth?: boolean;
  onDateChange?: (value: string) => void;
  error?: boolean;
  emptyEnabled?: boolean;
}

const Container = styled(Row)`
  align-items: center;
  gap: 5px;
`;

const LocalSelect = styled(Select)`
  width: 77px;
`;


const DateField: FunctionComponent<DateFieldProps> = ({
  value,
  onlyYearAndMonth,
  onDateChange,
  error,
  emptyEnabled,
  ...props
}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'core'});
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [daysInMonth, setDaysInMonth] = useState(31);
    const [isLoaded, setIsLoaded] = useState(false);
    const currentYear = new Date().getFullYear();
    const pad0 = (num: number) => num.toString().padStart(2, "0");
    
    const yearsOptions: SelectOption[] = 
      Array
        .from(Array(11).keys())
        .map(year => ({ 
          value: (year + currentYear).toString(),
          label: (year + currentYear).toString(),
        }));
  
    const monthsOptions: SelectOption[] =
      Array
        .from(Array(12).keys())
        .map(month => ({
          value: pad0(month + 1),
          label: (month + 1).toString(),
        }));

    const daysOptions: SelectOption[] =
      Array
        .from(Array(daysInMonth).keys())
        .map(day => ({
          value: pad0(day + 1),
          label: (day + 1).toString(),
        }));

    if (emptyEnabled) {
      yearsOptions.unshift({ label: "", value: null});
      monthsOptions.unshift({ label: "", value: null});
      daysOptions.unshift({ label: "", value: null});
    }

    useEffect(() => {
      if (!value || isLoaded) 
        return;

      const date = new Date(value);
      setYear(date.getFullYear().toString());
      setMonth(pad0(date.getMonth() + 1));
      setDay(pad0(date.getDate()));
      setIsLoaded(true);
    }, [value])

    useEffect(() => {
        if (!onlyYearAndMonth && !year && !month && !day) {
          onDateChange?.("");
          return;
        }

        if (!year || !month)
          return;

        if (!onlyYearAndMonth && !day)
          return;

        const date = new Date(Number(year), Number(month), 0);
        let newDay = day;

        //If the selected day is greater than the number of days in the month, 
        //set the day to the last day of the month
        if (Number(day) > date.getDate()) {
          newDay = pad0(date.getDate());
          setDay(newDay);
        }

        //We still need a day because the backend expects a full date; otherwise, the date is not saved
        onDateChange?.(`${year}-${month}-${onlyYearAndMonth ? "01" : newDay}`);
    }, [year, month, day]);

    //update days options when month/year changes
    useEffect(() => {
      if (!year || !month)
        return;

      const date = new Date(Number(year), Number(month), 0);
      setDaysInMonth(date.getDate());
    }, [year, month]);

    return (
        <Container>
          <LocalSelect 
            options={yearsOptions}
            value={year}
            onValueChange={setYear}
            placeholder={t("select")}
            {...props}
          />
          <Text>{t("units.years")}</Text>
          <LocalSelect 
            options={monthsOptions}
            value={month}
            onValueChange={newMonth => {
              setDay("");
              setMonth(newMonth);
              //onDateChange?.(`${year}-${newMonth}-${onlyYearAndMonth ? "01" : ""}`);
            }}
            placeholder={t("select")}
            {...props}
          />
          <Text>{t("units.months")}</Text>
          { !onlyYearAndMonth &&
            <>
              <LocalSelect 
                disabled={!!!month}
                options={daysOptions}
                value={day}
                onValueChange={setDay}
                placeholder={t("select")}
                {...props}
              />
              <Text>{t("units.days")}</Text>
            </>
          }
        </Container>
    );
}

export default DateField;