import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

type LabelProps = HTMLAttributes<HTMLDivElement> & {
    required?: boolean;
    reversed?: boolean;
    children?: ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
`;

const Text = styled.span`
  overflow: hidden;
  word-wrap: break-word;
`

const Prompt = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: #FFFFFF;
  background-color: #F98686;
  border-radius: 10px;
  padding: 0 5px; 
  white-space: nowrap;
`

const Label: FunctionComponent<LabelProps> = ({
    required,
    reversed,
    children,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Container {...props} style={{ gap: reversed ? 5 : 8 }}>
            {required && reversed && <Prompt>{t('core.required_field')}</Prompt>}
            <Text>{children}</Text>
            {required && !reversed && <Prompt>{t('core.required_field')}</Prompt>}
        </Container>
    );
};

export default Label;