import { FunctionComponent } from "react";
import {
  CategoryContainer,
  GridRowsContainer,
  HeadingMediumContainer,
  SectionHeadingLarge,
  SectionHeadingMedium,
} from "../commonStyle"
import { SelectOption } from "../../../../core/components/Select";
import {SupportPlanFormProps} from "../types";
import ScheduleRow from "../components/ScheduleRow";
import ScheduleDateRow from "../components/ScheduleDateRow";
import EntrustingRow from "../components/EntrustingRow";
import EntrustingPersonLastNameRow from "../components/EntrustingPersonLastNameRow";
import EntrustingPersonFirstNameRow from "../components/EntrustingPersonFirstNameRow";
import EntrustingPersonTitleRow from "../components/EntrustingPersonTitleRow";
import MethodRow from "../components/MethodRow";
import LanguageCategory from "../components/LanguageCategory";
import ScheduleTimeCategory from "../components/ScheduleTimeCategory";

interface OrientationSectionProps extends SupportPlanFormProps {
  nationalityOptions?: SelectOption[];
}

export const OrientationSection: FunctionComponent<OrientationSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
  }) => {
  return (
    <>
      <CategoryContainer>
        <SectionHeadingLarge>ア. 情報提供内容等</SectionHeadingLarge>
      </CategoryContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>a. 本邦での生活一般に関する事項</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"orientationGeneralLifeSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"orientationGeneralLifeScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"orientationGeneralLifeEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"orientationGeneralLifeEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"orientationGeneralLifeEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"orientationGeneralLifeEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"orientationGeneralLifeEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話やDVD等の動画視聴等（質問に応じる体制あり）"}
          secondCheckPropertyName={"orientationGeneralLifeEntrustingMethodVideoCall"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>b. 法令の規定により外国人が履行しなければならない国又は地方公共団体の機関に対する届出その他の手続に関する事項及び必要に応じて同行し手続を補助すること</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"orientationProcedureSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"orientationProcedureScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"orientationProcedureEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"orientationProcedureEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"orientationProcedureEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"orientationProcedureEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"orientationProcedureEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話やDVD等の動画視聴等（質問に応じる体制あり）"}
          secondCheckPropertyName={"orientationProcedureEntrustingMethodVideoCall"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>c. 相談・苦情の連絡先、申出をすべき国又は地方公共団体の機関の連絡先</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"orientationRequestsSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"orientationRequestsScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"orientationRequestsEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"orientationRequestsEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"orientationRequestsEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"orientationRequestsEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"orientationRequestsEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話やDVD等の動画視聴等（質問に応じる体制あり）"}
          secondCheckPropertyName={"orientationRequestsEntrustingMethodVideoCall"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>d. 十分に理解することができる言語により医療を受けることができる医療機関に関する事項</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"orientationMedicalSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"orientationMedicalScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"orientationMedicalEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"orientationMedicalEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"orientationMedicalEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"orientationMedicalEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"orientationMedicalEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話やDVD等の動画視聴等（質問に応じる体制あり）"}
          secondCheckPropertyName={"orientationMedicalEntrustingMethodVideoCall"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>e. 防災・防犯に関する事項、急病その他の緊急時における対応に必要な事項</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"orientationEmergencySchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"orientationEmergencyScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"orientationEmergencyEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"orientationEmergencyEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"orientationEmergencyEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"orientationEmergencyEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"orientationEmergencyEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話やDVD等の動画視聴等（質問に応じる体制あり）"}
          secondCheckPropertyName={"orientationEmergencyEntrustingMethodVideoCall"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>f. 出入国又は労働に関する法令規定の違反を知ったときの対応方法その他当該外国人の法的保護に必要な事項</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"orientationViolationSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"orientationViolationScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"orientationViolationEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"orientationViolationEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"orientationViolationEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"orientationViolationEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"orientationViolationEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話やDVD等の動画視聴等（質問に応じる体制あり）"}
          secondCheckPropertyName={"orientationViolationEntrustingMethodVideoCall"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <LanguageCategory
        categoryName={"イ. 実施言語"}
        languageIdPropertyName={"orientationLanguageId"}
        languageOtherLastNamePropertyName={"orientationLanguageOtherLastName"}
        languageOtherFirstNamePropertyName={"orientationLanguageOtherFirstName"}
        languageOtherAffiliationPropertyName={"orientationLanguageOtherAffiliation"}
        supportPlan={supportPlan}
        onChangeSupportPlan={onChangeSupportPlan}
      />

      <ScheduleTimeCategory
        categoryName={"ウ. 実施予定時間"}
        propertyName={"orientationScheduleTime"}
        supportPlan={supportPlan}
      />
    </>
  );
};

export default OrientationSection;