import React, {FunctionComponent} from "react";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import {
  CategoryContainer,
  CustomLabel, FieldCell, GridRow, GridRowsContainer,
  LabelCell, LocalNumberField, Row10,
  SectionHeadingLarge, UnitText
} from "../commonStyle";

interface ScheduleTimeCategoryProps extends SupportPlanFormProps {
  categoryName: string;
  propertyName: SupportPlanExtractType<number>;
}

const ScheduleTimeCategory: FunctionComponent<ScheduleTimeCategoryProps> = (
  {
    supportPlan,
    categoryName,
    propertyName,
}) => {
  return (
    <>
      <CategoryContainer>
        <SectionHeadingLarge>{categoryName}</SectionHeadingLarge>
      </CategoryContainer>

      <GridRowsContainer>
        {/* 実施予定時間（合計） */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>実施予定時間（合計）</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Row10>
              <LocalNumberField
                readOnly
                value={supportPlan?.[propertyName] ?? 0}
              />
              <UnitText>時間</UnitText>
            </Row10>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default ScheduleTimeCategory;