import { useTranslation } from "react-i18next";
import { Section, SectionHeading, SectionHeadingContainer, SectionLabel, SectionLabelCell, SectionRow, SectionValue, SectionValueCell, SectionValuesContainer } from "./commonStyle";
import { SectionProps } from "../types/props";
import { FunctionComponent, useEffect, useState } from "react";
import { LANGUAGE_LEVEL_ITEMS } from "../constants/options";
import { EMPTY_VAL } from "../constants/defaultValue";
import { JobPreferredGender, JobState } from "../enums/job";
import { useJobTranslation } from "../hook";
import { useValueUnit } from "../../core/hooks";

interface CandidateConditionsSectionProps extends SectionProps {}

const CandidateConditionsSection: FunctionComponent<CandidateConditionsSectionProps> = ({
  job
}) => {
  const { addYearsOld } = useValueUnit();
  const { t: tJob } = useTranslation('translation', { keyPrefix: 'job' });
  const { t: tCore }= useTranslation('translation', { keyPrefix: 'core' });
  const { t, i18n } = useTranslation();
  const { findTranslation } = useJobTranslation();
  const [jlptLevel, setJlptLevel] = useState<string>(EMPTY_VAL);
  const [preferredGender, setPreferredGender] = useState<string>(EMPTY_VAL);
  const [preferredNationality, setPreferredNationality] = useState<string>(EMPTY_VAL);
  const [preferredAge, setPreferredAge] = useState<string>(EMPTY_VAL);
  const [requiredConditions, setRequiredConditions] = useState<string>(EMPTY_VAL);
  const [preferredConditions, setPreferredConditions] = useState<string>(EMPTY_VAL);

  useEffect(() => {
    if (!job)
      return;

    const jlptLevelLabel = LANGUAGE_LEVEL_ITEMS.find(v => v.value === job?.jlptLevel)?.label;
    setJlptLevel(jlptLevelLabel ? t(`${jlptLevelLabel}`) : EMPTY_VAL);
    
    switch (job?.preferredGender) {
      case JobPreferredGender.Female:
        setPreferredGender(tCore('female'));
        break;
      case JobPreferredGender.Male:
        setPreferredGender(tCore('male'));
        break;
      case JobPreferredGender.NotAny:
        setPreferredGender(tJob('preferred_none'));
        break;
      default:
        setPreferredGender(EMPTY_VAL);
    }

    const nationalityNames = job?.preferredNationalities?.map(
      n => tCore(`nationalities.${n.tkey}`)
    );
    setPreferredNationality(
      nationalityNames && nationalityNames.length > 0
        ? nationalityNames.join(', ')
        : EMPTY_VAL
    ) ;

    if (job?.preferredAgeFrom && job?.preferredAgeTo) 
      setPreferredAge(`${job?.preferredAgeFrom} ~ ${addYearsOld(job?.preferredAgeTo)}`);
    else if (job?.preferredAgeFrom && !job?.preferredAgeTo)
      setPreferredAge(`${addYearsOld(job?.preferredAgeFrom)} ~`);
    else if (!job?.preferredAgeFrom && job?.preferredAgeTo)
      setPreferredAge(`~ ${addYearsOld(job?.preferredAgeTo)}`);
    else
      setPreferredAge(EMPTY_VAL);
    

    if (job?.state === JobState.Published) {
      setRequiredConditions(findTranslation(job?.translations, "requiredConditions") || EMPTY_VAL);
      setPreferredConditions(findTranslation(job?.translations, "preferredConditions") || EMPTY_VAL);
    } else {
      setRequiredConditions(job?.requiredConditions || EMPTY_VAL);
      setPreferredConditions(job?.preferredConditions || EMPTY_VAL);
    }

  }, [job, i18n.language]);
  

  return (
    <Section>
      <SectionHeadingContainer>
        <SectionHeading>
          {tJob('candidate_requirements')}
        </SectionHeading>
      </SectionHeadingContainer>

       {/* 日本語能力試験（JPT）レベル */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('jlpt_level')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValuesContainer>
            <SectionValue>
              {jlptLevel}
            </SectionValue>
          </SectionValuesContainer>
        </SectionValueCell>
      </SectionRow>

      {/* 好ましい性別 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('preferred_gender')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{preferredGender}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 好ましい国籍 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>   
            {tJob('preferred_nationality')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{preferredNationality}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 好ましい年齢 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('preferred_age_range')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{preferredAge}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 必須条件 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('required_conditions')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {requiredConditions}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 歓迎条件 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('preferred_conditions')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {preferredConditions}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

    </Section>
  );
};

export default CandidateConditionsSection;