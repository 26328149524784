import { useTranslation } from "react-i18next";
import { JobEmploymentConditionTranslation, JobTranslation } from "./types/api";
import { decodeHTMLEntities } from "../core/utils";

export const useJobTranslation = () => {
  const { i18n } = useTranslation();
  const findTranslation = (
    translations: JobTranslation[] | null | undefined = [], 
    key: keyof Omit<JobTranslation, 'language' | 'id' | 'jobPostId'>, 
    languageCode?: string
  ) => {
    if (!translations) return '';

    const langCode = languageCode || i18n.language;
    const result = translations.find(t => t.language.code === langCode)?.[key];
    return result ? decodeHTMLEntities(result) : '';
  }

  return {
    findTranslation
  }
}