import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Text from '../../../core/components/Text';
import Field from '../../../core/components/Field';
import Label from '../../../core/components/Label';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Select from '../../../core/components/Select';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import { useJobPostCategory, useJobPostOptions } from '../../../core/hooks';
import { useReducerContext } from '../../../core/contexts/ReducerContext';

interface SkillsSectionProps {
    jobType?: string;
    onJobTypeChange?: (jobType: string) => void;
}

const SkillsSection: FunctionComponent<SkillsSectionProps> = ({
    jobType,
    onJobTypeChange,
}) => {
    const { state } = useReducerContext();
    const { t } = useTranslation();
    const isMobileDevice = useMediaQuery({ query: MOBILE_DEVICE });
    const [industry, setIndustry] = useState('');
    const isEmpty = (value: any) => !(!!value);
    const { industryOptions, jobTypeOptionsDic } = useJobPostOptions();
    const jobPostCategory = useJobPostCategory();
    const jobTypeOptions = jobTypeOptionsDic[industry];

    useEffect(() => {
        setIndustry(jobPostCategory.getIndustryIdByJobTypeId(jobType));
    }, [jobType, state.industries, state.jobTypes]);

    return (
        <Section>
            <SectionHeading>{t('candidate.skills')}</SectionHeading>

            <Field>
                <Label required>{t('job.industry')}</Label>
                <Select
                    placeholder="core.select"
                    style={{ width: isMobileDevice ? 'inherit' : 150 }}
                    options={industryOptions}
                    value={industry}
                    error={isEmpty(industry)}
                    onValueChange={setIndustry}
                />
                {   isEmpty(industry) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.dropdown_menu')}</Text>
                }
            </Field>

            <Field>
                <Label required>{t('job.job_type')}</Label>
                <Select
                    disabled={!industry}
                    placeholder="core.select"
                    style={{ width: isMobileDevice ? 'inherit' : 150 }}
                    options={jobTypeOptions}
                    value={jobType}
                    error={isEmpty(jobType)}
                    onValueChange={onJobTypeChange}
                />
                {   isEmpty(jobType) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.dropdown_menu')}</Text>
                }
            </Field>
        </Section>
    );
};

export default SkillsSection;