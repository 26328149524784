import {FunctionComponent} from "react";
import {
  Column16,
  CustomLabel,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingMediumContainer,
  LabelCell,
  LocalCheckBox,
  SectionHeadingMedium,
  UnitText,
  WordBreakLabel,
  SectionHeadingLarge,
  CategoryContainer, SectionHeadingMediumDescription, Row10, LocalNumberField,
} from "../commonStyle"
import Radio from "../../../../core/components/Radio";
import {isEmpty} from "../../../../core/utils";
import {SupportPlanFormProps} from "../types";
import {APPROPRIATE, NOT_OWN_HOUSING} from "../../../../core/constants/defaultValue";
import * as React from "react";
import {HousingEnsureSituation} from "../../../../core/enums/supportPlans";
import ScheduleRow from "../components/ScheduleRow";
import ScheduleDateInitializedTextRow from "../components/ScheduleDateInitializedTextRow";
import EntrustingRow from "../components/EntrustingRow";
import EntrustingPersonLastNameRow from "../components/EntrustingPersonLastNameRow";
import EntrustingPersonFirstNameRow from "../components/EntrustingPersonFirstNameRow";
import EntrustingPersonTitleRow from "../components/EntrustingPersonTitleRow";
import MethodWithOtherRow from "../components/MethodWithOtherRow";

interface LifeSectionProps extends SupportPlanFormProps {}

export const LifeSection: FunctionComponent<LifeSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
}) => {
  return (
    <>
      <CategoryContainer>
        <SectionHeadingLarge>ア. 適切な住居の確保に係る支援</SectionHeadingLarge>
      </CategoryContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          a. 特定技能外国人が賃貸借契約を行う場合<br/>
          <SectionHeadingMediumDescription>
            不動産仲介事業者や賃貸物件の情報を提供し、必要に応じて住宅確保に係る手続に同行し、住居探しの補助を行う。
            また、賃貸借契約の締結時に連帯保証人が必要な場合に、適当な連帯保証人がいないときは、支援対象者の連帯保証人となる又は利用可能な家賃債務保証業者を確保し自らが緊急連絡先となる
          </SectionHeadingMediumDescription>
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"lifeForeignerHousingSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"lifeForeignerHousingScheduleDateContent"}
          initialValue={APPROPRIATE}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"lifeForeignerHousingEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"lifeForeignerHousingEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"lifeForeignerHousingEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"lifeForeignerHousingEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          b. 特定技能所属機関が物件を借り上げる場合<br/>
          <SectionHeadingMediumDescription>
            自ら賃借人となって賃貸借契約を締結した上で、１号特定技能外国人の合意の下、住居として提供する
          </SectionHeadingMediumDescription>
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"lifeRentalHousingSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"lifeRentalHousingScheduleDateContent"}
          initialValue={APPROPRIATE}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"lifeRentalHousingEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"lifeRentalHousingEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"lifeRentalHousingEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"lifeRentalHousingEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          c. 特定技能所属機関の自己所有物件の場合<br/>
          <SectionHeadingMediumDescription>
            所有する社宅等を、１号特定技能外国人の合意の下、当該外国人に対して住居として提供する
          </SectionHeadingMediumDescription>
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"lifeOwnHousingSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"lifeOwnHousingScheduleDateContent"}
          initialValue={NOT_OWN_HOUSING}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"lifeOwnHousingEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"lifeOwnHousingEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"lifeOwnHousingEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"lifeOwnHousingEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          d. 情報提供する又は住居とし て提供する住居の概要（確保予定の場合を含む）
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 住居の確保状況 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>住居の確保状況</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column16>
              <Radio
                value={HousingEnsureSituation.Already}
                checked={
                  supportPlan?.lifeHousingOverviewEnsureSituation === HousingEnsureSituation.Already
                }
                onValueChange={(val) => {
                  onChangeSupportPlan?.({lifeHousingOverviewEnsureSituation: HousingEnsureSituation.Already});
                }}
              >
                在留資格変更許可申請（又は在留資格認定証明書交付申請）の時点で確保しているもの
              </Radio>
              <Radio
                value={HousingEnsureSituation.Will}
                checked={
                  supportPlan?.lifeHousingOverviewEnsureSituation === HousingEnsureSituation.Will
                }
                onValueChange={(val) => {
                  onChangeSupportPlan?.({lifeHousingOverviewEnsureSituation: HousingEnsureSituation.Will});
                }}
              >
                在留資格変更許可申請（又は在留資格認定証明書交付申請）の後に確保するもの
              </Radio>
            </Column16>
          </FieldCell>
        </GridRow>

        {/* 同居人数計 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>同居人数計</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Row10>
              <LocalNumberField
                allowZero
                placeholder="0"
                step="1"
                min="0"
                value={supportPlan?.lifeHousingOverviewLivingTogetherNumberOfPeople ?? 0}
                error={isEmpty(supportPlan?.lifeHousingOverviewLivingTogetherNumberOfPeople)}
                onValueChange={(val: number | null) => {
                  onChangeSupportPlan?.({lifeHousingOverviewLivingTogetherNumberOfPeople: Number(val)});
                }}
              />
              <UnitText>人（本人を含めた人数）</UnitText>
            </Row10>
          </FieldCell>
        </GridRow>

        {/* 居間の広さ */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel>居間の広さ</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <LocalCheckBox
              checked={!!supportPlan?.lifeHousingOverviewLivingRoomSize_7_5OrMore}
              onValueChange={() => {
                onChangeSupportPlan?.({ lifeHousingOverviewLivingRoomSize_7_5OrMore: true});
              }}
            >
              1人当たり7.5㎡以上を確保
            </LocalCheckBox>
          </FieldCell>
        </GridRow>

        {/* 寝室の広さ */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel>寝室の広さ</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <LocalCheckBox
              checked={!!supportPlan?.lifeHousingOverviewBedroomSize_4_5OrMore}
              onValueChange={() => {
                onChangeSupportPlan?.({ lifeHousingOverviewBedroomSize_4_5OrMore: true});
              }}
            >
              1人当たり4.5㎡以上を確保
            </LocalCheckBox>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>

      <CategoryContainer>
        <SectionHeadingLarge>イ. 生活に必要な契約に係る支援</SectionHeadingLarge>
      </CategoryContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>a. 銀行その他の金融機関における預金口座又は貯金口座の開設の手続の補助</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"lifeAccountOpeningSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"lifeAccountOpeningScheduleDateContent"}
          initialValue={APPROPRIATE}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"lifeAccountOpeningEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"lifeAccountOpeningEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"lifeAccountOpeningEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"lifeAccountOpeningEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"手続に係る情報提供"}
          firstCheckPropertyName={"lifeAccountOpeningEntrustingMethodProviding"}
          secondCheckLabel={"必要に応じて手続に同行"}
          secondCheckPropertyName={"lifeAccountOpeningEntrustingMethodAccompanying"}
          otherCheckPropertyName={"lifeAccountOpeningEntrustingMethodOther"}
          otherContentPropertyName={"lifeAccountOpeningEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>b. 携帯電話の利用に関する契約の手続の補助</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"lifeMobilePhoneSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"lifeMobilePhoneScheduleDateContent"}
          initialValue={APPROPRIATE}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"lifeMobilePhoneEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"lifeMobilePhoneEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"lifeMobilePhoneEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"lifeMobilePhoneEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"手続に係る情報提供"}
          firstCheckPropertyName={"lifeMobilePhoneEntrustingMethodProviding"}
          secondCheckLabel={"必要に応じて手続に同行"}
          secondCheckPropertyName={"lifeMobilePhoneEntrustingMethodAccompanying"}
          otherCheckPropertyName={"lifeMobilePhoneEntrustingMethodOther"}
          otherContentPropertyName={"lifeMobilePhoneEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>c. 電気・水道・ガス等のライフラインに関する手続の補助</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"lifeLifelineSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"lifeLifelineScheduleDateContent"}
          initialValue={APPROPRIATE}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"lifeLifelineEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"lifeLifelineEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"lifeLifelineEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"lifeLifelineEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"手続に係る情報提供"}
          firstCheckPropertyName={"lifeLifelineEntrustingMethodProviding"}
          secondCheckLabel={"必要に応じて手続に同行"}
          secondCheckPropertyName={"lifeLifelineEntrustingMethodAccompanying"}
          otherCheckPropertyName={"lifeLifelineEntrustingMethodOther"}
          otherContentPropertyName={"lifeLifelineEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>
    </>
  );
};

export default LifeSection;