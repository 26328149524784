const ACTIONS = {
  START_LOADING: 'TYPE_START_LOADING',
  STOP_LOADING: 'TYPE_STOP_LOADING',
  SET_PROMPT: 'TYPE_SET_PROMPT',
  CLEAR_PROMPT: 'TYPE_CLEAR_PROMPT',
  START_CONFIRMATION: 'TYPE_START_CONFIRMATION',
  STOP_CONFIRMATION: 'TYPE_STOP_CONFIRMATION',
  LOGIN: 'TYPE_LOGIN',
  LOGOUT: 'TYPE_LOGOUT',
  UPDATE_PROFILE: 'TYPE_UPDATE_PROFILE',
  UPDATE_COMPANY: 'TYPE_UPDATE_COMPANY',
  UPDATE_CANDIDATE: 'TYPE_UPDATE_CANDIDATE',
  UPDATE_USER_SETTING: 'TYPE_UPDATE_USER_SETTING',
  ADMIN_LOGIN: 'TYPE_ADMIN_LOGIN',
  ADMIN_LOGOUT: 'TYPE_ADMIN_LOGOUT',
  SET_NATIONALITIES: 'TYPE_SET_NATIONALITIES',
  SET_INDUSTRIES: 'TYPE_SET_INDUSTRIES',
  SET_JOB_TYPES: 'TYPE_SET_JOB_TYPES',
  SET_ADDITIONAL_INFORMATION_TYPES: 'TYPE_SET_ADDITIONAL_INFORMATION_TYPES',
  SET_NAVIGABLE: 'TYPE_SET_NAVIGABLE',
  START_UPLOADING: 'TYPE_START_UPLOADING',
  STOP_UPLOADING: 'TYPE_STOP_UPLOADING',
}

export default ACTIONS;