import { useTranslation } from "react-i18next";
import { Section, SectionHeading, SectionHeadingContainer, SectionLabel, SectionLabelCell, SectionRow, SectionValue, SectionValueCell, SectionValuesContainer } from "./commonStyle";
import { SectionProps } from "../types/props";
import { FunctionComponent, useEffect, useState } from "react";
import { EMPTY_VAL } from "../constants/defaultValue";
import { JobDormType } from "../enums/job";
import { COMMUTE_METHOD_ITEMS } from "../constants/options";
import { useValueUnit } from "../../core/hooks";

interface LivingEnvironmentSectionProps extends SectionProps {}

const LivingEnvironmentSection: FunctionComponent<LivingEnvironmentSectionProps> = ({
  job
}) => {
  const { t, i18n } = useTranslation();
  const { t: tJob } = useTranslation('translation', { keyPrefix: 'job' });
  const { t: tCore }= useTranslation('translation', { keyPrefix: 'core' });
  const { addMinute } = useValueUnit();
  const [dormitory, setDormitory] = useState<string>(EMPTY_VAL);
  const [commuteMethodAndTime, setCommuteMethodAndTime] = useState<string>(EMPTY_VAL);
  const [furnitureAppliances, setFurnitureAppliances] = useState<string>(EMPTY_VAL);
  const [movingExpensesTransportation, setMovingExpensesTransportation] = useState<string>(EMPTY_VAL);
  const [movingExpensesBaggage, setMovingExpensesBaggage] = useState<string>(EMPTY_VAL);

  useEffect(() => {
    if (!job)
      return;

    if (job.dormitory === true) {
      let dormType = '';
      switch (job.dormitoryType) {
        case JobDormType.Apartment:
          dormType = tJob('apartment');
          break;
        case JobDormType.SingleHouse:
          dormType = tJob('single_house');
          break;
      }

      setDormitory(
        `${tCore('option_available')}${dormType ? ` (${dormType})` : ''}`
      )
    } else if (job.dormitory === false) {
      setDormitory(tCore('option_not_available'));
    } else {
      setDormitory(EMPTY_VAL);
    }

    if (!(job.commuteMethod && job.commuteMinutes))
      setCommuteMethodAndTime(EMPTY_VAL);
    else {
      let label = COMMUTE_METHOD_ITEMS.find(item => item.value === job.commuteMethod)?.label;
      const method = label ? t(label) : EMPTY_VAL;
      const time = job.commuteMinutes ? addMinute(job.commuteMinutes) : EMPTY_VAL;
      setCommuteMethodAndTime(`${method} (${tJob('total_commuting_time')} ${time})`);
    }

    if (job.furnitureAppliances === true) 
      setFurnitureAppliances(tJob('provided'));
    else if (job.furnitureAppliances === false)
      setFurnitureAppliances(tJob('not_provided'));
    else
      setFurnitureAppliances(EMPTY_VAL);

    if (job.transportationMovingExpensesCovered === true) {
      const amount = job.transportationMovingExpensesMaximumSupportAllowance;
      const text = amount
        ? tJob('covered_by_employer_maximum_amount_yen', { amount })  
        : tJob('covered_by_employer');

      setMovingExpensesTransportation(text);
    } else if (job.transportationMovingExpensesCovered === false) 
      setMovingExpensesTransportation(tJob('paid_by_employee'));
    else 
      setMovingExpensesTransportation(EMPTY_VAL);
    
    if (job.baggageDeliveryMovingExpensesCovered === true) {
      const amount = job.baggageDeliveryMovingExpensesMaximumSupportAllowance;
      const text = amount
        ? tJob('covered_by_employer_maximum_amount_yen', { amount }) 
        : tJob('covered_by_employer');

      setMovingExpensesBaggage(text);
    } else if (job.baggageDeliveryMovingExpensesCovered === false) 
      setMovingExpensesBaggage(tJob('paid_by_employee'));
    else 
      setMovingExpensesBaggage(EMPTY_VAL);
  }, [job, i18n.language]);

  return (
    <Section>
      <SectionHeadingContainer>
        <SectionHeading>
          {tJob('housing')}
        </SectionHeading>
      </SectionHeadingContainer>

       {/* 宿舎 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('dormitory')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValuesContainer>
            <SectionValue>
              { dormitory }
            </SectionValue>
          </SectionValuesContainer>
        </SectionValueCell>
      </SectionRow>

      {/* 職場までの通勤時間 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('commute_method_and_time')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            { commuteMethodAndTime }
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 家具・家電 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('furniture_appliances')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            { furnitureAppliances }
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 引越し交通費用 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('moving_expenses_transportation')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            { movingExpensesTransportation }
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 引越し荷物配送費用 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('moving_expenses_baggage')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            { movingExpensesBaggage }
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

    </Section>
  );
};

export default LivingEnvironmentSection;