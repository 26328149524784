import { FunctionComponent, HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../core/components/Button';
import CategoryHeading from '../../core/components/CategoryHeading';
import Text from '../../core/components/Text';
import { useTranslation } from 'react-i18next';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import { useJobPostCategory } from '../../core/hooks';
import { MOBILE_DEVICE } from '../../core/constants/styles';
import Column from '../../core/components/Column';
import { Job } from '../types/api';
import { decodeHTMLEntities, getJobLocation } from '../../core/utils';

type ProposeCandidateCardProps = HTMLAttributes<HTMLDivElement> & {
    job: Job;
    readonly?: boolean;
    onPropose: (id: number) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 560px;
  margin-bottom: 10px;
  padding: 20px 20px 14px 20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  
  > *:not(:first-child) {
    margin-left: 10px;
  }
`;

const AnonymousActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
  }
`;

const LocalCategoryHeading = styled(CategoryHeading)`
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProposeCandidateCard: FunctionComponent<ProposeCandidateCardProps> = ({
    job,
    readonly,
    onPropose
}) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { state } = useReducerContext();
    const jobPostCategory = useJobPostCategory();

    const title = decodeHTMLEntities(job?.translations?.find(t => t.language.code === i18n.language)?.name) ?? job?.name;
    const location = getJobLocation(job, i18n.language);
    const industry = jobPostCategory.getIndustryNameByJobTypeId(job.jobTypeId);
    const jobType = jobPostCategory.getJobTypeName(job.jobTypeId);
    const grossSalary = job.grossSalary;
    const grossSalaryUpperLimit = job.grossSalaryUpperLimit;

    return (
        <Container>
            <LocalCategoryHeading>{title}</LocalCategoryHeading>
            <Text style={{ marginTop: 10, color: '#999999' }}>{location}</Text>
            <Text style={{ marginTop: 4 }}>{industry} - {jobType}</Text>
            <Text style={{ marginTop: 4 }}>
                {
                    grossSalaryUpperLimit
                        ? t('job.dollars', { number: (`${grossSalary?.toLocaleString()} ~ ${grossSalaryUpperLimit.toLocaleString()}`)})
                        : t('job.dollars', { number: grossSalary?.toLocaleString() ?? '-' })
                }
            </Text>
            {!readonly && (
                <Actions>
                    <Button onClick={() => onPropose?.(job.id)}>
                        {state.isCandidate ? t('proposal.apply') : t('proposal.propose')}
                    </Button>
                </Actions>
            )}
            {!state.isLoggedIn && (
                <AnonymousActions>
                    <Column style={{ flex:1, gap: 5, overflow: 'hidden' }} center>
                        <Text style={{ color: '#999999' }}>
                            {t('job.job_seekers')}
                        </Text>
                        <Button
                            style={{ width: '100%' }}
                            onClick={() => navigate('/register')}
                        >
                            {t('job.register_and_apply')}
                        </Button>
                    </Column>
                    <Column style={{ flex: 1, gap: 5, overflow: 'hidden' }} center>
                        <Text style={{ color: '#999999' }}>
                            {t('job.recruitment_agencies')}
                        </Text>
                        <Button
                            style={{ width: '100%' }}
                            variant="tertiary"
                            onClick={() => navigate('/contact')}
                        >
                            {t('job.contact')}
                        </Button>
                    </Column>
                </AnonymousActions>
            )}
        </Container>
    );
};

export default ProposeCandidateCard;