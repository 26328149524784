export const LANGUAGE_LABELS: { [key: string]: string } = {
    'en': 'English',
    'ja': '日本語',
    'vi': 'Tiếng Việt',
    'id': 'Bahasa Indonesia',
    'my': 'မြန်မာ',
    'ne': 'नेपाली',
};

export const LANGUAGE_REGION_LABELS: { [key: string]: string } = {
    'en': 'Philippines',
    'ja': '日本',
    'vi': 'Việt Nam',
    'id': 'Indonesia',
    'my': 'မြန်မာ',
    'ne': 'नेपाल',
};