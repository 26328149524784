import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import EnhancedLink from '../../components/EnhancedLink';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import { useReducerContext } from '../../contexts/ReducerContext';
import { useTranslation } from 'react-i18next';

interface OrganizationSettingsPanelProps {
}

interface ItemWrapperProps {
  active?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Item = styled(EnhancedLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding: 0 10px;
`;

const ItemWrapper = styled.div<ItemWrapperProps>`
    width: 100%;
    background-color: ${({ active }) => active ? "#FFDFD1" : ""};
`;

const LocalSectionHeading = styled(SectionHeading)`
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
`;

const LocalSection = styled(Section)`
    width: 200px;
    padding: 0;
    margin-top: 25px;
`;

const OrganizationSettingsPanel: FunctionComponent<OrganizationSettingsPanelProps> = ({
}) => {
    const { state } = useReducerContext();
    const { t } = useTranslation('translation', { keyPrefix: "core.settings" });
    const [organizationActive, setOrganizationActive] = useState(false);
    const [usersActive, setUsersActive] = useState(false);
    const [interviewActive, setInterviewActive] = useState(false);
    const [documentsActive, setDocumentsActive] = useState(false);

    return (
        <Container>
            <LocalSectionHeading>{t("title")}</LocalSectionHeading>
            <LocalSection>

                {/* Common settings between Employers and Partners */}
                <ItemWrapper active={organizationActive}>
                    <Item to="/settings/organization" onActiveChange={setOrganizationActive}>
                        {t("organization.title")}
                    </Item>
                </ItemWrapper>
                <ItemWrapper active={usersActive}>
                    <Item to="/settings/users" onActiveChange={setUsersActive}>
                        {t("manage_users.title")}
                    </Item>
                </ItemWrapper>

                {state.isEmployer && (
                    <>
                        <ItemWrapper active={interviewActive}>
                            <Item to="/settings/interview" onActiveChange={setInterviewActive}>
                                {t("interview.title")}
                            </Item>
                        </ItemWrapper> 
                        <ItemWrapper active={documentsActive}>
                            <Item to="/settings/documents" onActiveChange={setDocumentsActive}>
                                {t("documents.title")}
                            </Item>
                        </ItemWrapper>
                    </>
                )}
            </LocalSection>
        </Container>
    );
};

export default OrganizationSettingsPanel;