import { useTranslation } from "react-i18next";
import { Section, SectionHeading, SectionHeadingContainer, SectionLabel, SectionLabelCell, SectionRow, SectionValue, SectionValueCell, SectionValuesContainer } from "./commonStyle";
import { SectionProps } from "../types/props";
import { EMPTY_VAL } from "../constants/defaultValue";
import { useJobPostCategory } from "../../core/hooks";
import { EcContractType } from "../../core/enums/employmentConditions";
import { JobContractPeriod, JobContractRenewal, JobJPConversationSkill, JobState } from "../enums/job";
import { formatDate } from "../../core/utils";
import { useEffect, useState } from "react";
import { useJobTranslation } from "../hook";

interface BasicInformationSectionProps extends SectionProps {}

const BasicInformationSection: React.FC<BasicInformationSectionProps> = ({
  job
}) => {
  const jobPostCategory = useJobPostCategory();
  const { findTranslation } = useJobTranslation();
  const { i18n } = useTranslation();
  const { t: tJob } = useTranslation('translation', { keyPrefix: 'job' });
  const { t: tCore } = useTranslation('translation', { keyPrefix: 'core' });
  const { t: tCand } = useTranslation('translation', { keyPrefix: 'candidate' });
  const isPublished = job?.state === JobState.Published;
  const [numOfPositions, setNumOfPositions] = useState<string>(EMPTY_VAL);
  const [contractType, setContractType] = useState<string>(EMPTY_VAL);
  const [contractPeriod, setContractPeriod] = useState<string>(EMPTY_VAL);
  const [conversationSkill, setConversationSkill] = useState<string>(EMPTY_VAL);
  const [contractRenewal, setContractRenewal] = useState<string>(EMPTY_VAL);
  const [criteriaForRenewal, setCriteriaForRenewal] = useState<string>(EMPTY_VAL);
  const [jobDescription, setJobDescription] = useState<string>(EMPTY_VAL);

  useEffect(() => {
    if (!job)
      return;

      if (job?.numberOfPositions) 
        setNumOfPositions(`${job?.numberOfPositions} ${tCore('units.people')}`);
      else
        setNumOfPositions(EMPTY_VAL);

      switch (job?.ecPlaceOfEmploymentContractType) {
        case EcContractType.DirectEmployment:
          setContractType(tJob("direct_employment"));
          break;
        case EcContractType.DispatchEmployment:
          setContractType(tJob("dispatch_employment"));
          break;
        default:
          setContractType(EMPTY_VAL);
      }

      switch (job?.contractPeriod) {
          case JobContractPeriod.Indefinite:
            setContractPeriod(tJob("indefinite"));
            break;
          case JobContractPeriod.FixedTerm:
            const years = job?.contractPeriodYears
              ? `(${job?.contractPeriodYears} ${tCore('units.years')})`
              : '';
            setContractPeriod(`${tJob('fixed_term')} ${years}`);
            break;
          default:
            setContractPeriod(EMPTY_VAL);
      }

      switch (job?.japaneseConversationSkills) {
        case JobJPConversationSkill.CanSpeakBasicPhrases:
          setConversationSkill(tCand('can_speak_basic_phrases'));
          break;
        case JobJPConversationSkill.CanCarryOnEverydayConversation:
          setConversationSkill(tCand('can_carry_on_everyday_conversations'));
          break;
        case JobJPConversationSkill.Fluent:
          setConversationSkill(tCand('fluent'));
          break;
        default:
          setConversationSkill(EMPTY_VAL);
      }

    
      switch (job?.contractRenewal) {
        case JobContractRenewal.Automatic:
          setContractRenewal(tJob('automatic_renewal'));
          break;
        case JobContractRenewal.Possible:
          setContractRenewal(tJob('possible_renewal'));
          break;
        case JobContractRenewal.No:
          setContractRenewal(tJob('no_renewal'));
          break;
        default:
          setContractRenewal(EMPTY_VAL);
      }

      if (isPublished) {
        setJobDescription(findTranslation(job?.translations, "jobDescription") || EMPTY_VAL);
      } else {
        setJobDescription(job?.jobDescription || EMPTY_VAL);
      }

      const criteria: string [] = [];
      if (job?.ecContractPeriodRenewalConditionWorkload)
        criteria.push(tJob('criteria_work_volume'));

      if (job?.ecContractPeriodRenewalConditionEmployeePerformance) 
        criteria.push(tJob('criteria_performance'));
      
      if (job?.ecContractPeriodRenewalConditionEmployeeAbility)
        criteria.push(tJob('criteria_ability'));

      if (job?.ecContractPeriodRenewalConditionCompanySituation)
        criteria.push(tJob('criteria_company_financial_condition'));

      if (job?.ecContractPeriodRenewalConditionProgressSituation)
        criteria.push(tJob('criteria_progress'));

      if (job?.ecContractPeriodRenewalConditionOther) 
        criteria.push(tJob('criteria_others'));

      if (criteria.length > 0)
        setCriteriaForRenewal(criteria.join(" / "));
      else 
        setCriteriaForRenewal(EMPTY_VAL);
  }, [job, i18n.language])

  return (
    <Section>
      <SectionHeadingContainer>
        <SectionHeading>
          {tJob('basic_information')}
        </SectionHeading>
      </SectionHeadingContainer>

       {/* 募集人数 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('number_of_positions')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {numOfPositions}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 募集締切日 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('application_deadline')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {formatDate(job?.applicationDeadline) || EMPTY_VAL}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 入社予定時期 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('planned_date_of_employment')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {formatDate(job?.plannedDateOfEmployment) || EMPTY_VAL}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 業種 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('industry')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            { jobPostCategory.getIndustryNameByJobTypeId(job?.jobTypeId) || EMPTY_VAL }
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 職種 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('job_type')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            { jobPostCategory.getJobTypeName(job?.jobTypeId) || EMPTY_VAL }
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 雇用形態 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('type_of_contract')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {contractType}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 契約期間の定め */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('contract_period')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {contractPeriod}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/*　日本語の会話能力 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('japanese_conversation_skills')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {conversationSkill}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/*　契約の更新 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('contract_renewal')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {contractRenewal}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 契約更新の判断基準 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('criteria_for_contract_renewal')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {criteriaForRenewal}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 海外居住者の応募 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('acceptance_of_overseas_residents')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            { job?.acceptOverseasResidents 
              ? tJob('acceptance_of_overseas_residents_acceptable') 
              : EMPTY_VAL
            }
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 仕事の内容 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob('job_description')}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>
            {jobDescription}
          </SectionValue>
        </SectionValueCell>
      </SectionRow>

    </Section>
  );
};

export default BasicInformationSection;