import { AnchorHTMLAttributes, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface TextLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    variant?: 'normal' | 'small';
    children?: ReactNode;
}

const Container = styled.a<TextLinkProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${(props: TextLinkProps) => props.variant === 'small' ? 12 : 14}px;
  font-weight: 400;
  line-height: ${(props: TextLinkProps) => props.variant === 'small' ? 17 : 20}px;
  color: #017698;
  text-decoration: ${(props: TextLinkProps) => props.variant === 'small' ? 'none' : 'underline'};
`;

const Text: FunctionComponent<TextLinkProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default Text;