import { Section, SectionHeading, SectionHeadingContainer } from "./commonStyle";
import { SectionProps } from "../types/props";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { JobQandASection } from "../components/JobQASection";

interface QuestionAndAnswerSectionProps extends SectionProps {}

const LocalSectionHeadingContainer = styled(SectionHeadingContainer)`
  border-bottom: 1px solid #c2c2c2;
`;

const ContentWrapper = styled.div`
  padding: 20px;
`;

const QuestionAndAnswerSection: FunctionComponent<QuestionAndAnswerSectionProps> = ({
  job
}) => {
  return (
    <Section>
      <LocalSectionHeadingContainer>
        <SectionHeading>
          Q & A
        </SectionHeading>
      </LocalSectionHeadingContainer>

      <ContentWrapper>
        <JobQandASection jobPostId={job?.id} />
      </ContentWrapper>

    </Section>
  );
};

export default QuestionAndAnswerSection;