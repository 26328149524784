
export enum CandidateGender {
  Female = 'female',
  Male = 'male'
}

export enum CandidateJapaneseConversationSkills {
  CanSpeakBasicPhrases = 'can_speak_basic_phrases',
  CanCarryOnEverydayConversations = 'can_carry_on_everyday_conversations',
  Fluent = 'fluent',
}

export enum CandidateVisaStatus {
  Student = 'student',
  SpecifiedSkilledWorker1 = 'specified_skilled_worker_1',
  SpecifiedSkilledWorker2 = 'specified_skilled_worker_2',
  Trainee = 'trainee',
  DesignatedActivities = 'designated_activities',
  LivingOverseas = 'living_overseas',
  Other = 'other',
}

export enum CandidateJlptLevel {
  N1 = 'n1',
  N2 = 'n2',
  N3 = 'n3',
  N4 = 'n4',
  N5 = 'n5',
}