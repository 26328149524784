import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Proposal } from "../../../../types";
import Button from "../../../../../core/components/Button";
import {useReducerContext} from "../../../../../core/contexts/ReducerContext";
import ACTIONS from "../../../../../core/constants/actions";
import {useNavigate} from "react-router-dom";
import {getJob} from "../../../../../job/apis/job";
import {getProposalEmploymentConditions} from "../../../../apis/employmentCondition";
import {prepareInitializedSpFormData} from "../spFormInitialization";
import {createProposalSupportPlans} from "../../../../apis/supportPlan";
import axios from "axios";
import {getCandidate} from "../../../../../candidate/apis/candidate";


interface SupportPlansCreationSegmentProps {
  proposal: Proposal;
}

enum SupportPlansStatus {
  NOT_CREATED = 'NOT_CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  && {
    margin-top: 20px;
  }
`;

const ActionButton = styled(Button)`
  height: 35px;
  width: 160px;
  padding: 0 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const SupportPlansCreationSegment: FunctionComponent<
  SupportPlansCreationSegmentProps
> = ({ proposal }) => {
  const { t } = useTranslation();
  const { dispatch, state } = useReducerContext();
  const navigate = useNavigate();
  const supportPlansStatus = (() => {
    if (!proposal.supportPlan) {
      return SupportPlansStatus.NOT_CREATED;
    }
      return SupportPlansStatus.IN_PROGRESS;
  })();

  const onClick = async () => {
    if (!proposal)
      return;

    const moveToFormPage = () =>
      navigate(`/applications/${proposal.id}/documents/support_plans/edit`);

    if (supportPlansStatus !== SupportPlansStatus.NOT_CREATED) {
      moveToFormPage();
      return;
    }

    dispatch( { type: ACTIONS.START_LOADING });

    try {
      const job = await getJob(proposal.jobPostId);
      const candidate = await getCandidate(proposal.candidateId);
      let ec;
      try {
        ec = await getProposalEmploymentConditions(proposal.employmentCondition ? proposal.employmentCondition.id : '');
      } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.status === 404) {
          // Do nothing as the support plan may be created before the employment conditions are created.
        } else {
          dispatch({
            type: ACTIONS.SET_PROMPT,
            payload: {
              type: 'warning',
              message: "支援計画書の作成に失敗しました"
            }
          });
          return;
        }
      }

      const initDate = prepareInitializedSpFormData({
        company: state.company!,
        job,
        candidate,
        ec
      });
      await createProposalSupportPlans(proposal.id, initDate);

      moveToFormPage();

      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: 'success',
          message: "支援計画書を作成しました"
        }
      });
    } catch {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: 'warning',
          message: "支援計画書の作成に失敗しました"
        }
      });
    } finally {
      dispatch( { type: ACTIONS.STOP_LOADING });
    }
  }
  return (
    <Container>
      <span>{t("proposal.create_support_plans_description")}</span>
      <ActionButton
        onClick={onClick}
      >
        {t("proposal.create_support_plans")}
      </ActionButton>
    </Container>
  );
};

export default SupportPlansCreationSegment;
