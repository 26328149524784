import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

type TextButtonProps = HTMLAttributes<HTMLButtonElement> & {
};

const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  color: #017698;
  background: none;
  border: none;
`;

const TextButton: FunctionComponent<TextButtonProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default TextButton;