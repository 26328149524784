import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type RowProps = HTMLAttributes<HTMLDivElement> & {
    center?: boolean;
    children?: ReactNode;
};

export const Container = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  align-items: ${(props: RowProps) => props.center ? 'center' : 'flex-start'};
`;

const Row: FunctionComponent<RowProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default Row;