export enum EcContractRenewal  {
  AutomaticRenewal = 'yes',
  NoRenewal = 'no',
  ConditionalRenewal = 'depends',
}

export enum EcContractType {
  DirectEmployment = "direct",
  DispatchEmployment = "dispatched",
}

//変形労働時間制（単位）
export enum EcVariableWorkingHoursUnit {
  Week = "week",
  Month = "month",
  Year = "year",
}

//非定例休日（単位）
export enum EcIrregularHolidayUnit {
  Week = "week",
  Month = "month"
}

//支払方法
export enum EcPaymentMethod {
  BankTransfer = "bank",
  Cash = "cash",
}

//支給形態
export enum EcWagePaymentType {
  Monthly = "month",
  Daily = "day",
  Hourly = "hour",
}