import { FunctionComponent } from "react";
import {
  GridRowsContainer,
  HeadingMediumContainer,
  SectionHeadingMedium,
} from "../commonStyle"
import { SelectOption } from "../../../../core/components/Select";
import {SupportPlanFormProps} from "../types";
import * as React from "react";
import ScheduleRow from "../components/ScheduleRow";
import ScheduleDateReadOnlyRow from "../components/ScheduleDateReadOnlyRow";
import EntrustingRow from "../components/EntrustingRow";
import EntrustingPersonLastNameRow from "../components/EntrustingPersonLastNameRow";
import EntrustingPersonFirstNameRow from "../components/EntrustingPersonFirstNameRow";
import EntrustingPersonTitleRow from "../components/EntrustingPersonTitleRow";

interface InteractionsSectionProps extends SupportPlanFormProps {
  nationalityOptions?: SelectOption[];
}

export const InteractionsSection: FunctionComponent<InteractionsSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
  }) => {
  return (
    <>
      <HeadingMediumContainer>
        <SectionHeadingMedium>
          a. 必要に応じ、地方公共団体やボランティア団体等が主催する地域住民との交流の場に関する情報の提供や地域の自治会等の案内を行い、各行事等への参加の手続の補助を行うほか、
          必要に応じて同行して各行事の注意事項や実施方法を説明するなどの補助を行う
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"interactionsProvidingSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"interactionsProvidingScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"interactionsProvidingEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"interactionsProvidingEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"interactionsProvidingEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"interactionsProvidingEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>b. 日本の文化を理解するために必要な情報として、就労又は生活する地域の行事に関する案内を行うほか、必要に応じて同行し現地で説明するなどの補助を行う</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"interactionsCulturalSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"interactionsCulturalScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"interactionsCulturalEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"interactionsCulturalEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"interactionsCulturalEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"interactionsCulturalEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>
    </>
  );
};

export default InteractionsSection;