import {
    ChangeEvent,
    FunctionComponent,
    InputHTMLAttributes,
    ReactNode
} from 'react';
import styled from 'styled-components'

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
    onValueChange?: (checked: boolean) => void;
    children?: ReactNode;
}

type ContainerProps = CheckboxProps & {
};

type ButtonProps = CheckboxProps & {
};

const Input = styled.input`
  display: none !important;
`;

const Button = styled.div<ButtonProps>`
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 6px;
  background: ${(props: ButtonProps) => props.disabled ? '#D5D5D5' : '#FFFFFF'};
  border: 1px solid #999999;
  border-radius: 3px;
  box-sizing: border-box;
`;

const Container = styled.label<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: ${(props: ContainerProps) => props.disabled ? '#999999' : '#444444'};
  cursor: pointer;
  pointer-events: ${(props: ContainerProps) => props.disabled ? 'none' : 'auto'};
  
  ${Input}:checked ~ ${Button} {
    background: #FF730D;
    border-color: #FF730D;
    
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      right: 1px;
      bottom: 2px;
      left: 1px;
      background: center/cover no-repeat url("/images/icon-checked.svg");
    }
  }
  
  &:hover > ${Button} {
    background: #FFC194;
    border-color: #FF730D;
  }

  &:hover ${Input}:checked ~ ${Button} {
    &:after {
      background-image: url("/images/icon-checked-hovered.svg");
    }
  }
`;

const Checkbox: FunctionComponent<CheckboxProps> = ({
    className,
    style,
    disabled,
    children,
    onValueChange,
    ...props
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onValueChange && onValueChange(event.target.checked);
    }

    return (
        <Container className={className} style={style} disabled={disabled}>
            <Input
                type="checkbox"
                disabled={disabled}
                onChange={handleChange}
                {...props}
            />
            <Button disabled={disabled} />
            {children}
        </Container>
    );
};

export default Checkbox;
