import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Radio from '../../core/components/Radio';
import { Job } from '../../job/types/api';
import { decodeHTMLEntities } from '../../core/utils';
import { useJobPostCategory } from '../../core/hooks';

interface JobListTableProps extends HTMLAttributes<HTMLDivElement> {
    jobs?: Job[];
    selectedIndex?: number;
    onItemClick?: (index: number) => void;
    onItemSelect?: (index: number) => void;
}

const Item = styled.div`
  display: contents;
  box-sizing: border-box;
  
  &:hover {
    > * {
      background: #FFDFD1;
      border-top: 2px solid #FFC194;
      border-bottom: 2px solid #FFC194;
    }

    > *:first-child {
      border-left: 2px solid #FFC194;
    }

    > *:last-child {
      border-right: 2px solid #FFC194;
    }
  }

  > * {
    max-height: 40px;
    min-height: 40px;
    background: #FFFFFF;
    border-bottom: 1px solid #D5D5D5;
    box-sizing: border-box;
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content minmax(0, 268px) max-content max-content auto;
  width: 100%;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  box-sizing: border-box;
  overflow: auto;

  > ${Item}:last-child {
    &:hover {
      > * {
        background: #FFDFD1;
        border-bottom: 2px solid #FFC194;
      }
      
      > *:first-child {
        border-bottom-left-radius: 5px;
      }

      > *:last-child {
        border-bottom-right-radius: 5px;
      }
    }
    
    > * {
      border-bottom: none;
    }
  }
`;

const Header = styled.div`
  display: contents;

  > * {
    position: sticky;
    top: 0;
    max-height: 40px;
    min-height: 40px;
    background: #E5E5E5;
    border-bottom: 1px solid #D5D5D5;
    z-index: 2;
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
`;

const Link = styled.a`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #017698;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const JobListTable: FunctionComponent<JobListTableProps> = ({
    jobs,
    selectedIndex,
    onItemClick,
    onItemSelect,
    ...props
}) => {
    const { t, i18n } = useTranslation();
    const jobPostCategory = useJobPostCategory();

    return (
        <Container {...props}>
            <Header>
                <Cell />
                <Cell>{t('job.title')}</Cell>
                <Cell>{t('job.industry')}</Cell>
                <Cell>{t('job.job_type')}</Cell>
                <Cell>{t('scout.remaining_scouts')}</Cell>
            </Header>
            {jobs?.map((job, index) => (
                <Item
                    key={job.id}
                    onClick={() => job.remainingScoutsNum > 0 && onItemClick?.(index)}
                >
                    <Cell>
                        <Radio
                            disabled={job.remainingScoutsNum < 1}
                            checked={index === selectedIndex}
                            onClick={() => onItemSelect?.(index)}
                        />
                    </Cell>
                    <Cell>
                        <Link href={`/jobs/${job.id}`} target="_blank">
                            {
                              decodeHTMLEntities(job.translations.find(t => t.language.code === i18n.language)?.name)
                              ?? job.name
                            }
                        </Link>
                    </Cell>
                    <Cell>
                        {jobPostCategory.getIndustryNameByJobTypeId(job.jobTypeId)}
                    </Cell>
                    <Cell>
                        {jobPostCategory.getJobTypeName(job.jobTypeId)}
                    </Cell>
                    <Cell>
                        {job.remainingScoutsNum < 1 ? 0 : job.remainingScoutsNum}
                    </Cell>
                </Item>
            ))}
        </Container>
    );
};

export default JobListTable;