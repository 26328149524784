import { FunctionComponent } from "react";
import styled from "styled-components";
import { ReactComponent as HistoryIcon } from "../../../../../assets/icon-history.svg";
import { ReactComponent as OpenIcon } from "../../../../../assets/icon-open-in-new.svg";
import Column from "../../../../../core/components/Column";
import Text from "../../../../../core/components/Text";
import { useNavigate } from "react-router-dom";
import { Proposal } from "../../../../types";
import moment from "moment";


interface EmployeeOfferStatusSegmentProps {
  proposal: Proposal;
  employmentOfferSentDate: string;
}

/* NOTE: these styles are adjusted to the old design.
   When the page design is updated, these need to be updated */
   const EmployeeOfferStatusBanner = styled.div`
   display: grid;
   grid-template-columns: 18px 1fr;
   padding: 10px;
   border-radius: 5px;
   gap: 14px;
   background-color: #f2f2f2;
   align-items: center;
   width: 100%;
 `;

const EmployeeOfferStatus = styled(Column)`
  gap: 5px;
`;

const Link = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #017698;
  cursor: pointer;
  margin-left: 7px;
  margin-right: 7px;
`;

const OfferSentDate = styled(Text)`
  font-size: 13px;
  line-height: 19px;
  color: #999999;
`;


const EmployeeOfferStatusSegment: FunctionComponent<EmployeeOfferStatusSegmentProps> = ({
  proposal,
  employmentOfferSentDate
}) => {
  const navigate = useNavigate();

  return (
    <EmployeeOfferStatusBanner>
      <HistoryIcon />
      <EmployeeOfferStatus>
        <Text>
          自動生成した
          <Link
            onClick={() =>
              navigate(
                `/applications/${proposal!.id}/documents/employment_offer`
              )
            }
          >
            内定証明書
            <OpenIcon />
          </Link>
          を候補者のメールアドレス宛に送付しました
        </Text>
        <OfferSentDate>
          {moment(employmentOfferSentDate).format("YYYY-MM-DD HH:mm")}
        </OfferSentDate>
      </EmployeeOfferStatus>
    </EmployeeOfferStatusBanner>
  );
};

export default EmployeeOfferStatusSegment;
