import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { updateCompany } from '../../apis/company';
import Button from '../../components/Button';
import CategoryHeading from '../../components/CategoryHeading';
import Column from '../../components/Column';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import TextInput from '../../components/TextInput';
import ACTIONS from '../../constants/actions';
import { useReducerContext } from '../../contexts/ReducerContext';
import { useTranslation } from 'react-i18next';

interface InterviewSettingsProps {
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
  gap: 10px;
`;

const SubCategoryHeading = styled(CategoryHeading)`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
`;

const LocalSection = styled(Section)`
    width: 100%;
    margin-top: 10px;
`;

const LocalSectionHeading = styled(SectionHeading)`
   font-weight: 500;
   font-size: 32px;
   line-height: 46px;
   height: auto;
`;

const LocalTextInput = styled(TextInput)`
  width: 300px;
  margin-top: 4px;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 42px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 25px;
  object-fit: cover;
`;

const Prompt = styled.div`
  margin-left: 6px;
  color: #40BF6E;
  font-size: 14px;
`

const InterviewSettings: FunctionComponent<InterviewSettingsProps> = () => {
    const { state, dispatch } = useReducerContext();
    const { t } = useTranslation('translation', { keyPrefix: "core.settings" });
    const { t : tWithoutPrefix } = useTranslation();
    const [zoomLink, setZoomLink] = useState(state.company?.meetingInvitationLink);
    const [zoomId, setZoomId] = useState(state.company?.meetingId);
    const [zoomPass, setZoomPass] = useState(state.company?.meetingPassword);
    const [message, setMessage] = useState('');

    const handleSave = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: '設定を更新しています...',
            },
        });
        try {
            const company = await updateCompany(state.company?.id!, {
                meetingInvitationLink: zoomLink,
                meetingId: zoomId,
                meetingPassword: zoomPass,
            });
            dispatch({
                type: ACTIONS.UPDATE_COMPANY,
                payload: {
                    company,
                },
            });
            setMessage('設定が保存されました')
        } catch (e) {
            // TODO: error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    return (
        <Container>
            <LocalSectionHeading>
                {t("interview.title")}
            </LocalSectionHeading>
            <LocalSection>
                <Column>
                    <SubCategoryHeading>{t("interview.meetingInvitationLink")}</SubCategoryHeading>
                    <LocalTextInput
                        value={zoomLink}
                        onTextChange={setZoomLink}
                    />
                </Column>
                <Column>
                    <SubCategoryHeading>{t("interview.meetingId")}</SubCategoryHeading>
                    <LocalTextInput
                        value={zoomId}
                        onTextChange={setZoomId}
                    />
                </Column>
                <Column>
                    <SubCategoryHeading>{t("interview.meetingPassword")}</SubCategoryHeading>
                    <LocalTextInput
                        value={zoomPass}
                        onTextChange={setZoomPass}
                    />
                </Column>
                <Action>
                    <Button onClick={handleSave}>{tWithoutPrefix("core.save")}</Button>
                    {message && (
                        <>
                            <Icon src="/images/icon-checked-circle.svg" />
                            <Prompt>{message}</Prompt>
                        </>
                    )}
                </Action>
            </LocalSection>
        </Container>
    );
};

export default InterviewSettings;