import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import DetailedView from './DetailedView';
import ListView from './ListView';
import SearchPanel from './SearchPanel';
import { getJobs, getJobsCount, getPrefecturesWithAvailableJob } from '../../apis/job';
import QuestionTypeModal from '../../components/QuestionTypeModal';
import QuestionPostModal from '../../components/QuestionPostModal';
import ACTIONS from '../../../core/constants/actions';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { Job } from '../../types/api';
import { SelectOption } from '../../../core/components/Select';
import Footer from '../../../core/components/Footer';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import { JobQueryParameter } from '../../../core/types/api';
import ProfileMissingModal from '../../components/ProfileMissingModal';
import { isCandidateProfileComplete } from '../../utils';
import { JobState } from '../../enums/job';

const ONE_DAY_OFFSET = 86400000;

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #FAF6F4;
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding: 10px 17px;
  
  @media ${MOBILE_DEVICE} {
    padding: 10px;
  }
`;

interface SearchJobPageProps {
}

const JobSearchPage: FunctionComponent<SearchJobPageProps> = ({
}) => {
    const context = useReducerContext();
    const containerRef = useRef<HTMLDivElement|null>(null);
    const searchPanelRef = useRef<HTMLDivElement|null>(null);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [detailed, setDetailed] = useState(true);
    const [searchString, setSearchString] = useState('');
    const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
    const [locations, setLocations] = useState<string[]>([]);
    const [industry, setIndustry] = useState('');
    const [jobTypes, setJobTypes] = useState<string[]>([]);
    const [gender, setGender] = useState('');
    const [conversationSkill, setConversationSkill] = useState('');
    const [jlptLevel, setJlptLevel] = useState('');
    const [top, setTop] = useState(0);
    const [allFetched, setAllFetched] = useState(false)
    const [questions, setQuestions] = useState<{ [key: number]: any[] }>({});
    const [questionJobId, setQuestionJobId] = useState<number|null>(null);
    const [questionType, setQuestionType] = useState<'job'|'candidate'>('job');
    const [questionTypeShown, setQuestionTypeShown] = useState(false);
    const [questionPostShown, setQuestionPostShown] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastParameters, setLastParameters] = useState<JobQueryParameter>({});
    const [jobsCount, setJobsCount] = useState(25);
    const [profileMissingModal, setProfileMissingModal] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const FILTERS_STORAGE_NAME = 'JobSearchFilters';
    const PER = 40;

    const updateTop = () => {
        if (containerRef.current && searchPanelRef.current) {
            const container = containerRef.current as HTMLDivElement;
            const searchPanel = searchPanelRef.current as HTMLDivElement;
            setTop(container.offsetTop + 10 + (container.scrollTop > searchPanel.offsetHeight
                ? 0
                : searchPanel.offsetHeight - container.scrollTop
            ));
        }
    }

    const onSearch = async () => {
        let params: JobQueryParameter = lastParameters;
        params.searchString = searchString;

        await handleSearch(params, true);      
    }

    const getFilterValues = () => {
        let params: JobQueryParameter = {}

        if (locations.length > 0) 
            params.prefectures = locations
        
        //Backend API supports to filter by multiple industries,
        //But frontend only allows the user to select one industry
        if (industry)
            params.industries = [industry];

        if (jobTypes.length > 0)
            params.jobTypes = jobTypes;

        if (conversationSkill)
            params.japaneseConversationSkills = conversationSkill;

        if (jlptLevel)
            params.jlptLevel = jlptLevel;

        return params;
    }

    //**SPEC**
    // (1) Filter values are saved and these values are always used together with searches until cleared
    // (2) In this search, the previous search string is used instead of the current text in the search bar
    const onApplyFilter = async () => {
        let params: JobQueryParameter = getFilterValues();

        if (lastParameters.searchString)
            params.searchString = lastParameters.searchString;

        await handleSearch(params, true);
        localStorage.setItem(FILTERS_STORAGE_NAME, JSON.stringify(params));
    }

    //**SPEC**
    // (1) Filter values are cleared (reset)
    // (2) In this search, the previous search string is used instead of the current text in the search bar
    const onClearFilter = async () => {
        let params: JobQueryParameter = {};
        
        if (lastParameters.searchString)
            params.searchString = lastParameters.searchString;
        
        await handleSearch(params, true);
        localStorage.removeItem(FILTERS_STORAGE_NAME);
    }

    const initializeFilters = async () => {
        const jobPrefectures: string [] = await getPrefecturesWithAvailableJob();
        const locationOptions = jobPrefectures.map(prefecture => {
            // "prefecture": e.g. "HOKKAIDO", "TOKYO TO", "OSAKA FU", "FUKUOKA KEN"
            return { 
                label: `prefecture.${prefecture.toLowerCase().split(' ')[0]}`, 
                value: prefecture 
            } 
        })
        const stringfiedJSON = sessionStorage.getItem(FILTERS_STORAGE_NAME);

        setLocationOptions(locationOptions);
       
        if (!stringfiedJSON) 
            return;
        
        const params = JSON.parse(stringfiedJSON);

        if (params.conversationSkill) 
            setConversationSkill(params.conversationSkill);
        
        if (params.jlptLevel)
            setJlptLevel(params.jlptLevel);
            
        if (params.industry) {
            setIndustry(params.industry);
            
            if (params.jobTypes) 
                setJobTypes(params.jobTypes)
        }

        if (params.locations) {

            //Filter out locations where jobs are no longer available
            const filtered = params.locations.filter((location: string) => {
                return jobPrefectures.includes(location);
            })

            setLocations(filtered);
        }
    }

    const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
        if (e.target === containerRef.current) {
            updateTop();

            if (!jobs.length) {
                return
            }
            const element = e.target as HTMLDivElement
            if (element.clientHeight + element.scrollTop >= element.scrollHeight && !allFetched) {
                await handleSearch(lastParameters);
            }
        }
    }
 
    const handleSearch = async (params: JobQueryParameter = {}, reset: boolean = false) => {

        context.dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('job.searching_jobs'),
            }
        })
        try {
            params = {
                ...params,
                state: JobState.Published,
                sort: 'published_at'
            }
            
            const page = reset? 1 : currentPage + 1;
            const count = reset? await getJobsCount(params) : jobsCount;
            let newJobs = await getJobs({ ...params, page, per: PER });

            const today = new Date();
            newJobs = newJobs.filter((job) => !job.applicationDeadline
              || new Date(job.applicationDeadline).getTime() + ONE_DAY_OFFSET > today.getTime()
            );

            if (!reset) 
                newJobs = [...jobs, ...newJobs];

            setAllFetched(newJobs.length >= count);
            setCurrentPage(page);
            setJobsCount(count);
            setJobs(newJobs);
            setLastParameters(params);
        } catch (e) {
            // TODO: error handling
        } finally {
            context.dispatch({
                type: ACTIONS.STOP_LOADING,
            })
        }
    }

    const handlePropose = (jobId: number) => {
        if (context.state.isCandidate && !isCandidateProfileComplete(context.state.candidate!)) {
            setProfileMissingModal(true);
        } else {
            navigate(`/jobs/${jobId}/propose`);
        }
    };

    const handleAsk = (jobId: number) => {
        setQuestionJobId(jobId);
        setQuestionTypeShown(true);
    };

    const handleNavigate = (jobId: number) => {
        window.open(`/jobs/${jobId}`, '_blank');
    };

    const handleQuestionsLoad = async (jobId: number) => {
        context.dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: 'Loading questions...',
            }
        })
        try {
            /*
            const qaSets = (await getQuestionsForJob(jobId)).qaSets as QASet[];
            setQuestions({
                ...questions,
                [jobId]: qaSets || [],
            });
             */
        } catch (e) {
            // TODO: error handling
        }
        context.dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleQuestionModalClose = () => {
        setQuestionTypeShown(false);
        setQuestionPostShown(false);
        setQuestionJobId(null);
        setQuestionType('job');
    };

    const handleQuestionTypeSelect = (type: 'job'|'candidate') => {
        setQuestionType(type);
        setQuestionTypeShown(false);
        setQuestionPostShown(true);
    };

    const handleQuestionPostSubmit = async (message: string) => {
        context.dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: 'Submitting question...',
            }
        })
        try {
            setQuestions({
                ...questions,
                [questionJobId!]: [
                    ...(questions[questionJobId!] ? questions[questionJobId!] : []),
                    {
                        Id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
                        askingCompanyId: 0,
                        starter: context.state.companyId,
                        jobId: questionJobId!,
                        type: questionType,
                        qaItems: [
                            {
                                isCompanyResponse: false,
                                message,
                                createdAt: Date.now().toString(),
                            },
                        ],
                        status: 'waiting',
                    },
                ],
            });
        } catch (e) {
            // TODO: error handling
        }
        handleQuestionModalClose();
        context.dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    useEffect(() => {
        (async () => {
            context.dispatch({
                type: ACTIONS.START_LOADING,
                payload: {
                    message: 'Fetching prefectures...',
                }
            })
            try {
                await initializeFilters();
                await handleSearch({}, true);
            } catch (e) {
                // TODO: error handling
            }
            context.dispatch({
                type: ACTIONS.STOP_LOADING,
            })
        })()
    }, [])

    useEffect(() => {
        updateTop();
    }, [containerRef.current, searchPanelRef.current])

    useEffect(() => {
        setJobTypes([])
    }, [industry])

    return (
        <Page ref={containerRef} onScroll={handleScroll}>
            <SearchPanel
                ref={searchPanelRef}
                detailed={detailed}
                keyword={searchString}
                locationOptions={locationOptions}
                locations={locations}
                industry={industry}
                jobTypes={jobTypes}
                gender={gender}
                conversationSkill={conversationSkill}
                jlptLevel={jlptLevel}
                onDetailedChange={setDetailed}
                onKeywordChange={setSearchString}
                onLocationsChange={setLocations}
                onIndustryChange={setIndustry}
                onJobTypesChange={setJobTypes}
                onGenderChange={setGender}
                onConversationSkillChange={setConversationSkill}
                onJlptLevelChange={setJlptLevel}
                onSearch={onSearch}
                onApplyFilter={onApplyFilter}
                onClearFilter={onClearFilter}
                onResize={updateTop}
            />
            <Content>
                {detailed
                    ? (
                        <DetailedView
                            top={top}
                            jobs={jobs}
                            questions={questions}
                            onPropose={handlePropose}
                            onAsk={handleAsk}
                            onQuestionsLoad={handleQuestionsLoad}
                        />
                    )
                    : <ListView jobs={jobs} onNavigate={handleNavigate} />
                }
            </Content>
            <Footer/>
            {questionTypeShown && (
                <QuestionTypeModal
                    onSelect={handleQuestionTypeSelect}
                    onClose={handleQuestionModalClose}
                />
            )}
            {questionPostShown && (
                <QuestionPostModal
                    type={questionType}
                    onSubmit={handleQuestionPostSubmit}
                    onClose={handleQuestionModalClose}
                />
            )}
            <ProfileMissingModal 
                open={profileMissingModal} 
                onClick={() => navigate('/profile/edit')}
            />
        </Page>
    )
}

export default JobSearchPage
