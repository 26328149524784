import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import EnhancedLink from './EnhancedLink';
import Row from './Row';
import { useReducerContext } from '../contexts/ReducerContext';
import { DESKTOP_DEVICE, MOBILE_DEVICE } from '../constants/styles';
import PersonalMenuSelector from './PersonalMenuSelector';
import LanguageSelector from './LanguageSelector';

interface HeaderProps {
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-height: 72px;
  min-height: 72px;
  padding-right: 32px;
  padding-left: 40px;
  border-bottom: 1px solid #969696;
  
  @media ${MOBILE_DEVICE} {
    max-height: 40px;
    min-height: 40px;
    padding: 0 20px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font: normal normal bold 26px/36px "Noto Sans JP";
  color: #FF730D;
  
  > *:not(:first-child) {
    margin-left: 10px;
  }
  
  > img {
    height: 28px;
  }
  
  @media ${MOBILE_DEVICE} {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    
    > img {
      height: 22px;
    }
    
    > span {
      display: none;
    }
  }
`;

const Links = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;

  > *:not(:first-child) {
    margin-left: 20px;
  }
  
  @media ${MOBILE_DEVICE} {
    justify-content: space-evenly;
    margin-left: 0;
    
    > *:not(:first-child) {
      margin-left: unset;
    }
  }
`;

const HomeLink = styled(Link)`
  text-decoration: none;
  outline: none;
`;

const SettingsLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 36px;
`;

const SettingsIcon = styled.img`
  width: 25px;
  height: 25px;
  object-fit: cover;
`;

const Header: FunctionComponent<HeaderProps> = () => {
    const { state } = useReducerContext();
    const { isLoggedIn, isEmployer, isPartner, isNavigable } = state;
    const { t } = useTranslation();
    const isDesktopDevice = useMediaQuery({
        query: DESKTOP_DEVICE,
    });
    const isMobileDevice = !isDesktopDevice;

    return (
        <Container>
            <HomeLink to="/jobs">
                <LogoContainer>
                    <img alt="logo" src="/images/tokuty.svg" />
                    <span>tokuty</span>
                </LogoContainer>
            </HomeLink>


            { isNavigable && 
              <>
                <Links>
                    {isLoggedIn && (
                        <EnhancedLink
                            to="/jobs"
                            icon="/images/icon-jobs.svg"
                            activeIcon="/images/icon-jobs-active.svg"
                        >
                            {t('core.jobs')}
                        </EnhancedLink>
                    )}
                    {isEmployer && (
                        <EnhancedLink 
                            to="/applications"
                            icon="/images/icon-proposals.svg"
                            activeIcon="/images/icon-proposals-active.svg"
                        >
                            {t('core.applications')}
                        </EnhancedLink>
                    )}
                    {(isPartner && isDesktopDevice) && (
                        <EnhancedLink to="/candidates">
                            {t('core.candidates')}
                        </EnhancedLink>
                    )}
                    {(isLoggedIn && !isEmployer) && (
                        <EnhancedLink
                            to="/proposals"
                            icon="/images/icon-proposals.svg"
                            activeIcon="/images/icon-proposals-active.svg"
                        >
                            {t(state.isCandidate ? 'core.applications' : 'core.proposals')}
                        </EnhancedLink>
                    )}
                    {/* Don't show the scout menu on the partner's mobile screen (Desktop is ok) */}
                    {(isLoggedIn && (isDesktopDevice || !isPartner)) && (
                        <EnhancedLink 
                          to="/scouts"
                          icon="/images/icon-scout.svg"
                          activeIcon="/images/icon-scout-active.svg"
                        >
                            {t('core.scout')}
                        </EnhancedLink>
                    )}
                    {(isPartner && isMobileDevice) && (
                        <EnhancedLink 
                          to="/candidates"
                          icon="/images/icon-person.svg"
                          activeIcon='/images/icon-person-active.svg'
                        >
                            {t('core.candidates')}
                        </EnhancedLink>
                    )}
                </Links>
                {(isDesktopDevice && (isEmployer || isPartner)) && (
                    <SettingsLink to="/settings">
                        <SettingsIcon alt="settings" src="/images/icon-gear-wheel.svg" />
                    </SettingsLink>
                )}
                <Row center>
                    {isLoggedIn
                        ? (
                          <PersonalMenuSelector />
                        )
                        : (
                          <>
                            <EnhancedLink to="/login">
                                {t('core.login')}
                            </EnhancedLink>
                            <LanguageSelector style={{ marginLeft: 27 }} />
                          </>
                        )
                    }
                </Row>
              </>
            }


        </Container>
    );
};

export default Header;