import React, {FunctionComponent, MouseEvent, useState} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

import Button from './Button';
import { MOBILE_DEVICE } from '../constants/styles';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  > *:not(:first-child) {
    margin-left: 10px;
  }
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    width: 100%;

    > *:not(:first-child) {
      margin-top: 10px;
      margin-left: unset;
    }
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 700px;
  max-height: 40px;
  min-height: 40px;
  padding: 0 12px;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 5px;

  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`

const Icon = styled.img`
  width: 18px;
  height: 18px;

  @media (max-width: 768px) {
    width: 16px;
    height: 16px;
  }
`

const Input = styled.input`
  flex: 1;
  padding: 0;
  margin-left: 8px;
  background: transparent;
  border: none;
  outline: none;
`

interface SearchBarProps {
  className?: string;
  placeholder?: string;
  onChange?: (keyword: string) => void;
  onSearch?: (keyword: string) => void;
}

const SearchBar: FunctionComponent<SearchBarProps> = ({
    className,
    placeholder,
    onChange,
    onSearch,
}) => {
    const [keyword, setKeyword] = useState('')
    const { t } = useTranslation();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
        onChange && onChange(e.target.value)
    }

    const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
        onSearch && onSearch(keyword)
    }

    return (
        <Container className={className}>
            <InputContainer>
                <Icon src="/images/icon-search.svg" />
                <Input type="text" value={keyword} onChange={handleChange} placeholder={placeholder}/>
            </InputContainer>
            <Button onClick={handleSubmit}>
                {t('core.search')}
            </Button>
        </Container>
    )
}

export default SearchBar
