import{ FunctionComponent } from 'react';
import styled from 'styled-components';

import Modal from '../../core/components/Modal';
import PageHeading from '../../core/components/PageHeading';
import PanelButton from '../../core/components/PanelButton';
import SectionHeading from '../../core/components/SectionHeading';
import Text from '../../core/components/Text';
import { useTranslation } from 'react-i18next';

interface QuestionTypeModalProps {
    onSelect?: (type: 'job'|'candidate') => void;
    onClose?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 5px;
`;

const QuestionTypeModal: FunctionComponent<QuestionTypeModalProps> = ({
    onSelect,
    onClose,
}) => {
    const { t } = useTranslation();

    return (
        <Modal onClose={onClose}>
            <Container>
                <PageHeading>
                    {t('tokuty.question_type_title')}
                </PageHeading>

                <PanelButton
                    style={{ width: '100%', marginTop: 10 }}
                    onClick={() => onSelect && onSelect('job')}
                >
                    <SectionHeading>
                        {t('tokuty.job_question_title_short')}
                    </SectionHeading>
                    <Text style={{ textAlign: 'left' }}>
                        {t('tokuty.job_question_example')}
                    </Text>
                    <Text style={{ color: '#999999', textAlign: 'left' }}>
                        {t('tokuty.job_question_description')}
                    </Text>
                </PanelButton>

                <PanelButton
                    style={{ width: '100%', marginTop: 20 }}
                    onClick={() => onSelect && onSelect('candidate')}
                >
                    <SectionHeading>
                        {t('tokuty.candidate_question_title_short')}
                    </SectionHeading>
                    <Text style={{ textAlign: 'left' }}>
                        {t('tokuty.candidate_question_example')}
                    </Text>
                    <Text style={{ color: '#999999', textAlign: 'left' }}>
                        {t('tokuty.candidate_question_description')}
                    </Text>
                </PanelButton>
            </Container>
        </Modal>
    );
};

export default QuestionTypeModal;