import { FunctionComponent } from "react";
import DatePicker from "../../../../core/components/DatePicker";
import styled from "styled-components";
import {
  Column5, CustomLabel, DatePickerWrapper,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell, LocalNumberField, LocalSelect, LocalTextInput, LocalTextInputExtend, RadioButtonsWrapper, Row10,
  SectionHeadingLarge, UnitText
} from "../commonStyle"
import { SelectOption } from "../../../../core/components/Select";
import Radio from "../../../../core/components/Radio";
import { isEmpty } from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";
import {SupportPlanFormProps} from "../types";
import {Gender, Nationality} from "../../../../core/enums/supportPlans";

interface TargetSectionProps extends SupportPlanFormProps {
  nationalityOptions?: SelectOption[];
}

const LocalRow = styled.div`
  display: flex;
  gap: 10px;
`;

export const TargetSection: FunctionComponent<TargetSectionProps> = (
  {
    supportPlan,
    otherTargetsDisplayCount,
    onChangeSupportPlan,
    addOtherTarget,
    deleteAllOtherTargets,
    nationalityOptions
}) => {
  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>Ⅰ. 支援対象者</SectionHeadingLarge>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 名前 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>名前</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="John"
                value={supportPlan?.targetFirstName ?? ""}
                error={isEmpty(supportPlan?.targetFirstName)}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({targetFirstName: val});
                }}
              />
              {isEmpty(supportPlan?.targetFirstName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 名字 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>名字</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="Doe"
                value={supportPlan?.targetLastName ?? ""}
                error={isEmpty(supportPlan?.targetLastName)}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({targetLastName: val});
                }}
              />
              {isEmpty(supportPlan?.targetLastName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援対象者人数 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援対象者人数</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Row10>
              <LocalNumberField
                allowZero
                placeholder="0"
                step="1"
                min="0"
                value={supportPlan?.targetNumberOfPeople ?? 0}
                error={isEmpty(supportPlan?.targetNumberOfPeople)}
                onValueChange={(val: number | null) => {
                  if (val === 0)
                    deleteAllOtherTargets?.();
                  if (val && val > 0 && !otherTargetsDisplayCount)
                    addOtherTarget?.();
                  onChangeSupportPlan?.({targetNumberOfPeople: Number(val)});
                }}
              />
              <UnitText>人（本人以外の人数）</UnitText>
            </Row10>
          </FieldCell>
        </GridRow>

        {/* 性別 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>性別</CustomLabel>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                value={supportPlan?.targetGender ?? ""}
                checked={
                  supportPlan?.targetGender === Gender.male
                }
                onValueChange={(val) => {
                  onChangeSupportPlan?.({targetGender: Gender.male});
                }}
                error={isEmpty(supportPlan?.targetGender)}
              >
                男性
              </Radio>
              <Radio
                value={supportPlan?.targetGender ?? ""}
                checked={
                  supportPlan?.targetGender === Gender.female
                }
                onValueChange={(val) => {
                  onChangeSupportPlan?.({targetGender: Gender.female});
                }}
                error={isEmpty(supportPlan?.targetGender)}
              >
                女性
              </Radio>
            </RadioButtonsWrapper>
            {isEmpty(supportPlan?.targetGender) &&
                <ErrorMessage>選択してください</ErrorMessage>
            }
          </FieldCell>
        </GridRow>

        {/* 生年月日 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>生年月日</CustomLabel>
          </LabelCell>
          <FieldCell>
            <DatePickerWrapper>
              <DatePicker
                placeholder="2000/01/23"
                selected={
                  supportPlan?.targetDateOfBirth
                    ? new Date(supportPlan?.targetDateOfBirth)
                    : undefined
                }
                onChange={(val) =>
                  onChangeSupportPlan?.({targetDateOfBirth: val.toISOString()})
                }
                customInput={<LocalTextInput/>}
                error={isEmpty(supportPlan?.targetDateOfBirth)}
              />
            </DatePickerWrapper>
            {isEmpty(supportPlan?.targetDateOfBirth) &&
                <ErrorMessage>入力してください</ErrorMessage>
            }
          </FieldCell>
        </GridRow>

        {/* 国籍・地域 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>
              国籍・地域
            </CustomLabel>
          </LabelCell>
          <FieldCell>
            <LocalRow>
              <LocalSelect
                placeholder="選択"
                options={nationalityOptions}
                value={supportPlan?.targetNationalityId?.toString() ?? ""}
                onValueChange={val => onChangeSupportPlan?.(
                  supportPlan?.targetNationalityId?.toString() === Nationality.other ?
                  {targetNationalityId: val } :
                  {targetNationalityId: val, targetNationalityOther: ""}
                )}
                error={isEmpty(supportPlan?.targetNationalityId)}
              />
              {
                supportPlan?.targetNationalityId?.toString() === Nationality.other &&
                  <LocalTextInputExtend
                    placeholder="インド"
                    value={supportPlan?.targetNationalityOther ?? ""}
                    error={isEmpty(supportPlan?.targetNationalityOther)}
                    onTextChange={(val: string) => {
                      onChangeSupportPlan?.({targetNationalityOther: val});
                    }}
                  />
              }
            </LocalRow>
            {isEmpty(supportPlan?.targetNationalityId) &&
                <ErrorMessage>選択してください</ErrorMessage>
            }
            {supportPlan?.targetNationalityId?.toString() === Nationality.other &&
              isEmpty(supportPlan?.targetNationalityOther) &&
                <ErrorMessage>入力してください</ErrorMessage>
            }
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default TargetSection;