import { useTranslation } from "react-i18next";
import {
  Section,
  SectionHeading,
  SectionHeadingContainer,
  SectionLabel,
  SectionLabelCell,
  SectionRow,
  SectionValue,
  SectionValueCell,
  SectionValuesContainer,
} from "./commonStyle";
import { SectionProps } from "../types/props";
import { FunctionComponent, useEffect, useState } from "react";
import { EMPTY_VAL } from "../constants/defaultValue";
import { JobInterviewType, JobState } from "../enums/job";
import { useJobTranslation } from "../hook";
import { useValueUnit } from "../../core/hooks";

interface ResignationOtherSectionProps extends SectionProps {}

const ResignationOtherSection: FunctionComponent<
  ResignationOtherSectionProps
> = ({ job }) => {
  const { findTranslation } = useJobTranslation();
  const { i18n } = useTranslation();
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });
  const { t: tCore } = useTranslation("translation", { keyPrefix: "core" });
  const { addYen } = useValueUnit();
  const [resignationProcedure, setResignationProcedure] =
    useState<string>(EMPTY_VAL);
  const [specialAllowanceUponHire, setSpecialAllowanceUponHire] = useState<string>(EMPTY_VAL);
  const [employeeNationality, setEmployeeNationality] = useState<string>(EMPTY_VAL);
  const [interviewMethod, setInterviewMethod] = useState<string>(EMPTY_VAL);
  const [
    availableDaysAndTimesForInterviews,
    setAvailableDaysAndTimesForInterviews,
  ] = useState<string>(EMPTY_VAL);
  const [otherSupplInfoAboutThisJob, setOtherSupplInfoAboutThisJob] = useState<string>(EMPTY_VAL);

  useEffect(() => {
    if (!job) return;

    setResignationProcedure(
      job.ecResignationProcedureForResignationForPersonalReasons?.toString() ??  EMPTY_VAL
    );

    if (job.specialAllowanceUponHire != null) 
      setSpecialAllowanceUponHire(addYen(job.specialAllowanceUponHire));
    else
      setSpecialAllowanceUponHire(EMPTY_VAL);

    const nationalityNames = job.currentNationalities?.map((n) =>
      tCore(`nationalities.${n.tkey}`)
    );
    setEmployeeNationality(nationalityNames?.join(" / ") || EMPTY_VAL);

    switch (job.interviewMethod) {
      case JobInterviewType.Online:
        setInterviewMethod(tJob("online_interview"));
        break;
      case JobInterviewType.Offline:
        setInterviewMethod(tJob("offline_interview"));
        break;
      default:
        setInterviewMethod(EMPTY_VAL);
    }

    if (job.state === JobState.Published) {
      setAvailableDaysAndTimesForInterviews(
        findTranslation(
          job.translations,
          "availableDaysAndTimesForInterviews"
        ) || EMPTY_VAL
      );
      setOtherSupplInfoAboutThisJob(
        findTranslation(
          job.translations, 
          "otherSupplementaryInformationAboutThisJob"
        ) || EMPTY_VAL
      );
    } else {
      setAvailableDaysAndTimesForInterviews(
        job.availableDaysAndTimesForInterviews || EMPTY_VAL
      );
      setOtherSupplInfoAboutThisJob(
        job.otherSupplementaryInformationAboutThisJob || EMPTY_VAL
      );
    }
  }, [job, i18n.language]);

  return (
    <Section>
      <SectionHeadingContainer>
        <SectionHeading>{tJob("resignation_and_other_matters")}</SectionHeading>
      </SectionHeadingContainer>

      {/* 自己都合退職の手続き */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob("procedure_for_voluntary_resignation")}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValuesContainer>
            <SectionValue>{resignationProcedure}</SectionValue>
          </SectionValuesContainer>
        </SectionValueCell>
      </SectionRow>

      {/* 配属準備金 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>{tJob("special_allowance_upon_hire")}</SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{specialAllowanceUponHire}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 現在働いている外国人従業員の国籍 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob("nationalities_of_currently_working_foreign_employees")}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{employeeNationality}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 面談実施方法 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>{tJob("interview_method")}</SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{interviewMethod}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 面談可能な曜日と時間帯 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>
            {tJob("available_days_and_times_for_interview")}
          </SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{availableDaysAndTimesForInterviews}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 求人に関するその他補足 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>{tJob("other_supplementary_information_about_this_job")}</SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{otherSupplInfoAboutThisJob}</SectionValue>
        </SectionValueCell>
      </SectionRow>
    </Section>
  );
};

export default ResignationOtherSection;
