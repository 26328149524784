import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "../../../../assets/icon-check-mark.svg";

interface CheckMarkTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  children?: ReactNode;
  checked?: boolean;
}

interface CheckMarkProps extends React.HTMLAttributes<HTMLSpanElement> {
  checked?: boolean;
}

const Container = styled.span`
  display: inline-block;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
`;

const ChildrenContainer = styled.span`
  margin-left: 9px;
`;

const CheckContainer = styled.span`
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  border: 1px solid black;
  flex-shrink: 0;
`;

const CheckIcon = styled(Icon)`
  position: absolute;
  top: -1.8px;
  left: -2.2px;
`;

const CheckMarkBox: FunctionComponent<CheckMarkProps> = ({ 
  checked,
  ...props
}) => {
  return (
    <CheckContainer {...props}>
      {checked && <CheckIcon />}
    </CheckContainer>
  )
}

const CheckMark: FunctionComponent<CheckMarkTextProps> = ({
  children,
  checked,
  ...props
}) => {
  return (
    <Container {...props}>
      <Content>
        <CheckMarkBox checked={checked}/>
        <ChildrenContainer>{children}</ChildrenContainer>
      </Content>
    </Container>
  )
}

export default CheckMark;