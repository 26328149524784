import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

interface FooterProps {
}

const Container = styled.footer`
  padding: 30px;
  width: 100% !important;
`;

const ItemList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  gap: 12px;
  font-size: 12px;
  color: #999999;
  align-items: end;
`;

const mixinLink = css`
  text-decoration: none;
  color: #999999;

  &:hover {
    text-decoration: underline;
  }
`

const LocalLink = styled(Link)`
  ${mixinLink}
` 
const Anchor = styled.a`
  ${mixinLink}
`

const Footer: FunctionComponent<FooterProps> = () => {
    const { t } = useTranslation();

    return (
        <Container>
          <ItemList>
            <li>&#9400;	2022 tokuty</li>
            <LocalLink to="/privacy_policy">{t('core.privacy_policy')}</LocalLink>
            <li><Anchor href="https://www.corp.tokuty.jp">{t('core.operating_company')}</Anchor></li>
          </ItemList>
        </Container>
    );
};

export default Footer;