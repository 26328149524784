import {FunctionComponent, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Message from '../../core/components/Message';
import Modal from '../../core/components/Modal';
import PageHeading from '../../core/components/PageHeading';
import InterviewCalendar from './InterviewCalendar';
import Button from '../../core/components/Button';
import {MOBILE_DEVICE} from "../../core/constants/styles";

interface RescheduleInterviewModalProps {
  open?: boolean;
  value?: Date[];
  onValueChange?: (value: Date[]) => void;
  onClose?: () => void;
  onSubmit?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 755px;
  max-height: 90vh;
  padding: 30px;
  background: #FFFFFF;
  overflow: hidden;
  transition: all 300ms ease-in-out;

  &.hidden {
    transform: translateX(calc(50vh + 100%));
  }
  
  @media ${MOBILE_DEVICE} {
    width: 100vw;
    height: 100vh;
    max-height: unset;
  }
`;

const CalendarContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
`;

const ActionsContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

const LocalPageHeading = styled(PageHeading)`
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
`;

const CancelButton = styled(Button)`
  border: none;
`;

const RescheduleInterviewModal: FunctionComponent<RescheduleInterviewModalProps> = ({
  open,
  value,
  onValueChange,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setHidden(!open);
  }, [open])

  const handleDateToggle = (date: Date) => {
    if (value && onValueChange) {
      const time = date.getTime();
      const index = value.findIndex(item => item.getTime() === time);
      if (index !== -1) {
        const dates = [...value]
        dates.splice(index, 1)
        onValueChange(dates)
      } else if (value.length < 20) {
        onValueChange([...value, date]);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Container className={hidden ? 'hidden' : ''}>
        <LocalPageHeading>
          {t('proposal.reschedule_interview')}
        </LocalPageHeading>
        <Message withIcon>
          {t('proposal.reschedule_interview_prompt')}
        </Message>
        <CalendarContainer>
          <InterviewCalendar
            style={{ width: '100%' }}
            value={value || []}
            onToggle={handleDateToggle}
          />
        </CalendarContainer>
        <ActionsContainer>
          <CancelButton
            variant="secondary"
            onClick={onClose}
          >
            {t('proposal.send_later')}
          </CancelButton>
          <Button
            disabled={Number(value?.length) < 1}
            onClick={onSubmit}
          >
            {t('core.submit')}
          </Button>
        </ActionsContainer>
      </Container>
    </Modal>
  );
};

export default RescheduleInterviewModal;