import { FunctionComponent } from 'react';
import styled from 'styled-components';

import CategoryHeading from '../../../core/components/CategoryHeading';
import Column from '../../../core/components/Column';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Text from '../../../core/components/Text';
import { useTranslation } from 'react-i18next';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import moment from 'moment';

type BasicInformationSectionProps = {
    avatar?: string;
    firstNameEn?: string;
    middleNameEn?: string;
    lastNameEn?: string;
    firstNameJp?: string;
    middleNameJp?: string;
    lastNameJp?: string;
    age?: string;
    gender?: string;
    nationality?: string;
    visaStatus?: string;
    address?: string;
    conversationSkills?: string;
    jlptLevel?: string;
    workHours?: string;
    nearestPossibleDate?: string;
};

const Avatar = styled.img`
  width: 101px;
  height: 101px;
  margin-top: 20px;
  border-radius: 5px;
  object-fit: cover;
`;

const Block = styled(Column)`
  margin-top: 8px;
  
  > *:not(:first-child) {
    margin-top: 10px;
  }

  @media ${MOBILE_DEVICE} {
    > *:not(:first-child) {
      margin-top: 20px;
    }
  }
`;

const Field = styled(Row)`
  > *:first-child {
    width: 233px;
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    
    > *:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 4px;
      color: #999999;
    }
  }
`;

const BasicInformationSection: FunctionComponent<BasicInformationSectionProps> = ({
    avatar,
    firstNameEn,
    middleNameEn,
    lastNameEn,
    firstNameJp,
    middleNameJp,
    lastNameJp,
    age,
    gender,
    nationality,
    visaStatus,
    address,
    conversationSkills,
    jlptLevel,
    workHours,
    nearestPossibleDate,
}) => {
    const { t } = useTranslation();

    return (
        <Section>
            <SectionHeading>{t('candidate.basic_information')}</SectionHeading>
            {avatar && <Avatar src={avatar} />}
            <CategoryHeading>{t('candidate.name_in_english')}</CategoryHeading>
            <Block>
                <Field>
                    <Text>{t('candidate.first_name')}</Text>
                    <Text>{firstNameEn || '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.middle_name')}</Text>
                    <Text>{middleNameEn || '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.last_name')}</Text>
                    <Text>{lastNameEn || '-'}</Text>
                </Field>
            </Block>
            <CategoryHeading>{t('candidate.name_in_japanese')}</CategoryHeading>
            <Block>
                <Field>
                    <Text>{t('candidate.first_name')}</Text>
                    <Text>{firstNameJp || '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.middle_name')}</Text>
                    <Text>{middleNameJp || '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.last_name')}</Text>
                    <Text>{lastNameJp || '-'}</Text>
                </Field>
            </Block>
            <Block style={{ marginTop: 20 }}>
                <Field>
                    <Text>{t('candidate.age')}</Text>
                    <Text>{age || '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.gender')}</Text>
                    <Text>{gender ? t(`core.${gender}`) : '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.nationality')}</Text>
                    <Text>{nationality || '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.candidate_visa_status')}</Text>
                    <Text>{visaStatus ? t(`candidate.${visaStatus}`) : '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.current_address')}</Text>
                    <Text>{address || '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.japanese_conversation_skills')}</Text>
                    <Text>
                        {conversationSkills ? t(`candidate.${conversationSkills}`) : '-'}
                    </Text>
                </Field>
                <Field>
                    <Text>{t('candidate.jlpt_level')}</Text>
                    <Text>{jlptLevel?.toUpperCase() || '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.current_work_hours')}</Text>
                    <Text>{workHours || '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.nearest_possible_date')}</Text>
                    <Text>{nearestPossibleDate 
                        ? moment(nearestPossibleDate).format('YYYY/MM/DD')
                        : '-'}
                    </Text>
                </Field>
            </Block>
        </Section>
    );
};

export default BasicInformationSection;