import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../../core/components/Button';
import Select, { SelectOption } from '../../../core/components/Select';
import Text from '../../../core/components/Text';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { STATUS_OPTIONS_FOR_PARTNERS, STATUS_OPTIONS_FOR_CANDIDATES } from '../../constants';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import { useMediaQuery } from 'react-responsive';
import CategoryHeading from '../../../core/components/CategoryHeading';
import { Mapping } from '../../../core/types/common';
import { Job } from '../../../job/types/api';

interface SearchPanelProps {
    jobs?: Mapping<Job>;
    onSubmit?: (filters: { [key: string]: any }) => void;
    onClear?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  
  @media ${MOBILE_DEVICE} {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 35px 20px 20px 20px;
    background: #FFFFFF;
    box-shadow: 0px -2px 6px rgba(153, 153, 153, 0.5);
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  max-height: 30px;
  min-height: 30px;
  padding: 0 10px;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  
  input {
    flex: 1;
    padding: 0;
    margin-right: 8px;
    background: transparent;
    border: none;
    outline: none;
  }
  
  img {
    width: 16px;
    height: 16px;
    object-fit: cover;
  }
`

const FilterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    gap: 40px;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  
  img {
    width: 18px;
    object-fit: cover;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 14px;
  right: 14px;
  width: 16px;
  height: 16px;
  object-fit: cover;
  cursor: pointer;
`;

const ApplyButton = styled(Button)`
  height: 30px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const TextButton = styled(Text)`
  cursor: pointer;
`;

const SearchPanel: FunctionComponent<SearchPanelProps> = ({
    jobs,
    onSubmit,
    onClear,
}) => {
    const { state } = useReducerContext();
    const { t } = useTranslation();
    const isMobileDevice = useMediaQuery({
        query: MOBILE_DEVICE,
    });
    const [name, setName] = useState('');
    const [job, setJob] = useState('');
    const [status, setStatus] = useState('');
    const [hidden, setHidden] = useState(true);
    const [jobOptions, setJobOptions] = useState<SelectOption[]>([]);

    const handleClear = () => {
        setName('');
        setJob('');
        setStatus('');

        onClear?.();
    };

    const handleSubmit = () => {
        const filters: { [key: string]: any } = {};
        if (name) {
            filters.candidateName = name;
        }
        if (job) {
            filters.jobPostId = job;
        }
        if (status) {
            filters.status = status;
        }
        onSubmit?.(filters);
        setHidden(true);
    };

    useEffect(() => {
        if (jobs) {
            const jobOptions = Object.entries(jobs)
                .reverse()
                .map(([key, value]) => ({
                    label: value.name,
                    value: key,
                }));
            setJobOptions(jobOptions);
        }
    }, [jobs]);

    return (
        <>
            {isMobileDevice && (
                <Button
                    style={{ width: 'auto', padding: '0 10px' }}
                    variant="tertiary"
                    onClick={() => setHidden(false)}
                >
                    {t('core.filter')}
                </Button>
            )}
            <Container style={{ display: isMobileDevice && hidden ? 'none' : 'flex' }}>
                {isMobileDevice && (
                    <>
                        <CloseButton
                            alt="close"
                            src="/images/icon-close.svg"
                            onClick={() => setHidden(true)}
                        />
                        <CategoryHeading style={{ color: '#444444' }}>
                            {t('core.filter')}
                        </CategoryHeading>
                    </>
                )}
                {state.isPartner && (
                    <InputContainer>
                        <input
                            type="text"
                            placeholder={t('core.name')}
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                        <img alt="search" src="/images/icon-search.svg" />
                    </InputContainer>
                )}

                <FilterContainer>
                    {!isMobileDevice && (
                        <Icon>
                            <img alt="filter" src="/images/icon-filter.svg" />
                        </Icon>
                    )}
                    {state.isEmployer && (
                        <Select
                            style={{ marginRight: 10 }}
                            placeholder={t('scout.suggested_job')}
                            options={jobOptions}
                            value={job}
                            onValueChange={setJob}
                        />
                    )}
                    <Select
                        style={{ marginLeft: 0 }}
                        placeholder={t('core.status')}
                        options={state.isCandidate? STATUS_OPTIONS_FOR_CANDIDATES : STATUS_OPTIONS_FOR_PARTNERS}
                        value={status}
                        onValueChange={setStatus}
                    />
                    {isMobileDevice
                        ? (
                            <ButtonContainer>
                                <Button
                                    style={{ width: 'auto', padding: '0 8px' }}
                                    variant="secondary"
                                    onClick={handleClear}
                                >
                                    {t('core.clear')}
                                </Button>
                                <Button
                                    style={{ flex: 1 }}
                                    onClick={handleSubmit}
                                >
                                    {t('core.apply')}
                                </Button>
                            </ButtonContainer>
                        )
                        : (
                            <>
                                <ApplyButton
                                    variant="quart"
                                    onClick={handleSubmit}
                                >
                                    {t('core.apply')}
                                </ApplyButton>
                                <TextButton onClick={handleClear}>
                                    {t('scout.reset')}
                                </TextButton>
                            </>
                        )
                    }
                </FilterContainer>
            </Container>
        </>
    );
};

export default SearchPanel;