import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  SectionHeading,
  LabelContainer,
} from "../../commonStyles";
import { FormBlockSectionProps } from "../../types";

interface EmployeeSectionProps extends FormBlockSectionProps {}

const EmployeeSection: FunctionComponent<EmployeeSectionProps> = ({
  employmentConditions: ec,
  onChange
}) => {

  return (
    <FormSection>
      <SectionHeading>被用者名</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>姓（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Nguyen" 
            value={ec?.employeeEnFamilyName ?? ""}
            onTextChange={val => onChange?.({ employeeEnFamilyName: val})}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>名（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Nam" 
            value={ec?.employeeEnGivenName ?? ""}
            onTextChange={val => onChange?.({ employeeEnGivenName: val})}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>ミドルネーム（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Van" 
            value={ec?.employeeEnMiddleName ?? ""}
            onTextChange={val => onChange?.({ employeeEnMiddleName: val})}
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default EmployeeSection;
