import { FunctionComponent } from "react";
import {
  GridRowsContainer,
  HeadingMediumContainer,
  SectionHeadingMedium,
} from "../commonStyle"
import { SelectOption } from "../../../../core/components/Select";
import {SupportPlanFormProps} from "../types";
import {APPROPRIATE} from "../../../../core/constants/defaultValue";
import * as React from "react";
import ScheduleRow from "../components/ScheduleRow";
import ScheduleDateInitializedTextRow from "../components/ScheduleDateInitializedTextRow";
import EntrustingRow from "../components/EntrustingRow";
import EntrustingPersonLastNameRow from "../components/EntrustingPersonLastNameRow";
import EntrustingPersonFirstNameRow from "../components/EntrustingPersonFirstNameRow";
import EntrustingPersonTitleRow from "../components/EntrustingPersonTitleRow";

interface opportunitiesSectionProps extends SupportPlanFormProps {
  nationalityOptions?: SelectOption[];
}

export const opportunitiesSection: FunctionComponent<opportunitiesSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
  }) => {
  return (
    <>
      <HeadingMediumContainer>
        <SectionHeadingMedium>a. 日本語教室や日本語教育機関に関する入学案内の情報を提供し、必要に応じて同行して入学の手続の補助を行う</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          supportPlan={supportPlan}
          propertyName={"opportunitiesAdmissionSchedule"}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"opportunitiesAdmissionScheduleDateContent"}
          initialValue={APPROPRIATE}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"opportunitiesAdmissionEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"opportunitiesAdmissionEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"opportunitiesAdmissionEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"opportunitiesAdmissionEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>b. 自主学習のための日本語学習教材やオンラインの日本語講座に関する情報の提供し、必要に応じて日本語学習教材の入手やオンラインの日本語講座の利用契約手続の補助を行う</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"opportunitiesMaterialsSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"opportunitiesMaterialsScheduleDateContent"}
          initialValue={APPROPRIATE}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"opportunitiesMaterialsEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"opportunitiesMaterialsEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"opportunitiesMaterialsEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"opportunitiesMaterialsEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>c. １号特定技能外国人との合意の下、日本語教師と契約して１号特定技能外国人に日本語の講習の機会を提供する</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"opportunitiesTeachersSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"opportunitiesTeachersScheduleDateContent"}
          initialValue={APPROPRIATE}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"opportunitiesTeachersEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"opportunitiesTeachersEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"opportunitiesTeachersEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"opportunitiesTeachersEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>
    </>
  );
};

export default opportunitiesSection;