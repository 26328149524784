import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Modal from '../../core/components/Modal';
import CategoryHeading from '../../core/components/CategoryHeading';
import PageHeading from '../../core/components/PageHeading';
import Text from '../../core/components/Text';
import { ReactComponent as RedInfoIcon } from '../../assets/icon-info-red-filled.svg';
import { useReducerContext } from '../../core/contexts/ReducerContext';

interface ProfileMissingModalProps {
    open: boolean;
    onClick: () => void;
}

const HeadingContainer = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    gap: 10px;
`;

const LocalPageHeading = styled(PageHeading)`
  font-size: 20px;
  line-height: 29px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  border-radius: 5px;
  background: #FFFFFF;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 20px;
`;

const Button = styled(CategoryHeading)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 50px;
  min-height: 50px;
  color: #FF730D;
  border-top: 1px solid #E5E5E5;
  cursor: pointer;
`;

const ProfileMissingModal: FunctionComponent<ProfileMissingModalProps> = ({
    open,
    onClick
}) => {
    const { state } = useReducerContext();
    const { t } = useTranslation('translation', { keyPrefix: 'job.profile_missing_modal' });
    const message = state.isPartner 
        ? t('complete_profile_to_propose') 
        : t('complete_profile_to_apply');

    return (
        <Modal open={open}>
            <Container>
                <TopContainer>
                    <HeadingContainer>
                        <RedInfoIcon />
                        <LocalPageHeading>
                            {t('profile_incomplete')}
                        </LocalPageHeading>
                    </HeadingContainer>
                    <Text>
                        {message}
                    </Text>
                </TopContainer>
                <Button onClick={onClick}>
                    {t('enter_profile_info')}
                </Button>
            </Container>
        </Modal>
    );
};

export default ProfileMissingModal;