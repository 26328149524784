import {FunctionComponent} from "react";
import DatePicker from "../../../../core/components/DatePicker";
import styled from "styled-components";
import {
  Column5, CustomLabel, DatePickerWrapper,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer, HeadingMediumContainer,
  LabelCell, LocalSelect, LocalTextInput, LocalTextInputExtend, RadioButtonsWrapper, SectionGrid,
  SectionHeadingLarge, SectionHeadingMedium
} from "../commonStyle"
import { SelectOption } from "../../../../core/components/Select";
import Radio from "../../../../core/components/Radio";
import { isEmpty } from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import {Gender, Nationality} from "../../../../core/enums/supportPlans";
import Button from "../../../../core/components/Button";
import { ReactComponent as PlusIcon } from "../../../../assets/icon-plus.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icon-trash-can.svg";
import {MAX_OTHER_TARGET_COUNT} from "../constants";

interface OtherTargetSectionProps extends SupportPlanFormProps {
  nationalityOptions?: SelectOption[];
}

const DeleteButton = styled.button.attrs({
  variant: 'secondary'
})`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    padding: 3px 16px;
    width: 78px;
    height: 24px;
    font-weight: 700;
    font-size: 12px;
    white-space: nowrap;
    gap: 4px;
    background-color: #F00022;
    color: #FFFFFF;
    border: none;
    border-radius: 8px;
    path {
        fill: #FFFFFF;
    }

    &:hover {
        background-color: #FFFFFF;
        color: #F00022;
        border: 1px solid #F00022;
        path {
            fill: #F00022;
        }
    }
`;

const LocalRow = styled.div`
  display: flex;
  gap: 10px;
`;

const GridRowBottomContainer = styled.div`
`;

export const GridRowBottom = styled(SectionGrid)`
  min-height: 56px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

const AddButton = styled(Button).attrs({
  variant: 'secondary'
})`
  margin: 10px 30px;
  padding: 0 15px;
  min-width: 172px;
  font-weight: 700;
  font-size: 14px;
  gap: 10px;

  &:hover {
    path {
      fill: #444444; 
    }
  }
`;

export const OtherTargetSection: FunctionComponent<OtherTargetSectionProps> = (
  {
    supportPlan,
    otherTargetsDisplayCount,
    onChangeSupportPlan,
    addOtherTarget,
    deleteOtherTarget,
    nationalityOptions
}) => {

  function getOtherTargetPropertyName<T>(index: number, suffix: string): SupportPlanExtractType<T> {
    return `otherTarget_${index + 1}${suffix}` as SupportPlanExtractType<T>;
  }

  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>（本人以外の支援対象者）</SectionHeadingLarge>
      </HeadingContainer>

      {
        otherTargetsDisplayCount &&
        Array.from({length: otherTargetsDisplayCount}, (_, i) => i)?.map((index: number) => {
          return (
            <div key={index}>
              <HeadingMediumContainer>
                <SectionHeadingMedium>{`${index + 1}人目`}</SectionHeadingMedium>
                <DeleteButton onClick={() => deleteOtherTarget?.(index)}>
                  <IconContainer>
                    <TrashIcon />
                  </IconContainer>
                  削除
                </DeleteButton>
              </HeadingMediumContainer>

              <GridRowsContainer>
                {/* 名前 */}
                <GridRow>
                  <LabelCell>
                    <CustomLabel required>名前</CustomLabel>
                  </LabelCell>
                  <FieldCell>
                    <Column5>
                      <LocalTextInputExtend
                        placeholder="John"
                        value={supportPlan?.[getOtherTargetPropertyName<string>(index, "FirstName")] ?? ""}
                        error={isEmpty(supportPlan?.[getOtherTargetPropertyName<string>(index, "FirstName")] )}
                        onTextChange={(val: string) => {
                          onChangeSupportPlan?.({[getOtherTargetPropertyName<string>(index, "FirstName")]: val});
                        }}
                      />
                      {isEmpty(supportPlan?.[getOtherTargetPropertyName<string>(index, "FirstName")]) &&
                          <ErrorMessage>必ず入力してください</ErrorMessage>
                      }
                    </Column5>
                  </FieldCell>
                </GridRow>

                {/* 名字 */}
                <GridRow>
                  <LabelCell>
                    <CustomLabel required>名字</CustomLabel>
                  </LabelCell>
                  <FieldCell>
                    <Column5>
                      <LocalTextInputExtend
                        placeholder="Doe"
                        value={supportPlan?.[getOtherTargetPropertyName<string>(index, "LastName")] ?? ""}
                        error={isEmpty(supportPlan?.[getOtherTargetPropertyName<string>(index, "LastName")])}
                        onTextChange={(val: string) => {
                          onChangeSupportPlan?.({[getOtherTargetPropertyName<string>(index, "LastName")]: val});
                        }}
                      />
                      {isEmpty(supportPlan?.[getOtherTargetPropertyName<string>(index, "LastName")]) &&
                          <ErrorMessage>必ず入力してください</ErrorMessage>
                      }
                    </Column5>
                  </FieldCell>
                </GridRow>

                {/* 性別 */}
                <GridRow>
                  <LabelCell>
                    <CustomLabel required>性別</CustomLabel>
                  </LabelCell>
                  <FieldCell>
                    <RadioButtonsWrapper>
                      <Radio
                        value={supportPlan?.[getOtherTargetPropertyName<number>(index, "Gender")] ?? ""}
                        checked={
                          supportPlan?.[getOtherTargetPropertyName<number>(index, "Gender")] === Gender.male
                        }
                        onValueChange={() => {
                          onChangeSupportPlan?.(
                            {[getOtherTargetPropertyName<number>(index, "Gender")]: Gender.male}
                          );
                        }}
                        error={isEmpty(supportPlan?.[getOtherTargetPropertyName<number>(index, "Gender")])}
                      >
                        男性
                      </Radio>
                      <Radio
                        value={supportPlan?.[getOtherTargetPropertyName<number>(index, "Gender")] ?? ""}
                        checked={
                          supportPlan?.[getOtherTargetPropertyName<number>(index, "Gender")] === Gender.female
                        }
                        onValueChange={() => {
                          onChangeSupportPlan?.(
                            {[getOtherTargetPropertyName<number>(index, "Gender")]: Gender.female}
                          );
                        }}
                        error={isEmpty(supportPlan?.[getOtherTargetPropertyName<number>(index, "Gender")])}
                      >
                        女性
                      </Radio>
                    </RadioButtonsWrapper>
                    {isEmpty(supportPlan?.[getOtherTargetPropertyName<number>(index, "Gender")]) &&
                        <ErrorMessage>選択してください</ErrorMessage>
                    }
                  </FieldCell>
                </GridRow>

                {/* 生年月日 */}
                <GridRow>
                  <LabelCell>
                    <CustomLabel required>生年月日</CustomLabel>
                  </LabelCell>
                  <FieldCell>
                    <DatePickerWrapper>
                      <DatePicker
                        placeholder="2000/01/23"
                        selected={
                          (() => {
                            const dateOfBirth =
                              supportPlan?.[getOtherTargetPropertyName<string>(index, "DateOfBirth")];
                            return dateOfBirth ? new Date(dateOfBirth) : undefined;
                          })()
                        }
                        onChange={(val) =>
                          onChangeSupportPlan?.(
                            {[getOtherTargetPropertyName<string>(index, "DateOfBirth")]: val.toISOString()}
                          )
                        }
                        customInput={<LocalTextInput/>}
                        error={isEmpty(supportPlan?.[getOtherTargetPropertyName<string>(index, "DateOfBirth")])}
                      />
                    </DatePickerWrapper>
                    {isEmpty(supportPlan?.[getOtherTargetPropertyName<string>(index, "DateOfBirth")]) &&
                        <ErrorMessage>入力してください</ErrorMessage>
                    }
                  </FieldCell>
                </GridRow>

                {/* 国籍・地域 */}
                <GridRowBottom>
                  <LabelCell>
                    <CustomLabel required>
                      国籍・地域
                    </CustomLabel>
                  </LabelCell>
                  <FieldCell>
                    <LocalRow>
                      <LocalSelect
                        placeholder="選択"
                        options={nationalityOptions}
                        value={supportPlan?.[getOtherTargetPropertyName<number>(index, "NationalityId")]?.toString() ?? ""}
                        onValueChange={val => onChangeSupportPlan?.(
                          [getOtherTargetPropertyName<number>(index, "NationalityId")]?.toString() === Nationality.other ?
                            {[getOtherTargetPropertyName<number>(index, "NationalityId")]: val} :
                            {[getOtherTargetPropertyName<number>(index, "NationalityId")]: val, [getOtherTargetPropertyName<string>(index, "NationalityOther")]: ""}
                        )}
                        error={isEmpty(supportPlan?.[getOtherTargetPropertyName<number>(index, "NationalityId")])}
                      />
                      {
                        supportPlan?.[getOtherTargetPropertyName<number>(index, "NationalityId")]?.toString() === Nationality.other &&
                          <LocalTextInputExtend
                              placeholder="インド"
                              value={supportPlan?.[getOtherTargetPropertyName<string>(index, "NationalityOther")] ?? ""}
                              error={isEmpty(supportPlan?.[getOtherTargetPropertyName<string>(index, "NationalityOther")])}
                              onTextChange={(val: string) => {
                                onChangeSupportPlan?.(
                                  {[getOtherTargetPropertyName<string>(index, "NationalityOther")]: val}
                                );
                              }}
                          />
                      }
                    </LocalRow>
                    {isEmpty(supportPlan?.[getOtherTargetPropertyName<number>(index, "NationalityId")]) &&
                        <ErrorMessage>選択してください</ErrorMessage>
                    }
                    {supportPlan?.[getOtherTargetPropertyName<number>(index, "NationalityId")]?.toString() === Nationality.other &&
                      isEmpty([getOtherTargetPropertyName<string>(index, "NationalityOther")]) &&
                        <ErrorMessage>入力してください</ErrorMessage>
                    }
                  </FieldCell>
                </GridRowBottom>
              </GridRowsContainer>
            </div>
          )
        })
      }
      {
        otherTargetsDisplayCount && otherTargetsDisplayCount < MAX_OTHER_TARGET_COUNT && (
          <GridRowBottomContainer>
            <GridRow>
              <AddButton onClick={addOtherTarget}>
                <IconContainer>
                  <PlusIcon />
                </IconContainer>
                支援対象者を追加
              </AddButton>
            </GridRow>
          </GridRowBottomContainer>
        )
      }
    </>
  );
};

export default OtherTargetSection;