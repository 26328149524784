import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Column from '../../../core/components/Column';
import Message from '../../../core/components/Message';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Text from '../../../core/components/Text';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import { useReducerContext } from '../../../core/contexts/ReducerContext';

interface ContactInformationSectionProps {
    email?: string;
    lineId?: string;
    facebookLink?: string;
}

const Block = styled(Column)`
  margin-top: 8px;
  
  > *:not(:first-child) {
    margin-top: 10px;
  }

  @media ${MOBILE_DEVICE} {
    > *:not(:first-child) {
      margin-top: 20px;
    }
  }
`;

const Field = styled(Row)`
  > *:first-child {
    width: 233px;
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media ${MOBILE_DEVICE} {
    flex-direction: column;

    > *:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 4px;
      color: #999999;
    }
  }
`;

const ContactInformationSection: FunctionComponent<ContactInformationSectionProps> = ({
    email,
    lineId,
    facebookLink,
}) => {
    const context = useReducerContext();
    const { t } = useTranslation();

    return (
        <Section>
            {context.state.isPartner && (
                <Message style={{ marginBottom: 20 }}>
                    {t('candidate.revealed_after_hired_prompt')}
                </Message>
            )}
            <SectionHeading>{t('candidate.contact_information')}</SectionHeading>
            <Block style={{ marginTop: 20 }}>
                <Field>
                    <Text>{t('candidate.email')}</Text>
                    <Text>{email ? email : '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.line_id')}</Text>
                    <Text>{lineId ? lineId : '-'}</Text>
                </Field>
                <Field>
                    <Text>{t('candidate.facebook_link')}</Text>
                    <Text>{facebookLink ? facebookLink : '-'}</Text>
                </Field>
            </Block>
        </Section>
    );
};

export default ContactInformationSection;