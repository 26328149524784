import { FunctionComponent, useEffect, useState } from "react";
import {
  FieldCell,
  NoteInputWrapper,
  LabelCell,
  LocalSelect,
  LocalTextInput,
  Note,
  SectionHeadingLarge,
  RadioButtonsWrapper,
  InputLetterWrapper,
  CheckboxesWrapper,
  HeadingContainer,
  LocalNumberField,
  GridRowsContainer,
  GridRow,
  DatePickerWrapper,
  Row5,
  UnitText,
  LocalTextInputExtend,
  LocalMultiTextInput,
  Column5,
  Row20,
  Row10,
} from "../commonStyle";
import Label from "../../../../core/components/Label";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../../core/components/DatePicker";
import styled from "styled-components";
import { SelectOption } from "../../../../core/components/Select";
import moment, { Moment } from "moment";
import Radio from "../../../../core/components/Radio";
import Row from "../../../../core/components/Row";
import Checkbox from "../../../../core/components/Checkbox";
import { JobFormSectionProps } from "../type";
import { JobContractPeriod, JobContractRenewal, JobJPConversationSkill } from "../../../enums/job";
import { EcContractRenewal, EcContractType } from "../../../../core/enums/employmentConditions";
import { isEmpty } from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";
import { useJobPostCategory, useJobPostOptions } from "../../../../core/hooks";
import { useReducerContext } from "../../../../core/contexts/ReducerContext";

interface BasicInformationSectionProps extends JobFormSectionProps {}

const LocalRow = styled(Row)`
  gap: 20px;
  align-items: center;
`;

const createEmploymentDateOptions = async (language: string) => {
  try {
    await import(`moment/locale/${language}`);
  } catch (e) {}

  const localMoment = moment();
  localMoment.locale(language);
  const dates: SelectOption[] = new Array(20).fill(0).map((_, index) => {
    const date: Moment = localMoment.clone().add(index, "month").set({
      date: 1,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    return {
      label: date.format("MMMM YYYY"),
      value: date.format("YYYY-MM-DD"),
    };
  });

  dates.unshift({
    label: "",
    value: null,
  });

  return dates;
};

export const BasicInformationSection: FunctionComponent<BasicInformationSectionProps> = ({ 
  job, 
  onChange 
}) => {
  const { state } = useReducerContext();
  const { t: tJob, i18n } = useTranslation("translation", { keyPrefix: "job" });
  const { t: tCore } = useTranslation("translation", { keyPrefix: "core" });
  const { t: tCand } = useTranslation("translation", { keyPrefix: "candidate" }); 
  const [employmentDateOptions, setEmploymentDateOptions] = useState<
    SelectOption[]
  >([]);
  const jobPostCategory = useJobPostCategory();
  const { industryOptions, jobTypeOptionsDic } = useJobPostOptions();
  const [industryType, setIndustryType] = useState<string>("");

  useEffect(() => {
    (async () => {
      const employmentDateOptions = await createEmploymentDateOptions(
        i18n.language
      );
      setEmploymentDateOptions(employmentDateOptions);
    })();
  }, [i18n.language]);

  useEffect(() => {
    if (!job?.jobTypeId) 
      return;

    setIndustryType(jobPostCategory.getIndustryIdByJobTypeId(job?.jobTypeId));
  }, [job?.jobTypeId, state.industries, state.jobTypes]);

  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>{tJob("basic_information")}</SectionHeadingLarge>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 求人件名 */}
        <GridRow>
          <LabelCell>
            <Label required>{tJob("post_title")}</Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>{tJob('post_title_description')}</Note>
              <LocalTextInputExtend
                placeholder={tJob("post_title_example")}
                value={job?.name ?? ""}
                error={isEmpty(job?.name)}
                onTextChange={(val) => {
                  onChange?.({ name: val });
                }}
              />
              { isEmpty(job?.name) &&
                <ErrorMessage>{tCore('input_prompt.text_field')}</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 募集人数 */}
        <GridRow>
          <LabelCell>
            <Label required>{tJob("number_of_positions")}</Label>
          </LabelCell>
          <FieldCell>
            <Row10>
              <LocalNumberField
                    placeholder="3"
                    step="1"
                    min="0"
                    value={job?.numberOfPositions ?? ""}
                    error={isEmpty(job?.numberOfPositions)}
                    onValueChange={(val) => {
                      onChange?.({ numberOfPositions: Number(val) });
                    }}
                  />
              <UnitText>{tCore('units.people')}</UnitText>
            </Row10>
          </FieldCell>
        </GridRow>

        {/* 募集締切日 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob("application_deadline")}</Label>
          </LabelCell>
          <FieldCell>
            <DatePickerWrapper>
              <DatePicker 
                placeholder="YYYY/MM/DD"
                selected={
                  job?.applicationDeadline
                    ? new Date(job?.applicationDeadline)
                    : undefined
                }
                onChange={(val) => 
                  onChange?.({ applicationDeadline: val.toISOString() })
                }
                customInput={<LocalTextInput />}
              />
            </DatePickerWrapper>
          </FieldCell>
        </GridRow>
        
        {/* 入社予定時期 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob("planned_date_of_employment")}</Label>
          </LabelCell>
          <FieldCell>
            <LocalSelect
              value={job?.plannedDateOfEmployment ?? ""}
              options={employmentDateOptions}
              placeholder={tCore("select")}
              onValueChange={(val) => {
                onChange?.({ plannedDateOfEmployment: val });
              }}
            />
          </FieldCell>
        </GridRow>

        {/* 業種 
          There is no corresponding column at backend to store the industry type.
          This is because it is extracted from the job type. */}
        <GridRow>
          <LabelCell>
            <Label required>{tJob("industry")}</Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalSelect
                placeholder={tCore("select")}
                value={industryType ?? ""}
                options={industryOptions}
                onValueChange={val => {
                  setIndustryType(val);
                  onChange?.({ jobTypeId: undefined });
                }}
                error={isEmpty(industryType)}
              />
              { isEmpty(industryType) &&
                <ErrorMessage>{tCore('input_prompt.dropdown_menu')}</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 職種 */}
        <GridRow>
          <LabelCell>
            <Label required>{tJob("job_type")}</Label>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              {!industryType && <Note>{tJob('select_industry_first')}</Note>}
              <Column5>
                <LocalSelect
                  placeholder={tCore("select")}
                  disabled={!industryType}
                  value={job?.jobTypeId?.toString() ?? ""}
                  options={jobTypeOptionsDic[industryType] ?? []}
                  onValueChange={(val) => {
                    onChange?.({ 
                      jobTypeId: val, 
                      ecJobTypeId: val
                    });
                  }}
                  error={!!industryType && isEmpty(job?.jobTypeId)}
                />
                { !!industryType && isEmpty(job?.jobTypeId) &&
                  <ErrorMessage>{tCore('input_prompt.dropdown_menu')}</ErrorMessage>
                }
              </Column5>
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 雇用形態 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob("type_of_contract")}</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                value={EcContractType.DirectEmployment}
                checked={
                  job?.ecPlaceOfEmploymentContractType ===
                  EcContractType.DirectEmployment
                }
                onValueChange={(val) => {
                  onChange?.({ ecPlaceOfEmploymentContractType: val });
                }}
              >
                {tJob('direct_employment')}
              </Radio>
              <Radio
                value={EcContractType.DispatchEmployment}
                checked={
                  job?.ecPlaceOfEmploymentContractType ===
                  EcContractType.DispatchEmployment
                }
                onValueChange={(val) => {
                  onChange?.({ ecPlaceOfEmploymentContractType: val });
                }}
              >
                {tJob('dispatch_employment')}
              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

        {/* 契約期間の定め */}
        <GridRow>
          <LabelCell>
            <Label required>{tJob("contract_period")}</Label>
          </LabelCell>
          <FieldCell>
            <Column5>       
              <RadioButtonsWrapper>
                <Radio
                  value={JobContractPeriod.Indefinite}
                  checked={job?.contractPeriod === JobContractPeriod.Indefinite}
                  onValueChange={(val) => onChange?.({ contractPeriod: val })}
                  error={isEmpty(job?.contractPeriod)}
                >
                  {`${tJob('not_applicable')} (${tJob('indefinite')})`}
                </Radio>
                <LocalRow>
                  <Radio
                    value={JobContractPeriod.FixedTerm}
                    checked={job?.contractPeriod === JobContractPeriod.FixedTerm}
                    onValueChange={(val) => onChange?.({ contractPeriod: val })}
                    error={isEmpty(job?.contractPeriod)}
                  >
                    {`${tJob('applicable')} (${tJob('fixed_term')})`}
                  </Radio>
                  <InputLetterWrapper>
                    <Row5>
                      <LocalNumberField 
                        disabled={job?.contractPeriod !== JobContractPeriod.FixedTerm}
                        value={
                          job?.contractPeriod === JobContractPeriod.FixedTerm
                          ? (job?.contractPeriodYears ?? "")
                          : ""
                        }
                        placeholder="12" 
                        onValueChange={(val) => {
                          onChange?.({ contractPeriodYears: Number(val) });
                        }}
                        error={
                          job?.contractPeriod === JobContractPeriod.FixedTerm &&
                          isEmpty(job?.contractPeriodYears) 
                        }
                        style={{ width: 50 }} />
                      <UnitText>{tCore('units.years')}</UnitText>
                    </Row5>
                  </InputLetterWrapper>
                </LocalRow>
              </RadioButtonsWrapper>
              { isEmpty(job?.contractPeriod) &&
                <ErrorMessage>{tCore('input_prompt.radio_button')}</ErrorMessage>
              }
              { job?.contractPeriod === JobContractPeriod.FixedTerm && isEmpty(job?.contractPeriodYears) &&
                <ErrorMessage>{tCore('input_prompt.text_field')}</ErrorMessage>
              } 
            </Column5>
          </FieldCell>        
        </GridRow>

        {/* 日本語の会話能力 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCand("japanese_conversation_skills")}</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                value={JobJPConversationSkill.CanSpeakBasicPhrases}
                checked={
                  job?.japaneseConversationSkills === JobJPConversationSkill.CanSpeakBasicPhrases
                }
                onValueChange={(val) => {
                  onChange?.({ japaneseConversationSkills: val });
                }}
                error={isEmpty(job?.japaneseConversationSkills)}
              >
                {tCand('can_speak_basic_phrases')}
              </Radio>
              <Radio
                value={JobJPConversationSkill.CanCarryOnEverydayConversation}
                checked={
                  job?.japaneseConversationSkills === JobJPConversationSkill.CanCarryOnEverydayConversation
                }
                onValueChange={(val) => {
                  onChange?.({ japaneseConversationSkills: val });
                }}
                error={isEmpty(job?.japaneseConversationSkills)}
              >
                {tCand('can_carry_on_everyday_conversations')}
              </Radio>
              <Radio
                value={JobJPConversationSkill.Fluent}
                checked={
                  job?.japaneseConversationSkills === JobJPConversationSkill.Fluent
                }
                onValueChange={(val) => {
                  onChange?.({ japaneseConversationSkills: val });
                }}
                error={isEmpty(job?.japaneseConversationSkills)}
              >
                {tCand('fluent')}
              </Radio>
            </RadioButtonsWrapper>
            { isEmpty(job?.japaneseConversationSkills) &&
                <ErrorMessage>{tCore('input_prompt.radio_button')}</ErrorMessage>
              }
          </FieldCell>
        </GridRow>

        {/* 契約の更新 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('contract_renewal')}</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={
                  job?.contractRenewal === JobContractRenewal.Automatic
                }
                onValueChange={() => {
                  onChange?.({ 
                    contractRenewal: JobContractRenewal.Automatic,
                    ecContractPeriodRenewal: EcContractRenewal.AutomaticRenewal 
                  });
                }}
              >
                {tJob('automatic_renewal')}
              </Radio>
              <Radio
                checked={
                  job?.contractRenewal === JobContractRenewal.Possible
                }
                onValueChange={() => {
                  onChange?.({ 
                    contractRenewal: JobContractRenewal.Possible,
                    ecContractPeriodRenewal: EcContractRenewal.ConditionalRenewal
                  });
                }}
              >
                {tJob('possible_renewal')}
              </Radio>
              <Radio
                checked={job?.contractRenewal === JobContractRenewal.No}
                onValueChange={() => {
                  onChange?.({ 
                    contractRenewal: JobContractRenewal.No,
                    ecContractPeriodRenewal: EcContractRenewal.NoRenewal
                  });
                }}
              >
                {tJob('no_renewal')}
              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

        {/* 契約更新の判断基準 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('criteria_for_contract_renewal')}</Label>
          </LabelCell>
          <FieldCell>
            <CheckboxesWrapper>
              <Checkbox
                checked={!!job?.ecContractPeriodRenewalConditionWorkload}
                onValueChange={(val) => {
                  onChange?.({ ecContractPeriodRenewalConditionWorkload: val });
                }}
              >
                {tJob('criteria_work_volume')}
              </Checkbox>

              <Checkbox
                checked={
                  !!job?.ecContractPeriodRenewalConditionEmployeePerformance
                }
                onValueChange={(val) => {
                  onChange?.({
                    ecContractPeriodRenewalConditionEmployeePerformance: val,
                  });
                }}
              >
                {tJob('criteria_performance')}
              </Checkbox>

              <Checkbox
                checked={!!job?.ecContractPeriodRenewalConditionEmployeeAbility}
                onValueChange={(val) => {
                  onChange?.({ ecContractPeriodRenewalConditionEmployeeAbility: val });
                }}
              >
                {tJob('criteria_ability')}
              </Checkbox>

              <Checkbox
                checked={!!job?.ecContractPeriodRenewalConditionCompanySituation}
                onValueChange={(val) => {
                  onChange?.({ ecContractPeriodRenewalConditionCompanySituation: val });
                }}
              >
                {tJob('criteria_company_financial_condition')}
              </Checkbox>

              <Checkbox
                checked={!!job?.ecContractPeriodRenewalConditionProgressSituation}
                onValueChange={(val) => {
                  onChange?.({ ecContractPeriodRenewalConditionProgressSituation: val });
                }}
              >
                {tJob('criteria_progress')}
              </Checkbox>
              
              <Row20>
                <Checkbox
                  checked={!!job?.ecContractPeriodRenewalConditionOther}
                  onValueChange={(val) => {
                    onChange?.({ ecContractPeriodRenewalConditionOther: val });
                  }}
                >
                  {tJob('criteria_others')}
                </Checkbox>
                <LocalTextInput
                  placeholder="内容"
                  value={
                    !!job?.ecContractPeriodRenewalConditionOther
                      ? (job?.ecContractPeriodRenewalConditionOtherDetail ?? "")
                      : ""
                  }
                  onTextChange={(val) => {
                    onChange?.({ ecContractPeriodRenewalConditionOtherDetail: val });
                  }}
                  disabled={!!!job?.ecContractPeriodRenewalConditionOther}
                />
              </Row20>
            </CheckboxesWrapper>
          </FieldCell>
        </GridRow>

        {/* 海外居住者の応募 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob("acceptance_of_overseas_residents")}</Label>
          </LabelCell>
          <FieldCell>
            <Checkbox
              checked={!!job?.acceptOverseasResidents}
              onValueChange={(val) => {
                onChange?.({ acceptOverseasResidents: val });
              }}
            >
              {tJob('acceptance_of_overseas_residents_acceptable')}
            </Checkbox>
          </FieldCell>
        </GridRow>

        {/* 仕事の内容 */}
        <GridRow>
          <LabelCell>
            <Label required>{tJob("job_description")}</Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>{tJob('job_description_description')}</Note>
              <LocalMultiTextInput
                placeholder={tJob("job_description_example")}
                value={job?.jobDescription ?? ""}
                onTextChange={(val) => {
                  onChange?.({ jobDescription: val });
                }}
                invalid={isEmpty(job?.jobDescription)}
                style={{
                  minHeight: 144
                }}
              />
              { isEmpty(job?.jobDescription) &&
                <ErrorMessage>{tCore('input_prompt.text_field')}</ErrorMessage>
              
              }
            </Column5>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default BasicInformationSection;