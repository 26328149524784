import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  SectionHeading,
  LabelContainer,
  RadioButtonsContainer,
  ItemTextInputCompact,
} from "../../commonStyles";
import Radio from "../../../../../core/components/Radio";
import { EcContractType } from "../../../../../core/enums/employmentConditions";
import { FormBlockSectionProps } from "../../types";

interface PlaceOfEmploymentSectionProps extends FormBlockSectionProps {}

const PlacementOfEmploymentSection: FunctionComponent<PlaceOfEmploymentSectionProps> = ({
  employmentConditions: ec,
  onChange
}) => {

  return (
    <FormSection>
      <SectionHeading>就業の場所</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>雇用形態</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
                checked={
                  ec?.placeOfEmploymentContractType === EcContractType.DirectEmployment
                }
                value={EcContractType.DirectEmployment}
                onValueChange={val => onChange?.({ placeOfEmploymentContractType: val })}
            >
              直接雇用
            </Radio>
            <Radio
                checked={
                  ec?.placeOfEmploymentContractType === EcContractType.DispatchEmployment
                }
                value={EcContractType.DispatchEmployment}
                onValueChange={val => onChange?.({ placeOfEmploymentContractType: val })}
            >
              派遣雇用
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>事業所名（日本語）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="取手工場" 
            value={ec?.placeOfEmploymentJaOfficeName ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentJaOfficeName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>事業所名（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Toride Koujou" 
            value={ec?.placeOfEmploymentEnOfficeName ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentEnOfficeName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>郵便番号</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInputCompact
            value={ec?.placeOfEmploymentPostalCode ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentPostalCode: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>都道府県</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="東京都" 
            value={ec?.placeOfEmploymentPrefecture ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentPrefecture: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>市区</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="港区" 
            value={ec?.placeOfEmploymentCityWard ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentCityWard: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>町村</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="海岸" 
            value={ec?.placeOfEmploymentTown ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentTown: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>番地</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="１丁目９−１８" 
            value={ec?.placeOfEmploymentAddressNumber ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentAddressNumber: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>建物名等</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="國際浜松町ビル 2階" 
            value={ec?.placeOfEmploymentBuilding ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentBuilding: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>連絡先</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInputCompact
            placeholder="03-1234-5678" 
            value={ec?.placeOfEmploymentPhoneNumber ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentPhoneNumber: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>雇入れ後の変更の可能性</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
                checked={ec?.placeOfEmploymentChangesAfterEmployment === true}
                onValueChange={() => onChange?.({ placeOfEmploymentChangesAfterEmployment: true })}
            >
              あり
            </Radio>
            <Radio
                checked={ec?.placeOfEmploymentChangesAfterEmployment === false}
                onValueChange={() => onChange?.({ placeOfEmploymentChangesAfterEmployment: false })}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>事業所名（日本語）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="取手工場" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentJaOfficeNameAfterChange ?? ""
                : ""
            }
            onTextChange={val => onChange?.({ placeOfEmploymentJaOfficeNameAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>事業所名（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="Toride Koujou" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentEnOfficeNameAfterChange ?? ""
                : ""
            }
            onTextChange={val => onChange?.({ placeOfEmploymentEnOfficeNameAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>郵便番号</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInputCompact
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentPostalCodeAfterChange ?? ""
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentPostalCodeAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>都道府県</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="東京都" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
              ? ec?.placeOfEmploymentPrefectureAfterChange ?? ""
              : ""
            }
            onTextChange={val => onChange?.({ placeOfEmploymentPrefectureAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>市区</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="港区" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentCityWardAfterChange ?? ""
                : ""
            }
            onTextChange={val => onChange?.({ placeOfEmploymentCityWardAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>町村</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="海岸" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentTownAfterChange ?? "" 
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentTownAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>番地</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="１丁目９−１８" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentAddressNumberAfterChange ?? ""
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentAddressNumberAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>建物名等</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="國際浜松町ビル 2階" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentBuildingAfterChange ?? ""
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentBuildingAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>連絡先</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInputCompact
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="03-1234-5678" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentPhoneNumberAfterChange ?? ""
                : ""  
              }
            onTextChange={val => onChange?.({ placeOfEmploymentPhoneNumberAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default PlacementOfEmploymentSection;
