import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from './Button';
import CategoryHeading from './CategoryHeading';
import DeleteButton from './DeleteButton';
import Modal from './Modal';

interface UserInvitationModalProps {
    onSubmit?: () => void;
    onClose?: () => void;
}

const Container = styled.div`
  padding: 20px;
  background: #FFFFFF;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  > *:not(:first-child) {
    margin-left: 18px;
  }
`;

const DiscardUserInvitationModal: FunctionComponent<UserInvitationModalProps> = ({
    onSubmit,
    onClose,
}) => {
    const { t } = useTranslation();

    return (
        <Modal open onClose={onClose}>
            <Container>
                <CategoryHeading>{t('tokuty.discard_invitation_prompt')}</CategoryHeading>
                <ActionContainer style={{ marginTop: 20 }}>
                    <DeleteButton
                        onClick={() => onSubmit && onSubmit()}
                    >
                        {t('core.yes')}
                    </DeleteButton>
                    <Button
                        variant="secondary"
                        onClick={onClose}
                    >
                        {t('core.cancel')}
                    </Button>
                </ActionContainer>
            </Container>
        </Modal>
    )
};

export default DiscardUserInvitationModal;