import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import {
  AmountContainer,
  Section,
  SectionContent,
  SectionIndex,
} from "./styles";
import { Column5, InputValue, RightAlignedContainer, Item10, RowSpaceBetween } from "../../commonStyles";
import styled from "styled-components";
import { MOBILE_DEVICE } from "../../../../../core/constants/styles";
import { TemplateSectionProps } from "../../types";
import { formatAmount } from "../../util";
import { Trans, useTranslation } from "react-i18next";

interface TakeWageHomeSectionProps extends TemplateSectionProps {}

const LocalRowSpaceBetween = styled(RowSpaceBetween)`
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    align-items: flex-end;

    & > :first-child {
      align-self: flex-start;
    }
  }

`;

const TakeHomeWageSection: FunctionComponent<TakeWageHomeSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey,
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang }
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;
  const [takeHomeWageAmount, setTakeHomeWageAmount] = useState<string>("");

  const TakeHomeWageAmount = ({ children }: { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>;

  useEffect(() => {
    if (!ec) 
      return;

    setTakeHomeWageAmount(formatAmount(ec.takeHomeWage));
  }, [ec]);

  return (
    <Section>
      <SectionIndex>5.</SectionIndex>
      <SectionContent>
        <Column5 style={{ width: "100%" }}>
          <LocalRowSpaceBetween>
            <span>手取り支給額（３－４）</span>
            <AmountContainer>
              <Item10>約</Item10>
              <Item10>
                <InputValue>{takeHomeWageAmount}</InputValue>
              </Item10>
              <Item10>円（合計）</Item10>
            </AmountContainer>
          </LocalRowSpaceBetween>
          <RightAlignedContainer>
            <span>※欠勤等がない場合であって，時間外労働の割増賃金等は除く。</span>
          </RightAlignedContainer>
          <LocalRowSpaceBetween>
            <span>{tFixed("take_home_wage")}</span>
            <AmountContainer>
              <Trans
                i18nKey={getTKey("take_home_wage_amount")}
                tOptions={tOptions}
                values={{
                  amount: takeHomeWageAmount,
                }}
                components={{
                  seg: <Item10 />,
                  amount: <TakeHomeWageAmount />,
                }}
              />
            </AmountContainer>
          </LocalRowSpaceBetween>
          <RightAlignedContainer>
            <span>
              {tFixed("note")}
            </span>
          </RightAlignedContainer>
        </Column5>
      </SectionContent>
    </Section>
  );
};

export default TakeHomeWageSection;
