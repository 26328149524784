import { FunctionComponent, HTMLAttributes, MutableRefObject, UIEvent, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Scout } from '../types';
import { encodeCandidateId } from '../utils';
import { Candidate } from '../../candidate/types/api';
import { Mapping } from '../../core/types/common';
import { Job } from '../../job/types/api';
import moment from 'moment';
import Row from '../../core/components/Row';
import Text from '../../core/components/Text';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import { Proposal } from '../../proposal/types';
import { getProposals } from '../../proposal/apis/proposals';

interface ScoutListTableProps extends HTMLAttributes<HTMLDivElement> {
    scouts?: Scout[];
    jobs?: Mapping<Job>;
    candidates?: Mapping<Candidate>;
    onItemClick?: (index: number) => void;
    onScrollEnd?: () => void;
}

interface TableProps {
    simplified: boolean;
}

interface StatusProps {
    status: ScoutStatus;
}

type ScoutStatus = 'unread' | 'read' | 'proposed';


const Container = styled.div`
  width: 100%;
`;

const Item = styled.div`
  display: contents;
  
  &:hover {
    > * {
      background: #FFDFD1;
      border-top: 2px solid #FFC194;
      border-bottom: 2px solid #FFC194;
    }

    > *:first-child {
      border-left: 2px solid #FFC194;
    }

    > *:last-child {
      border-right: 2px solid #FFC194;
    }
  }

  > * {
    max-height: 40px;
    min-height: 40px;
    background: #FFFFFF;
    border-bottom: 1px solid #D5D5D5;
    cursor: pointer;
  }
`;

const Table = styled.div<TableProps>`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props: TableProps) => props.simplified
          ? 'max-content auto max-content'
          : 'minmax(146px, max-content) minmax(200px, max-content) auto max-content'
  };
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  overflow: auto;

  > ${Item}:last-child {
    &:hover {
      > * {
        background: #FFDFD1;
        border-bottom: 2px solid #FFC194;
      }

      > *:first-child {
        border-bottom-left-radius: 5px;
      }

      > *:last-child {
        border-bottom-right-radius: 5px;
      }
    }

    > * {
      border-bottom: none;
    }
  }
`;

const Header = styled.div`
  display: contents;
  
  > * {
    position: sticky;
    top: 0;
    max-height: 30px;
    min-height: 30px;
    background: #E5E5E5;
    border-bottom: 1px solid #D5D5D5;
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
`;

const Avatar = styled.div`
  width: 24px;
  height: 24px;
  background: #D5D5D5;
  border-radius: 5px;
  
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
`;

const Status = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  background: ${(props: StatusProps) => props.status === 'read'
    ? '#D9E8FF'
    : props.status === 'proposed' ? '#D5D5D5' : '#FFDFD1'
  }
`;

const ScoutListTable: FunctionComponent<ScoutListTableProps> = ({
    scouts,
    jobs,
    candidates,
    onItemClick,
    onScrollEnd,
    ...props
}) => {
    const { state } = useReducerContext();
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
    const [scoutStatuses, setScoutStatuses] = useState<Mapping<ScoutStatus>>({});
    const getScoutStatus = (scout: Scout): ScoutStatus => {
        return scoutStatuses[scout.id] ?? (scout.readAt? 'read' : 'unread');
    }
    const convertStatusIntoTransKey = (status: ScoutStatus): string => {
        return state.isCandidate && status === 'proposed'? 'applied' : status; 
    }
    const handleScroll = (event: UIEvent<HTMLDivElement>) => {
        const element = event.target as HTMLDivElement
        if (element.clientHeight + element.scrollTop >= element.scrollHeight) {
            onScrollEnd?.();
        }
    };

    useEffect(() => {
        if (!scouts || scouts.length === 0)
            return;

        (async () => {
            try {
                const statuses: Mapping<ScoutStatus> = {};
                const proposalsToScouts: Proposal[] = await getProposals({ scoutIds: scouts.map(s => s.id) });

                for (const scout of scouts) {
                    const proposal = proposalsToScouts.find(p => p.scoutId === scout.id);
                    statuses[scout.id] = proposal? 'proposed' : (scout.readAt? 'read' : 'unread');
                }

                setScoutStatuses(statuses);
            } catch (e){
            }
        })();

    }, [scouts]);

    return (
        <Container ref={containerRef} {...props}>
            <Table
                style={{ maxHeight: containerRef.current?.clientHeight ?? 0 }}
                simplified={state.isCandidate}
                onScroll={handleScroll}
            >
                <Header>
                    {state.isCandidate
                        ? (
                            <>
                                <Cell>{t('core.status')}</Cell>
                                <Cell>{t('core.job')}</Cell>
                                <Cell>{t('scout.scouted_at')}</Cell>
                            </>
                        )
                        : (
                            <>
                                <Cell>
                                    {t(state.isEmployer ? 'scout.candidate_id' : 'scout.scouted_candidate')}
                                </Cell>
                                <Cell>{t('scout.suggested_job')}</Cell>
                                <Cell>{t('core.status')}</Cell>
                                <Cell>{t('scout.scouted_at')}</Cell>
                            </>
                        )
                    }
                </Header>
                {scouts?.map((scout, index) => (
                    <Item key={scout.id} onClick={() => onItemClick?.(index)}>
                        {state.isCandidate
                            ? (
                                <>
                                    <Cell>
                                        <Status status={getScoutStatus(scout)}>
                                            {t(`scout.${convertStatusIntoTransKey(getScoutStatus(scout))}`)}
                                        </Status>
                                    </Cell>
                                    <Cell>
                                        {jobs?.[scout.jobPostId]?.name}
                                    </Cell>
                                    <Cell>
                                        {moment(scout.createdAt).format('YYYY/MM/DD')}
                                    </Cell>
                                </>
                            )
                            : (
                                <>
                                    <Cell>
                                        {state.isEmployer
                                            ? encodeCandidateId(scout.candidateId)
                                            : (
                                                <Row style={{ gap: 10 }} center>
                                                    <Avatar>
                                                        {candidates?.[scout.candidateId]?.image && (
                                                            <img alt="avatar" src={candidates[scout.candidateId].image?.url} />
                                                        )}
                                                    </Avatar>
                                                    <Text>
                                                        {candidates?.[scout.candidateId]?.enFirstName}
                                                        {' '}
                                                        {candidates?.[scout.candidateId]?.enMiddleName}
                                                        {' '}
                                                        {candidates?.[scout.candidateId]?.enLastName}
                                                    </Text>
                                                </Row>
                                            )
                                        }
                                    </Cell>
                                    <Cell>
                                        {jobs?.[scout.jobPostId]?.name}
                                    </Cell>
                                    <Cell>
                                        <Status status={getScoutStatus(scout)}>
                                            {t(`scout.${getScoutStatus(scout)}`)}
                                        </Status>
                                    </Cell>
                                    <Cell>
                                        {moment(scout.createdAt).format('YYYY/MM/DD')}
                                    </Cell>
                                </>
                            )
                        }
                    </Item>
                ))}

            </Table>
        </Container>
    );
};

export default ScoutListTable;