import { apiUtil } from "../../core/utils";
import { VisaCreationSupportResponse } from "../types";

export const sendCompanyInfoFileToSupportVisaDocCreation = async (proposalId: number | string, companyInfoFiles?: File[]) => {
  const data = new FormData();

  companyInfoFiles?.forEach(file => data.append('files[]', file));
  
  const response = await apiUtil.post(`/proposals/${proposalId}/send_visa_document_data_to_employer_spreadsheet`, data);
  if (response.status >= 200 && response.status < 300) {
    return response.data as VisaCreationSupportResponse;
  }
  throw new Error('API error');
};


export const sendPersonalInfoFileForVisaDocCreation = async (proposalId: number | string, personalInfoFiles?: File[]) => {
  const data = new FormData();

  personalInfoFiles?.forEach(file => data.append('files[]', file));
  
  const response = await apiUtil.post(`/proposals/${proposalId}/send_visa_document_data_to_candidate_spreadsheet`, data);
  if (response.status >= 200 && response.status < 300) {
    return response.data as VisaCreationSupportResponse;
  }
  throw new Error('API error');
}