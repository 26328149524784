import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { signIn } from '../apis/auth';
import Button from '../components/Button';
import Column from '../components/Column';
import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import Section from '../components/Section';
import SubCategoryHeading from '../components/SubCategoryHeading';
import TextInput from '../components/TextInput';
import TextLink from '../components/TextLink';
import ACTIONS from '../constants/actions';
import { MOBILE_DEVICE } from '../constants/styles';
import { useReducerContext } from '../contexts/ReducerContext';
import { User, UserSetting } from '../types/api';
import {  getLanguageIdFromCode } from '../utils';
import { updateUserSettings } from '../apis/user';


interface LoginPageProps {
}

const LocalPage = styled(Page)`
  > * {
    width: 500px;
  }
  
  @media ${MOBILE_DEVICE} {
    > * {
      width: 100%;
    }
  }
`;

const LocalSection = styled(Section)`
  padding: 50px 75px 67px 75px;
  
  @media ${MOBILE_DEVICE} {
    padding: 20px;
    background: transparent;
    border: none;
  }
`;

const LocalTextInput = styled(TextInput)`
  width: 100%;
`;

const LocalButton = styled(Button)`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  
  @media ${MOBILE_DEVICE} {
    gap: 20px;
  }
`;

const FormContainer = styled(Column)`
  gap: 20px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 15px;
  }
`

const InputContainer = styled(Column)`
  gap: 3px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 5px;
  }
`

const ActionContainer = styled(Column)`
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const LoginPage: FunctionComponent<LoginPageProps> = () => {
    const { dispatch } = useReducerContext();
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;
      if (!(email && password)) return;
      handleLogin();
    }

    const handleLogin = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('core.logging_in'),
            },
        });
        try {
            let data: User = await signIn(email, password);
            let preferredLanguage = data.userSetting.language?.code;

            //Temporary solution for existing users who don't have their preferred language set
            //In the future, all users will have a preferred language when they create an account
            //so, the following code wouldn't be required
            if (!preferredLanguage) {
              const updatedSetting: UserSetting = await updateUserSettings(
                data.userSetting.id, 
                { languageId: getLanguageIdFromCode(i18n.language) }
              );
              preferredLanguage = updatedSetting.language!.code;
              data.userSetting = updatedSetting;

            }
            
            i18n.changeLanguage(preferredLanguage);
            localStorage.setItem('lng', preferredLanguage);

            dispatch({
                type: ACTIONS.LOGIN,
                payload:  {
                  company: data.company,
                  candidate: data.candidate,
                  isEmployer: !!data.company?.employer?.id,
                  isPartner: !!data.company?.partner?.id,
                  isCandidate: !!data.candidate?.id,
                  userSetting: data.userSetting
                }
            });
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'success',
                    message: t('core.login_success'),
                },
            });
        } catch (e) {
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'warning',
                    message: t('core.login_failed'),
                },
            });
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    return (
        <LocalPage>
            <LocalSection>
                <Container>
                    <PageHeading>
                        {t('core.login')}
                    </PageHeading>

                    <FormContainer>
                        <InputContainer>
                            <SubCategoryHeading>
                                {t('core.email')}
                            </SubCategoryHeading>
                            <LocalTextInput
                                value={email}
                                onTextChange={setEmail}
                                onKeyDown={handleKeyDown}
                            />
                        </InputContainer>
                        <InputContainer>
                            <SubCategoryHeading>
                                {t('core.password')}
                            </SubCategoryHeading>
                            <LocalTextInput
                                type="password"
                                value={password}
                                onTextChange={setPassword}
                                onKeyDown={handleKeyDown}
                            />
                            <TextLink variant="small" href="/forgot-password">
                                {t('core.forgot_password')}
                            </TextLink>
                        </InputContainer>
                    </FormContainer>

                    <ActionContainer>
                        <LocalButton
                            disabled={!(email && password)}
                            onClick={handleLogin}
                        >
                            {t('core.log_in')}
                        </LocalButton>
                        <TextLink href="/register">
                            {t('core.go_registration')}
                        </TextLink>
                    </ActionContainer>
                </Container>
            </LocalSection>
        </LocalPage>
    );
};

export default LoginPage;