import { FunctionComponent, useEffect, useState } from "react";
import { Column5 } from "../../commonStyles";
import { ContractTextField, Section } from "./styles";
import { TemplateSectionProps } from "../../types";
import { useTranslation } from "react-i18next";

interface ContractSectionProps extends TemplateSectionProps {}


const ContractSection: FunctionComponent<ContractSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const tFixedToTransLang = i18n.getFixedT(transHandler.targetLangCode, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const [candidateName, setCandidateName] = useState<string>("");
  const [orgNameInJp, setOrgNameInJp] = useState<string>("");
  const [orgNameInEn, setOrgNameInEn] = useState<string>("");

  useEffect(() => {
    if (!ec) 
      return;
    
      setCandidateName(
        `${ec.employeeEnFamilyName} ${ec.employeeEnGivenName} ${ec.employeeEnMiddleName}`.trim()
      );
      setOrgNameInJp(ec.employerJaOrganizationName);
      setOrgNameInEn(ec.employerEnOrganizationName);
  }, [ec]);

  return (
    <Section>
      <Column5>
        <ContractTextField>
          <div>特定技能所属機関</div>
          <div>{ orgNameInJp }</div>
          <div>（以下「甲」という。）と</div>
        </ContractTextField>
        <ContractTextField>
          <div>{tFixed("org_name")}</div>
          <div>{ orgNameInEn }</div>
          <div>{tFixed("org_name_abbr")}</div>
        </ContractTextField>
      </Column5>
      <Column5>
        <ContractTextField>
          <div>特定技能外国人（候補者を含む。）</div>
          <div>{ candidateName }</div>
          <div>（以下「乙」という。）は，</div>
        </ContractTextField>
        <ContractTextField>
          <div>{tFixed("employee_name")}</div>
          <div>{ candidateName }</div>
          <div>{tFixed("employee_name_abbr")}</div>
        </ContractTextField>
      </Column5>
      <Column5>
        <span>別添の雇用条件書に記載された内容に従い，特定技能雇用契約を締結する。</span>
        <span>{tFixed("specified_skill_contract_agreement")}</span>
      </Column5>
      <Column5>
        <span>本雇用契約は，乙が，在留資格「特定技能１号」若しくは「特定技能２号」により本邦に入国して，又は同在留資格への変更等を受けて，特定産業分野であって法務大臣が指定するものに属する法務省令で定める技能を要する業務に従事する活動を開始する時点をもって効力を生じるものとする。</span>
        <span>{tFixed("contract_effective_start")}</span>
      </Column5>
      <Column5>
        <span>雇用条件書に記載の雇用契約期間（雇用契約の始期と終期）は，実際の入国日又は許可日に伴って変更されるものとする。</span>
        <span>{tFixed("contract_period_adjust")}</span>
      </Column5>
      <Column5>
        <span>なお，雇用契約を更新することなく雇用契約期間を満了した場合，及び乙が何らかの事由で在留資格を喪失した時点で雇用契約は終了するものとする。</span>
        <span>{tFixed("contract_end_conditions")}</span>
      </Column5>
      <Column5>
        <span>本雇用契約書及び雇用条件書は２部作成し，甲乙それぞれが保有するものとする。</span>
        <span>{tFixed("contract_copies")}</span>
      </Column5>
    </Section>
  )
}

export default ContractSection;