import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { MOBILE_DEVICE } from '../constants/styles';

type FieldProps = HTMLAttributes<HTMLDivElement> & {
    children?: ReactNode;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  > *:not(:first-child) {
    margin-top: 4px;
  }
  
  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const Field: FunctionComponent<FieldProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default Field;