import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import InterviewCalendar from '../../components/InterviewCalendar';
import InterviewCalendarModal from '../../components/InterviewCalendarModal';
import StepContent from '../../components/StepContent';
import StepLabel from '../../components/StepLabel';
import { Interview } from '../../types';
import Button from '../../../core/components/Button';
import Message from '../../../core/components/Message';
import Row from '../../../core/components/Row';
import Text from '../../../core/components/Text';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { DESKTOP_DEVICE } from '../../../core/constants/styles';
import { compareDate } from '../../../core/utils';
import OtherMenu from '../../../core/components/OtherMenu';
import MenuItem from '../../../core/components/MenuItem';

interface InterviewParagraphProps {
    active?: boolean;
    completed?: boolean;
    scheduling?: boolean;
    number?: number;
    label?: string;
    interview?: Interview;
    state?: string;
    schedulingDates?: Date[];
    meetingLink?: string;
    meetingId?: string;
    meetingPassword?: string;
    reason?: string;
    comment?: string;
    onSchedulingDatesChange?: (dates: Date[]) => void;
    onInterviewSlotSelect?: (slotId: number) => void;
    onInterviewCancel?: () => void;
    onInterviewEdit?: () => void;
    onInterviewComplete?: () => void;
}

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const InterviewParagraph: FunctionComponent<InterviewParagraphProps> = ({
    active,
    completed,
    scheduling,
    number,
    label,
    interview,
    state,
    schedulingDates,
    meetingLink,
    meetingId,
    meetingPassword,
    reason,
    comment,
    onSchedulingDatesChange,
    onInterviewSlotSelect,
    onInterviewCancel,
    onInterviewEdit,
    onInterviewComplete,
}) => {
    const context = useReducerContext();
    const [interviewDates, setInterviewDates] = useState<Date[]>([]);
    const [suggestModalOpen, setSuggestModalOpen] = useState(false);
    const [pickModalOpen, setPickModalOpen] = useState(false);
    const { t } = useTranslation();
    const waiting = (context.state.isEmployer && interview?.slotsSetBy === 'employer')
        || (context.state.isPartner && interview?.slotsSetBy === 'partner')
        || (context.state.isCandidate && interview?.slotsSetBy === 'candidate')
        || !!interview?.selectedSlotId;
    const selectedDate = interview?.selectedSlotId
        ? interview.slots.find((slot) => slot.id === interview.selectedSlotId)?.start
        : undefined;
    const isDesktopDevice = useMediaQuery({
        query: DESKTOP_DEVICE,
    });

    const handleSchedulingDateToggle = (date: Date) => {
        if (!schedulingDates || !onSchedulingDatesChange) {
            return;
        }

        const time = date.getTime();
        const index = schedulingDates.findIndex(item => item.getTime() === time);
        if (index !== -1) {
            const dates = [...schedulingDates]
            dates.splice(index, 1)
            onSchedulingDatesChange(dates)
        } else if (schedulingDates.length < 20) {
            onSchedulingDatesChange([...schedulingDates, date]);
        }
    };

    const handleInterviewDatePick = (date: Date) => {
        const time = date.getTime();
        const index = interviewDates.findIndex((item) => item.getTime() === time);
        if (index !== -1) {
            onInterviewSlotSelect?.(interview!.slots[index].id);
        }
    };

    useEffect(() => {
        if (interview?.slots) {
            const dates = interview.slots.map((slot) => new Date(slot.start));
            setInterviewDates(dates);
        } else {
            setInterviewDates([]);
        }
    }, [interview?.slots]);

    return (
        <>
            <Row center style={{ position: 'relative', justifyContent: 'space-between', width: '100%' }}>
                <StepLabel
                  active={active}
                  completed={completed}
                  number={number}
                  comment={interview?.manuallyCompletedAt
                    ? (context.state.isEmployer
                      ? t('proposal.completed_manually')
                      : moment(interview.manuallyCompletedAt)
                        .utcOffset('+09:00')
                        .format('YYYY/MM/DD HH:mm') + ' JST'
                    )
                    : (completed
                        ? moment(selectedDate)
                          .add(1, 'hour')
                          .utcOffset('+09:00')
                          .format('YYYY/MM/DD HH:mm') + ' JST'
                        : undefined
                    )

                  }
                >
                    {label}
                </StepLabel>
                <Actions>
                    {(active && selectedDate && !interview?.canceledAt) && (
                        <Button
                            style={{ width: 'unset'}}
                            variant="inline"
                            onClick={onInterviewCancel}
                        >
                            {t('core.cancel')}
                        </Button>
                    )}
                    {(context.state.isEmployer && active && !!interview) && (
                        <OtherMenu>
                            <MenuItem onClick={onInterviewComplete}>
                                {t('proposal.manually_complete_interview')}
                            </MenuItem>
                        </OtherMenu>
                    )}
                </Actions>
            </Row>
            <StepContent active={completed}>
                {(active && interview?.canceledAt && !scheduling) && (
                    <>
                        <Message>
                            {t('proposal.status_canceled')}
                        </Message>
                        <Text style={{ marginTop: '24px' }}>
                            {t('proposal.reason_for_cancel')}
                        </Text>
                        <Message style={{ marginTop: '10px' }}>
                            <Text>
                                {t(`proposal.reason_${interview.cancelReason}`)}
                            </Text>
                            {!!interview.cancelComment && (
                                <Text style={{ marginTop: '8px' }}>{interview.cancelComment}</Text>
                            )}
                        </Message>
                    </>
                )}
                {state === 'declined' && (
                    <>
                        <Message>
                            {t('proposal.status_declined')}
                        </Message>
                        <Text style={{ marginTop: '24px' }}>
                            {t(`proposal.reason_for_decline`)}
                        </Text>
                        <Message style={{ marginTop: '10px' }}>
                            <Text>
                                {t(`proposal.decline_proposal_reason.${reason}`)}
                            </Text>
                            {!!comment && (
                                <Text style={{ marginTop: '8px' }}>{comment}</Text>
                            )}
                        </Message>
                    </>
                )}
                {(active && waiting && !interview.canceledAt) && (selectedDate
                        ? (
                            <>
                                <Row center>
                                    <Text>{t('proposal.interview_time_set')}</Text>
                                    <Text style={{ marginLeft: '8px', fontWeight: 'bold' }}>
                                        {moment(selectedDate)
                                            .utcOffset('+09:00')
                                        .format('MMMM Do, YYYY H:mm')
                                    }
                                    {' JST'}
                                </Text>
                            </Row>
                            <Text style={{ marginTop: '10px' }}>{
                                t('proposal.join_meeting')}
                            </Text>
                            <Message style={{ marginTop: '10px' }}>
                                <a target="_blank" href={meetingLink}>{meetingLink}</a>
                            </Message>
                            {meetingId && (
                                <Row center style={{ marginTop: 10 }}>
                                    <Text style={{ whiteSpace: 'nowrap' }}>
                                        Meeting ID
                                    </Text>
                                    <Message style={{ marginLeft: 10}}>
                                        {meetingId}
                                    </Message>
                                </Row>
                            )}
                            {meetingPassword && (
                                <Row center style={{ marginTop: 10 }}>
                                    <Text>
                                        Password
                                    </Text>
                                    <Message style={{ marginLeft: 10}}>
                                        {meetingPassword}
                                    </Message>
                                </Row>
                            )}
                        </>
                        )
                        : (
                            <>
                                <Row style={{ justifyContent: 'space-between' }} center>
                                    <Text style={{ fontWeight: 700 }}>
                                        {t('proposal.sent_dates')}
                                    </Text>
                                    <Button variant="inline" onClick={onInterviewEdit}>
                                        {t('core.edit')}
                                    </Button>
                                </Row>
                                <Message style={{ width: '100%', marginTop: '10px' }}>
                                    {interviewDates.sort(compareDate).map((date) => (
                                        <div key={date.toISOString()}>
                                            {moment(date)
                                                .utcOffset('+09:00')
                                                .format('YYYY/MM/DD (ddd) H:mm')
                                            }
                                            {moment(date)
                                                .add(1, 'hour')
                                                .utcOffset('+09:00')
                                                .format(' - H:mm')
                                            }
                                    </div>
                                ))}
                            </Message>
                        </>
                    )
                )}
                {(active && scheduling) && (isDesktopDevice
                    ? (
                        <>
                            <Text>
                                {t('proposal.suggest_dates')}
                            </Text>
                            <InterviewCalendar
                                style={{ width: '570px', marginTop: '10px' }}
                                value={schedulingDates || []}
                                onToggle={handleSchedulingDateToggle}
                            />
                        </>
                    )
                    : (
                        <>
                            <Button
                                style={{ width: 'unset' }}
                                variant="tertiary"
                                onClick={() => setSuggestModalOpen(true)}
                            >
                                {t('proposal.select_dates')}
                            </Button>
                            <Message style={{ width: '100%', marginTop: '10px', fontWeight: 'bold' }}>
                                {(schedulingDates || []).map((date) => (
                                    <div key={date.toISOString()}>
                                        {moment(date)
                                            .utcOffset('+09:00')
                                            .format('M/D/YYYY (ddd) H:mm')
                                        }
                                        {moment(date)
                                            .add(1, 'hour')
                                            .utcOffset('+09:00')
                                            .format(' - H:mm')
                                        }
                                        {' JST'}
                                    </div>
                                ))}
                            </Message>
                        </>
                    )
                )}
                {active && !(waiting || scheduling) && (
                    <>
                        {isDesktopDevice
                            ? (
                                <>
                                    <Text>{t('proposal.select_date')}</Text>
                                    <InterviewCalendar
                                        readonly
                                        style={{ width: '570px', marginTop: '10px' }}
                                        value={interviewDates}
                                        onPick={handleInterviewDatePick}
                                    />
                                </>
                            )
                            : (
                                <Button
                                    style={{ width: 'unset' }}
                                    variant="tertiary"
                                    onClick={() => setPickModalOpen(true)}
                                >
                                    {t('proposal.select_date_short')}
                                </Button>
                            )
                        }
                        <Text style={{ marginTop: 10 }}>
                            {t('proposal.full_suggested_dates')}
                        </Text>
                        <Message style={{ width: '100%', marginTop: 10 }}>
                            {interviewDates.map((date, index) => (
                                <div key={index}>
                                    {moment(date)
                                        .utcOffset('+09:00')
                                        .format('M/D/YYYY (ddd) H:mm')
                                    }
                                    {moment(date)
                                        .add(1, 'hour')
                                        .utcOffset('+09:00')
                                        .format(' - H:mm')
                                    }
                                    {' JST'}
                                </div>
                            ))}
                        </Message>
                    </>
                )}
            </StepContent>
            {suggestModalOpen && (
                <InterviewCalendarModal
                    open
                    onClose={() => setSuggestModalOpen(false)}
                    onConfirm={(dates) => {
                        onSchedulingDatesChange?.(dates);
                        setSuggestModalOpen(false);
                    }}
                />
            )}
            {pickModalOpen && (
                <InterviewCalendarModal
                    readonly
                    open
                    defaultValue={interviewDates}
                    onClose={() => setPickModalOpen(false)}
                    onConfirm={(dates) => {
                        dates.length && handleInterviewDatePick(dates[0]);
                        setPickModalOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default InterviewParagraph;