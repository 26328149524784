import moment from 'moment';
import { FunctionComponent, HTMLAttributes, UIEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Scout } from '../types';
import { encodeCandidateId } from '../utils';
import { Candidate } from '../../candidate/types/api';
import Button from '../../core/components/Button';
import CategoryHeading from '../../core/components/CategoryHeading';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import { Mapping } from '../../core/types/common';
import { Job } from '../../job/types/api';
import { useJobPostCategory, useNationalities } from '../../core/hooks';

interface ScoutListTableProps extends HTMLAttributes<HTMLDivElement> {
    scouts?: Scout[];
    jobs?: Mapping<Job>;
    candidates?: Mapping<Candidate>;
    onItemClick?: (index: number) => void;
    onScrollEnd?: () => void;
}

const Item = styled.div`
  display: contents;

  > * {
    max-height: 40px;
    min-height: 40px;
    background: #FFFFFF;
    border-bottom: 1px solid #D5D5D5;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content auto max-content;
  width: 100%;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  overflow: auto;
  
  > ${Item}:last-child {
    > * {
      border-bottom: none;
    }
  }
`;

const Header = styled.div`
  display: contents;
  
  > * {
    position: sticky;
    top: 0;
    max-height: 30px;
    min-height: 30px;
    background: #E5E5E5;
    border-bottom: 1px solid #D5D5D5;
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
`;

const PlaceholderItem = styled.div`
  grid-column: span 7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #FFFFFF;
`;

const ScoutListTable: FunctionComponent<ScoutListTableProps> = ({
    scouts,
    jobs,
    candidates,
    onItemClick,
    onScrollEnd,
    ...props
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const jobPostCategory = useJobPostCategory();
    const { getNationalityName } = useNationalities();

    const handleScroll = (event: UIEvent<HTMLDivElement>) => {
        const element = event.target as HTMLDivElement
        if (element.clientHeight + element.scrollTop >= element.scrollHeight) {
            onScrollEnd?.();
        }
    };

    return (
        <Container
            {...props}
            style={{
                ...props.style,
                gridTemplateRows: scouts?.length ? 'none' : 'auto 1fr'
            }}
            onScroll={handleScroll}
        >
            <Header>
                <Cell>{t('scout.candidate_id')}</Cell>
                <Cell>{t('job.job_type')}</Cell>
                <Cell>{t('candidate.nationality')}</Cell>
                <Cell>{t('candidate.gender')}</Cell>
                <Cell>{t('candidate.age')}</Cell>
                <Cell>{t('candidate.japanese_level')}</Cell>
                <Cell>{t('scout.scouted_at')}</Cell>
            </Header>
            {scouts?.map((scout, index) => {
                const candidate = candidates?.[scout.candidateId];
                return !!candidate
                    ? (
                        <Item key={scout.id} onClick={() => onItemClick?.(index)}>
                            <Cell>
                                {encodeCandidateId(candidate.id)}
                            </Cell>
                            <Cell>
                                {jobPostCategory.getJobTypeName(candidate.jobTypeId) || '-'}
                            </Cell>
                            <Cell>
                                {getNationalityName(candidate.nationalityId) || '-'}
                            </Cell>
                            <Cell>
                                {candidate.gender ? t(`core.${candidate.gender}`) : '-'}
                            </Cell>
                            <Cell>
                                {candidate.age ?? '-'}
                            </Cell>
                            <Cell>
                                {candidate.japaneseConversationSkills
                                    ? t(`candidate.${candidate.japaneseConversationSkills}`)
                                    : '-'
                                }
                            </Cell>
                            <Cell>
                                {moment(scout.createdAt).format('YYYY/MM/DD')}
                            </Cell>
                        </Item>
                    )
                    : null;
            })}
            {!scouts?.length && (
                <PlaceholderItem>
                    <CategoryHeading>
                        {t('scout.no_recent_scouts')}
                    </CategoryHeading>
                    <Button onClick={() => navigate('/scouts/new')}>
                        {t('scout.try_sending_scouts')}
                    </Button>
                </PlaceholderItem>
            )}
        </Container>
    );
};

export default ScoutListTable;