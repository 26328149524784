import { useTranslation } from "react-i18next";
import {
  Section,
  SectionHeading,
  SectionHeadingContainer,
  SectionLabel,
  SectionLabelCell,
  SectionRow,
  SectionValue,
  SectionValueCell,
  SectionValuesContainer,
} from "./commonStyle";
import { SectionProps } from "../types/props";
import { FunctionComponent, useEffect, useState } from "react";
import { EMPTY_VAL } from "../constants/defaultValue";
import { useValueUnit } from "../../core/hooks";
import { JobState } from "../enums/job";
import { useJobTranslation } from "../hook";

interface HolidaysVacationSectionProps extends SectionProps {}

const HolidaysVacationSection: FunctionComponent<
  HolidaysVacationSectionProps
> = ({ job }) => {
  const { addDay } = useValueUnit();
  const { findTranslation } = useJobTranslation();
  const { i18n } = useTranslation();
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });
  const { t: tCore } = useTranslation("translation", { keyPrefix: "core" });
  const [annualDaysOff, setAnnualDaysOff] = useState<string>(EMPTY_VAL);
  const [regularHoliday, setRegularHoliday] = useState<string>(EMPTY_VAL);
  const [otherRegularHoliday, setOtherRegularHoliday] = useState<string>(EMPTY_VAL);
  const [annualPaidVacation, setAnnualPaidVacation] = useState<string>(EMPTY_VAL);
  const [otherHolidayPaid, setOtherHolidayPaid] = useState<string>(EMPTY_VAL);
  const [otherHolidayUnpaid, setOtherHolidayUnpaid] = useState<string>(EMPTY_VAL);

  useEffect(() => {
    if (!job) return;

    setAnnualDaysOff(addDay(job.averageAnnualDaysOff) || EMPTY_VAL);

    const daysOfWeekTransKeys = [
      "days.monday",
      "days.tuesday",
      "days.wednesday",
      "days.thursday",
      "days.friday",
      "days.saturday",
      "days.sunday",
    ];

    const filteredDaysOfWeekTransKeys = [
      job.ecHolidaysFixedDaysMonday,
      job.ecHolidaysFixedDaysTuesday,
      job.ecHolidaysFixedDaysWednesday,
      job.ecHolidaysFixedDaysThursday,
      job.ecHolidaysFixedDaysFriday,
      job.ecHolidaysFixedDaysSaturday,
      job.ecHolidaysFixedDaysSunday,
    ]
      .map((v, i) => (v ? daysOfWeekTransKeys[i] : ""))
      .filter((v) => v);

    const regularHolidays = filteredDaysOfWeekTransKeys.map((key) =>
      tCore(key)
    );

    if (job.ecHolidaysNationalHolidaysOfJapan)
      regularHolidays.push(tJob("national_holidays"));

    setRegularHoliday(regularHolidays.join(" / ") || EMPTY_VAL);

    if (job.state === JobState.Published) {
      setOtherRegularHoliday(
        findTranslation(job.translations, "ecHolidaysOtherFixedHolidays") ||
          EMPTY_VAL
      );
        
      setAnnualPaidVacation(job.ecLeavesAnnualPaidLeavesAfter_6MonthsDays
        ? `${job.ecLeavesAnnualPaidLeavesAfter_6MonthsDays} ${tCore("units.days")}`
        : EMPTY_VAL
      );
      setOtherHolidayPaid(findTranslation(job.translations, "ecLeavesOtherPaidLeaves") || EMPTY_VAL);
      setOtherHolidayUnpaid(findTranslation(job.translations, "ecLeavesOtherUnpaidLeaves") || EMPTY_VAL);

    } else {
      setOtherRegularHoliday(job.ecHolidaysOtherFixedHolidays || EMPTY_VAL);
      setOtherHolidayPaid(job.ecLeavesOtherPaidLeaves || EMPTY_VAL);
      setOtherHolidayUnpaid(job.ecLeavesOtherUnpaidLeaves || EMPTY_VAL);
    }
  }, [job, i18n.language]);

  return (
    <Section>
      <SectionHeadingContainer>
        <SectionHeading>{tJob("holidays_vacation")}</SectionHeading>
      </SectionHeadingContainer>

      {/* 年間休日数 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>{tJob("annual_days_off")}</SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValuesContainer>
            <SectionValue>{annualDaysOff}</SectionValue>
          </SectionValuesContainer>
        </SectionValueCell>
      </SectionRow>

      {/* 定例休日 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>{tJob("regular_holidays")}</SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{regularHoliday}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* その他の定例休日 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>{tJob("other_regular_holidays")}</SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{otherRegularHoliday}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* 年次有給休暇 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>{tJob("annual_paid_leave")}</SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValue>{annualPaidVacation}</SectionValue>
        </SectionValueCell>
      </SectionRow>

      {/* その他の休暇 */}
      <SectionRow>
        <SectionLabelCell>
          <SectionLabel>{tJob("other_holidays")}</SectionLabel>
        </SectionLabelCell>
        <SectionValueCell>
          <SectionValuesContainer>
            { otherHolidayPaid === EMPTY_VAL && otherHolidayUnpaid === EMPTY_VAL &&
              <SectionValue>{EMPTY_VAL}</SectionValue>
            }
            
            {otherHolidayPaid !== EMPTY_VAL && 
              <SectionValue>{otherHolidayPaid}</SectionValue>
            }

            {otherHolidayUnpaid !== EMPTY_VAL && 
              <SectionValue>{otherHolidayUnpaid}</SectionValue>
            }          
          </SectionValuesContainer>
        </SectionValueCell>
      </SectionRow>
    </Section>
  );
};

export default HolidaysVacationSection;
