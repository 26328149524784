import { FunctionComponent } from "react";
import {
  CategoryContainer,
  GridRowsContainer,
  HeadingMediumContainer,
  SectionHeadingLarge,
  SectionHeadingMedium,
} from "../commonStyle"
import { SelectOption } from "../../../../core/components/Select";
import {SupportPlanFormProps} from "../types";
import * as React from "react";
import {QUARTERLY} from "../../../../core/constants/defaultValue";
import ScheduleRow from "../components/ScheduleRow";
import ScheduleDateInitializedTextRow from "../components/ScheduleDateInitializedTextRow";
import EntrustingRow from "../components/EntrustingRow";
import EntrustingPersonLastNameRow from "../components/EntrustingPersonLastNameRow";
import EntrustingPersonFirstNameRow from "../components/EntrustingPersonFirstNameRow";
import EntrustingPersonTitleRow from "../components/EntrustingPersonTitleRow";
import MethodRow from "../components/MethodRow";
import ScheduleDateReadOnlyRow from "../components/ScheduleDateReadOnlyRow";
import LanguageCategory from "../components/LanguageCategory";

interface InterviewsSectionProps extends SupportPlanFormProps {
  nationalityOptions?: SelectOption[];
}

export const InterviewsSection: FunctionComponent<InterviewsSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
  }) => {
  return (
    <>
      <CategoryContainer>
        <SectionHeadingLarge>ア. 面談内容等</SectionHeadingLarge>
      </CategoryContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          a. １号特定技能外国人の労働状況や生活状況を確認するため、
          当該外国人及びその監督をする立場にある者それぞれと定期的な面談（3か月に1回以上）を実施する
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"interviewsLaborSituationSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"interviewsLaborSituationScheduleDateContent"}
          initialValue={QUARTERLY}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"interviewsLaborSituationEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"interviewsLaborSituationEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"interviewsLaborSituationEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"interviewsLaborSituationEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"interviewsLaborSituationEntrustingMethodInPerson"}
          secondCheckLabel={"無線や船舶電話（漁船漁業のみ）"}
          secondCheckPropertyName={"interviewsLaborSituationEntrustingMethodTransceiver"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>b. 再確認のため、生活オリエンテーションにおいて提供した情報について、改めて提供する</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"interviewsReconfirmationSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateInitializedTextRow
          propertyName={"interviewsReconfirmationScheduleDateContent"}
          initialValue={QUARTERLY}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"interviewsReconfirmationEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"interviewsReconfirmationEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"interviewsReconfirmationEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"interviewsReconfirmationEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          c. 労働基準法その他の労働に関する法令の規定に違反していることを知ったときは、
          労働基準監督署その他の関係行政機関へ通報する
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"interviewsLegalViolationSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"interviewsLegalViolationScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"interviewsLegalViolationEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"interviewsLegalViolationEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"interviewsLegalViolationEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"interviewsLegalViolationEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>
          d. 資格外活動等の入管法違反又は旅券及び在留カードの取上げ等その他の問題の発生を知ったときは、
          その旨を地方出入国在留管理局に通報する
        </SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          propertyName={"interviewsOtherProblemsSchedule"}
          supportPlan={supportPlan}
        />

        {/* 実施予定時期 */}
        <ScheduleDateReadOnlyRow
          propertyName={"interviewsOtherProblemsScheduleDateContent"}
          supportPlan={supportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"interviewsOtherProblemsEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"interviewsOtherProblemsEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"interviewsOtherProblemsEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"interviewsOtherProblemsEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <LanguageCategory
        categoryName={"イ. 実施言語"}
        languageIdPropertyName={"interviewsLanguageId"}
        languageOtherLastNamePropertyName={"interviewsLanguageOtherLastName"}
        languageOtherFirstNamePropertyName={"interviewsLanguageOtherFirstName"}
        languageOtherAffiliationPropertyName={"interviewsLanguageOtherAffiliation"}
        supportPlan={supportPlan}
        onChangeSupportPlan={onChangeSupportPlan}
      />
    </>
  );
};

export default InterviewsSection;