import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Document } from '../types';
import Modal from '../../core/components/Modal';
import PageHeading from '../../core/components/PageHeading';
import Text from '../../core/components/Text';
import Row from '../../core/components/Row';
import Button from '../../core/components/Button';
import Column from '../../core/components/Column';

interface SendDocumentsModalProps {
    open?: boolean;
    email?: string | null;
    documents?: Document[];
    onClose?: () => void;
    onConfirm?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 562px;
  max-height: 479px;
  padding: 20px;
  background: #FFFFFF;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin-top: 10px;
  border-radius: 5px;
  background: #F6F6F6;
  overflow: auto;
  
  > *:not(:first-child) {
    margin-top: 20px;
  }
`;

const Field = styled(Column)`
  > *:first-child {
    color: #999999;
    margin-bottom: 10px;
  }
`;

const Documents = styled(Column)`
  > *:not(:first-child) {
    margin-top: 5px;
  }
`;

const SendDocumentsModal: FunctionComponent<SendDocumentsModalProps> = ({
    open,
    email,
    documents,
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation();

    return (
        <Modal open={open}>
            <Container>
                <PageHeading>メールを送信します</PageHeading>
                <Text style={{ marginTop: 10 }}>
                    以下の内容を「{email}」宛に送信します。
                </Text>
                <Content>
                    <Field>
                        <Text>件名</Text>
                        <Text>雇用主より書類の送付</Text>
                    </Field>
                    <Field>
                        <Text>本文</Text>
                        <Text>雇用主があなたに書類を共有しました。添付ファイルをご確認ください。</Text>
                    </Field>
                    <Field>
                        <Text>添付書類</Text>
                        <Documents>
                            {documents?.map((document) => (
                                <Text key={document.id}>
                                    {document.url.split('/').slice(-1)}
                                </Text>
                            ))}
                        </Documents>
                    </Field>
                </Content>
                <Row style={{ justifyContent: 'center', width: '100%', marginTop: 30 }} center>
                    <Button onClick={onConfirm}>
                        {t('core.yes')}
                    </Button>
                    <Button
                        style={{ marginLeft: 20 }}
                        variant="secondary"
                        onClick={onClose}
                    >
                        {t('core.cancel')}
                    </Button>
                </Row>
            </Container>
        </Modal>
    );
};

export default SendDocumentsModal;