import { FunctionComponent } from "react";
import { ReactComponent as WarningIcon } from "../../../../assets/icon-info-red-filled.svg";
import styled from "styled-components";
import Text from "../../../../core/components/Text";

type NotificationType = "warning" | "info";

interface NotificationBarProps {
  type?: NotificationType;
  text?: string;
}

interface ItemProps {
  type?: NotificationType;
}

//As of Feb 15, 2024, we only have design for the warning type,
//so only use one style but keep the type prop for future use
const Container = styled.div<ItemProps>`
  background-color: ${(props: ItemProps) => {
    switch (props.type) {
      case "warning": return "#FFF0F0";
      default: return "#FFF0F0";
    } 
  }};
  border: 1px solid ${(props: ItemProps) => {
    switch (props.type) {
      case "warning": return "#E93232";
      default: return "#E93232";
    }
  }};
  min-height: 44px;
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  gap: 5px;
`;

const LocalText = styled(Text)<ItemProps>`
  color: ${(props: ItemProps) => {
    switch (props.type) {
      case "warning": return "#E93232";
      default: return "#E93232";
    }
  }};
`;

const NotificationBar: FunctionComponent<NotificationBarProps> = ({
  type,
  text
}) => {
  const Icon = (() => {
    switch (type) {
      case "warning": return WarningIcon;
      default: return WarningIcon;
    }
  })();

  return (
    <Container type={type}>
      <Icon /> 
      <LocalText>{text}</LocalText>
    </Container>
  );
};

export default NotificationBar;