import { FunctionComponent, HTMLAttributes, useState } from "react";
import styled from "styled-components";
import Button from "../../../../../core/components/Button";
import UploadButton from "../../../../../core/components/UploadButton";
import Text from "../../../../../core/components/Text";
import { ReactComponent as AlertIcon } from "../../../../../assets/icon-alert.svg";
import { ReactComponent as TrashIcon } from "../../../../../assets/icon-trash-can.svg";
import { ReactComponent as OpenInNewIcon } from "../../../../../assets/icon-open-in-new.svg";
import { Proposal } from "../../../../types";
import { Candidate } from "../../../../../candidate/types/api";
import { useTranslation } from "react-i18next";
import { useReducerContext } from "../../../../../core/contexts/ReducerContext";
import { sendCompanyInfoFileToSupportVisaDocCreation, sendPersonalInfoFileForVisaDocCreation } from "../../../../apis/ai";
import { getProposal } from "../../../../apis/proposals";
import ACTIONS from "../../../../../core/constants/actions";
import Accordion from "../../../../../core/components/Accordion";

interface VisaDocAnalysisSegmentProps extends HTMLAttributes<HTMLDivElement> {
  proposal: Proposal;
  candidate: Candidate;
}

interface UploadFileNameProps {
  isUnprocessable: boolean;
}

type UploadFile = {
  data: File;
  isUnprocessable: boolean;
}

const Note = styled(Text)`
  font-size: 12px;
  color: #8a8e94;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  align-items: stretch;
`;

const CategoryHeading = styled.h3`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 4px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  border: 0.5px solid #e5e5e5;
  background: #fff;
  padding: 20px;
  color: #444;
  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
`;

const UploadSegment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  width: 100%;
  gap: 10px;
`;

const LocalUploadButton = styled(UploadButton)`
  width: 100%;
`;

const ContentSeparator = styled.hr`
  width: 100%;
  height: 1px;
  background: rgb(0 0 0 / 0.05);
  border: 0;
  margin: 0;
`;

const InfoItemList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 5px 0;
  margin: 0;
  flex-grow: 1;
`;

const PersonalInfoItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

const CompanyInfoItem = styled.li`
  padding: 5px 0;
`;

const FileNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FileName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  word-break: break-all;
`;

const FileNameText = styled(Text)<UploadFileNameProps>`
  color: ${props => props.isUnprocessable ? "#e93232" : "#444"};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px 30px 40px;
  gap: 40px;
  border-top: 1px solid #d5d5d5;
`;

const AlertBanner = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  padding: 8px 20px;
  border-radius: 5px;
  background-color: #fff0f0;
  gap: 10px;
  align-items: center;
  flex-grow: 1;

  & * {
    color: #e93232;
    fill: #e93232;
  }
`;

const Anchor = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
`;

const DetailButton = styled(Button).attrs({ variant: "inline" })`
  padding: 2px 13px;

  &:hover, &:active, &:focus {
    path {
      fill: #444;
    }
  }
`;

const OpenIcon = styled(OpenInNewIcon)`
  margin-left: 6px;
  & path {
    fill: #444;
  }
`;

const VisaDocAnalysisSegment: FunctionComponent<VisaDocAnalysisSegmentProps> = ({
  proposal,
  candidate,
  ...props
}) => {
  const FILE_SIZE_LIMIT_MB_NUM = 5;
  const FILE_SIZE_LIMIT = FILE_SIZE_LIMIT_MB_NUM * 1024 * 1024; // 5MB
  const ANALYSIS_MAX_ATTEMPTS = 10;
  const { state, dispatch } = useReducerContext();
  const { t } = useTranslation();
  const [otherPersonalInfoFiles, setOtherPersonalInfoFiles] = useState<UploadFile[]>([]);
  const [otherCompanyInfoFiles, setOtherCompanyInfoFiles] = useState<UploadFile[]>([]);
  const totalAttempts = proposal.aiAnalyzeSuccessCount + proposal.aiAnalyzeFailureCount;
  const hasNeverAttemptedAnalysis = totalAttempts === 0;
  const [isAnalysisReachedLimit, setAnalysisReachedLimit] = useState(
    totalAttempts >= ANALYSIS_MAX_ATTEMPTS
  );
  const [aiAnalysisTotalAttempts, setAiAnalysisTotalAttempts] = useState(totalAttempts);
  const getAdditionalInfoDocName = (infoTypeId: number): string => {
    const tkey = state?.additionalInformationTypes?.[infoTypeId]?.tkey;
    return tkey ? t(`candidate.additional_information_types.${tkey}`) : "";
  };
  const disablePersonalInfoImport = 
    isAnalysisReachedLimit ||  
    (otherPersonalInfoFiles.length === 0 && 
     candidate.additionalInformations.length === 0);
    
  const disableCompanyInfoImport = 
    isAnalysisReachedLimit || 
    (otherCompanyInfoFiles.length === 0 && 
     !proposal?.employmentCondition);

const isValidFile = (file: File | undefined): boolean => {
  if (!file) 
    return false;

  if (file.size > FILE_SIZE_LIMIT) {
    dispatch({
      type: ACTIONS.SET_PROMPT,
      payload: {
        type: "warning",
        message: `
          <strong>ファイルのアップロードに失敗しました。</strong><br/>
          ${FILE_SIZE_LIMIT_MB_NUM}MB以下のファイルを選択してください
        `,
      },
    });
    return false;
  }

  return true;
}

  const showAnalysisSuccessMessage = () => {
    dispatch({
      type: ACTIONS.SET_PROMPT,
      payload: {
        type: "success",
        message: `
          <strong>データ読み取り成功</strong><br/>
          ビザ申請用のデータ読み取りに成功しました。<br/>
          読み取り情報をご確認ください。
        `,
      },      
    });
  }

  const showAnalysisFailureMessage = () => {
    dispatch({
      type: ACTIONS.SET_PROMPT,
      payload: {
        type: "warning",
        message: `
          <strong>データ読み取り失敗</strong><br/>
          ビザ申請用のデータ読み取りに失敗しました。<br/>
          お手数ですが、直接ご登録をお願いいたします。
        `,
      }
    });
  }

  const updateAiAnalysisAttemptCountsAndLimit = async () => {
    //Backend doesn't return the counts after we call the analysis API,
    //so we need to get the latest counts from the backend.
    try {
      const { aiAnalyzeSuccessCount, aiAnalyzeFailureCount } = await getProposal(proposal.id);
      const newTotalAttempts = aiAnalyzeSuccessCount + aiAnalyzeFailureCount; 
      setAiAnalysisTotalAttempts(newTotalAttempts);
      setAnalysisReachedLimit((newTotalAttempts >= ANALYSIS_MAX_ATTEMPTS));
    } catch {
      
    }
  }

  const onClickDataImportForPersonalInfo = async () => {
    dispatch({
      type: ACTIONS.START_LOADING,
      payload: {
        message: "ビザ申請書類の個人情報を取り込んでいます。",
      },
    });
    
    try {
      const files = otherPersonalInfoFiles.map(file => file.data);
      const resp = await sendPersonalInfoFileForVisaDocCreation(proposal.id, files);
      const failedFiles = resp.failedFiles ?? [];

      if (failedFiles.length > 0)
        setOtherPersonalInfoFiles(prev => prev.map(file => ({
          ...file,
          isUnprocessable: failedFiles.includes(file.data.name)
        })));

      showAnalysisSuccessMessage();
    } catch {
      showAnalysisFailureMessage();
    } finally {
      updateAiAnalysisAttemptCountsAndLimit();
    }

    dispatch({ type: ACTIONS.STOP_LOADING });
  }

  const onClickDataImportForCompanyInfo = async () => {
    dispatch({
      type: ACTIONS.START_LOADING,
      payload: {
        message: "ビザ申請書類の企業情報を取り込んでいます。",
      }
    });

    try {
      const files = otherCompanyInfoFiles.map(file => file.data);
      const resp = await sendCompanyInfoFileToSupportVisaDocCreation(proposal.id, files);
      const failedFiles = resp.failedFiles ?? [];

      if (failedFiles.length > 0)
        setOtherCompanyInfoFiles(prev => prev.map(file => ({
          ...file,
          isUnprocessable: failedFiles.includes(file.data.name)
        })));

      showAnalysisSuccessMessage();
    } catch (e) {
      showAnalysisFailureMessage();
    } finally {
      updateAiAnalysisAttemptCountsAndLimit();
    }
    
    dispatch({ type: ACTIONS.STOP_LOADING });
  }

  return (
    <Accordion 
      active={hasNeverAttemptedAnalysis && !!proposal.employmentCondition}
      headingText='データからビザ申請書類を作成'
      additionalComponentInHeading={
        <Note>
          { aiAnalysisTotalAttempts === 0
            ? "※個人情報、企業情報の取り込みが可能です。"
              : `${aiAnalysisTotalAttempts} 回取り込みを行っています`
          }
        </Note>
      }
      {...props}
    >
      <ContentGrid>

        {/* AI Analysis with personal (candidate's) documents */}
        <Column>
          <CategoryHeading>ビザ申請書類の個人情報取込</CategoryHeading>
          <ContentContainer>
            <Content>
              <Text>候補者の提出済書類から、ビザ申請書類を作成できます。</Text>
              <InfoItemList>
                {candidate?.additionalInformations?.map((info, index) => (
                  <PersonalInfoItem key={`additional-info-${index}`}>
                    <Text>
                      {getAdditionalInfoDocName(info.typeId) || info.name}
                    </Text>
                    <Anchor href={info.url} target="_blank" rel="noreferrer">
                      <DetailButton>
                        <span>詳細</span>
                        <OpenIcon />
                      </DetailButton>
                    </Anchor>
                  </PersonalInfoItem>
                ))}
              </InfoItemList>

              <ContentSeparator />

              <UploadSegment>
                <Text>上記以外の書類を利用</Text>
                <LocalUploadButton
                  accept=".docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png,.webp,.bmp,.tiff,.tif,.gif,.txt"
                  onFileChange={file => {
                    if(!isValidFile(file)) 
                      return;
                      
                    setOtherPersonalInfoFiles(prev => [...prev, { data: file, isUnprocessable: false }]);
                  }}
                  buttonText="ファイルを選択"
                />
                {otherPersonalInfoFiles.length > 0 && (
                  <FileNamesContainer>
                    {otherPersonalInfoFiles.map((file, index) => (
                      <FileName key={`personal-info-file-${index}`}>
                        <FileNameText isUnprocessable={file.isUnprocessable}>
                          {file.data.name}
                        </FileNameText>
                        <Anchor
                          href="#"
                          onClick={() => {
                            setOtherPersonalInfoFiles(prev => prev.filter((_, i) => i !== index));
                          }}
                        >
                          <TrashIcon />
                        </Anchor>
                      </FileName>
                    ))}
                    { otherPersonalInfoFiles.some(f => f.isUnprocessable) &&
                      <AlertBanner>
                        <AlertIcon />
                        <Text>
                          赤文字で表示中のファイルで読み取りエラーが発生しました。一度削除してから、再度お試しください。
                        </Text>
                      </AlertBanner>
                    }
                  </FileNamesContainer>
                )}
              </UploadSegment>
            </Content>

            <ContentSeparator />

            <UploadButton 
              disabled={disablePersonalInfoImport}
              variant="primary" 
              buttonText="データ取込" 
              onClick={onClickDataImportForPersonalInfo}
            />
          </ContentContainer>
        </Column>


        {/* AI Analysis with company documents */}
        <Column>
          <CategoryHeading>ビザ申請書類の企業情報取込</CategoryHeading>
          <ContentContainer>
            <Content>
              <Text>登録済の企業情報から、ビザ申請書類を作成できます。</Text>
              <InfoItemList>
                { proposal?.employmentCondition &&
                  <CompanyInfoItem>
                    <Text>雇用条件書</Text>
                  </CompanyInfoItem>
                }
                <CompanyInfoItem>
                  <Text>求人票</Text>
                </CompanyInfoItem>
              </InfoItemList>

              <ContentSeparator />

              <UploadSegment>
                <Text>上記以外の書類を利用</Text>
                <LocalUploadButton
                  accept=".docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png,.webp,.bmp,.tiff,.tif,.gif,.txt"
                  onFileChange={file => {
                    if(!isValidFile(file)) 
                      return;
                      
                    setOtherCompanyInfoFiles(prev => [...prev, { data: file, isUnprocessable: false }]);
                  }}
                  buttonText="ファイルを選択"
                />
                {otherCompanyInfoFiles.length > 0 && (
                  <FileNamesContainer>
                    {otherCompanyInfoFiles.map((file, index) => (
                      <FileName key={`company-info-file-${index}`}>
                        <FileNameText isUnprocessable={file.isUnprocessable}>
                          {file.data.name}
                        </FileNameText>
                        <Anchor
                          href="#"
                          onClick={() => {
                            setOtherCompanyInfoFiles(prev => prev.filter((_, i) => i !== index));
                          }}
                        >
                          <TrashIcon />
                        </Anchor>
                      </FileName>
                    ))}
                    { otherCompanyInfoFiles.some(f => f.isUnprocessable) &&
                      <AlertBanner>
                        <AlertIcon />
                        <Text>
                          赤文字で表示中のファイルで読み取りエラーが発生しました。
                        </Text>
                      </AlertBanner>
                    }
                  </FileNamesContainer>
                )}
              </UploadSegment>
            </Content>

            <ContentSeparator />

            <UploadButton 
              disabled={disableCompanyInfoImport}
              variant="primary" 
              buttonText="データ取込" 
              onClick={onClickDataImportForCompanyInfo}
            />
          </ContentContainer>
        </Column>
      </ContentGrid>

      <ContentFooter>
        <AlertBanner>
          <AlertIcon />
          <Text>データ取り込みは、失敗時を含めて最大{ANALYSIS_MAX_ATTEMPTS}回まで可能です。</Text>
        </AlertBanner>
      </ContentFooter>
    </Accordion>
  );
};

export default VisaDocAnalysisSegment;
