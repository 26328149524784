import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Text from '../../core/components/Text';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import { Job } from '../../job/types/api';
import SubCategoryHeading from '../../core/components/SubCategoryHeading';
import { MOBILE_DEVICE } from '../../core/constants/styles';
import { decodeHTMLEntities } from '../../core/utils';
import { useJobPostCategory } from '../../core/hooks';

interface JobOverviewProps {
    job: Job;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  gap: 10px;
  
  @media ${MOBILE_DEVICE} {
    padding: 0;
    border: none;
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Thumbnail = styled.div`
  width: 103px;
  height: 70px;
  background: #D5D5D5;
  border-radius: 5px;
  
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
`;

const Information = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 20px;
  grid-row-gap: 5px;
  overflow: hidden;
  
  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  > *:nth-child(odd) {
    color: #999999;
  }
`;

const JobOverview: FunctionComponent<JobOverviewProps> = ({
    job,
}) => {
    const { t, i18n } = useTranslation();
    const jobPostCategory = useJobPostCategory();

    return (
        <Container>
            <SubCategoryHeading>
                {
                    decodeHTMLEntities(job.translations.find(t => t.language.code === i18n.language)?.name)
                    ?? job.name
                }
            </SubCategoryHeading>

            <TopContainer>
                <Thumbnail>
                    {!!job.images.length && (
                        <img alt="thumbnail" src={job.images[0].url} />
                    )}
                </Thumbnail>

                <Information>
                    <Text>
                        {t('job.industry')}/{t('job.job_type')}
                    </Text>
                    <Text>
                        {jobPostCategory.getIndustryNameByJobTypeId(job.jobTypeId)}
                        {' - '}
                        {jobPostCategory.getJobTypeName(job.jobTypeId)}
                    </Text>

                    <Text>
                        {t('job.location')}
                    </Text>
                    <Text>
                        {job.jaPrefecture}
                        {''}
                        {job.jaTown}
                    </Text>

                    <Text>{t('job.wage')}</Text>
                    <Text>
                        {t(`job.salary_${job.salaryType}`)}
                        {' '}
                        {t('job.dollars', {
                            number: (job.hourlyDailySalary || job.grossSalary)?.toLocaleString() ?? '-'
                        })}
                    </Text>
                </Information>
            </TopContainer>

            <Text>
                {
                    decodeHTMLEntities(job.translations.find(t => t.language.code === i18n.language)?.jobDescription)
                    ?? job.jobDescription
                }
            </Text>
        </Container>
    );
};

export default JobOverview;