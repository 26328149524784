import { FunctionComponent, useEffect, useInsertionEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../components/Button';
import Column from '../components/Column';
import Label from '../components/Label';
import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import Text from '../components/Text';
import { useReducerContext } from '../contexts/ReducerContext';
import ACTIONS from '../constants/actions';
import { MOBILE_DEVICE } from '../constants/styles';
import Section from '../components/Section';
import Radio from '../components/Radio';
import { LANGUAGE_LABELS } from '../constants/labels';
import { updateUserSettings } from '../apis/user';
import { getLanguageIdFromCode } from '../utils';
import i18next from 'i18next';
import { UserSetting } from '../types/api';

interface AccountCreatedPageProps {
}

const LocalPage = styled(Page)`
  > * {
    width: 500px;
  }

  @media ${MOBILE_DEVICE} {
    > * {
      width: 100%;
    }
  }
`;

const LocalSection = styled(Section)`
  padding: 50px 75px 67px 75px;
  
  @media ${MOBILE_DEVICE} {
    padding: 20px;
    background: transparent;
    border: none;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LocalButton = styled(Button)`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  
  @media ${MOBILE_DEVICE} {
    gap: 20px;
  }
`;

const InputContainer = styled(Column)`
  gap: 5px;
`;

const AccountCreatedPage: FunctionComponent<AccountCreatedPageProps> = () => {
  const { state, dispatch } = useReducerContext();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState('');
  const onClickStartButton = async () => {    
    
    try {               
      dispatch({  type: ACTIONS.START_LOADING });

      const updatedSetting: UserSetting = await updateUserSettings(
        state.userSetting.id, 
        { 
          languageId: getLanguageIdFromCode(language)!
        }
      );

      dispatch({
        type: ACTIONS.UPDATE_USER_SETTING,
        payload: updatedSetting
      });

      i18n.changeLanguage(language);
      localStorage.setItem('lng', language);
      
      navigate('/jobs');

    } catch (e) {
      dispatch({ 
        type: ACTIONS.SET_PROMPT, 
        payload: { type: 'warning', message: t('core.personal_setting.update_failed') } 
      });
    } finally {
      dispatch({ type: ACTIONS.STOP_LOADING, });
    }
  }

  
  return (
      <LocalPage>
        <LocalSection>
          <Container>
            <PageHeading>
              {t('core.account_created')}
            </PageHeading>
            <Text>
              {t('core.account_created_description')}
            </Text>
            
            <InputContainer>
              <Label>{t('core.choose_language')}</Label>
              <Column style={{ width: '100%' }}>
                <RadioContainer>
                    { 
                      Object.entries(LANGUAGE_LABELS).map(([code, langName], i) => {
                        return (
                          <Radio
                            key={`language-option-${i}`}
                            checked={language === code}
                            value={code}
                            onValueChange={setLanguage}
                          >
                            {langName}
                          </Radio>
                        )
                      })
                    }
                </RadioContainer>
                  
              </Column>
            </InputContainer>

            <LocalButton disabled={language === ''} onClick={onClickStartButton}>
              {t('core.start_browsing')}
            </LocalButton>
          </Container>
        </LocalSection>
      </LocalPage>
    );
};

export default AccountCreatedPage;