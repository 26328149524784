import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { JobState } from '../enums/job';

interface JobStatusProps {
    variant: JobState;
}

const Container = styled.div<JobStatusProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95px;
  height: 25px;
  color: ${(props: JobStatusProps) => props.variant === JobState.Draft
          ? '#444444'
          : (props.variant === JobState.Published ? '#1FA44F' : '#999999')
  };
  background: ${(props: JobStatusProps) => props.variant === JobState.Draft
          ? '#FFFFFF'
          : (props.variant === JobState.Published ? '#DBF0E3' : '#D5D5D5')
  };
  font-size: 14px;
  font-weight: 400;
  border: ${(props: JobStatusProps) => props.variant === JobState.Draft ? '1px solid #D5D5D5' : 'none'};
  border-radius: 30px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const JobStatus: FunctionComponent<JobStatusProps> = ({
    variant,
}) => {
    const { t } = useTranslation();

    return (
        <Container variant={variant}>
            {t(`job.status_${variant}`)}
        </Container>
    );
};

export default JobStatus;