import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import styled from 'styled-components';

import { ReactComponent as NextIcon } from '../../assets/icon-next-banner.svg';
import { ReactComponent as WarningIcon } from '../../assets/icon-warning-banner.svg';

interface BannerButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
}

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #444444;
  background: #FFF0F0;
  
  &.clickable {
    cursor: pointer;
  }
  
  &.clickable:hover {
    background: #FFE5E5;
  }
  
  > img.warning {
    width: 18px;
    height: 18px;
  }

  > img.next {
    width: 20px;
    height: 20px;
  }
`;

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`;

const NextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

const BannerButton: FunctionComponent<BannerButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button className={props.onClick ? 'clickable' : ''} {...props}>
      <WarningContainer>
        <WarningIcon />
      </WarningContainer>
      {children}
      {props.onClick && (
        <NextContainer>
          <NextIcon />
        </NextContainer>
      )}
    </Button>
  );
};

export default BannerButton;