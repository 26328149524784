import en from './en.json';
import ja from './ja.json';
import vi from './vi.json';
import my from './my.json';
import id from './id.json';
import ne from './ne.json';

const resources = {
    en: {
        translation: en,
    },
    ja: {
        translation: ja,
    },
    vi: {
        translation: vi,
    },
    my: {
        translation: my,
    },
    id: {
        translation: id,
    },
    ne: {
        translation: ne,
    }
};

export default resources;