import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from './Button';
import Modal from './Modal';
import MultiTextInput from './MultiTextInput';
import PageHeading from './PageHeading';
import Row from './Row';
import Select from './Select';
import Text from './Text';

type Option = {
    label: string;
    value: string;
};

type DeclineConfirmationModalProps = {
    open?: boolean;
    title: string;
    message: string;
    options?: Option[];
    reason?: string;
    placeholder?: string;
    comment?: string;
    onReasonChange?: (reason: string) => void;
    onCommentChange?: (comment: string) => void;
    onConfirm?: () => void;
    onCancel?: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;
  padding: 24px 39px 30px 29px;
  background: #FFFFFF;
  border-radius: 5px;
`;

const Prompt = styled.div`
  margin-left: 8px;
  font-family: "Hiragino Kaku Gothic ProN", sans-serif;
  font-size: 18px;
  color: #999999;
  zoom: 0.5;
`

const DeclineConfirmationModal: FunctionComponent<DeclineConfirmationModalProps> = ({
    open,
    title,
    message,
    options,
    reason,
    placeholder,
    comment,
    onReasonChange,
    onCommentChange,
    onConfirm,
    onCancel,
}) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onCancel}>
            <Container>
                <PageHeading>{title}</PageHeading>
                <Text style={{ marginTop: '20px', color: '#E93232' }}>
                    {t('core.cannot_undo_warning')}
                </Text>
                <Text style={{ marginTop: '10px' }}>
                    {message}
                </Text>
                <Select
                    style={{ width: '100%', marginTop: '5px' }}
                    options={options}
                    value={reason}
                    onValueChange={onReasonChange}
                />
                <Row style={{ alignItems: 'flex-end' }}>
                    <Text style={{ marginTop: '20px' }}>{t('core.comments')}</Text>
                    <Prompt>({t('core.form_optional')})</Prompt>
                </Row>
                <MultiTextInput
                    style={{ width: '100%', height: '120px', marginTop: '5px', resize: 'none' }}
                    placeholder={placeholder}
                    value={comment}
                    onTextChange={onCommentChange}
                />
                <Row style={{ alignSelf: 'center', marginTop: '63px' }}>
                    <Button onClick={() => onConfirm && onConfirm()}>
                        {t('core.yes')}
                    </Button>
                    <Button
                        style={{ marginLeft: '20px' }}
                        variant="secondary"
                        onClick={onCancel}
                    >
                        {t('core.no')}
                    </Button>
                </Row>
            </Container>
        </Modal>
    );
};

export default DeclineConfirmationModal;