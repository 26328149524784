import { UserSetting, UserSettingsParameter } from '../types/api';
import { apiUtil} from '../utils';
import { User } from '../types/api';

export const createUser = async (email: string) => {
    const response = await apiUtil.post(
        '/users',
        {
            user: {
                email,
            },
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API Error');
};

export const inviteUser = async (email: string) => {
    const response = await apiUtil.post(
        '/users/invite',
        {
            email,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as User;
    }
    throw new Error('API Error');
};

export const confirmInvitation = async (
    confirmationToken: string,
    firstName: string,
    lastName: string,
    password: string,
    passwordConfirmation: string,
) => {
    const response = await apiUtil.patch(
        '/users/confirmations/confirm_through_invitation',
        {
            user: {
                confirmationToken,
                firstName,
                lastName,
                password,
                passwordConfirmation,
            },
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as User;
    }
    throw new Error('API Error');
};

export const getUsers = async () => {
    const response = await apiUtil.get('/users');
    if (response.status >= 200 && response.status < 300) {
        return response.data as User[];
    }
    throw new Error('API Error');
};

export const getUserByConfirmationToken = async (confirmationToken: string) => {
    const response = await apiUtil.get(`/users/show_by_confirmation_token?confirmation_token=${confirmationToken}`);
    if (response.status >= 200 && response.status < 300) {
        return response.data as User;
    }
    throw new Error('API Error');
};

export const sendConfirmationEmail = async (email: string) => {
    const response = await apiUtil.post(
        '/users/confirmations/send_confirmation_instructions',
        {
            user: {
                email,
            },
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API Error');
};

export const verifyEmail = async (confirmationToken: string) => {
    const response = await apiUtil.patch(
        '/users/confirmations/confirm',
        {
            user: {
                confirmationToken,
            },
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API Error');
};

export const initializePassword = async (confirmationToken: string, password: string, passwordConfirmation: string) => {
    const response = await apiUtil.patch(
        '/users/passwords/initialize',
        {
            user: {
                confirmationToken,
                password,
                passwordConfirmation,
            },
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API Error');
};

export const requestPasswordReset = async (email: string) => {
    const response = await apiUtil.post(
        '/users/password',
        {
            user: {
                email,
            },
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API Error');
};

export const resetPassword = async (resetPasswordToken: string, password: string, passwordConfirmation: string) => {
    const response = await apiUtil.put(
        '/users/password',
        {
            user: {
                resetPasswordToken,
                password,
                passwordConfirmation,
            },
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API Error');
};

export const updateUserSettings = async (userSettingId: string | number, params: UserSettingsParameter) => {
    const response = await apiUtil.patch(`/user_settings/${userSettingId}`, { ...params });
    if (response.status >= 200 && response.status < 300) {
        return response.data as UserSetting;
    }
    throw new Error('API Error');
  };