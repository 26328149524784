import { FunctionComponent, ReactNode, useEffect } from 'react';

import ACTIONS from '../constants/actions';
import { useReducerContext } from '../contexts/ReducerContext';
import Page from './Page';

interface PageWithoutNavigatorProps {
    children?: ReactNode;
}

const PageWithoutNavigator: FunctionComponent<PageWithoutNavigatorProps> = ({
    children,
    ...props
}) => {
    const { dispatch } = useReducerContext();

    useEffect(() => {
        dispatch({
            type: ACTIONS.SET_NAVIGABLE,
            payload: {
                isNavigable: false,
            },
        });

        return () => {
            dispatch({
                type: ACTIONS.SET_NAVIGABLE,
                payload: {
                    isNavigable: true,
                },
            });
        };
    }, []);

    return (
        <Page {...props}>
            {children}
        </Page>
    );
}; 

export default PageWithoutNavigator;