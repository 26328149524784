import { FunctionComponent } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import Field from '../../../core/components/Field';
import Label from '../../../core/components/Label';
import Message from '../../../core/components/Message';
import TextInput from '../../../core/components/TextInput';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Text from '../../../core/components/Text';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import { isValidEmailAddress } from '../../../core/utils';

type ContactInformationSectionProps = {
    email?: string;
    lineId?: string;
    facebookLink?: string;
    onEmailChange?: (email: string) => void;
    onLineIdChange?: (lineId: string) => void;
    onFacebookLinkChange?: (facebookLink: string) => void;
};

const ContactInformationSection: FunctionComponent<ContactInformationSectionProps> = ({
    email,
    lineId,
    facebookLink,
    onEmailChange,
    onLineIdChange,
    onFacebookLinkChange,
}) => {
    const { t } = useTranslation();
    const isMobileDevice = useMediaQuery({
        query: MOBILE_DEVICE,
    });
    const isEmpty = (value: any) => !!!value;

    return (
        <Section>
            <Message>{t('candidate.revealed_after_hired_prompt')}</Message>
            <SectionHeading style={{ marginTop: 20 }}>
                {t('candidate.contact_information')}
            </SectionHeading>

            <Field>
                <Label required>{t('candidate.email')}</Label>
                <TextInput
                    style={{ width: isMobileDevice ? 'inherit' : 340 }}
                    type="email"
                    value={email}
                    error={isEmpty(email) || !isValidEmailAddress(email)}
                    onTextChange={onEmailChange}
                />
                { isEmpty(email) &&
                    <Text error={true} style={ {fontSize: 12}}>{t('core.input_prompt.text_field')}</Text>
                }

                { !isEmpty(email) && !isValidEmailAddress(email) &&
                    <Text error={true} style={ {fontSize: 12}}>{t('core.validation.email')}</Text>
                }
            </Field>

            <Text style={{ marginTop: 10, color: '#999999'}}>
                {t('candidate.enter_one_of_following_prompt')}
            </Text>

            <Field style={{ marginTop: 10 }}>
                <Label>{t('candidate.line_id')}</Label>
                <TextInput
                    style={{ width: isMobileDevice ? 'inherit' : 340 }}
                    value={lineId}
                    onTextChange={onLineIdChange}
                />
            </Field>

            <Field>
                <Label>{t('candidate.facebook_link')}</Label>
                <TextInput
                    style={{ width: isMobileDevice ? 'inherit' : 340 }}
                    value={facebookLink}
                    onTextChange={onFacebookLinkChange}
                />
            </Field>
        </Section>
    );
};

export default ContactInformationSection;
