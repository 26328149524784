import React, {FunctionComponent} from "react";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import {
  Column5,
  FieldCell, GridRow,
  LabelCell, LocalTextInputExtend, WordBreakLabel,
} from "../commonStyle";
import {isEmpty} from "../../../../core/utils";
import ErrorMessage from "./ErrorMessage";

interface EntrustingPersonLastNameRowProps extends SupportPlanFormProps {
  propertyName: SupportPlanExtractType<string>;
}

const EntrustingPersonLastNameRow: FunctionComponent<EntrustingPersonLastNameRowProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
    propertyName,
}) => {
  return (
    <GridRow>
      <LabelCell>
        <WordBreakLabel required>委託を受けた実施担当者の名字</WordBreakLabel>
      </LabelCell>
      <FieldCell>
        <Column5>
          <LocalTextInputExtend
            placeholder="山田"
            value={supportPlan?.[propertyName] ?? ""}
            error={isEmpty(supportPlan?.[propertyName])}
            onTextChange={(val: string) => {
              onChangeSupportPlan?.({[propertyName]: val});
            }}
          />
          {isEmpty(supportPlan?.[propertyName]) &&
              <ErrorMessage>必ず入力してください</ErrorMessage>
          }
        </Column5>
      </FieldCell>
    </GridRow>
  );
};

export default EntrustingPersonLastNameRow;