import { FunctionComponent } from "react";
import {
  CategoryContainer,
  GridRowsContainer,
  HeadingMediumContainer,
  SectionHeadingLarge,
  SectionHeadingMedium,
} from "../commonStyle"
import { SelectOption } from "../../../../core/components/Select";
import {SupportPlanFormProps} from "../types";
import ScheduleRow from "../components/ScheduleRow";
import * as React from "react";
import ScheduleDateRow from "../components/ScheduleDateRow";
import EntrustingRow from "../components/EntrustingRow";
import EntrustingPersonLastNameRow from "../components/EntrustingPersonLastNameRow";
import EntrustingPersonFirstNameRow from "../components/EntrustingPersonFirstNameRow";
import EntrustingPersonTitleRow from "../components/EntrustingPersonTitleRow";
import MethodWithOtherRow from "../components/MethodWithOtherRow";
import LanguageCategory from "../components/LanguageCategory";
import ScheduleTimeCategory from "../components/ScheduleTimeCategory";

interface GuidanceSectionProps extends SupportPlanFormProps {
  nationalityOptions?: SelectOption[];
}

export const GuidanceSection: FunctionComponent<GuidanceSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
}) => {
  return (
    <>
      <CategoryContainer>
        <SectionHeadingLarge>ア. 情報提供内容等</SectionHeadingLarge>
      </CategoryContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>a. 従事する業務の内容、報酬の額その他の労働条件に関する事項</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          supportPlan={supportPlan}
          propertyName={"guidanceWorkingConditionsSchedule"}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"guidanceWorkingConditionsScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"guidanceWorkingConditionsEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"guidanceWorkingConditionsEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"guidanceWorkingConditionsEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"guidanceWorkingConditionsEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"guidanceWorkingConditionsEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話装置"}
          secondCheckPropertyName={"guidanceWorkingConditionsEntrustingMethodVideoCall"}
          otherCheckPropertyName={"guidanceWorkingConditionsEntrustingMethodOther"}
          otherContentPropertyName={"guidanceWorkingConditionsEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>b. 本邦において行うことができる活動の内容</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          supportPlan={supportPlan}
          propertyName={"guidanceActivitySchedule"}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"guidanceActivityScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"guidanceActivityEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"guidanceActivityEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"guidanceActivityEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"guidanceActivityEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"guidanceActivityEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話装置"}
          secondCheckPropertyName={"guidanceActivityEntrustingMethodVideoCall"}
          otherCheckPropertyName={"guidanceActivityEntrustingMethodOther"}
          otherContentPropertyName={"guidanceActivityEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>c. 入国に当たっての手続きに関する事項</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          supportPlan={supportPlan}
          propertyName={"guidanceProceduresSchedule"}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"guidanceProceduresScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"guidanceProceduresEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"guidanceProceduresEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"guidanceProceduresEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"guidanceProceduresEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"guidanceProceduresEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話装置"}
          secondCheckPropertyName={"guidanceProceduresEntrustingMethodVideoCall"}
          otherCheckPropertyName={"guidanceProceduresEntrustingMethodOther"}
          otherContentPropertyName={"guidanceProceduresEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>d. 保証金の消臭、契約の不履行についての違約金契約などの締結の禁止</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          supportPlan={supportPlan}
          propertyName={"guidanceProhibitionsSchedule"}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"guidanceProhibitionsScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"guidanceProhibitionsEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"guidanceProhibitionsEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"guidanceProhibitionsEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"guidanceProhibitionsEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"guidanceProhibitionsEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話装置"}
          secondCheckPropertyName={"guidanceProhibitionsEntrustingMethodVideoCall"}
          otherCheckPropertyName={"guidanceProhibitionsEntrustingMethodOther"}
          otherContentPropertyName={"guidanceProhibitionsEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>e. 入国の準備に関し外国の機関に支払った費用について、当該費用の額及び内訳を十分に理解して支払わせなければならないこと</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          supportPlan={supportPlan}
          propertyName={"guidanceUnderstandingCostsSchedule"}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"guidanceUnderstandingCostsScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"guidanceUnderstandingCostsEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"guidanceUnderstandingCostsEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"guidanceUnderstandingCostsEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"guidanceUnderstandingCostsEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"guidanceUnderstandingCostsEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話装置"}
          secondCheckPropertyName={"guidanceUnderstandingCostsEntrustingMethodVideoCall"}
          otherCheckPropertyName={"guidanceUnderstandingCostsEntrustingMethodOther"}
          otherContentPropertyName={"guidanceUnderstandingCostsEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>f. 支援に関する費用を負担させないこととしていること</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          supportPlan={supportPlan}
          propertyName={"guidanceNoBearingCostsSchedule"}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"guidanceNoBearingCostsScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"guidanceNoBearingCostsEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"guidanceNoBearingCostsEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"guidanceNoBearingCostsEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"guidanceNoBearingCostsEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"guidanceNoBearingCostsEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話装置"}
          secondCheckPropertyName={"guidanceNoBearingCostsEntrustingMethodVideoCall"}
          otherCheckPropertyName={"guidanceNoBearingCostsEntrustingMethodOther"}
          otherContentPropertyName={"guidanceNoBearingCostsEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>g. 入国する際の送迎に関する支援の内容</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          supportPlan={supportPlan}
          propertyName={"guidancePickUpDropOffSchedule"}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"guidancePickUpDropOffScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"guidancePickUpDropOffEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"guidancePickUpDropOffEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"guidancePickUpDropOffEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"guidancePickUpDropOffEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"guidancePickUpDropOffEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話装置"}
          secondCheckPropertyName={"guidancePickUpDropOffEntrustingMethodVideoCall"}
          otherCheckPropertyName={"guidancePickUpDropOffEntrustingMethodOther"}
          otherContentPropertyName={"guidancePickUpDropOffEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>h. 住居の確保に関する支援の内容</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          supportPlan={supportPlan}
          propertyName={"guidanceHousingSchedule"}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"guidanceHousingScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"guidanceHousingEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"guidanceHousingEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"guidanceHousingEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"guidanceHousingEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"guidanceHousingEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話装置"}
          secondCheckPropertyName={"guidanceHousingEntrustingMethodVideoCall"}
          otherCheckPropertyName={"guidanceHousingEntrustingMethodOther"}
          otherContentPropertyName={"guidanceHousingEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>i. 相談・苦情の対応に関する内容</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          supportPlan={supportPlan}
          propertyName={"guidanceRequestsSchedule"}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"guidanceRequestsScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"guidanceRequestsEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"guidanceRequestsEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"guidanceRequestsEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"guidanceRequestsEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"guidanceRequestsEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話装置"}
          secondCheckPropertyName={"guidanceRequestsEntrustingMethodVideoCall"}
          otherCheckPropertyName={"guidanceRequestsEntrustingMethodOther"}
          otherContentPropertyName={"guidanceRequestsEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <HeadingMediumContainer>
        <SectionHeadingMedium>j. 特定技能所属機関等の支援担当者指名及び連絡先</SectionHeadingMedium>
      </HeadingMediumContainer>

      <GridRowsContainer>
        {/* 実施予定の有無 */}
        <ScheduleRow
          supportPlan={supportPlan}
          propertyName={"guidancePersonContactSchedule"}
        />

        {/* 実施予定時期 */}
        <ScheduleDateRow
          propertyName={"guidancePersonContactScheduleDate"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託の有無 */}
        <EntrustingRow
          propertyName={"guidancePersonContactEntrusting"}
          supportPlan={supportPlan}
        />

        {/* 委託を受けた実施担当者の名字 */}
        <EntrustingPersonLastNameRow
          propertyName={"guidancePersonContactEntrustingPersonLastName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の名前 */}
        <EntrustingPersonFirstNameRow
          propertyName={"guidancePersonContactEntrustingPersonFirstName"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 委託を受けた実施担当者の役職名 */}
        <EntrustingPersonTitleRow
          propertyName={"guidancePersonContactEntrustingPersonTitle"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />

        {/* 実施方法 */}
        <MethodWithOtherRow
          firstCheckLabel={"対面"}
          firstCheckPropertyName={"guidancePersonContactEntrustingMethodInPerson"}
          secondCheckLabel={"テレビ電話装置"}
          secondCheckPropertyName={"guidancePersonContactEntrustingMethodVideoCall"}
          otherCheckPropertyName={"guidancePersonContactEntrustingMethodOther"}
          otherContentPropertyName={"guidancePersonContactEntrustingMethodOtherContent"}
          supportPlan={supportPlan}
          onChangeSupportPlan={onChangeSupportPlan}
        />
      </GridRowsContainer>

      <LanguageCategory
        categoryName={"イ. 実施言語"}
        languageIdPropertyName={"guidanceLanguageId"}
        languageOtherLastNamePropertyName={"guidanceLanguageOtherLastName"}
        languageOtherFirstNamePropertyName={"guidanceLanguageOtherFirstName"}
        languageOtherAffiliationPropertyName={"guidanceLanguageOtherAffiliation"}
        supportPlan={supportPlan}
        onChangeSupportPlan={onChangeSupportPlan}
      />

      <ScheduleTimeCategory
        categoryName={"ウ. 実施予定時間"}
        propertyName={"guidanceScheduleTime"}
        supportPlan={supportPlan}
      />
    </>
  );
};

export default GuidanceSection;