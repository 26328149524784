import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Page from '../components/Page';
import Section from '../components/Section';
import { useTranslation } from 'react-i18next';

interface PageProps {
}


const LocalSection = styled(Section)`
  padding: 50px 75px 67px 75px;
`;


const PrivacyPolicyPage: FunctionComponent<PageProps> = () => {
   
    const { i18n } = useTranslation();

    return (
        <Page>
            <LocalSection>
                { i18n.language === 'ja'? <PolicyInJP /> : <PolicyInEN /> } 
            </LocalSection>
        </Page>
    );
};


const PolicyInJP : FunctionComponent<{}> = () => {
    return (
        <div>
            <h1>事前登録同意手続き</h1>
            <h2>利用者方針と個人情報の取り扱いに関して</h2>
            <p>トクティー株式会社（以下「tokuty」）は、以下の使用条件を定めています。下記をご確認のうえ、
                ご理解とご了解を賜りますようお願い申し上げます。個人情報の提出は任意ですが、情報を提出しない場合、
                必要な情報を提供できない場合があります。すべての応募者は、
                実際の就職前に面接のために私たちのオフィスに来なければなりません。</p>
            <h2>個人情報の取扱いについて</h2>
            <h3>1.個人情報の利用</h3>
            <p>Tokutyは、以下の理由により、個人情報を使用します。個人情報が本来の目的を超えて使用された場合、
                情報の所有者から事前の同意を得ることになります。</p>
            <p>個人情報とは、任意の人物の名前と生年月日、その他の日付と声明、
                および/またはその人物に特別に割り当てられた番号で、読者が特定の個人を識別できるようにする情報を指します。 
                （それ自体では十分ではないかもしれないが、他の情報と簡単に参照できる情報を含み、
                    そのような他の情報と共にアクセスされた場合にその人を特定するのに役立つ）。</p>
            <ol>
                <li>適切な雇用、仕事関連のコミュニケーションおよび契約書への誘導</li>
                <li>労働管理</li>
                <li>保健福祉給付の提供</li>
                <li>緊急事態の場合の連絡先</li>
                <li>雇用チェック</li>
                <li>Tokutyに要求されるサービスの提供</li>
                <li>Tokutyらの情報の提供</li>
                <li>Tokutyの事業活動に関わる研究及びマーケティング活動及び署名の協力の要請。
                    また、弊社の出版物を転載したり、企業や企業に情報を提供したり、
                    さまざまな統計データやサンプル情報を分析情報として利用することもあります。
                    特定の個人を特定できないようにデータを処理して集約する際に、これを行います。</li>
                <li>Tokutyに提供された個人情報の更新および変更</li>
            </ol>

            <h3>第三者への個人情報の提供または開示</h3>
            <p>Tokutyは、以下の場合および「1.個人情報の使用」で指定された目的を除き、第三者に個人情報を提供しません。</p>
            <ol>
                <li>情報の所有者から同意を得た場合</li>
                <li>法令により情報が必要とされる場合</li>
                <li>個人情報が人命や財産を保護するために必要であり、情報所有者の同意を得ることが困難な場合。</li>
                <li>公衆衛生の改善や児童の健全な成長促進のために個人情報が特に必要とされ、
                    情報所有者の同意を得ることが困難な場合。法令で定める事務の実施において地方自治体又は政府との
                    協力のために個人情報が必要とされ、その者の同意を得て事務の執行を妨げるおそれがある場合</li>
            </ol>

            <h3>個人情報の開示</h3>
            <p>Tokutyは、合理的な期間および合理的な範囲において、開示されることを希望する個人情報所有者の要求に対処します。
                問題の人物が申請者であることを確認したら、それを行います。</p>

            <h3>個人情報の改訂</h3>
            <p>Tokutyは、Tokutyに提供された個人情報を修正、使用停止、削除、または追加することを
                希望する情報所有者の要求を合理的な期間かつ合理的な範囲で対処します。
                問題の人物が申請者であることを確認したら、それを行います。</p>

            <h3>個人情報の使用または削除の終了</h3>
            <p>Tokutyは、Tokutyに提供された個人情報の使用を終了または削除することを希望する
                情報所有者の要請に合理的な期間かつ合理的な範囲で対応します。問題の人物が申請者であることを確認したら、それを行います。
                個人情報の使用または削除が終了すると、当社は希望するサービスを提供することができない場合があります。
                ご理解とご協力をお願い申し上げます。
                当社は、適用される法律および規則に基づいて保持されている個人情報を終了または削除することができない場合があります。</p>

            <h3>お問い合わせの窓口</h3>
            <p>個人情報に関してご不明な点がございましたら、インフォメーションデスクまでご連絡ください。私たちの連絡先は以下の通りです。</p>

            <p>
                株式会社トクティー、個人情報管理委員会総務部<br/>
                E-mail：salesteam@tokuty.jp<br/>
                TEL：03-6453-6998
            </p>
            <p>弊社の営業時間は、月曜日から金曜日の午前10時から午後6時までです。</p>
        </div>
    )
}


const PolicyInEN : FunctionComponent<{}> = () => {
    return (
        <div>
            <h1>Pre-Registration Consent Procedures</h1>
            <h2>IN REGARD TO USER POLICY AND THE HANDLING OF PERSONAL INFORMATION</h2>
            <p>Tokuty Co., Ltd. (hereafter “Tokuty”), have established the following terms of use. 
                Please confirm the following and we hope for your understanding and consent. 
                Submission of personal information is optional, however, if you do not submit 
                information we may not be able to provide you with necessary information. 
                All applicants must come to our office for an interview prior to actual employment.</p>

            <h2>In regard to the handling of personal information</h2>
            <h3>1. Use of personal information</h3>
            <p>Tokuty will use his/her personal information for the following reasons. 
                When personal information is used beyond its original purpose,
                we will obtain prior consent from the information owner.</p>
            <p>Personal information refers to information containing the name and the date of birth of any person,
                other dates and statements and/or a number assigned specifically to that person, 
                which enable readers to identify that particular individual. 
                (Including information that may not be adequate by itself but can easily be referenced against 
                other information and help identify the person when accessed in conjunction with such other information).</p>
            <ol>
                <li>Inducement to suitable employment, work related communication and contract agreements</li>
                <li>Labor management</li>
                <li>Provision of health and welfare benefits</li>
                <li>Contacts in case of emergencies</li>
                <li>Employment check</li>
                <li>Provision of service requested to Tokuty</li>
                <li>Provision of information from Tokuty</li>
                <li>Research and marketing activities related to business activities of Tokuty 
                    and request for cooperation to signatures. We may also use information to 
                    reprint publications of our company or provide information to businesses and corporations, 
                    various statistical data or sample information as analytical information. 
                    We will do so upon processing and aggregating the data in a way that a particular individual 
                    cannot be identified.</li>
                <li>Renewal and modification of personal information provided to Tokuty</li>
            </ol>

            <h3>Provision or disclosure of personal information to a third party</h3>
            <p>Tokuty will not provide personal information to a third party except for 
                the following cases and for purposes specified in “1.use of personal information”.</p>
            <ol>
                <li>Cases in which we have consent from the information owner</li>
                <li>Cases in which the information is required by laws and regulations</li>
                <li>Cases in which the personal information is necessary to protect 
                    a human life or property and in which it is difficult to obtain the consent of the information owner.</li>
                <li>Cases in which the personal information is specially necessary 
                    for improving public health or promoting the sound growth of children and in which 
                    it is difficult to obtain the consent of the information owner.</li>
                <li>Cases in which the personal information is necessary for cooperating with 
                    local authorities or the government, in executing the affairs prescribed by laws 
                    and regulations and in which obtaining the consent of the person is likely to impede 
                    the execution of the affairs concerned</li>
            </ol>
            <h3>Disclosure of personal information</h3>
            <p>Tokuty will, in a reasonable period of time and to a reasonable extent, 
                address any request of a personal information owner who wishes to have 
                his / her information disclosed. We will do so upon confirming that 
                the person in question is the applicant him/her self.</p>

            <h3>Revision of personal information</h3>
            <p>Tokuty will, in a reasonable period of time and to a reasonable extent, 
                address any request of an information owner who wishes to correct, 
                stop the use of, delete or add his/her own personal information provided to Tokuty. 
                We will do so upon confirming that the person in question is the applicant him/her self.</p>

            <h3>Termination of use or deletion of personal information</h3>
            <p>Tokuty will, in a reasonable period of time and to a reasonable extent, 
                address any request of an information owner who wishes to terminate 
                the use of or delete his/her personal information provided to Tokuty. 
                We will do so upon confirming that the person in question is the applicant him/her self. 
                Upon the termination of use or deletion of personal information, 
                we may not be able to provide the service he/she desires. 
                We ask for your understanding and cooperation. We may not be able to terminate or delete 
                personal information held under applicable laws and regulations.</p>

            <h3>Contact point for inquires</h3>
            <p>If you have any questions or opinions regarding personal information, 
                please contact our information desk. Our contact details are as follows;</p>

            <p>
                Tokuty Co., Ltd., General Affairs Department, Personal Information Management Committee<br/>
                E-mail：salesteam@tokuty.jp<br/>
                TEL：03-6453-6998
            </p>

            <p>Our office hours are from 10am to 6pm, Monday to Friday.</p>
        </div>
    )
}

export default PrivacyPolicyPage;