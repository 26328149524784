import { FunctionComponent } from "react";
import { JobFormSectionProps } from "../type";
import { FieldCell, GridRow, GridRowsContainer, LabelCell, LocalNumberFieldSmall, LocalTextInputExtend, Note, RadioButtonsWrapper, Row10, Row5, UnitText } from "../commonStyle";
import Label from "../../../../core/components/Label";
import Radio from "../../../../core/components/Radio";
import TimeField from "../../../../core/components/TimeField";
import AmountOfTimeField from "../../../../core/components/AmountOfTimeField";
import styled from "styled-components";
import Row from "../../../../core/components/Row";
import { JobWorkStyle } from "../../../enums/job";
import { EcVariableWorkingHoursUnit } from "../../../../core/enums/employmentConditions";
import Text from "../../../../core/components/Text";
import { useTranslation } from "react-i18next";

interface WorkHoursSectionProps extends JobFormSectionProps {}

const LocalRow = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const ShiftItemsContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 12px;
  background-color: #FAFAFA;
`;

const ShiftItem = styled.div`
`;

const ShiftItemHeader = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Separator = styled.hr`
  flex-grow: 1;
  height: 1px;  
  border: none;
  background-color: #D5D5D5;
  border-color:#D5D5D5;
`;

const ShiftItemGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 100px) 1fr;
  align-items: center;
`;

const ShiftHeader = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
`;

const ShiftItemLabelCell = styled.div`
  display: flex;
  align-items: center;
  min-height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
`;

const ShiftItemFieldCell = styled.div`
  padding-left: 20px;
`;

const VariableWorkingHoursOptionsContainer = styled.div`
  margin-top: 15px;
  border-radius: 12px;
  padding: 20px;
  background-color: #F7F7F7;
`;

const WorkHoursSection: FunctionComponent<WorkHoursSectionProps> = ({
  job,
  onChange
}) => {
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });
  const { t: tCore } = useTranslation('translation', { keyPrefix: 'core' });
  
  return (
    <>
     <GridRowsContainer>
        {/* 一日の勤務時間 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('basic_work_hours')}
            </Label>
          </LabelCell>
          <FieldCell>
            <AmountOfTimeField 
              hours={job?.basicWorkHoursPerDay}
              minutes={job?.basicWorkMinutesPerDay}
              onHoursChange={value => onChange?.({ 
                basicWorkHoursPerDay: value, 
                ecWorkHoursNormalWorkingHour: value
              })}
              onMinutesChange={value => onChange?.({ 
                basicWorkMinutesPerDay: value,
                ecWorkHoursNormalWorkingMinute: value
              })}
              placeholderForHours="8"
              placeholderForMinutes="30"
            />
          </FieldCell>
        </GridRow>

        {/* 勤務形態 */}        
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('work_style')}
            </Label>
          </LabelCell>
          <FieldCell>
          <RadioButtonsWrapper>
              <Radio
                checked={job?.workStyle === JobWorkStyle.FixedHours}
                value={JobWorkStyle.FixedHours}
                onValueChange={value => onChange?.({ 
                  workStyle: value, 
                  ecWorkHoursShiftSystem: false
                })}
              >
                {tJob('fixed_hours')}
              </Radio>
              <Radio
                checked={job?.workStyle === JobWorkStyle.ShiftSystem}
                value={JobWorkStyle.ShiftSystem}
                onValueChange={value => onChange?.({ 
                  workStyle: value, 
                  ecWorkHoursShiftSystem: true
                })}
              >
                {tJob('shift_system')}
              </Radio>
            </RadioButtonsWrapper>

            { job?.workStyle === JobWorkStyle.ShiftSystem &&
              <ShiftItemsContainer>
                <ShiftItem>
                  <ShiftItemHeader>
                    <ShiftHeader>{tJob('shift', { index: 1 })}</ShiftHeader>
                    <Separator />
                  </ShiftItemHeader>
                  <ShiftItemGrid>
                    <ShiftItemLabelCell>
                      <Label>{tJob('job_working_hours')}</Label>
                    </ShiftItemLabelCell>
                    <ShiftItemFieldCell>
                      <Row10>
                        <TimeField
                          placeholderForHours="08"
                          placeholderForMinutes="00"
                          hours={job?.ecWorkHoursShiftSystem_1StartTimeHour}
                          minutes={job?.ecWorkHoursShiftSystem_1StartTimeMinute}
                          onHoursChange={value => onChange?.({ ecWorkHoursShiftSystem_1StartTimeHour: value })}
                          onMinutesChange={value => onChange?.({ ecWorkHoursShiftSystem_1StartTimeMinute: value })}
                        />
                        ~
                        <TimeField
                          placeholderForHours="17"
                          placeholderForMinutes="00"
                          hours={job?.ecWorkHoursShiftSystem_1EndTimeHour}
                          minutes={job?.ecWorkHoursShiftSystem_1EndTimeMinute}
                          onHoursChange={value => onChange?.({ ecWorkHoursShiftSystem_1EndTimeHour: value })}
                          onMinutesChange={value => onChange?.({ ecWorkHoursShiftSystem_1EndTimeMinute: value })}
                        />
                      </Row10>
                    </ShiftItemFieldCell>
                    <ShiftItemLabelCell>
                      <Label>{tJob('applicable_date')}</Label>
                    </ShiftItemLabelCell>
                    <ShiftItemFieldCell>
                      <LocalTextInputExtend 
                        placeholder="月曜日、火曜日"
                        value={job?.ecWorkHoursShiftSystem_1ApplicationDate ?? ""}
                        onTextChange={value => onChange?.({ ecWorkHoursShiftSystem_1ApplicationDate: value })}
                      />
                    </ShiftItemFieldCell>
                  </ShiftItemGrid>
                </ShiftItem>

                <ShiftItem>
                  <ShiftItemHeader>
                    <ShiftHeader>{tJob('shift', { index: 2 })}</ShiftHeader>
                    <Separator />
                  </ShiftItemHeader>
                  <ShiftItemGrid>
                    <ShiftItemLabelCell>
                      <Label>{tJob('job_working_hours')}</Label>
                    </ShiftItemLabelCell>
                    <ShiftItemFieldCell>
                      <Row10>
                        <TimeField
                          placeholderForHours="08"
                          placeholderForMinutes="00"
                          hours={job?.ecWorkHoursShiftSystem_2StartTimeHour}
                          minutes={job?.ecWorkHoursShiftSystem_2StartTimeMinute}
                          onHoursChange={value => onChange?.({ ecWorkHoursShiftSystem_2StartTimeHour: value })}
                          onMinutesChange={value => onChange?.({ ecWorkHoursShiftSystem_2StartTimeMinute: value })}
                        />
                        ~
                        <TimeField
                          placeholderForHours="17"
                          placeholderForMinutes="00"
                          hours={job?.ecWorkHoursShiftSystem_2EndTimeHour}
                          minutes={job?.ecWorkHoursShiftSystem_2EndTimeMinute}
                          onHoursChange={value => onChange?.({ ecWorkHoursShiftSystem_2EndTimeHour: value })}
                          onMinutesChange={value => onChange?.({ ecWorkHoursShiftSystem_2EndTimeMinute: value })}
                        />
                      </Row10>
                    </ShiftItemFieldCell>
                    <ShiftItemLabelCell>
                      <Label>{tJob('applicable_date')}</Label>
                    </ShiftItemLabelCell>
                    <ShiftItemFieldCell>
                      <LocalTextInputExtend 
                        placeholder="月曜日、火曜日"
                        value={job?.ecWorkHoursShiftSystem_2ApplicationDate ?? ""}
                        onTextChange={value => onChange?.({ ecWorkHoursShiftSystem_2ApplicationDate: value })}
                      />
                    </ShiftItemFieldCell>
                  </ShiftItemGrid>
                </ShiftItem>

                <ShiftItem>
                  <ShiftItemHeader>
                    <ShiftHeader>{tJob('shift', { index: 3 })}</ShiftHeader>
                    <Separator />
                  </ShiftItemHeader>
                  <ShiftItemGrid>
                    <ShiftItemLabelCell>
                      <Label>{tJob('job_working_hours')}</Label>
                    </ShiftItemLabelCell>
                    <ShiftItemFieldCell>
                      <Row10>
                        <TimeField
                          placeholderForHours="08"
                          placeholderForMinutes="00"
                          hours={job?.ecWorkHoursShiftSystem_3StartTimeHour}
                          minutes={job?.ecWorkHoursShiftSystem_3StartTimeMinute}
                          onHoursChange={value => onChange?.({ ecWorkHoursShiftSystem_3StartTimeHour: value })}
                          onMinutesChange={value => onChange?.({ ecWorkHoursShiftSystem_3StartTimeMinute: value })}
                        />
                        ~
                        <TimeField
                          placeholderForHours="17"
                          placeholderForMinutes="00"
                          hours={job?.ecWorkHoursShiftSystem_3EndTimeHour}
                          minutes={job?.ecWorkHoursShiftSystem_3EndTimeMinute}
                          onHoursChange={value => onChange?.({ ecWorkHoursShiftSystem_3EndTimeHour: value })}
                          onMinutesChange={value => onChange?.({ ecWorkHoursShiftSystem_3EndTimeMinute: value })}
                        />
                      </Row10>
                    </ShiftItemFieldCell>
                    <ShiftItemLabelCell>
                      <Label>{tJob('applicable_date')}</Label>
                    </ShiftItemLabelCell>
                    <ShiftItemFieldCell>
                      <LocalTextInputExtend 
                        placeholder="月曜日、火曜日"
                        value={job?.ecWorkHoursShiftSystem_3ApplicationDate ?? ""}
                        onTextChange={value => onChange?.({ ecWorkHoursShiftSystem_3ApplicationDate: value })}
                      />
                    </ShiftItemFieldCell>
                  </ShiftItemGrid>
                </ShiftItem>
              </ShiftItemsContainer>
            }
          </FieldCell>
        </GridRow>

        {/* 変形労働時間制 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('variable_working_hours_system')}
            </Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={job?.ecWorkHoursVariableWorkingHoursSystem === true}
                onValueChange={() => onChange?.({ ecWorkHoursVariableWorkingHoursSystem: true })}
              >
                {tCore('option_available')}
              </Radio>
              <Radio
                checked={job?.ecWorkHoursVariableWorkingHoursSystem === false}
                onValueChange={() => onChange?.({ ecWorkHoursVariableWorkingHoursSystem: false })}
              >
                {tCore('option_not_available')}
              </Radio>
            </RadioButtonsWrapper>
            { job?.ecWorkHoursVariableWorkingHoursSystem === true &&
              <VariableWorkingHoursOptionsContainer>
                <RadioButtonsWrapper>
                  <Radio
                    checked={job?.ecWorkHoursVariableWorkingHoursSystemUnit === EcVariableWorkingHoursUnit.Year}
                    value={EcVariableWorkingHoursUnit.Year}
                    onValueChange={value => onChange?.({ ecWorkHoursVariableWorkingHoursSystemUnit: value })}
                  >
                    {tJob('variable_working_hours_unit.1year')}
                  </Radio>
                  <Radio
                    checked={job?.ecWorkHoursVariableWorkingHoursSystemUnit === EcVariableWorkingHoursUnit.Month}
                    value={EcVariableWorkingHoursUnit.Month}
                    onValueChange={value => onChange?.({ ecWorkHoursVariableWorkingHoursSystemUnit: value })}
                  >
                    {tJob('variable_working_hours_unit.1month')}
                  </Radio>
                  <Radio
                    checked={job?.ecWorkHoursVariableWorkingHoursSystemUnit === EcVariableWorkingHoursUnit.Week}
                    value={EcVariableWorkingHoursUnit.Week}
                    onValueChange={value => onChange?.({ ecWorkHoursVariableWorkingHoursSystemUnit: value })}
                  >
                    {tJob('variable_working_hours_unit.1week')}
                  </Radio>
                </RadioButtonsWrapper>
              </VariableWorkingHoursOptionsContainer>
            }
          </FieldCell>
        </GridRow>

        {/* 労働日数（月平均） */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('working_days_monthly_average')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <LocalNumberFieldSmall 
                allowDecimal
                placeholder="20"
                value={job?.averageMonthlyWorkDays ?? ""}
                onValueChange={value => onChange?.({ averageMonthlyWorkDays: value })}
              />
                <LocalRow>
                  <UnitText>{tCore('units.days')}</UnitText>
                  <Note>（{tJob('decimals_allowed')}）</Note>
                </LocalRow>
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 残業時間（月平均） */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('monthly_overtime')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <LocalNumberFieldSmall 
                placeholder="10"
                value={job?.averageMonthlyOvertimeHours ?? ""}
                onValueChange={value => onChange?.({ averageMonthlyOvertimeHours: value })}
              />
              <UnitText>{tCore('units.hours')}</UnitText>  
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 休憩時間 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('break')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <LocalNumberFieldSmall 
                placeholder="105"
                value={job?.ecWorkHoursBreakTime ?? ""}
                onValueChange={value => onChange?.({ ecWorkHoursBreakTime: value })}
              />
              <UnitText>{tCore('units.minutes')}</UnitText>  
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 所定労働時間数（週）*/}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('scheduled_num_of_working_hours_week')}
            </Label>
          </LabelCell>
          <FieldCell>
            <AmountOfTimeField 
              placeholderForHours="40"
              placeholderForMinutes="00"
              hours={job?.ecWorkHoursWeeklyNormalWorkingHour}
              minutes={job?.ecWorkHoursWeeklyNormalWorkingMinute}
              onHoursChange={value => onChange?.({ ecWorkHoursWeeklyNormalWorkingHour: value })}
              onMinutesChange={value => onChange?.({ ecWorkHoursWeeklyNormalWorkingMinute: value })}
            />
          </FieldCell>
        </GridRow>

        {/* 所定労働時間数（月間）*/}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('scheduled_num_of_working_hours_monthly')}
            </Label>
          </LabelCell>
          <FieldCell>
            <AmountOfTimeField 
              placeholderForHours="160"
              placeholderForMinutes="00"
              hours={job?.ecWorkHoursMonthlyNormalWorkingHour}
              minutes={job?.ecWorkHoursMonthlyNormalWorkingMinute}
              onHoursChange={value => onChange?.({ ecWorkHoursMonthlyNormalWorkingHour: value })}
              onMinutesChange={value => onChange?.({ ecWorkHoursMonthlyNormalWorkingMinute: value })
              }
            />
          </FieldCell>
        </GridRow>

        {/* 所定労働時間数（年間）　*/}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('scheduled_num_of_working_hours_annual')}
            </Label>
          </LabelCell>
          <FieldCell>
            <AmountOfTimeField 
              placeholderForHours="1920"
              placeholderForMinutes="00"
              hours={job?.ecWorkHoursAnnualNormalWorkingHour}
              minutes={job?.ecWorkHoursAnnualNormalWorkingMinute}
              onHoursChange={value => onChange?.({ ecWorkHoursAnnualNormalWorkingHour: value })}
              onMinutesChange={value => onChange?.({ ecWorkHoursAnnualNormalWorkingMinute: value })}
            />
          </FieldCell>
        </GridRow>

        {/* 所定労働日数（週）*/}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('scheduled_num_of_working_days_week')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <LocalNumberFieldSmall 
                allowDecimal
                placeholder="3"
                value={job?.ecWorkHoursWeeklyNormalWorkingDay_1}
                onValueChange={value => onChange?.({ ecWorkHoursWeeklyNormalWorkingDay_1: String(value ?? "") })}
              />
              ~
              <LocalNumberFieldSmall
                allowDecimal
                placeholder="5"
                value={job?.ecWorkHoursWeeklyNormalWorkingDay_2}
                onValueChange={value => onChange?.({ ecWorkHoursWeeklyNormalWorkingDay_2: String(value ?? "") })}
              />
              <LocalRow>
                <UnitText>{tCore('units.days')}</UnitText>
                <Note>（{tJob('decimals_allowed')}）</Note>
              </LocalRow>
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 所定労働日数（月間）*/}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('scheduled_num_of_working_days_monthly')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <LocalNumberFieldSmall 
                allowDecimal
                placeholder="20"
                value={job?.ecWorkHoursMonthlyNormalWorkingDay}
                onValueChange={value => onChange?.({ ecWorkHoursMonthlyNormalWorkingDay: String(value ?? "") })}
              />
              <LocalRow>
                <UnitText>{tCore('units.days')}</UnitText>
                <Note>（{tJob('decimals_allowed')}）</Note>
              </LocalRow>
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 所定労働日数（年間）*/}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('scheduled_num_of_working_days_annual')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <LocalNumberFieldSmall 
                allowDecimal
                placeholder="240"
                value={job?.ecWorkHoursAnnualNormalWorkingDay}
                onValueChange={value => onChange?.({ ecWorkHoursAnnualNormalWorkingDay: String(value ?? "") })}
              />
              <LocalRow>
                <UnitText>{tCore('units.days')}</UnitText>
                <Note>（{tJob('decimals_allowed')}）</Note>
              </LocalRow>
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 所定外労働時間 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('overtime_working_hours')}
            </Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={job?.ecWorkHoursOvertimeWork === true}
                value={""}
                onValueChange={() => onChange?.({ ecWorkHoursOvertimeWork: true })}
              >
                {tJob("applicable")}
              </Radio>
              <Radio
                checked={job?.ecWorkHoursOvertimeWork === false}
                value={""}
                onValueChange={() => onChange?.({ ecWorkHoursOvertimeWork: false })}
              >
                {tJob("not_applicable")}
              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

      </GridRowsContainer>
    </>
  )
}

export default WorkHoursSection;