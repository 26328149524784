import { FunctionComponent, HTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import SearchBar from '../../../core/components/SearchBar';
import Button from '../../../core/components/Button';
import FilterButton from '../../../core/components/FilterButton';
import Label from '../../../core/components/Label';
import Select from '../../../core/components/Select';
import Field from '../../../core/components/Field';
import Checkbox from '../../../core/components/Checkbox';
import { useTranslation } from 'react-i18next';
import { MOBILE_DEVICE } from '../../../core/constants/styles';

type SearchPanelProps = HTMLAttributes<HTMLDivElement> & {
    status?: string;
    industry?: string;
    onStatusChange?: (status: string) => void;
    onIndustryChange?: (industry: string) => void;
    onSearch?: (keyword: string) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  background: #FFFFFF;
  border-bottom: 1px solid #E5E5E5;

  @media ${MOBILE_DEVICE} {
    padding: 20px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 20px;

  > *:not(:first-child) {
    margin-left: 20px;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  
  > *:not(:first-child) {
    margin-left: 60px;
  }
`;

const LocalSearchBar = styled(SearchBar)`
  width: 400px;
`;

const SearchPanel: FunctionComponent<SearchPanelProps> = ({
    status,
    industry,
    onStatusChange,
    onIndustryChange,
    onSearch,
    ...props
}) => {
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation();

    return (
        <Container {...props}>
            <SearchContainer>
                <LocalSearchBar onSearch={onSearch} />
                <FilterButton
                    style={{ display: 'none', marginLeft: '36px' }}
                    defaultChecked={expanded}
                    onToggle={() => setExpanded(!expanded)}
                />
            </SearchContainer>
            {expanded && (
                <>
                    <FilterContainer>
                        <Field>
                            <Label>{t('ui.status')}</Label>
                            <Select
                                placeholder="core.select"
                                value={status}
                                onValueChange={onStatusChange}
                            />
                        </Field>
                        <Field>
                            <Label>{t('tokuty.industry')}</Label>
                            <Select
                                placeholder="core.select"
                                options={[]}
                                value={industry}
                                onValueChange={onIndustryChange}
                            />
                        </Field>
                        <Checkbox style={{ display: 'none', marginBottom: '5px' }}>
                            {t('tokuty.action_required')}
                        </Checkbox>
                    </FilterContainer>
                    <ActionContainer>
                        <Button variant="secondary">{t('tokuty.apply_filter')}</Button>
                        <Button variant="tertiary">{t('tokuty.clear_filter')}</Button>
                        <Button variant="tertiary">{t('tokuty.save_filter')}</Button>
                    </ActionContainer>
                </>
            )}
        </Container>
    );
};

export default SearchPanel;