export const STATUS_OPTIONS_FOR_PARTNERS = [
    {
        label: 'scout.unread',
        value: 'unread',
    },
    {
        label: 'scout.read',
        value: 'read',
    },
    {
        label: 'scout.proposed',
        value: 'proposed',
    },
];

export const STATUS_OPTIONS_FOR_CANDIDATES = [
    {
        label: 'scout.unread',
        value: 'unread',
    },
    {
        label: 'scout.read',
        value: 'read',
    },
    {
        label: 'scout.applied',
        value: 'proposed',
    },
];