import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import Text from '../components/Text';
import Column from '../components/Column';
import Label from '../components/Label';
import TextInput from '../components/TextInput';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { useReducerContext } from '../contexts/ReducerContext';
import ACTIONS from '../constants/actions';
import { createUser } from '../apis/user';
import { MOBILE_DEVICE } from '../constants/styles';
import Section from '../components/Section';
import TextLink from '../components/TextLink';
import Message from '../components/Message';

interface RegistrationPageProps {
}

const LocalPage = styled(Page)`
  > * {
    width: 500px;
  }

  @media ${MOBILE_DEVICE} {
    > * {
      width: 100%;
    }
  }
`;

const LocalSection = styled(Section)`
  padding: 50px 75px 67px 75px;
  
  @media ${MOBILE_DEVICE} {
    padding: 20px;
    background: transparent;
    border: none;
  }
`;

const LocalTextInput = styled(TextInput)`
  width: 100%;
`;

const LocalButton = styled(Button)`
  width: 100%;
`;

const LocalMessage = styled(Message)`
  background: #E5E5E5;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;

const InfoContainer = styled(Column)`
  gap: 10px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 20px;
  }
`

const InputContainer = styled(Column)`
  gap: 3px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 5px;
  }
`

const ActionContainer = styled(Column)`
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const RegistrationPage: FunctionComponent<RegistrationPageProps> = () => {
    const { dispatch } = useReducerContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleSubmit = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
        });
        try {
            await createUser(email);
            navigate('/sent', {
                state: {
                    email,
                },
            });
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    return (
        <LocalPage>
            <LocalSection>
                <Container>
                    <InfoContainer>
                        <PageHeading>
                            {t('core.create_account')}
                        </PageHeading>
                        <Text>
                            {t('core.enter_email')}
                        </Text>
                    </InfoContainer>

                    <InputContainer>
                        <Label>{t('core.email')}</Label>
                        <LocalTextInput
                            type="email"
                            value={email}
                            onTextChange={setEmail}
                        />
                    </InputContainer>

                    <ActionContainer>
                        <LocalButton onClick={handleSubmit}>
                            {t('core.send_email')}
                        </LocalButton>
                        <TextLink href="/login">
                            {t('core.go_login')}
                        </TextLink>
                    </ActionContainer>
                    <LocalMessage>
                        <TextLink href="/contact">
                            {t('core.recruitment_agency_prompt')}
                        </TextLink>
                    </LocalMessage>
                </Container>
            </LocalSection>
        </LocalPage>
    );
};

export default RegistrationPage;