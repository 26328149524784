import { Fragment, FunctionComponent, useEffect, useMemo, useState } from "react"

import styled from "styled-components";
import Button from "../../../core/components/Button";
import Text from "../../../core/components/Text";
import { ReactComponent as AlertIcon } from "../../../assets/icon-warning-banner.svg";
import { ReactComponent as TrashCanIcon } from "../../../assets/icon-trash-can.svg";
import Checkbox from "../../../core/components/Checkbox";
import UploadButton from "../../../core/components/UploadButton";
import { AdditionalInformationType, Candidate, CandidateAIAnalysisResponse } from "../../types/api";

import { useReducerContext } from "../../../core/contexts/ReducerContext";
import ACTIONS from "../../../core/constants/actions";
import { getCandidate, updateCandidate } from "../../apis/candidate";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Accordion from "../../../core/components/Accordion";
import { analyzeCandidateDataByAI } from "../../../candidate/apis/analysis";
import { GenderFromAI, JapaneseConversationSkillsFromAI, JlptLevelFromAI, VisaStatusFromAI } from "../../enums/ai";
import { CandidateGender, CandidateJapaneseConversationSkills, CandidateJlptLevel, CandidateVisaStatus } from "../../enums/candidate";
import { isEmpty } from "../../../core/utils";


interface AiAnalysisSectionProps {
  additionalInformationTypes: AdditionalInformationType[];
  registerNewCandidate: () => Promise<Candidate>;
  onUpdateCandidate: (candidate: Candidate) => void;
}

interface UploadFileNameProps {
  isUnprocessable: boolean;
}

interface NoteTextProps {
  alert?: boolean;
}

type CandidateFile = {
  file: File | null;
  label: string;
  typeId: number;
  canUpload: boolean;
  isUnprocessable: boolean;
}

enum ExcludingAdditionalInfoTypeID {
  TrainingCert3Kyu = 5,
  TrainingEvalSheet = 6,
  Others = 9
}

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(auto, 230px) 1fr;
  gap: 20px;
  border-bottom: 1px solid #D5D5D5;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 30px;
`;

const FileUploadPanelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 10px;
`;

const FileUploadPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FileUploadControl = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const UploadFileItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const FileNameText = styled(Text)<UploadFileNameProps>`
  color: ${props => props.isUnprocessable ? "#e93232" : "#444"};
`;

const TrashCanAnchor = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

const NoteList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Note = styled.li`   
  position: relative;
  padding-left: 15px;

  &::before {
    content: "•";
    color: #8a8e94;
    font-size: 15px;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const NoteText = styled(Text)<NoteTextProps>`
  font-size: 12px;
  color: ${props => props.alert ? "#e93232" : "#8a8e94"};
`;

const AlertBanner = styled.div`
  display: grid;
  border-radius: 5px;
  background: #FFF0F0;
  grid-template-columns: max-content 1fr;
  gap: 10px;
  padding: 10px 20px;
  align-items: center;

  & * {
    color: #e93232;
    fill: #e93232;
  }
`;

const Separator = styled.hr`
  height: 1px;
  width: 100%;
  margin: 0;
  border: none;
  background:  rgb(0 0 0 / 0.05);
`;

const GridFooter = styled.div`
  display: grid;
  width: 100%;
  grid-column: span 2;
  grid-template-columns: 1fr max-content;
  gap: 40px;
  padding: 10px 20px 30px 20px;
  align-items: center;
`;

const AiAnalysisSection: FunctionComponent<AiAnalysisSectionProps> = ({
  registerNewCandidate,
  onUpdateCandidate,
  additionalInformationTypes
}) => {
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; //5MB
  const ANALYSIS_ATTEMPT_LIMIT = 10;
  const { dispatch } = useReducerContext();
  const { t: tAi } = useTranslation('translation', { keyPrefix: 'candidate.ai_analysis' });
  const { t: tDoc } = useTranslation('translation', { keyPrefix: 'candidate.additional_information_types' });
  const navigate = useNavigate();
  const [candidateFiles, setCandidateFiles] = useState<CandidateFile[]>([]);
  const [attemptCount, setAttemptCount] = useState(0);
  const [candidateId, setCandidateId] = useState<number | null>(null);
  const isReachedAnalysisAttemptLimit = attemptCount >= ANALYSIS_ATTEMPT_LIMIT;
  const isNoFileToAnalyze = candidateFiles.filter(candFile => candFile.canUpload && candFile.file).length === 0;
  const isThereUnprocessableFile = candidateFiles.some(candFile => candFile.isUnprocessable);
  const hasTriedAnalysis = attemptCount > 0;
  const additionalTypesExcluded = useMemo(() => additionalInformationTypes.filter(type => (
    ![
      ExcludingAdditionalInfoTypeID.TrainingCert3Kyu,
      ExcludingAdditionalInfoTypeID.TrainingEvalSheet,
      ExcludingAdditionalInfoTypeID.Others
    ].includes(type.id)
  )) , [additionalInformationTypes]);
  const statusText = attemptCount === 0
    ? tAi("note_import_candidate_info")
    : tAi("candidate_info_import_count", { count: attemptCount });
  const validateFileSize = (file: File): boolean => {
    if (file.size > FILE_SIZE_LIMIT) {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: tAi("file_upload_failure"),
        },
      });
      return false;
    }

    return true;
  }
  const updateCandidateFile = (index: number, newCandVal: Partial<CandidateFile>) => {
    setCandidateFiles(prev =>
      prev.map((candFile, i) => (
        i === index
          ? { ...candFile, ...newCandVal }
          : candFile
      ))
    );
  }

  const onClickAnalyze = async () => {
    let createdCandidateId = candidateId;
    let isAnalysisSuccessful = false;

    dispatch({
      type: ACTIONS.START_LOADING,
      payload: {
        message: `
          <strong>${tAi("reading_candidate_data")}</strong><br/>
          ${tAi("please_wait")}
        `,
      },
    });

    try {
      if (!createdCandidateId) {
        const candidate = await registerNewCandidate();
        createdCandidateId = candidate.id;
        navigate(`/candidates/${createdCandidateId}/edit`, { replace: true });
        setCandidateId(createdCandidateId);
      }

      const candidateFilesToUpload = candidateFiles
        .filter(candFile => candFile.file && candFile.typeId)
        .map(candFile => ({ file: candFile.file as File, typeId: candFile.typeId }))
      const response = await analyzeCandidateDataByAI(createdCandidateId, candidateFilesToUpload);
      const failedFiles = response.failedFiles;

      updateCandidateData(createdCandidateId, response);

      if (failedFiles) {
        setCandidateFiles(prev => 
          prev.map(candFile => ({
            ...candFile,
            isUnprocessable: failedFiles.includes(candFile.file?.name || "")
          }))
        );
      }

      isAnalysisSuccessful = true;
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "success",
          header: tAi("data_read_success"),
          message: tAi("candidate_data_read_success"),
        },
      });
  
    } catch {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          header: tAi("data_read_failure"),
          message: tAi("candidate_data_read_failure"),
        },
      });
    } finally {
      if (createdCandidateId) {
        try {
          const candidate = await getCandidate(createdCandidateId);
          setAttemptCount(
            candidate.aiAnalyzeSuccessCount + 
            candidate.aiAnalyzeFailureCount
          );
        } catch {}
      }
    }

    if (isAnalysisSuccessful) {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "success",
          header: tAi("data_read_success"),
          message: tAi("candidate_data_read_success"),
        },
      });
    }

    dispatch({ type: ACTIONS.STOP_LOADING });
  }

  const updateCandidateData = async (candidateId: number, analyzedData: CandidateAIAnalysisResponse) => {
    const data: Partial<Candidate> = {};

    if (analyzedData.enFirstName)
      data.enFirstName = analyzedData.enFirstName;

    if (analyzedData.enMiddleName)
      data.enMiddleName = analyzedData.enMiddleName;

    if (analyzedData.enLastName)
      data.enLastName = analyzedData.enLastName;

    if (analyzedData?.jaFirstName) 
      data.jaFirstName = analyzedData.jaFirstName;

    if (analyzedData?.jaMiddleName)
      data.jaMiddleName = analyzedData.jaMiddleName;

    if (analyzedData?.jaLastName)
      data.jaLastName = analyzedData.jaLastName;

    if (analyzedData.age)
      data.age = Number(analyzedData.age);

    switch (analyzedData.gender) {
      case GenderFromAI.Male:
        data.gender = CandidateGender.Male;
        break;
      case GenderFromAI.Female:
        data.gender = CandidateGender.Female;
        break;
    }

    if (analyzedData.nationalityId)
      data.nationalityId = Number(analyzedData.nationalityId);

    switch (analyzedData.visaStatus) {
      case VisaStatusFromAI.Student:
        data.visaStatus = CandidateVisaStatus.Student;
        break;
      case VisaStatusFromAI.SpecifiedSkilledWorker1:
        data.visaStatus = CandidateVisaStatus.SpecifiedSkilledWorker1;
        break;
      case VisaStatusFromAI.SpecifiedSkilledWorker2:
        data.visaStatus = CandidateVisaStatus.SpecifiedSkilledWorker2;
        break;
      case VisaStatusFromAI.Trainee:
        data.visaStatus = CandidateVisaStatus.Trainee;
        break;
      case VisaStatusFromAI.DesignatedActivities:
        data.visaStatus = CandidateVisaStatus.DesignatedActivities;
        break;
      case VisaStatusFromAI.LivingOverseas:
        data.visaStatus = CandidateVisaStatus.LivingOverseas;
        break;
      case VisaStatusFromAI.Other:
        data.visaStatus = CandidateVisaStatus.Other;
        break;
    }

    if (analyzedData.currentAddress)
      data.currentAddress = analyzedData.currentAddress;

    switch (analyzedData.japaneseConversationSkills) {
      case JapaneseConversationSkillsFromAI.CanSpeakBasicPhrases:
        data.japaneseConversationSkills = 
          CandidateJapaneseConversationSkills.CanSpeakBasicPhrases;
        break;
      case JapaneseConversationSkillsFromAI.CanCarryOnEverydayConversation:
        data.japaneseConversationSkills = 
          CandidateJapaneseConversationSkills.CanCarryOnEverydayConversations;
        break;
      case JapaneseConversationSkillsFromAI.Fluent:
        data.japaneseConversationSkills = 
          CandidateJapaneseConversationSkills.Fluent;
        break;
    }
  
    switch (analyzedData.jlptLevel) {
      case JlptLevelFromAI.N1:
        data.jlptLevel = CandidateJlptLevel.N1;
        break;
      case JlptLevelFromAI.N2:
        data.jlptLevel = CandidateJlptLevel.N2;
        break;
      case JlptLevelFromAI.N3:
        data.jlptLevel = CandidateJlptLevel.N3;
        break;
      case JlptLevelFromAI.N4:
        data.jlptLevel = CandidateJlptLevel.N4;
        break;
      case JlptLevelFromAI.N5:
        data.jlptLevel = CandidateJlptLevel.N5;
        break;
    }

    if (!isEmpty(data)) {
      const updatedCandData = await updateCandidate(candidateId, data);
      onUpdateCandidate(updatedCandData);
    }
  }

  useEffect(() => {
    setCandidateFiles(
      additionalTypesExcluded.map(type => ({
        file: null,
        label: tDoc(type.tkey),
        typeId: type.id,
        canUpload: true,
        isUnprocessable: false
      }))
    );
  }, [additionalTypesExcluded]);


  return (
    <>
      <Accordion
        active={false}
        headingText={tAi("create_from_file")}
        additionalComponentInHeading={
          <NoteText alert={hasTriedAnalysis}>{statusText}</NoteText>
        }
        style={{ marginTop: 12 }}
      >
        <Grid>
          <LabelContainer>
            <Text><strong>{tAi("create_from_file")}</strong></Text>
          </LabelContainer>

          <FileUploadPanelsContainer>
            
            {candidateFiles.map((candFile, i) => (
              <Fragment key={`candidate-file-${i}`}>
                <FileUploadPanel>
                  <FileUploadControl>
                    <Checkbox
                      checked={candFile.canUpload}
                      onValueChange={val => 
                        updateCandidateFile(i, { canUpload: val })
                      }
                    >
                      {candFile.label}
                    </Checkbox>
                    <UploadButton 
                      buttonText={tAi("select_file")}
                      onFileChange={file => 
                        validateFileSize(file) && updateCandidateFile(i, { file })
                      }
                    />
                  </FileUploadControl>
                  { candFile.file &&
                    <UploadFileItem>
                      <FileNameText isUnprocessable={candFile.isUnprocessable}>
                        {candFile.file.name}
                      </FileNameText>
                      <TrashCanAnchor onClick={() => 
                        updateCandidateFile(i, { file: null })
                      }>
                        <TrashCanIcon />
                      </TrashCanAnchor>
                    </UploadFileItem>
                  }
                </FileUploadPanel>
                <Separator />
              </Fragment>
            ))}

            { isThereUnprocessableFile &&
              <>
                <AlertBanner>
                  <AlertIcon />
                  <Text>{tAi("read_error_occurred")}</Text>
                </AlertBanner>
                <Separator />
              </>
            }

            <NoteList>
              <Note>
                <NoteText>
                  {tAi("assist_create_candidate_info")}
                </NoteText>
              </Note>
              <Note>
                <NoteText>
                  {tAi("low_accuracy_warning")}
                </NoteText>
              </Note>
            </NoteList>
          </FileUploadPanelsContainer>
        </Grid>

        <GridFooter>
          <AlertBanner>
            <AlertIcon />
            <Text>{tAi("max_import_attempts", { count: ANALYSIS_ATTEMPT_LIMIT })}</Text>
          </AlertBanner>
          <Button
            disabled={
              isNoFileToAnalyze ||
              isReachedAnalysisAttemptLimit
            }
            onClick={onClickAnalyze}
          >
            {tAi("execute")}
          </Button>
        </GridFooter>
      </Accordion>
    </>
  )
}

export default AiAnalysisSection