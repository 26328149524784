import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../components/Button";
import ACTIONS from "../constants/actions";
import { useReducerContext } from "../contexts/ReducerContext";
import EmploymentOfferTemplate from "../components/EmploymentOfferTemplate";
import Column from "../components/Column";
import { MOBILE_DEVICE } from "../constants/styles";
import { getEmploymentOfferLetter as getEmploymentOfferLetterWithToken } from "../../proposal/apis/public/documents";
import { getEmploymentOfferLetter } from "../../proposal/apis/documents";
import { EmploymentOfferLetter } from "../types/api";
import PageWithoutNavigator from "../components/PageWithoutNavigator";

interface EmploymentOfferViewPageProps {}

const LocalPage = styled(PageWithoutNavigator)`
  @media ${MOBILE_DEVICE} {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
  }

  @media print and (max-width: 1100px) {
    visibility: hidden;
  }
`;

const LocalColumn = styled(Column)`
  gap: 20px;
`;

const PageHeading = styled.h2`
  font-weight: 500;
  font-size: 26px;
  line-height: 37px;
  margin: 0;
`;

const PrintButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  margin-left: auto;
  min-width: 120px;

  @media ${MOBILE_DEVICE} {
    width: auto;
  }
`;

const EmploymentOfferContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  overflow: hidden;

  @media print {
    visibility: visible;
    z-index: 1000;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0;
  }
`;

const EmploymentOfferViewPage: FunctionComponent<
  EmploymentOfferViewPageProps
> = () => {
  const { state, dispatch } = useReducerContext();
  const { proposalId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const isPublicAccess = !state.isLoggedIn && !!token;
  const isPublicAccessWithoutToken = !state.isLoggedIn && !token;
  const [employmentOffer, setEmploymentOffer] = useState<EmploymentOfferLetter>();
  const [isUsingOwnSignature, setIsUsingOwnSignature] = useState<boolean>(false);
  const [issuedDate, setIssuedDate] = useState<string>();
  const [candidateName, setCandidateName] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [sealUrl, setSealUrl] = useState<string>();

  useEffect(() => {
    if (isPublicAccessWithoutToken) {
      navigate("/login");
      return;
    }

    (async () => {
      dispatch({ type: ACTIONS.START_LOADING });
      try {
        const employmentOfferLetter = isPublicAccess 
          ? await getEmploymentOfferLetterWithToken(proposalId!, token!)
          : await getEmploymentOfferLetter(proposalId!);

        setEmploymentOffer(employmentOfferLetter);
        setIsUsingOwnSignature(employmentOfferLetter.employer.company.useOwnSignatureOnEmploymentOffer);
        setIssuedDate(employmentOfferLetter.employmentOfferSentAt);
        setCandidateName([
          employmentOfferLetter.candidate.enLastName,
          employmentOfferLetter.candidate.enFirstName, 
          employmentOfferLetter.candidate.enMiddleName
        ].join(" "));
        setCompanyName(employmentOfferLetter.employer.company.name);
        setAddress([
            employmentOfferLetter.employer.company.prefecture,
            employmentOfferLetter.employer.company.cityWard,
            employmentOfferLetter.employer.company.town,
            employmentOfferLetter.employer.company.addressNumber,

            //This address value is optional unlike the others above
            employmentOfferLetter.employer.company.buildingName ?? ""
        ].join(" "));
        setSealUrl(employmentOfferLetter.employer.company.companySealFile?.url ?? "");
      } catch (e) {  
        navigate("/login");
      } finally {
        dispatch({ type: ACTIONS.STOP_LOADING });
      }
    })();
  }, []);

  return (
    <LocalPage>
      {employmentOffer && (
        <LocalColumn>
          <PageHeading>{candidateName}さんの内定証明書</PageHeading>
          <PrintButton onClick={() => window.print()}>印刷</PrintButton>
          <EmploymentOfferContainer>
            <EmploymentOfferTemplate 
                isUsingOwnSignature={isUsingOwnSignature} 

                //This page can be viewed even before the employment offer is sent for previewing purposes
                //so, the issued date can be null
                issuedDate={issuedDate ?? new Date().toISOString()}

                candidateName={candidateName}
                companyName={companyName}
                address={address}
                sealUrl={sealUrl}
            />
          </EmploymentOfferContainer>
        </LocalColumn>
      )}
    </LocalPage>
  );
};

export default EmploymentOfferViewPage;
