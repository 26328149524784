import React, {FunctionComponent} from "react";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import {
  CustomLabel, FieldCell, GridRow,
  LabelCell,
  RadioButtonsWrapper
} from "../commonStyle";
import Radio from "../../../../core/components/Radio";

interface EntrustingRowProps extends SupportPlanFormProps {
  propertyName: SupportPlanExtractType<boolean>;
}

const EntrustingRow: FunctionComponent<EntrustingRowProps> = (
  {
    supportPlan,
    propertyName,
}) => {
  return (
    <GridRow>
      <LabelCell>
        <CustomLabel required>委託の有無</CustomLabel>
      </LabelCell>
      <FieldCell>
        <RadioButtonsWrapper>
          <Radio
            readOnly
            checked={
              supportPlan?.[propertyName] === true
            }
          >
            有
          </Radio>
          <Radio
            readOnly
            checked={
              supportPlan?.[propertyName] === false
            }
          >
            無
          </Radio>
        </RadioButtonsWrapper>
      </FieldCell>
    </GridRow>
  );
};

export default EntrustingRow;