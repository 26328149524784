import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { VISA_STATUS_ITEMS } from '../../constants/options';
import ButtonGroup from '../../../core/components/ButtonGroup';
import CategoryHeading from '../../../core/components/CategoryHeading';
import Column from '../../../core/components/Column';
import Field from '../../../core/components/Field';
import Label from '../../../core/components/Label';
import Radio from '../../../core/components/Radio';
import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Select, { SelectOption } from '../../../core/components/Select';
import Text from '../../../core/components/Text';
import TextInput from '../../../core/components/TextInput';
import UploadButton from '../../../core/components/UploadButton';
import { Nationality } from '../../../core/types/api';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import DatePicker from '../../../core/components/DatePicker';
import { CandidateGender, CandidateJapaneseConversationSkills, CandidateJlptLevel } from '../../enums/candidate';

interface BasicInformationSectionProps {
    nationalities?: Nationality[],
    avatar?: File | string;
    firstNameEn?: string;
    middleNameEn?: string;
    lastNameEn?: string;
    firstNameJp?: string;
    middleNameJp?: string;
    lastNameJp?: string;
    age?: string;
    gender?: string;
    nationality?: string;
    visaStatus?: string;
    address?: string;
    conversationSkills?: string;
    jlptLevel?: string;
    workHours?: string;
    nearestPossibleDate?: Date;
    onAvatarChange?: (avatar: File) => void;
    onFirstNameEnChange?: (firstNameEn: string) => void;
    onMiddleNameEnChange?: (middleNameEn: string) => void;
    onLastNameEnChange?: (lastNameEn: string) => void;
    onFirstNameJpChange?: (firstNameJp: string) => void;
    onMiddleNameJpChange?: (middleNameJp: string) => void;
    onLastNameJpChange?: (lastNameJp: string) => void;
    onAgeChange?: (age: string) => void;
    onGenderChange?: (gender: string) => void;
    onNationalityChange?: (nationality: string) => void;
    onVisaStatusChange?: (visaStatus: string) => void;
    onAddressChange?: (address: string) => void;
    onConversationSkillsChange?: (conversationSkills: string) => void;
    onJlptLevelChange?: (jlptLevel: string) => void;
    onWorkHoursChange?: (workHours: string) => void;
    onNearestPossibleDateChange?: (nearestPossibleDate: Date) => void;
}

const Block = styled(Column)`
  margin-top: 8px;
  
  > *:not(:first-child) {
    margin-top: 20px;
  }

  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const Avatar = styled.img`
  width: 101px;
  height: 101px;
  border-radius: 5px;
  object-fit: cover;
`;

const Placeholder = styled.div`
  width: 101px;
  height: 101px;
  background: #D5D5D5;
  border-radius: 5px;
  border: 1px solid #E93232;
`;

const BasicInformationSection: FunctionComponent<BasicInformationSectionProps> = ({
    nationalities,
    avatar,
    firstNameEn,
    middleNameEn,
    lastNameEn,
    firstNameJp,
    middleNameJp,
    lastNameJp,
    age,
    gender,
    nationality,
    visaStatus,
    address,
    conversationSkills,
    jlptLevel,
    workHours,
    nearestPossibleDate,
    onAvatarChange,
    onFirstNameEnChange,
    onMiddleNameEnChange,
    onLastNameEnChange,
    onFirstNameJpChange,
    onMiddleNameJpChange,
    onLastNameJpChange,
    onAgeChange,
    onGenderChange,
    onNationalityChange,
    onVisaStatusChange,
    onAddressChange,
    onConversationSkillsChange,
    onJlptLevelChange,
    onWorkHoursChange,
    onNearestPossibleDateChange
}) => {
    const [nationalityOptions, setNationalityOptions] = useState<SelectOption[]>([]);
    const isEmpty = (value: any) => !(!!value);
    const { t } = useTranslation();
    const isMobileDevice = useMediaQuery({
        query: MOBILE_DEVICE,
    });

    useEffect(() => {
        if (nationalities) {
            const nationalityOptions = nationalities.map((nationality) => ({
                label: nationality.name,
                value: String(nationality.id),
            }));
            setNationalityOptions(nationalityOptions);
        } else {
            setNationalityOptions([]);
        }
    }, [nationalities]);

    return (
        <Section>
            <SectionHeading>{t('candidate.basic_information')}</SectionHeading>

            <Field>
                <Label required>{t('candidate.candidate_profile_photo')}</Label>
                <Row center>
                    {avatar
                        ? <Avatar src={typeof avatar === 'string' ? avatar : URL.createObjectURL(avatar)} />
                        : <Placeholder />
                    }
                    <UploadButton
                        style={{ marginLeft: 20 }}
                        file={avatar}
                        onFileChange={onAvatarChange}
                    />
                </Row>
                {   isEmpty(avatar) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('candidate.upload_profile_picture')}</Text>
                }

            </Field>

            <CategoryHeading>{t('candidate.name_in_english')}</CategoryHeading>
            <Block>
                <Field>
                    <Label required>{t('candidate.first_name')}</Label>
                    <TextInput
                        style={{ width: isMobileDevice ? 'inherit' : 340 }}
                        value={firstNameEn}
                        error={isEmpty(firstNameEn)}
                        onTextChange={onFirstNameEnChange}
                    />
                    {   isEmpty(firstNameEn) &&
                        <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                    }
                </Field>
                <Field>
                    <Label>{t('candidate.middle_name')}</Label>
                    <TextInput
                        style={{ width: isMobileDevice ? 'inherit' : 340 }}
                        value={middleNameEn}
                        onTextChange={onMiddleNameEnChange}
                    />
                </Field>
                <Field>
                    <Label required>{t('candidate.last_name')}</Label>
                    <TextInput
                        style={{ width: isMobileDevice ? 'inherit' : 340 }}
                        value={lastNameEn}
                        error={isEmpty(lastNameEn)}
                        onTextChange={onLastNameEnChange}
                    />
                    {   isEmpty(lastNameEn) &&
                        <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                    }
                </Field>
            </Block>

            <CategoryHeading>{t('candidate.name_in_japanese')}</CategoryHeading>
            <Block>
                <Field>
                    <Label>{t('candidate.first_name')}</Label>
                    <TextInput
                        style={{ width: isMobileDevice ? 'inherit' : 340 }}
                        value={firstNameJp}
                        onTextChange={onFirstNameJpChange}
                    />
                </Field>
                <Field>
                    <Label>{t('candidate.middle_name')}</Label>
                    <TextInput
                        style={{ width: isMobileDevice ? 'inherit' : 340 }}
                        value={middleNameJp}
                        onTextChange={onMiddleNameJpChange}
                    />
                </Field>
                <Field>
                    <Label>{t('candidate.last_name')}</Label>
                    <TextInput
                        style={{ width: isMobileDevice ? 'inherit' : 340 }}
                        value={lastNameJp}
                        onTextChange={onLastNameJpChange}
                    />
                </Field>
            </Block>

            <Field>
                <Label required>{t('candidate.age')}</Label>
                <TextInput
                    style={{ width: isMobileDevice ? 'inherit' : 45 }}
                    type="number"
                    value={age}
                    error={isEmpty(age)}
                    onTextChange={onAgeChange}
                />
                {   isEmpty(age) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                }
            </Field>

            <Field>
                <Label required>{t('candidate.gender')}</Label>
                <ButtonGroup style={{ marginTop: '8px' }}>
                    <Radio
                        checked={gender === CandidateGender.Male}
                        value={CandidateGender.Male}
                        error={isEmpty(gender)}
                        onValueChange={onGenderChange}
                    >
                        {t('core.male')}
                    </Radio>
                    <Radio
                        checked={gender === CandidateGender.Female}
                        value={CandidateGender.Female}
                        error={isEmpty(gender)}
                        onValueChange={onGenderChange}
                    >
                        {t('core.female')}
                    </Radio>
                </ButtonGroup>
                {   isEmpty(gender) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.radio_button')}</Text>
                }
            </Field>

            <Field>
                <Label required>{t('candidate.nationality')}</Label>
                <Select
                    placeholder="core.select"
                    style={{ width: isMobileDevice ? 'inherit' : 150 }}
                    options={nationalityOptions}
                    value={nationality}
                    error={isEmpty(nationality)}
                    onValueChange={onNationalityChange}
                />
                {   isEmpty(nationality) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.dropdown_menu')}</Text>
                }
            </Field>

            <Field>
                <Label required>{t('candidate.candidate_visa_status')}</Label>
                <Select
                    placeholder="core.select"
                    style={{ width: isMobileDevice ? 'inherit' : 150 }}
                    options={VISA_STATUS_ITEMS}
                    value={visaStatus}
                    error={isEmpty(visaStatus)}
                    onValueChange={onVisaStatusChange}
                />
                {   isEmpty(visaStatus) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.dropdown_menu')}</Text>
                }
            </Field>

            <Field style={{ width: '100%' }}>
                <Label required>{t('candidate.current_address')}</Label>
                <TextInput
                    style={{ width: '100%' }}
                    value={address}
                    error={isEmpty(address)}
                    onTextChange={onAddressChange}
                />
                {   isEmpty(address) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                }
            </Field>

            <Field>
                <Label required>{t('candidate.japanese_conversation_skills')}</Label>
                <ButtonGroup style={{ marginTop: '8px' }}>
                    <Radio
                        value={CandidateJapaneseConversationSkills.CanSpeakBasicPhrases}
                        error={isEmpty(conversationSkills)}
                        checked={conversationSkills === CandidateJapaneseConversationSkills.CanSpeakBasicPhrases}
                        onValueChange={onConversationSkillsChange}
                    >
                        {t('candidate.can_speak_basic_phrases')}
                    </Radio>
                    <Radio
                        value={CandidateJapaneseConversationSkills.CanCarryOnEverydayConversations}
                        error={isEmpty(conversationSkills)}
                        checked={conversationSkills === CandidateJapaneseConversationSkills.CanCarryOnEverydayConversations}
                        onValueChange={onConversationSkillsChange}
                    >
                        {t('candidate.can_carry_on_everyday_conversations')}
                    </Radio>
                    <Radio
                        value={CandidateJapaneseConversationSkills.Fluent}
                        error={isEmpty(conversationSkills)}
                        checked={conversationSkills === CandidateJapaneseConversationSkills.Fluent}
                        onValueChange={onConversationSkillsChange}
                    >
                        {t('candidate.fluent')}
                    </Radio>
                </ButtonGroup>
                {   isEmpty(conversationSkills) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.radio_button')}</Text>
                }
            </Field>

            <Field>
                <Label required>{t('candidate.jlpt_level')}</Label>
                <ButtonGroup style={{ marginTop: '8px' }}>
                    <Radio
                        checked={jlptLevel === CandidateJlptLevel.N1}
                        value={CandidateJlptLevel.N1}
                        error={isEmpty(jlptLevel)}
                        onValueChange={onJlptLevelChange}
                    >
                        N1
                    </Radio>
                    <Radio
                        checked={jlptLevel === CandidateJlptLevel.N2}
                        value={CandidateJlptLevel.N2}
                        error={isEmpty(jlptLevel)}
                        onValueChange={onJlptLevelChange}
                    >
                        N2
                    </Radio>
                    <Radio
                        checked={jlptLevel === CandidateJlptLevel.N3}
                        value={CandidateJlptLevel.N3}
                        error={isEmpty(jlptLevel)}
                        onValueChange={onJlptLevelChange}
                    >
                        N3
                    </Radio>
                    <Radio
                        checked={jlptLevel === CandidateJlptLevel.N4}
                        value={CandidateJlptLevel.N4}
                        error={isEmpty(jlptLevel)}
                        onValueChange={onJlptLevelChange}
                    >
                        N4
                    </Radio>
                    <Radio
                        checked={jlptLevel === CandidateJlptLevel.N5}
                        value={CandidateJlptLevel.N5}
                        error={isEmpty(jlptLevel)}
                        onValueChange={onJlptLevelChange}
                    >
                        N5
                    </Radio>
                </ButtonGroup>
                {   isEmpty(jlptLevel) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.radio_button')}</Text>
                }
            </Field>

            <Field style={{ width: '100%' }}>
                <Label required>{t('candidate.current_work_hours')}</Label>
                <TextInput
                    style={{ width: '100%' }}
                    placeholder="09:00-18:00"
                    value={workHours}
                    error={isEmpty(workHours)}
                    onTextChange={onWorkHoursChange}
                />
                {   isEmpty(workHours) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                }
            </Field>

            <Field>
                <Label required>{t('candidate.nearest_possible_date')}</Label>
                <DatePicker
                    selected={nearestPossibleDate}
                    onChange={onNearestPossibleDateChange}
                    error={isEmpty(nearestPossibleDate)}
                    customInput={<TextInput style={{ width: '165px' }} />}
                />
                {   isEmpty(nearestPossibleDate) &&
                    <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
                }
            </Field>
        </Section>
    );
};

export default BasicInformationSection;