import { HTMLAttributes, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SearchBar from '../../../core/components/SearchBar';
import Button from '../../../core/components/Button';
import FilterButton from '../../../core/components/FilterButton';
import Label from '../../../core/components/Label';
import MultiSelect from '../../../core/components/MultiSelect';
import Select, { SelectOption } from '../../../core/components/Select';
import Field from '../../../core/components/Field';
import {
    CONVERSATION_SKILLS_OPTIONS,
    GENDER_ITEMS,
    LANGUAGE_LEVEL_ITEMS
} from '../../constants/options';
import { useJobPostOptions } from '../../../core/hooks';

type SearchPanelProps = HTMLAttributes<HTMLDivElement> & {
    detailed?: boolean;
    keyword?: string;
    locationOptions?: SelectOption[];
    locations?: string[];
    industry?: string;
    jobTypes?: string[];
    gender?: string;
    conversationSkill?: string;
    jlptLevel?: string;
    onDetailedChange?: (detailed: boolean) => void;
    onKeywordChange?: (keyword: string) => void;
    onLocationsChange?: (locations: string[]) => void;
    onIndustryChange?: (industry: string) => void;
    onJobTypesChange?: (jobType: string[]) => void;
    onGenderChange?: (gender: string) => void;
    onConversationSkillChange?: (conversationSkill: string) => void;
    onJlptLevelChange?: (jlptLevel: string) => void;
    onSearch?: () => void;
    onResize?: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  background: #FFFFFF;
  border-bottom: 1px solid #E5E5E5;
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 20px;

  > *:not(:first-child) {
    margin-left: 20px;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  
  > *:not(:first-child) {
    margin-left: 60px;
  }
`;

const LocalSearchBar = styled(SearchBar)`
  width: 400px;
`;


const SearchPanel = forwardRef<HTMLDivElement, SearchPanelProps>(({
    detailed,
    keyword,
    locationOptions,
    locations,
    industry,
    jobTypes,
    gender,
    conversationSkill,
    jlptLevel,
    onDetailedChange,
    onKeywordChange,
    onLocationsChange,
    onIndustryChange,
    onJobTypesChange,
    onGenderChange,
    onConversationSkillChange,
    onJlptLevelChange,
    onSearch,
    onResize,
    ...props
}, ref) => {
    const [expanded, setExpanded] = useState(false);
    const { industryOptions, jobTypeOptionsDic } = useJobPostOptions();
    const { t } = useTranslation();

    const clearFilters = () => {
        onLocationsChange && onLocationsChange([]);
        onIndustryChange && onIndustryChange('');
        onJobTypesChange && onJobTypesChange([]);
        onGenderChange && onGenderChange('');
        onConversationSkillChange && onConversationSkillChange('');
        onJlptLevelChange && onJlptLevelChange('');
    };

    useEffect(() => {
        setTimeout(() => {
            onResize && onResize();
        }, 0);
    }, [expanded])

    return (
        <Container ref={ref} {...props}>
            <SearchContainer>
                <LocalSearchBar onChange={onKeywordChange} onSearch={onSearch} />
                <FilterButton
                    style={{ display: 'none', marginLeft: '20px' }}
                    defaultChecked={expanded}
                    onToggle={() => setExpanded(!expanded)}
                />
            </SearchContainer>
            {expanded && (
                <>
                    <FilterContainer>
                        <Field>
                            <Label>{t('ui.location')}</Label>
                            <MultiSelect
                                placeholder="core.select"
                                options={locationOptions}
                                value={locations}
                                onValueChange={onLocationsChange}
                            />
                        </Field>
                        <Field>
                            <Label>{t('tokuty.industry')}</Label>
                            <Select
                                placeholder="core.select"
                                options={industryOptions}
                                value={industry}
                                onValueChange={onIndustryChange}
                            />
                        </Field>
                        <Field>
                            <Label>{t('tokuty.job_type')}</Label>
                            <MultiSelect
                                placeholder="core.select"
                                disabled={!industry}
                                options={industry ? jobTypeOptionsDic[industry] : []}
                                value={jobTypes}
                                onValueChange={onJobTypesChange}
                            />
                        </Field>
                        <Field>
                            <Label>{t('tokuty.gender')}</Label>
                            <Select
                                placeholder="core.select"
                                options={GENDER_ITEMS}
                                value={gender}
                                onValueChange={onGenderChange}
                            />
                        </Field>
                        <Field>
                            <Label>{t('tokuty.japanese_conversation_skills')}</Label>
                            <Select
                                placeholder="core.select"
                                options={CONVERSATION_SKILLS_OPTIONS}
                                value={conversationSkill}
                                onValueChange={onConversationSkillChange}
                            />
                        </Field>
                        <Field>
                            <Label>{t('tokuty.jlpt_level')}</Label>
                            <Select
                                placeholder="core.select"
                                options={LANGUAGE_LEVEL_ITEMS}
                                value={jlptLevel}
                                onValueChange={onJlptLevelChange}
                            />
                        </Field>
                    </FilterContainer>
                    <ActionContainer>
                        <Button variant="secondary" onClick={onSearch}>
                            {t('tokuty.apply_filter')}
                        </Button>
                        <Button variant="tertiary" onClick={clearFilters}>
                            {t('tokuty.clear_filter')}
                        </Button>
                        <Button variant="tertiary">{t('tokuty.save_filter')}</Button>
                    </ActionContainer>
                </>
            )}
        </Container>
    );
});

export default SearchPanel;