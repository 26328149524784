import { FunctionComponent, useEffect, useState } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  SectionHeading,
  LabelContainer,
  RadioButtonsContainer,
} from "../../commonStyles";
import Select from "../../../../../core/components/Select";
import { FormBlockSectionProps } from "../../types";
import { useJobPostCategory, useJobPostOptions } from "../../../../../core/hooks";
import Radio from "../../../../../core/components/Radio";
import { LocalTextInputExtend } from "../../../../../job/pages/JobFormPage/commonStyle";
import { useReducerContext } from "../../../../../core/contexts/ReducerContext";
interface TypeOfWorkSectionProps extends FormBlockSectionProps {}

const TypeOfWorkSection: FunctionComponent<TypeOfWorkSectionProps> = ({
  employmentConditions: ec,
  onChange,
  jobPost
}) => {
  const { state } = useReducerContext();
  const jobPostCategory = useJobPostCategory();
  const { industryOptions } = useJobPostOptions();
  const [industryType, setIndustryType] = useState<string>("");
  const [changedIndustryType, setChangedIndustryType] = useState<string>("");

  useEffect(() => {

    if (ec?.jobTypeId) {
      const industryId = jobPostCategory.getIndustryIdByJobTypeId(ec.jobTypeId);
      setIndustryType(industryId);
    }

    if (ec?.changedJobTypeId) {
      const industryId = jobPostCategory.getIndustryIdByJobTypeId(ec.changedJobTypeId);
      setChangedIndustryType(industryId);
    }
  }, [ec?.jobTypeId, ec?.changedJobTypeId]);


  //This is a workaround to keep track of the industry type selected by the user
  //because a Select field for Job type no longer exists in the form
  const findFirstJobTypeByIndustryId = (industryId: number) => {
    const jobTypes = state.industries[industryId]?.jobTypes;
    
    return (!jobTypes || jobTypes.length === 0) 
      ? ""
      : jobTypes[0].id;
  }

  return (
    <FormSection>
      <SectionHeading>業務内容</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>分野</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <Select 
            placeholder="選択する"
            value={industryType}
            onValueChange={val => {
              setIndustryType(val);
              const firstJobType = findFirstJobTypeByIndustryId(val);
              firstJobType && onChange?.({ jobTypeId: firstJobType });
            }}
            options={industryOptions}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>業務区分</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <LocalTextInputExtend
            placeholder="例：電気機組み立て"
            value={ec?.businessDivision ?? ""}
            onTextChange={val => onChange?.({ businessDivision: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>業務区分（外国語で入力）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <LocalTextInputExtend
            placeholder="例：電気機組み立て"
            value={ec?.i18nBusinessDivision ?? ""}
            onTextChange={val => onChange?.({ i18nBusinessDivision: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>雇入れ後の変更の可能性</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
                checked={ec?.jobChangesAfterEmployment === true}
                onValueChange={() => onChange?.({ jobChangesAfterEmployment: true })}
            >
              あり
            </Radio>
            <Radio
                checked={ec?.jobChangesAfterEmployment === false}
                onValueChange={() => onChange?.({ jobChangesAfterEmployment: false })}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>分野</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <Select 
            disabled={ec?.jobChangesAfterEmployment !== true}
            placeholder="選択する"
            value={
              ec?.jobChangesAfterEmployment === true
                ? changedIndustryType
                : ""
            }
            onValueChange={val => {
              setChangedIndustryType(val);
              const firstJobType = findFirstJobTypeByIndustryId(val);
              firstJobType && onChange?.({ changedJobTypeId: firstJobType });
            }}
            options={industryOptions}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>業務区分</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <LocalTextInputExtend
            disabled={ec?.jobChangesAfterEmployment !== true}
            placeholder="例：電気機組み立て"
            value={
              ec?.jobChangesAfterEmployment === true
                ? ec?.changedBusinessDivision ?? ""
                : ""
            }
            onTextChange={val => onChange?.({ changedBusinessDivision: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>業務区分（外国語で入力）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <LocalTextInputExtend
            disabled={ec?.jobChangesAfterEmployment !== true}
            placeholder="例：電気機組み立て"
            value={
              ec?.jobChangesAfterEmployment === true
                ? ec?.changedI18nBusinessDivision ?? ""
                : ""
            }
            onTextChange={val => onChange?.({ changedI18nBusinessDivision: val })}
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default TypeOfWorkSection;
