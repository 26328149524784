import React, {FunctionComponent, useState} from "react";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import {CustomLabel, DatePickerWrapper, FieldCell, GridRow, LabelCell, LocalTextInput} from "../commonStyle";
import DatePicker from "../../../../core/components/DatePicker";
import {isEmpty} from "../../../../core/utils";
import ErrorMessage from "./ErrorMessage";
import styled from "styled-components";
import moment from "moment";

interface ScheduleDateInitializedTextRowProps extends SupportPlanFormProps {
  propertyName: SupportPlanExtractType<string>;
  initialValue: string;
}

const LocalDatePickerTextInput = styled(LocalTextInput)`
  min-width: 728px;
`;

const ScheduleDateInitializedTextRow: FunctionComponent<ScheduleDateInitializedTextRowProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
    propertyName,
    initialValue
}) => {
  const [autoFocusScheduleDateContent, setAutoFocusScheduleDateContent] = useState(false)

  return (
    <>
      <GridRow>
        <LabelCell>
          <CustomLabel required>実施予定時期</CustomLabel>
        </LabelCell>
        <FieldCell>
          <DatePickerWrapper>
            {
              (
                supportPlan?.[propertyName]
                && !moment(supportPlan[propertyName]).isValid()
              ) ? (
                <LocalDatePickerTextInput
                  value={supportPlan?.[propertyName] ? supportPlan[propertyName] : ""}
                  onClick={() => {
                    onChangeSupportPlan?.({[propertyName]: ""})
                    setAutoFocusScheduleDateContent(true)
                  }}
                /> ) : (
                <DatePicker
                  placeholder="2000/01/23"
                  selected={
                    supportPlan?.[propertyName]
                      ? new Date(supportPlan?.[propertyName] as string)
                      : undefined
                  }
                  onChange={(val) => {
                    onChangeSupportPlan?.({[propertyName]: val.toISOString()})
                  }}
                  customInput={<LocalDatePickerTextInput/>}
                  error={isEmpty(supportPlan?.[propertyName])}
                  onBlur={(e) => {
                    if (isEmpty(e.target.value))
                      onChangeSupportPlan?.({[propertyName]: initialValue})
                  }}
                  autoFocus={autoFocusScheduleDateContent}
                />)
            }
          </DatePickerWrapper>
          {isEmpty(supportPlan?.[propertyName]) &&
            <ErrorMessage>入力してください</ErrorMessage>
          }
        </FieldCell>
      </GridRow>
    </>
  );
};

export default ScheduleDateInitializedTextRow;