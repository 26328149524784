import { FunctionComponent, HTMLAttributes, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Text from './Text';
import { MOBILE_DEVICE } from '../constants/styles';
import { useMediaQuery } from 'react-responsive';

interface EnhancedLinkProps extends HTMLAttributes<HTMLDivElement> {
    disabled?: boolean;
    to: string;
    icon?: string;
    activeIcon?: string;
    onActiveChange?: (active: boolean) => void;
}

interface ContainerProps {
    disabled?: boolean;
    active?: boolean;
}

const Icon = styled.img`
  display: none;
  
  @media ${MOBILE_DEVICE} {
    display: block;
  }
`;

const LocalText = styled(Text)`
  @media ${MOBILE_DEVICE} {
    display: none;
  }
`;

const Container = styled.div<ContainerProps>`
  padding: 8px;
  outline: none;
  cursor: ${(props: ContainerProps) => props.disabled ? 'not-allowed' : 'pointer'};

  ${LocalText} {
    color: ${(props: ContainerProps) => props.disabled
      ? '#999999'
      : props.active ? '#FF730D' : '#444444'
    };
  }
  
  @media ${MOBILE_DEVICE} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LocalLink = styled(Link)`
  text-decoration: none;
  outline: none;
`;

const EnhancedLink: FunctionComponent<EnhancedLinkProps> = ({
    disabled,
    to,
    icon,
    activeIcon,
    children,
    onActiveChange,
    ...props
}) => {
    const { pathname } = useLocation();
    const active = pathname.startsWith(to);
    const isMobileDevice = useMediaQuery({
        query: MOBILE_DEVICE,
    });
    const isActive = pathname.startsWith(to);

    useEffect(() => {
      onActiveChange?.(isActive);
    }, [isActive])

    return (disabled
        ? (
            <Container disabled={disabled} active={isActive} {...props}>
                <LocalText>
                    {children}
                </LocalText>
            </Container>
        ) : (
            <LocalLink to={to}>
                <Container disabled={disabled} active={active} {...props}>
                    <Icon src={active ? activeIcon : icon} />
                    <LocalText
                        style={{ display: isMobileDevice && icon && activeIcon ? 'none' : 'block' }}
                    >
                        {children}
                    </LocalText>
                </Container>
            </LocalLink>
        )
    );
};

export default EnhancedLink;