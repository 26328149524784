export enum GenderFromAI {
  Male = 1,
  Female = 2,
}

export enum VisaStatusFromAI {
  Student = 0,
  SpecifiedSkilledWorker1 = 1,
  SpecifiedSkilledWorker2 = 6,
  Trainee = 2,
  DesignatedActivities = 3,
  Other = 4,
  LivingOverseas = 5,
}

export enum JapaneseConversationSkillsFromAI {
  CanSpeakBasicPhrases = 0,
  CanCarryOnEverydayConversation = 1,
  Fluent = 2,
}

export enum JlptLevelFromAI {
  NotApplicable = 0,
  N1 = 1,
  N2 = 2,
  N3 = 3,
  N4 = 4,
  N5 = 5,
}