import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type CategoryHeadingProps = HTMLAttributes<HTMLHeadingElement> & {
    children?: ReactNode;
};

export const Container = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  color: #444444;
`;

const CategoryHeading: FunctionComponent<CategoryHeadingProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default CategoryHeading;