import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { Container as Row } from './Row';
import { Container as Section } from './Section';
import Footer from './Footer';

type PageProps = HTMLAttributes<HTMLDivElement> & {
    children?: ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding-top: 43px; //padding-bottom is not used because the footer is contained 
  background: #FAF6F4;
  overflow: auto;
  
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 43px; //This gives space between the footer and the content 
  width: 800px;
  flex-grow: 1;

  > ${Row}:not(:first-child) {
    margin-top: 20px;
  }

  > ${Section}:not(:first-child) {
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Page: FunctionComponent<PageProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            <Content>{children}</Content>
            <Footer/>
        </Container>
    );
};

export default Page;