import { FunctionComponent } from "react";
import { JobFormSectionProps } from "../type";
import { FieldCell, GridRow, GridRowsContainer, LabelCell, LocalMultiSelect, LocalMultiTextInput, LocalNumberFieldExtraSmall, LocalSelect, RadioButtonsWrapper, Row5 } from "../commonStyle";
import Label from "../../../../core/components/Label";
import Radio from "../../../../core/components/Radio";
import { SelectOption } from "../../../../core/components/Select";
import { LANGUAGE_LEVEL_ITEMS } from "../../../constants/options";
import { JobPreferredGender } from "../../../enums/job";
import { useTranslation } from "react-i18next";

interface CandidateConditionsSectionProps extends JobFormSectionProps {
  nationalityOptions: SelectOption[];
}

const CandidateConditionsSection: FunctionComponent<CandidateConditionsSectionProps> = ({
  job,
  onChange,
  nationalityOptions
}) => {
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });
  const { t: tCore } = useTranslation('translation', { keyPrefix: 'core' })

  return (
    <>
      <GridRowsContainer>
        {/* 日本語能力試験（JLPT）レベル */}        
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('jlpt_level')}
            </Label>
          </LabelCell>
          <FieldCell>
            <LocalSelect 
              placeholder={tCore('select')}
              options={LANGUAGE_LEVEL_ITEMS}
              value={job?.jlptLevel}
              onValueChange={value => onChange?.({ jlptLevel: value })}
            />
          </FieldCell>
        </GridRow>

        {/* 好ましい性別 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('preferred_gender')}
            </Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={job?.preferredGender === JobPreferredGender.NotAny}
                value={JobPreferredGender.NotAny}
                onValueChange={value => onChange?.({ preferredGender: value })}
              >
                {tJob('preferred_none')}
              </Radio>
              <Radio
                checked={job?.preferredGender === JobPreferredGender.Male}
                value={JobPreferredGender.Male}
                onValueChange={value => onChange?.({ preferredGender: value })}
              >
                {tCore('male')}
              </Radio>
              <Radio
                checked={job?.preferredGender === JobPreferredGender.Female}
                value={JobPreferredGender.Female}
                onValueChange={value => onChange?.({ preferredGender: value })}
              >
                {tCore('female')}
              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

        {/* 好ましい国籍 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('preferred_nationality')}
            </Label>
          </LabelCell>
          <FieldCell>
            <LocalMultiSelect 
              placeholder={tCore('select')}
              options={nationalityOptions}
              value={job?.preferredNationalityIds?.map(id => id.toString())}
              onValueChange={value => onChange?.({ preferredNationalityIds: value })}
            />
          </FieldCell>
        </GridRow>

        {/* 好ましい年齢 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('preferred_age_range')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <LocalNumberFieldExtraSmall 
                placeholder="21"
                value={job?.preferredAgeFrom ?? ""}
                onValueChange={value => onChange?.({ preferredAgeFrom: value })}
              />
              ~
              <LocalNumberFieldExtraSmall 
                placeholder="40"
                value={job?.preferredAgeTo ?? ""}
                onValueChange={value => onChange?.({ preferredAgeTo: value })}
              />
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 必須条件 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('required_conditions')}
            </Label>
          </LabelCell>
          <FieldCell>
            <LocalMultiTextInput 
              placeholder={tJob('required_conditions_description')}
              value={job?.requiredConditions ?? ""}
              onTextChange={value => onChange?.({ requiredConditions: value })}
            />
          </FieldCell>
        </GridRow>

        {/* 歓迎条件 */}
        <GridRow
          style={{ marginBottom: 30 }}
        >
          <LabelCell>
            <Label>
              {tJob('preferred_conditions')}
            </Label>
          </LabelCell>
          <FieldCell>
            <LocalMultiTextInput 
              value={job?.preferredConditions ?? ""}
              onTextChange={value => onChange?.({ preferredConditions: value })}
            />
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  )
}

export default CandidateConditionsSection;