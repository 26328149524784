import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import styled from 'styled-components';

interface PanelButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
}

const Container = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #444444;
  border-radius: 20px;
  box-sizing: border-box;
  outline: none;
  
  :disabled {
    background-color: #D5D5D5;
    border-color: #999999;
  }
  
  :hover {
    background-color: #FFDFD1;
    border: 2px solid #FFC194;
  }
  
  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const PanelButton: FunctionComponent<PanelButtonProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default PanelButton;