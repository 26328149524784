import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ActionButton from './ActionButton';
import { User } from '../types/api';

interface UserListTableProps extends HTMLAttributes<HTMLDivElement> {
    users: User[];
    invitations?: User[];
    onInvitationResend?: (index: number) => void;
    onInvitationDiscard?: (index: number) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #E5E5E5;
  border: 1px solid #D5D5D5;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-top: none;
  cursor: pointer;
  
  &:hover {
    background: #FFDFD1;
    border-color: #FF730D;
    border-top: 1px solid #FF730D;
  }
`;

const Item = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Name = styled(Item)`
  flex: 1;
`;

const Email = styled(Item)`
  flex: 2;
`;

const Role = styled(Item)`
  flex: 1;
`;

const Actions = styled(Item)`
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  
  > *:not(:first-child) {
    margin-left: 10px;
  }
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 21px;
  padding: 0 10px;
  color: #444444;
  background: #E5E5E5;
  border-radius: 30px;
`;

const UserListTable: FunctionComponent<UserListTableProps> = ({
    users,
    invitations,
    onInvitationResend,
    onInvitationDiscard,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Container {...props}>
            <Header>
                <Name>{t('core.name')}</Name>
                <Email>{t('core.email')}</Email>
                <Role />
                <Actions />
            </Header>
            {invitations && invitations.map((invitation, index) => (
                <Row key={invitation.id}>
                    <Name>
                        <Label>{t('tokuty.invite_pending')}</Label>
                    </Name>
                    <Email>{invitation.email}</Email>
                    <Role />
                    <Actions>
                        <ActionButton
                            variant="inline"
                            onClick={() => onInvitationResend && onInvitationResend(index)}
                        >
                            {t('tokuty.resend')}
                        </ActionButton>
                        <ActionButton
                            variant="inline"
                            color="red"
                            onClick={() => onInvitationDiscard && onInvitationDiscard(index)}
                        >
                            {t('tokuty.discard')}
                        </ActionButton>
                    </Actions>
                </Row>
            ))}
            {users.map((user) => (
                <Row key={user.id}>
                    <Name>
                        {[user.firstName, user.lastName].filter(item => !!item).join(' ') || '-'}
                    </Name>
                    <Email>{user.email}</Email>
                    <Role />
                    <Actions />
                </Row>
            ))}
        </Container>
    );
};

export default UserListTable;