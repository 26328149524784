import { FunctionComponent, HTMLAttributes, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Text from '../../../core/components/Text';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import { useComponentVisible } from '../../../core/hooks';

type SelectProps = HTMLAttributes<HTMLDivElement> & {
  initialValue: boolean;
  onValueChange: (value: boolean) => void;
};

type ContainerProps = {
  isScoutable: boolean;
  isMenuOpening: boolean;
};

type MenuProps = {
  visible: boolean;
};

const Container = styled.div<ContainerProps>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  min-width: 87px;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${(props: ContainerProps) => {
    if (props.isScoutable) 
      return props.isMenuOpening? "#FFC194" : "#FF9E57";
    else
      return props.isMenuOpening? "#C4C4C4" : "#999999";
  }};
  border: 2px solid transparent;
  border-radius: 5px;
  border-color: ${(props: ContainerProps) => {
    if (props.isScoutable)
      return props.isMenuOpening? "#FF9E57" : "transparent";
    else
      return props.isMenuOpening? "#999999" : "transparent";
  }};

  &:hover {
    background-color: ${(props: ContainerProps) => 
      props.isScoutable? "#FFC194" : "#C4C4C4"};
  }
  
  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const LocalText = styled(Text)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #FFFFFF;
  margin-right: 10px;
`

const Menu = styled.div<MenuProps>`
  position: absolute;
  top: calc(100% + 2px); //2px comes from the border width
  left: 0;
  min-width: 100%;
  max-height: 170px;
  padding: 4px 0;
  background: white;
  border-radius: 3px;
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.2);
  overflow: auto;
  visibility: ${(props: MenuProps) => props.visible ? 'visible' : 'hidden'};
  z-index: 9;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 30px;
  padding: 0 8px;
  white-space: nowrap;
  
  &:hover {
    color: white;
    background: #FF730D;
  }
`;


const Arrow = (props: { isMenuOpen: boolean }) => {
  const src = props.isMenuOpen? "/images/arrow-up.svg" : "/images/arrow-down.svg";
  return <img alt="arrow" src={src} />
}


const ScoutableSelect: FunctionComponent<SelectProps> = ({
  initialValue,
  onValueChange,
}) => {
  const { t } = useTranslation();
  const { ref, visible, setVisible } = useComponentVisible(false);
  const [receiveScout, setReceiveScout] = useState(initialValue);
  const handleContainerClick = () => setVisible(!visible);
  const MENU_OPTIONS = [
    { 
      label: t("candidate.scout.seeking_job"),
      value: true
    },
    {
      label: t("candidate.scout.not_seeking_job"),
      value: false
    }
  ]
  const handleItemClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const newValue = (event.target as HTMLDivElement).dataset.value === 'true';
    setReceiveScout(newValue);
    onValueChange && onValueChange(newValue);
    setVisible(false);
  };

  return (
    <Container
      onClick={handleContainerClick}
      isMenuOpening={visible}
      isScoutable={receiveScout}
    >
      <LocalText>
        { receiveScout? t("candidate.scout.seeking") : t("candidate.scout.not_seeking")}
      </LocalText>
      <Arrow isMenuOpen={visible}/>
      <Menu ref={ref} visible={visible}>
        {MENU_OPTIONS.map((option, i) => (
          <Item
            key={`scout-receive-option-${i}`}
            onClick={handleItemClick}
            data-value={option.value}
          >
            {t(option.label)}
          </Item>
        ))}
      </Menu>
    </Container>
  )
};

export default ScoutableSelect;
