import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Column from '../components/Column';
import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import Section from '../components/Section';
import SubCategoryHeading from '../components/SubCategoryHeading';
import Text from '../components/Text';
import TextInput from '../components/TextInput';
import { MOBILE_DEVICE } from '../constants/styles';
import Button from '../components/Button';
import TextLink from '../components/TextLink';
import Row from '../components/Row';
import { useReducerContext } from '../contexts/ReducerContext';
import ACTIONS from '../constants/actions';
import { requestPasswordReset } from '../apis/user';

interface ForgotPasswordPageProps {
}

const LocalPage = styled(Page)`
  > * {
    width: 500px;
  }
  
  @media ${MOBILE_DEVICE} {
    > * {
      width: 100%;
    }
  }
`;

const LocalSection = styled(Section)`
  padding: 50px 75px 67px 75px;
  
  @media ${MOBILE_DEVICE} {
    padding: 20px;
    background: transparent;
    border: none;
  }
`;

const LocalTextInput = styled(TextInput)`
  width: 100%;
`;

const LocalButton = styled(Button)`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  
  @media ${MOBILE_DEVICE} {
    gap: 20px;
  }
`;

const InfoContainer = styled(Column)`
  gap: 10px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 20px;
  }
`

const InputContainer = styled(Column)`
  gap: 3px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 5px;
  }
`

const ActionContainer = styled(Column)`
  align-items: center;
  gap: 20px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 10px;
  }
`;

const LinkContainer = styled(Row)`
  align-items: center;
  gap: 5px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 5px;
  }
`;

const ForgotPasswordPage: FunctionComponent<ForgotPasswordPageProps> = () => {
    const { dispatch } = useReducerContext();
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);

    const handleSend = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
        });
        try {
            await requestPasswordReset(email);
            if (sent) {
                dispatch({
                    type: ACTIONS.SET_PROMPT,
                    payload: {
                        type: 'success',
                        message: t('core.email_resent'),
                    },
                })
            } else {
                setSent(true);
            }
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    return (
        <LocalPage>
            <LocalSection>
                <Container>
                    {sent
                        ? (
                            <>
                                <InfoContainer>
                                    <PageHeading>
                                        {t('core.check_email_title')}
                                    </PageHeading>
                                    <Text>
                                        {t('core.check_email_description', { email })}
                                    </Text>
                                </InfoContainer>
                                <LinkContainer>
                                    <Text style={{ color: '#999999' }}>
                                        {t('core.resend_prompt')}
                                    </Text>
                                    <TextLink
                                        href="#"
                                        onClick={handleSend}
                                    >
                                        {t('core.click_to_resend')}
                                    </TextLink>
                                </LinkContainer>
                            </>
                        )
                        : (
                            <>
                                <InfoContainer>
                                    <PageHeading>
                                        {t('core.forgot_password_title')}
                                    </PageHeading>
                                    <Text>
                                        {t('core.forgot_password_description')}
                                    </Text>
                                </InfoContainer>

                                <InputContainer>
                                    <SubCategoryHeading>
                                        {t('core.email')}
                                    </SubCategoryHeading>
                                    <LocalTextInput
                                        value={email}
                                        onTextChange={setEmail}
                                    />
                                </InputContainer>

                                <ActionContainer>
                                    <LocalButton
                                        disabled={!email}
                                        onClick={handleSend}
                                    >
                                        {t('core.reset_password')}
                                    </LocalButton>
                                    <TextLink>
                                        {t('core.back_to_login')}
                                    </TextLink>
                                </ActionContainer>
                            </>
                        )
                    }
                </Container>
            </LocalSection>
        </LocalPage>
    );
};

export default ForgotPasswordPage;