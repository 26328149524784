import styled from "styled-components";
import Row from "../../../../core/components/Row";
import { FunctionComponent, ReactNode } from "react";


interface DescriptionFieldProps {
  label?: string;
  value?: string | ReactNode;
}

const FieldRow = styled(Row)`
  gap: 10px;
  width: 100%;
`;

const FieldLabelContainer = styled.div`
  max-width: 50%;
  word-break: break-all;
`;

const FieldInputContainer = styled.div`
  border-bottom: 0.5px solid black;
  flex-grow: 1;
  word-break: break-all;
`;

const DescriptionField: FunctionComponent<DescriptionFieldProps> = ({
  label,
  value
}) => {
  return (

    <FieldRow>
      <FieldLabelContainer>{label}</FieldLabelContainer>
      <FieldInputContainer>
        {value || <span>&nbsp;</span> }
      </FieldInputContainer>
    </FieldRow>
  )
}

export default DescriptionField;