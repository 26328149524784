import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import AdditionalInformationSection from './AdditionalInformationSection';
import BasicInformationSection from './BasicInformationSection';
import ContactInformationSection from './ContactInformationSection';
import {
    createCandidate,
    createCandidateAdditionalInformation,
    createCandidateImage, deleteCandidateAdditionalInformation,
    deleteCandidateImage,
    getAdditionalInformationTypes,
    getCandidate,
    updateCandidate
} from '../../apis/candidate';
import { AdditionalInformationType, Candidate } from '../../types/api';
import ActionBar from '../../../core/components/ActionBar';
import Button from '../../../core/components/Button';
import Page from '../../../core/components/Page';
import PageHeading from '../../../core/components/PageHeading';
import ACTIONS from '../../../core/constants/actions';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import SkillsSection from './SkillsSection';
import moment from 'moment';
import { isValidEmailAddress } from '../../../core/utils';
import { useNationalities } from '../../../core/hooks';
import {LanguageCode} from "../../../core/enums/language";
import AiAnalysisSection from './AIAnalysisSection';

interface CandidateFormPageProps {
}

const LocalPage = styled(Page)`
  @media ${MOBILE_DEVICE} {
    padding-top: 20px;
  }
`;

const LocalPageHeading = styled(PageHeading)`
  @media ${MOBILE_DEVICE} {
    padding: 0 20px;
  }
`;

const CandidateFormPage: FunctionComponent<CandidateFormPageProps> = () => {
    const { state, dispatch } = useReducerContext();
    const { nationalityList } = useNationalities();
    const { candidateId, jobId } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [additionalInformationTypes, setAdditionalInformationTypes] = useState<AdditionalInformationType[]>([]);
    const [profilePhotoId, setProfilePhotoId] = useState<number|undefined>();
    const [profilePhoto, setProfilePhoto] = useState<File|string>('');
    const [name, setName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nameJP, setNameJP] = useState('');
    const [middleNameJP, setMiddleNameJP] = useState('');
    const [lastNameJP, setLastNameJP] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [nationality, setNationality] = useState('');
    const [visa, setVisa] = useState('');
    const [location, setLocation] = useState('');
    const [naturalJapaneseLevel, setNatureJapaneseLevel] = useState('');
    const [japaneseLevel, setJapaneseLevel] = useState('');
    const [availabilityTimes, setAvailabilityTimes] = useState('');
    const [nearestPossibleDate, setNearestPossibleDate] = useState<Date>();
    const [jobTypeId, setJobTypeId] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [lineId, setLineId] = useState('');
    const [facebook, setFacebook] = useState('');
    const [uploads, setUploads] = useState(new Array(3).fill({
        type: '',
        file: undefined,
    }));
    const isNewCandidateAndPageNotRefreshed = useRef(!!!candidateId);
    const showAiAnalysisSection = 
        state.isPartner &&
        isNewCandidateAndPageNotRefreshed.current &&
        additionalInformationTypes.length > 0;

    const invalid = !profilePhoto
        || !name
        || !lastName
        || !age
        || !gender
        || !nationality
        || !visa
        || !location
        || !naturalJapaneseLevel
        || !japaneseLevel
        || !availabilityTimes
        || !nearestPossibleDate
        || !jobTypeId
        || !isValidEmailAddress(contactEmail);

    const candidateData = (() => {
        const data: Partial<Candidate> = {}
        if (name)
            data.enFirstName = name;

        if (middleName)
            data.enMiddleName = middleName;

        if (lastName)
            data.enLastName = lastName;

        if (nameJP)
            data.jaFirstName = nameJP;

        if (middleNameJP)
            data.jaMiddleName = middleNameJP;

        if (lastNameJP)
            data.jaLastName = lastNameJP;

        if (age)
            data.age = Number(age);

        if (gender)
            data.gender = gender;

        if (nationality)
            data.nationalityId = Number(nationality);

        if (visa)
            data.visaStatus = visa;

        if (location)
            data.currentAddress = location;

        if (naturalJapaneseLevel)
            data.japaneseConversationSkills = naturalJapaneseLevel;

        if (japaneseLevel)
            data.jlptLevel = japaneseLevel;

        if (availabilityTimes)
            data.currentWorkHours = availabilityTimes;

        if (nearestPossibleDate) {
            moment.locale(LanguageCode.English);
            const formattedDate = moment(nearestPossibleDate).format('YYYY-MM-DD');
            moment.locale(i18n.language);
            data.nearestPossibleDate = formattedDate;
        }

        if (jobTypeId)
            data.jobTypeId = Number(jobTypeId);

        if (contactEmail)
            data.email =  contactEmail;

        if (lineId)
            data.lineId = lineId;

        if (facebook)
            data.facebookProfileLink = facebook;

        return data;
    })();

    const createNewCandidateRecord = async (): Promise<Candidate> => {
        const candidate = await createCandidate(candidateData);
        if (typeof(profilePhoto) !== 'string') {
            await createCandidateImage(candidate.id, profilePhoto);
        }
        for (const upload of uploads) {
            if (upload.type && upload.file && !upload.removed) {
                if (typeof(upload.file) !== 'string') {
                    await createCandidateAdditionalInformation(
                        candidate.id,
                        upload.type,
                        upload.file,
                    );
                }
            }
        }

        return candidate;
    }

    const updateCandidateData = async (targetCandidateId: number | string): Promise<Candidate> => {
        if (typeof(profilePhoto) !== 'string') {
            if (profilePhotoId) {
                await deleteCandidateImage(profilePhotoId);
            }
            await createCandidateImage(targetCandidateId, profilePhoto);
        }

        for (const upload of uploads) {
            if (upload.id) {
                if (upload.removed || (upload.file && typeof(upload.file) !== 'string')) {
                    await deleteCandidateAdditionalInformation(upload.id);
                }
            }
            if (upload.type && upload.file) {
                if (typeof(upload.file) !== 'string') {
                    await createCandidateAdditionalInformation(
                        targetCandidateId,
                        upload.type,
                        upload.file,
                    );
                }
            }
        }

        const updatedCandidate = await updateCandidate(targetCandidateId, candidateData);

        if (state.isCandidate) {
            dispatch({
                type: ACTIONS.UPDATE_CANDIDATE,
                payload: {
                    candidate: updatedCandidate,
                },
            });
        }

        return updatedCandidate;
    }

    const createOrUpdateCandidate = async () => {
        const targetCandidateId = state.isCandidate ? state.candidate!.id : candidateId;
        const isUpdate = !!targetCandidateId;

        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t(`candidate.${isUpdate ? 'updat' : 'creat'}ing_candidate`),
            },
        });

        try {
            if (isUpdate) 
                await updateCandidateData(targetCandidateId);
            else
                await createNewCandidateRecord();
            
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'success',
                    message: t(`candidate.candidate_${isUpdate ? 'update' : 'registration'}_success`),
                },
            });
    
                navigate(state.isCandidate ? '/profile' : '/candidates');
            } catch (e) {
                dispatch({
                    type: ACTIONS.SET_PROMPT,
                    payload: {
                        type: 'warning',
                        message: t(`candidate.candidate_${isUpdate ? 'update' : 'registration'}_failed`),
                    },
                });
            }
            dispatch({
                type: ACTIONS.STOP_LOADING,
            })
        };
    
    const setCandidateData = (candidate: Candidate) => {
        if (candidate.image) {
            setProfilePhotoId(candidate.image.id);
            setProfilePhoto(candidate.image.url);
        }
        setName(candidate.enFirstName || '');
        setMiddleName(candidate.enMiddleName || '');
        setLastName(candidate.enLastName || '');
        setNameJP(candidate.jaFirstName || '');
        setMiddleNameJP(candidate.jaMiddleName || '');
        setLastNameJP(candidate.jaLastName || '');
        setAge(candidate.age? String(candidate.age) : '');
        setGender(candidate.gender || '');
        setNationality(candidate.nationalityId? String(candidate.nationalityId) : '');
        setVisa(candidate.visaStatus || '');
        setLocation(candidate.currentAddress || '');
        setNatureJapaneseLevel(candidate.japaneseConversationSkills || '');
        setJapaneseLevel(candidate.jlptLevel? String(candidate.jlptLevel) : '');
        setAvailabilityTimes(candidate.currentWorkHours || '');
        setNearestPossibleDate(candidate.nearestPossibleDate 
            ? new Date(candidate.nearestPossibleDate)
            : undefined
        );
        setJobTypeId(candidate.jobTypeId? String(candidate.jobTypeId) : '');
        setContactEmail(candidate.email || '');
        setLineId(candidate.lineId || '');
        setFacebook(candidate.facebookProfileLink || '');
        if (candidate?.additionalInformations?.length) {
            setUploads(candidate.additionalInformations.map((information) => ({
                id: information.id,
                type: String(information.typeId),
                file: information.url,
            })));
        }
    }

    const handleSubmitClick = () => {
        createOrUpdateCandidate();
    };

    const handleCancelClick = () => {
        navigate(-1);
    };

    useEffect(() => {
        (async () => {
            try {
                dispatch({
                    type: ACTIONS.START_LOADING,
                    payload: {
                        message: t('candidate.fetching_candidate_details'),
                    },
                });

                const additionalInformationTypes = await getAdditionalInformationTypes();
                setAdditionalInformationTypes(additionalInformationTypes);
                
                const response = state.isCandidate
                    ? state.candidate!
                    : await getCandidate(candidateId!);
                setCandidateData(response);
            } catch (e) {
                //TODO: error handling
            } finally {
                dispatch({
                    type: ACTIONS.STOP_LOADING,
                });
            }
        })();

    }, []);


    return (
        <LocalPage>
            <LocalPageHeading>
                {(state.isLoggedIn && !state.isEmployer && !state.isPartner)
                    ? t('core.profile')
                    : t(`candidate.${candidateId ? 'edit' : 'create'}_candidate`)
                }
            </LocalPageHeading>

            {showAiAnalysisSection &&
                <AiAnalysisSection 
                    additionalInformationTypes={additionalInformationTypes}
                    registerNewCandidate={createNewCandidateRecord}
                    onUpdateCandidate={setCandidateData}
                />
            }

            <BasicInformationSection
                nationalities={nationalityList}
                avatar={profilePhoto}
                firstNameEn={name}
                middleNameEn={middleName}
                lastNameEn={lastName}
                firstNameJp={nameJP}
                middleNameJp={middleNameJP}
                lastNameJp={lastNameJP}
                age={age}
                gender={gender}
                nationality={nationality}
                visaStatus={visa}
                address={location}
                conversationSkills={naturalJapaneseLevel}
                jlptLevel={japaneseLevel}
                workHours={availabilityTimes}
                nearestPossibleDate={nearestPossibleDate}
                onAvatarChange={setProfilePhoto}
                onFirstNameEnChange={setName}
                onMiddleNameEnChange={setMiddleName}
                onLastNameEnChange={setLastName}
                onFirstNameJpChange={setNameJP}
                onMiddleNameJpChange={setMiddleNameJP}
                onLastNameJpChange={setLastNameJP}
                onAgeChange={setAge}
                onGenderChange={setGender}
                onNationalityChange={setNationality}
                onVisaStatusChange={setVisa}
                onAddressChange={setLocation}
                onConversationSkillsChange={setNatureJapaneseLevel}
                onJlptLevelChange={setJapaneseLevel}
                onWorkHoursChange={setAvailabilityTimes}
                onNearestPossibleDateChange={setNearestPossibleDate}
            />
            <SkillsSection
                jobType={jobTypeId}
                onJobTypeChange={setJobTypeId}
            />
            <ContactInformationSection
                email={contactEmail}
                lineId={lineId}
                facebookLink={facebook}
                onEmailChange={setContactEmail}
                onLineIdChange={setLineId}
                onFacebookLinkChange={setFacebook}
            />
            <AdditionalInformationSection
                types={additionalInformationTypes}
                uploads={uploads}
                onUploadsChange={setUploads}
            />
            <ActionBar>
                <Button
                    disabled={invalid}
                    onClick={handleSubmitClick}
                >
                    {t('core.save')}
                </Button>
                <Button variant="secondary" onClick={handleCancelClick}>
                    {t('core.cancel')}
                </Button>
            </ActionBar>
        </LocalPage>
    );
};

export default CandidateFormPage;