import styled from "styled-components";
import Column from "../../../../../core/components/Column";
import { Row } from "../../commonStyles";


export const Section = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
`;

export const SectionIndex = styled.span``;

export const SectionContent = styled(Column)`
  gap: 10px;
`;

export const AmountContainer = styled.div`
  border-bottom: 1px solid black;
`;