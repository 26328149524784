import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type TextProps = HTMLAttributes<HTMLSpanElement> & {
    variant?: 'normal' | 'tiny';
    error?: boolean;
    children?: ReactNode;
};

type ContainerProps = TextProps & {
};

const Container = styled.span<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${(props: ContainerProps) => props.variant === 'tiny' ? 22 : 14}px;
  font-weight: 400;
  line-height: ${(props: ContainerProps) => props.variant === 'tiny' ? 32 : 20}px;
  color: ${(props: ContainerProps) => props.error
          ? '#E93232'
          : props.variant === 'tiny' ? '#999999' : '#444444'
  };
  zoom: ${(props: ContainerProps) => props.variant === 'tiny' ? 0.5 : 'unset'};
`;

const Text: FunctionComponent<TextProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default Text;