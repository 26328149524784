import { apiUtil } from '../../core/utils';
import { AdditionalInformationType, Avatar, Candidate } from '../types/api';

export const getAdditionalInformationTypes = async () => {
    const response = await apiUtil.get('/additional_information_types');
    if (response.status >= 200 && response.status < 300) {
        return response.data as AdditionalInformationType[];
    }
    throw new Error('API error');
};

export const createCandidate = async (candidate: { [key: string]: any }) => {
    const response = await apiUtil.post(
        '/candidates',
        {
            ...candidate,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Candidate;
    }
    throw new Error('API error');
};

export const getCandidate = async (candidateId: number|string) => {
    const response = await apiUtil.get(`/candidates/${candidateId}`);
    if (response.status >= 200 && response.status < 300) {
        return response.data as Candidate;
    }
    throw new Error('API error');
};

export const getCandidates = async (params: {} = {}) => {
    const response = await apiUtil.get('/candidates', { params });
    if (response.status >= 200 && response.status < 300) {
        return response.data as Candidate[];
    }
    throw new Error('API error');
};

export const updateCandidate = async (candidateId: number|string, candidate: { [key: string]: any }) => {
    const response = await apiUtil.patch(
        `/candidates/${candidateId}`,
        {
            ...candidate,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API error');
};

export const createCandidateImage = async (candidateId: number | string, file: File) => {
    const data = new FormData();
    data.append('candidateId', String(candidateId));
    data.append('image', file);
    const response = await apiUtil.post('/candidate_images', data);
    if (response.status >= 200 && response.status < 300) {
        return response.data as Avatar;
    }
    throw new Error('API error');
};

export const deleteCandidateImage = async (imageId: number | string) => {
    const response = await apiUtil.delete(`/candidate_images/${imageId}`);
    if (response.status >= 200 && response.status < 300) {
        return response.data as Avatar;
    }
    throw new Error('API error');
};

export const createCandidateAdditionalInformation = async (
    candidateId: number | string,
    typeId: number | string,
    file: File,
) => {
    const data = new FormData();
    data.append('candidateId', String(candidateId));
    data.append('typeId', String(typeId));
    data.append('image', file);
    const response = await apiUtil.post('/additional_informations', data);
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API error');
};

export const deleteCandidateAdditionalInformation = async (
    additionalInformationId: number | string,
) => {
    const response = await apiUtil.delete(`/additional_informations/${additionalInformationId}`);
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API error');
};
