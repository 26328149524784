import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Text from './Text';

interface ToggleButtonProps {
  toggled?: boolean;
  onToggle: (value: boolean) => void;
  disabled?: boolean;
}

const CheckBox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Container = styled.label`
  position: relative;
  display: inline-block;
  width: 70px;
  height: 30px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #999999;
  transition: 0.5s;
  border-radius: 100px;

  ${CheckBox}:checked + & {
    background-color: #FF730D;
  }

  ${CheckBox}:disabled + & {
    cursor: not-allowed;
    background-color: #999999;
  }
`;

const SliderCircle = styled.span`
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  background-color: #FFFFFF;
  transition: ease-in-out 0.3s;
  border-radius: 50%;

  ${CheckBox}:checked + ${Slider} & {
    transform: translateX(40px);
  }

  ${CheckBox}:disabled + ${Slider} & {
    background-color: #D5D5D5;
  }
`;

const StatusText = styled(Text)`
  position: absolute;
  left: 32px;
  bottom: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #FFFFFF;
  transition: 0.3s;

  ${CheckBox}:checked + ${Slider} & {
    left: 19px;
  }

  ${CheckBox}:disabled + ${Slider} & {
    color: #D5D5D5;
  }
`;

const ToggleButton: FunctionComponent<ToggleButtonProps> = ({ 
  toggled,
  onToggle,
  disabled = false
}) => {
  return (
    <Container>
      <CheckBox 
        type="checkbox" 
        onClick={() => onToggle(!toggled)} 
        onChange={() => {}} 
        disabled={disabled} 
        checked={toggled}
      />
      <Slider>
        <SliderCircle/>
        <StatusText>
          {toggled ? 'ON' : 'OFF'}
        </StatusText>
      </Slider>
    </Container>
  );
};

export default ToggleButton;
