import styled from "styled-components";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ContractTextField = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  word-break: break-all;

  & {
    > div {
      margin-right: 10px;
    }

    > div:nth-child(2) {
      margin-right: 10px;
      flex-grow: 1;
      border-bottom: 0.5px solid black;
    }

    > div:last-child {
      margin-right: 0;
    }
  }
`;