import { FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';

interface SpinnerProps extends React.HTMLAttributes<HTMLSpanElement> {};

const SpinnerSegment = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #FF730D;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: 1s infinite ease-in-out ${keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `};
`;

const Spinner: FunctionComponent<SpinnerProps> = ({
  style
}) => {
    return <SpinnerSegment style={style}/>;
};

export default Spinner;