//These values should match the ones defined in the backend
export enum JobState {
  Draft = "draft",
  Published = "published",
  Archived = "archived",
}

export enum JobContractPeriod {
  FixedTerm = "fixed_term",
  Indefinite = "indefinite",
}

export enum JobJPConversationSkill {
  CanSpeakBasicPhrases = "can_speak_basic_phrases",
  CanCarryOnEverydayConversation = "can_carry_on_everyday_conversations",
  Fluent = "fluent",
}

export enum JobContractRenewal {
  Automatic = "automatic",
  Possible = "possible",
  No = "no",
}

export enum JobPreferredGender {
  Male = "male",
  Female = "female",
  NotAny = "not_any"
}

export enum JobWorkStyle {
  ShiftSystem = "shift_system",
  FixedHours = "fixed_hours"
}

export enum JobSalaryType {
  Monthly = "monthly",
  Daily = "daily",
  Hourly = "hourly",
}

export enum JobDormType {
  Apartment = "apartment",
  SingleHouse = "single_house", 
}

export enum JobInterviewType {
  Online = "online",
  Offline = "offline",
}

export enum JobCommuteMethod {
  Walk = "walk",
  Bicycle = "bicycle",
  PublicTransport = "public_transport",
  Automobile = "automobile",
}

export enum JobJlptLevel {
  N1 = "n1",
  N2 = "n2",
  N3 = "n3",
  N4 = "n4",
  N5 = "n5",
}