import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { AmountContainer, Section, SectionContent, SectionIndex } from "./styles";
import { Column5, InputValue, Item10, RowSpaceBetween } from "../../commonStyles";
import { TemplateSectionProps } from "../../types";
import { formatAmount } from "../../util";
import { Trans, useTranslation } from "react-i18next";

interface EstimatedPaymentSectionProps extends TemplateSectionProps {
}

const EstimatedPaymentSection: FunctionComponent<EstimatedPaymentSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;
  const [estimatedPaymentPerMonth, setEstimatedPaymentPerMonth] = useState<string>("");

  const EsimatedMonthlyPayment = ({ children }: { children?: ReactNode }) =>
    <Item10><InputValue>{children}</InputValue></Item10>;

  useEffect(() => {
    if (!ec) 
      return;

    setEstimatedPaymentPerMonth(formatAmount(ec.estimatedPaymentPerMonth));
  }, [ec]);

  return (
    <Section>
      <SectionIndex>3.</SectionIndex>
      <SectionContent>
        <Column5>
          <RowSpaceBetween>
            <span>１か月当たりの支払概算額（１＋２）</span>
            <AmountContainer>
              <Item10>約</Item10>
              <Item10><InputValue>{estimatedPaymentPerMonth}</InputValue></Item10>
              <Item10> 円（合計）</Item10>
            </AmountContainer>
          </RowSpaceBetween>
          <RowSpaceBetween>
            <span>{tFixed('estimated_monthly_payment')}</span>
            <AmountContainer>
              <Trans
                i18nKey={getTKey("total_estimated")}
                tOptions={{ lng: transLang }}
                values={{
                  amount: estimatedPaymentPerMonth,
                }}
                components={{
                  seg: <Item10 />,
                  amount: <EsimatedMonthlyPayment />
                }}
              />
            </AmountContainer>
          </RowSpaceBetween>
        </Column5>
      </SectionContent>
      </Section>
  )
}

export default EstimatedPaymentSection;