import { FunctionComponent } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Row from './Row';
import { MOBILE_DEVICE } from '../constants/styles';

interface EmploymentOfferTemplateProps {
    issuedDate?: string;
    candidateName?: string;
    isUsingOwnSignature: boolean;
    companyName?: string;
    address?: string;
    sealUrl?: string;
}

const Layout = styled.div`
    display: flex;
`;

const Letter = styled.div`
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    width: auto;
    min-height: 961px;
    font-family: "Hiragino mincho pro", "Zen Old Mincho", serif;
    background-color: white;
    padding: 80px;

    @media print {
        min-height: 100vh;
    }

    @media ${MOBILE_DEVICE} {
        padding: 100px 20px;
    }

`;

const DateRow = styled(Row)`
    margin-left: auto;
    align-items: center;
`;

const PlaceHolder = styled.span`
    font-size: 14px;
    font-family: 'Noto Sans JP', 'Noto Sans', sans-serif;
    line-height: 20px;
    display: inline-block;
    height: 24px;
    min-width: 24px;
    padding: 2px 5px;
    background-color: #EDEDED;
`;

const DateUnit = styled.div`
    height: 24px;
    width: 24px;
    text-align: center;
`;

const Title = styled.div`
    margin: 40px auto 0 auto;
    font-size: 30px;
    font-weight: bold;
`;

const NameRow = styled.div`
    margin-top: 40px;
    display: flex;
    align-items: center;
`

const NameRowKey = styled.div`
    flex: 0 0 100px;
`;

const MainText = styled.div`
    margin-top: 40px;
`;

const Employer = styled.div`
    margin-top: 40px;
`;

const EmployerCompanyName = styled.div`
    margin-top: 10px;
`;

const EmployerCompanyAddress = styled.div`
    margin-top: 10px;
`;

const Signature = styled.div`
    margin-top: 40px;
    margin-left: auto;
`;

const SignatureCompanyName = styled.div`
    text-align: right;
`;

const SignatureCompanyAddress = styled.div`
    margin-top: 10px;
    text-align: right;
`;

const SignatureCompanySeal = styled.div`
    display: flex;
    margin-top: 20px;
`;

const SignatureCompanySealImg = styled.img`
    display: block;
    margin-left: auto;
    flex: 0 0 100px;
    width: 100%;
    max-width: 100px;
    height: auto;
`;

const NameRowValue = styled.div`
`;

const EmploymentOfferTemplate: FunctionComponent<EmploymentOfferTemplateProps> = ({
    issuedDate,
    candidateName,
    isUsingOwnSignature,
    companyName,
    address,
    sealUrl
}) => {
    const date = moment(issuedDate);

    return (
        <Layout>
            <Letter>
                <DateRow>
                    { issuedDate 
                        ? date.format('YYYY年M月D日')
                        :  <>
                            <PlaceHolder /><DateUnit>年</DateUnit>
                            <PlaceHolder /><DateUnit>月</DateUnit>
                            <PlaceHolder /><DateUnit>日</DateUnit>
                        </>
                    }
                </DateRow>
                <Title>
                    内定証明書
                </Title>
                <NameRow>
                    <NameRowKey>
                        氏名
                    </NameRowKey>
                    <NameRowValue>
                        { candidateName
                            ? candidateName
                            : <PlaceHolder>候補者の氏名</PlaceHolder>
                        }
                    </NameRowValue>
                </NameRow>
                <MainText>
                    　上記の者を、候補者として合格したことを証明いたします。当社が責任を持って在留資格の変更又は更新手続きおよび、入職手続きが適切に進むよう致します。但し、候補者が在留資格の変更又は更新の要件を満たしているものとします。
                </MainText>
                {isUsingOwnSignature ? (
                    <Signature>
                        <SignatureCompanyName>
                            { companyName
                                ? companyName
                                : <PlaceHolder>雇用主の機関名</PlaceHolder>
                            }
                        </SignatureCompanyName>
                        <SignatureCompanyAddress>
                            { address
                                ? address
                                : <PlaceHolder>雇用主の本社所在地</PlaceHolder>
                            }
                        </SignatureCompanyAddress>
                        { sealUrl &&
                            <SignatureCompanySeal>
                                <SignatureCompanySealImg alt="company seal" src={sealUrl} />
                            </SignatureCompanySeal>
                        }
                    </Signature>
                ) : (
                    <>
                        <Employer>
                            【雇用主】
                        </Employer>
                        <EmployerCompanyName>
                            { companyName
                                ? companyName
                                : <PlaceHolder>雇用主の機関名</PlaceHolder>
                            }
                        </EmployerCompanyName>
                        <EmployerCompanyAddress>
                            { address
                                ? address
                                : <PlaceHolder>雇用主の本社所在地</PlaceHolder>
                            }
                        </EmployerCompanyAddress>
                        <Signature>
                            <SignatureCompanyName>
                                トクティー株式会社
                            </SignatureCompanyName>
                            <SignatureCompanyAddress>
                                東京都港区海岸1-9-18国際浜松町ビル2階
                            </SignatureCompanyAddress>
                            <SignatureCompanySeal>
                                <SignatureCompanySealImg alt="seal of tokuty" src={"/images/tokuty-seal.jpg"} />
                            </SignatureCompanySeal>
                        </Signature>
                    </>
                )}
            </Letter>
        </Layout>
    );
};

export default EmploymentOfferTemplate;
