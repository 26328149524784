import { FunctionComponent, HTMLAttributes } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import i18n from 'i18next';

import { Proposal } from '../../types';
import { Candidate } from '../../../candidate/types/api';
import { Mapping } from '../../../core/types/common';
import { Industry, Job, JobType } from '../../../job/types/api';
import ProposalStatus from '../../components/ProposalStatus';
import { DESKTOP_DEVICE } from '../../../core/constants/styles';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { decodeHTMLEntities } from '../../../core/utils';
import { useJobPostCategory } from '../../../core/hooks';

interface ProposalListTableProps extends HTMLAttributes<HTMLDivElement> {
    industries: Mapping<Industry>;
    jobTypes: Mapping<JobType>;
    jobs: Mapping<Job>;
    candidates: Mapping<Candidate>;
    proposals: Proposal[];
    onItemClick?: (index: number) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #E5E5E5;
  border: 1px solid #D5D5D5;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-top: none;
  cursor: pointer;
  
  &:hover {
    background: #FFDFD1;
    border-color: #FF730D;
    border-top: 1px solid #FF730D;
  }
`;

const Item = styled.div`
  flex: 1;
  padding-left: 20px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Status = styled(Item)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const InterviewDate = styled(Item)`
  flex: 1.3;
`;

const JobName = styled(Item)`
  flex: 2.7;
`;

const IndustryName = styled(Item)`
  flex: 2;
`;

const CandidateName = styled(Item)`
  flex: 2;
`;

const ScoutIndication = styled(Item)`
  flex: 1;
`;

const LastUpdated = styled(Item)`
  flex: 2;
`;

const ScoutIndicator = styled.span`
  display: inline-block;
  text-align: center;
  padding: 2px 5px;
  background-color: #FFDFD1;
  border-radius: 5px;
  min-height: 21px;
`;

const ProposalListTable: FunctionComponent<ProposalListTableProps> = ({
    industries,
    jobTypes,
    jobs,
    candidates,
    proposals,
    onItemClick,
    ...props
}) => {
    const { t } = useTranslation();
    const { state } = useReducerContext();
    const jobPostCategory = useJobPostCategory();
    const isDekstopDevice = useMediaQuery({
        query: DESKTOP_DEVICE,
    });

    const findLatestInterviewDate = (proposal: Proposal) => {
      if (!proposal.interviews) return t('core.not_set');

      const latestInterview = proposal.interviews.slice(-1)[0];
      const latestInterviewDate = latestInterview.slots.find(slot => slot.id === latestInterview.selectedSlotId)?.start;

      return latestInterviewDate ? moment(latestInterviewDate).format('YYYY/MM/DD HH:mm') : t('core.not_set');
    }

    return (
        <Container {...props}>
            <Header>
                <Status>{t('core.status')}</Status>
                {isDekstopDevice && (
                  <InterviewDate>{t('proposal.interview_date')}</InterviewDate>
                )}
                <JobName>{t('proposal.job')}</JobName>
                {isDekstopDevice && (
                    <>
                        <IndustryName>{t('job.industry')}</IndustryName>
                        <CandidateName>{t('proposal.candidate')}</CandidateName>
                        { state.isEmployer &&
                          <ScoutIndication/>
                        }
                        <LastUpdated>{t('core.last_updated')}</LastUpdated>
                    </>
                )}
            </Header>
            {proposals.map((proposal, index) => (
                <Row
                    key={proposal.id}
                    onClick={() => onItemClick && onItemClick(index)}
                >
                    <Status>
                        <ProposalStatus variant={proposal.state} />
                    </Status>
                    {isDekstopDevice && (
                      <InterviewDate>{findLatestInterviewDate(proposal)}</InterviewDate>
                    )}
                    <JobName>
                        {
                          decodeHTMLEntities(jobs[proposal.jobPostId].translations.find(t => t.language.code === i18n.language)?.name)
                          ?? jobs[proposal.jobPostId]?.name
                        }
                    </JobName>
                    {isDekstopDevice && (
                        <>
                            <IndustryName>
                                {jobPostCategory.getIndustryNameByJobTypeId(jobs[proposal.jobPostId]?.jobTypeId) || '-'}
                            </IndustryName>
                            <CandidateName>
                                {candidates[proposal.candidateId]?.enFirstName}
                                {!!candidates[proposal.candidateId]?.enMiddleName && (
                                    ` ${candidates[proposal.candidateId].enMiddleName}`
                                )}
                                {' '}
                                {candidates[proposal.candidateId]?.enLastName}
                            </CandidateName>
                            { state.isEmployer &&
                              <ScoutIndication>
                                {!!proposal.scoutId &&
                                  <ScoutIndicator>{t('core.scout')}</ScoutIndicator>
                                }
                              </ScoutIndication>
                            }
                            <LastUpdated>
                                {moment(proposal.updatedAt).format('YYYY/MM/DD')}
                            </LastUpdated>
                        </>
                    )}
                </Row>
            ))}
        </Container>
    );
};

export default ProposalListTable;