import { ChangeEvent, FunctionComponent, InputHTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';

type MultiTextInputProps = InputHTMLAttributes<HTMLTextAreaElement> & {
    invalid?: boolean;
    textAreaRef?: RefObject<HTMLTextAreaElement>
    onTextChange?: (value: string) => void;
};

const TextArea = styled.textarea`
  min-height: 60px;
  padding: 5px 10px;
  font-size: 14px;
  color: #444444;
  background: #FFFFFF;
  border: 1px solid #999999;
  border-radius: 3px;
  box-sizing: border-box;
  outline: none;

  &:invalid {
    background: #FFF0F0;
    border-color: #E93232;
  }

  &:disabled {
    color: #999999;
    background: #D5D5D5;
  }
  
  &:focus {
    border-color: #444444;
  }
  
  &::placeholder {
    color: #999999;
  }
`;

const MultiTextInput: FunctionComponent<MultiTextInputProps> = ({
    invalid,
    onTextChange,
    textAreaRef,
    ...props
}) => {
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onTextChange && onTextChange(event.target.value);
    };

    return <TextArea required={invalid} ref={textAreaRef} onChange={handleChange} {...props} />;
};

export default MultiTextInput;