import { FunctionComponent } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Job } from '../../types/api';
import { decodeHTMLEntities, getJobLocation } from '../../../core/utils';
import { useJobPostCategory } from '../../../core/hooks';

type ListViewProps = {
    jobs: Job[];
    onNavigate?: (jobId: number) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #E5E5E5;
  border: 1px solid #D5D5D5;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-top: none;
  cursor: pointer;
  
  &:hover {
    background: #FFDFD1;
    border-color: #FF730D;
    border-top: 1px solid #FF730D;
  }
`;

const Item = styled.div`
  flex: 1;
  padding-left: 20px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Title = styled(Item)`
    flex: 3
`;

const Industry = styled(Item)`
    flex: 1
`;

const JobType = styled(Item)`
    flex: 1
`;

const Location = styled(Item)`
    flex: 1
`;

const Salary = styled(Item)`
    flex: 1
`;

const Positions = styled(Item)`
    flex: 1
`;

const Posted = styled(Item)`
    flex: 2
`;

const Deadline = styled(Item)`
  flex: 2
`;

const ListView: FunctionComponent<ListViewProps> = ({
    jobs,
    onNavigate,
}) => {
        const { t, i18n } = useTranslation();
    const jobPostCategory = useJobPostCategory();

    return (
        <Container>
            <Header>
                <Title>{t('job.title')}</Title>
                <Industry>{t('job.industry')}</Industry>
                <JobType>{t('job.job_type')}</JobType>
                <Location>{t('job.location')}</Location>
                <Salary>{t('job.salary')}</Salary>
                <Positions>{t('job.number_of_positions')}</Positions>
                <Posted>{t('core.date_created')}</Posted>
                <Deadline>{t('job.application_deadline')}</Deadline>
            </Header>
            {jobs.map(job => (
                <Row key={job.id} onClick={() => onNavigate && onNavigate(job.id)}>
                    <Title>
                        {
                            decodeHTMLEntities(job.translations?.find(trans => trans.language.code === i18n.language)?.name)
                            ?? job.name
                        }
                    </Title>
                    <Industry>
                        {jobPostCategory.getIndustryNameByJobTypeId(job.jobTypeId)}
                    </Industry>
                    <JobType>
                        {jobPostCategory.getJobTypeName(job.jobTypeId)}
                    </JobType>
                    <Location>
                        {getJobLocation(job, i18n.language)}
                    </Location>
                    <Salary>
                    { 
                        job.grossSalaryUpperLimit
                        ? (`${job.grossSalary?.toLocaleString()} ~ ${job.grossSalaryUpperLimit.toLocaleString()}`)
                        : job.grossSalary?.toLocaleString() ?? '-'
                    }
                    </Salary>
                    <Positions>{job.numberOfPositions}</Positions>
                    <Posted>{moment(job.publishedAt).format('YYYY/MM/DD')}</Posted>
                    <Deadline>
                        {job.applicationDeadline
                            ? moment(job.applicationDeadline).format('YYYY/MM/DD')
                            : '-'
                        }
                    </Deadline>
                </Row>
            ))}
        </Container>
    );
};

export default ListView;