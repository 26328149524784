import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import Label from '../../core/components/Label';

type StepLabelProps = HTMLAttributes<HTMLDivElement> & {
    active?: boolean;
    completed?: boolean;
    number?: number;
    comment?: string;
    children?: ReactNode;
};

type IconProps = {
    active?: boolean;
    completed?: boolean;
};

type TextProps = {
    active?: boolean;
    completed?: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const TextContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-left: 19px;
`;

const Icon = styled.div<IconProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: ${(props: IconProps) => props.active
          ? '#FFFFFF'
          : '#999999'
  };
  background-color: ${(props: IconProps) => props.active
          ? '#FF730D'
          : (props.completed ? '#8A8E94' : '#FFFFFF')
  };
  border: 2px solid ${(props: IconProps) => props.active
          ? '#FF730D'
          : '#8A8E94'
  };
  border-radius: 50%;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
`;

const Text = styled(Label)<TextProps>`
  color: ${(props: TextProps) => props.active
          ? '#FF730D'
          : (props.completed ? '#444444' : '#999999')
    };
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Comment = styled.div`
  padding: 0 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #999999;
  background: #F7F7F7;
`;

const StepLabel: FunctionComponent<StepLabelProps> = ({
    active,
    completed,
    number,
    comment,
    children,
}) => {
    return (
        <Container>
            <Icon active={active} completed={completed}>
                {completed
                    ? <img alt="checked" src="/images/icon-checked-white.svg" />
                    : number
                }
            </Icon>
            <TextContainer>
                <Text active={active} completed={completed}>
                    {children}
                </Text>
                {comment && (
                    <Comment>{comment}</Comment>
                )}
            </TextContainer>
        </Container>
    );
};

export default StepLabel;