import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const COLOR_MAPPING = {
    'screen': '#444444',
    'declined': '#E93232',
    'first_interview': '#227AFF',
    'second_interview': '#227AFF',
    'third_interview': '#227AFF',
    'result': '#7B61FF',
    'hired': '#1FA44F',
    'rejected': '#E93232',
    'offer_accepted': '#1FA44F',
    'offer_declined': '#E93232',
    'canceled': '#999999',
};

const BACKGROUND_MAPPING = {
    'screen': '#E5E5E5',
    'declined': '#FFF0F0',
    'first_interview': '#D9E8FF',
    'second_interview': '#D9E8FF',
    'third_interview': '#D9E8FF',
    'result': '#E5E0FF',
    'hired': '#DBF0E3',
    'rejected': '#FFF0F0',
    'offer_accepted': '#DBF0E3',
    'offer_declined': '#FFF0F0',
    'canceled': '#D5D5D5',
};

interface ProposalStatusProps {
    variant: 'screen'
        | 'declined'
        | 'first_interview'
        | 'second_interview'
        | 'third_interview'
        | 'result'
        | 'hired'
        | 'rejected'
        | 'offer_accepted'
        | 'offer_declined'
        | 'canceled';
}

const Container = styled.div<ProposalStatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 21px;
  min-height: 21px;
  padding: 0 5px;
  border-radius: 5px;
  color: ${(props: ProposalStatusProps) => COLOR_MAPPING[props.variant]};
  background: ${(props: ProposalStatusProps) => BACKGROUND_MAPPING[props.variant]};
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
`;

const ProposalStatus: FunctionComponent<ProposalStatusProps> = ({
    variant,
}) => {
    const { t } = useTranslation();
    return (
        <Container variant={variant}>
            {t(`proposal.status_${variant}`)}
        </Container>
    );
};

export default ProposalStatus;