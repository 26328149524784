import { FunctionComponent } from "react";
import styled from "styled-components";
import Spinner from "./Spinner";
import Text from "../../../../core/components/Text";

interface LoadingPromptProps {
}

const LoadingPromptContainer = styled.div`
  display: flex;
  padding: 100px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 5px;
  background: #ffffff;
`;

const StatusText = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
`;

const NoteText = styled(Text)`
  color: #999999;

`;

const LoadingPrompt: FunctionComponent<LoadingPromptProps> = () => {
  return (
    <LoadingPromptContainer>
      <Spinner/>
      <StatusText>雇用条件書のプレビューを作成しています</StatusText>
      <NoteText>少し時間がかかる場合があります</NoteText>
    </LoadingPromptContainer>
  )
}

export default LoadingPrompt;