import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Label from '../../core/components/Label';
import Text from '../../core/components/Text';

interface JobOverviewProps extends HTMLAttributes<HTMLDivElement> {
    minimized?: boolean;
    thumbnail?: string;
    title?: string;
    location?: string;
    industry?: string;
    job?: string;
    salaryType?: string;
    salary?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  > *:not(:first-child) {
    margin-top: 4px;
  }
`;

const Thumbnail = styled.img`
  width: 138px;
  height: 94px;
  margin-right: 57px;
  object-fit: cover;
  border-radius: 5px;
`;

const LocalLabel = styled(Label)`
  width: 100%;
`;


const JobOverview: FunctionComponent<JobOverviewProps> = ({
    minimized,
    thumbnail,
    title,
    location,
    industry,
    job,
    salaryType,
    salary,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Container {...props}>
            {!minimized && (
                <Thumbnail alt="thumbnail" src={thumbnail} />
            )}
            <RightContainer>
                {minimized && (
                    <Thumbnail style={{ marginBottom: 20 }} alt="thumbnail" src={thumbnail} />
                )}
                <LocalLabel>{title}</LocalLabel>
                <Text>{location}</Text>
                <Text>{industry}</Text>
                <Text>{job}</Text>
                <Text>
                    {t(`job.salary_${salaryType}`)}
                    {' '}
                    {t('job.dollars', { number: salary?.toLocaleString() ?? '-' })}
                </Text>
            </RightContainer>
        </Container>
    );
};

export default JobOverview;