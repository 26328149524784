import styled from "styled-components";
import Column from "../../../core/components/Column";
import { MOBILE_DEVICE } from "../../../core/constants/styles";

export const Segment = styled.div`
  width: 100%;
`;

export const Grid5 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 5px;
`;

export const Grid10 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 20px;
`;

export const Grid20 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 20px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const Row5 = styled(Row)`
  gap: 5px;
`;

export const Row10 = styled(Row)`
  gap: 10px;
`;

export const Row20 = styled(Row)`
  gap: 20px;
`;

export const Item5 = styled.span`
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
`;

export const Item10 = styled.span`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;

export const Item20 = styled.span`
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`

export const Column5 = styled(Column)`
  width: 100%;
  gap: 5px;
`;

export const Column10 = styled(Column)`
  width: 100%;
  gap: 10px;
`;

export const Column20 = styled(Column)`
  width: 100%;
  gap: 20px;
`;

export const FlexRowColumn = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media ${MOBILE_DEVICE} {
    flex-direction: column;
  }

  @media print {
    flex-direction: row;
  }
`;

export const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const RightAlignedContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const InputValue = styled.span`
  word-break: break-all;
`;