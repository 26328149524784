import { FunctionComponent, HTMLAttributes, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MOBILE_DEVICE } from '../constants/styles';
import { useComponentVisible } from '../hooks';

type SelectOption = {
    label: string;
    value: any;
};

type MultiSelectProps = HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean;
    required?: boolean;
    placeholder?: string;
    options?: SelectOption[];
    value?: any[];
    onValueChange?: (value: any[]) => void;
};

type ContainerProps = MultiSelectProps & {
    invalid?: boolean;
};

type MenuProps = {
    visible?: boolean;
};

type ItemProps = {
    checked?: boolean;
};

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 165px;
  height: 30px;
  padding-right: 6px;
  padding-left: 7px;
  font-size: 14px;
  color: ${(props: ContainerProps) => props.disabled ? '#999999' : '#444444'};
  background: ${
    (props: ContainerProps) => props.disabled
            ? '#D5D5D5'
            : (props.invalid ? '#FFF0F0' : '#FFFFFF')
  };
  border: 1px solid ${(props: ContainerProps) => props.invalid ? '#E93232' : '#999999'};
  border-radius: 5px;
  cursor: pointer;
  pointer-events: ${(props: MultiSelectProps) => props.disabled ? 'none' : 'auto'};
  
  &:hover {
    border-color: ${(props: ContainerProps) => props.invalid ? '#E93232' : '#444444'};
  }
  
  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const Placeholder = styled.span`
  flex: 1;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Text = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Arrow = styled.span`
  width: 0;
  height: 0;
  margin-left: 4px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #999999;
`

const Menu = styled.div<MenuProps>`
  position: absolute;
  top: 100%;
  left: 0;
  width: max-content;
  min-width: 100%;
  max-width: 500px;
  max-height: 210px;
  padding: 4px 0;
  background: white;
  border-radius: 3px;
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.2);
  overflow: auto;
  visibility: ${(props: MenuProps) => props.visible ? 'visible' : 'hidden'};
  z-index: 9;

  @media ${MOBILE_DEVICE} {
    width: 100%;
    max-width: 100%;
  }
`

const Checkbox = styled.div`
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 6px;
  background: #FFFFFF;
  border: 1px solid #999999;
  border-radius: 3px;
  box-sizing: border-box;
  flex-shrink: 0;

  &:after {
    display: none;
    content: '';
    position: absolute;
    top: 3px;
    right: 1px;
    bottom: 2px;
    left: 1px;
    background: center/cover no-repeat url("/media/checkbox-checked.svg");
  }
`;

const Item = styled.div<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 30px;
  padding: 5px 8px;

  > ${Checkbox} {
    background: ${(props: ItemProps) => props.checked ? '#FF730D' : '#FFFFFF'};
    border-color: ${(props: ItemProps) => props.checked ? '#FF730D' : '#999999'};
    
    &:after {
      display: ${(props: ItemProps) => props.checked ? 'block' : 'none'};
    }
  }

  &:hover {
    color: white;
    background: #FF730D;

    > ${Checkbox} {
      background: ${(props: ItemProps) => props.checked ? '#FF730D' : '#FFC194'};
      border-color: #FF730D;
    }
  }
`

const MultiSelect: FunctionComponent<MultiSelectProps> = ({
    required,
    placeholder,
    options,
    value,
    onValueChange,
    ...props
}) => {
    const { t } = useTranslation();
    const { ref, visible, setVisible } = useComponentVisible(false);
    const option = options
        ? options
            .filter(option => value && value.includes(option.value))
            .map(option => t(option.label))
            .join(', ')
        : null

    const handleContainerClick = () => {
        setVisible(!visible);
    };

    const handleItemClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (onValueChange) {
            const newValue = [...(value ?? [])];
            const index = newValue.indexOf((event.target as HTMLDivElement).dataset.value);
            if (index === -1) {
                newValue.push((event.target as HTMLDivElement).dataset.value);
            } else {
                newValue.splice(index, 1);
            }
            onValueChange(newValue);
        }
    };

    return (
        <Container
            invalid={required
                && (!options || !options.length || options.every(option => option.value !== value))
            }
            onClick={handleContainerClick}
            {...props}
        >
            {option
                ? <Text>{option}</Text>
                : (placeholder ? <Placeholder>{t(placeholder)}</Placeholder> : <Text />)
            }
            {value && value.length > 1 && (
                <Text style={{ flex: 'unset' }}>({value.length})</Text>
            )}
            <Arrow />
            <Menu ref={ref} visible={visible}>
                {options && options.map(option => (
                    <Item
                        key={option.value}
                        checked={value && value.includes(option.value)}
                        onClick={handleItemClick}
                        data-value={option.value}
                    >
                        <Checkbox data-value={option.value} />
                        {t(option.label)}
                    </Item>
                ))}
            </Menu>
        </Container>
    )
};

export default MultiSelect;
