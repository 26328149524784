import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Candidate } from '../../candidate/types/api';
import { encodeCandidateId } from '../utils';

interface SelectedCandidateListTableProps extends HTMLAttributes<HTMLDivElement> {
    candidates?: Candidate[];
}

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content auto;
  width: 100%;
`;

const Header = styled.div`
  display: contents;

  > * {
    max-height: 30px;
    min-height: 30px;
    background: #E5E5E5;
    border-top: 1px solid #D5D5D5;
    border-bottom: 1px solid #D5D5D5;
  }

  > *:first-child {
    border-left: 1px solid #D5D5D5;
    border-top-left-radius: 5px;
  }

  > *:last-child {
    border-right: 1px solid #D5D5D5;
    border-top-right-radius: 5px;
  }
`;

const Item = styled.div`
  display: contents;

  > * {
    max-height: 40px;
    min-height: 40px;
    background: #FFFFFF;
    border-bottom: 1px solid #D5D5D5;
  }

  > *:first-child {
    border-left: 1px solid #D5D5D5;
  }

  > *:last-child {
    border-right: 1px solid #D5D5D5;
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
`;

const SelectedCandidateListTable: FunctionComponent<SelectedCandidateListTableProps> = ({
    candidates,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Container {...props}>
            <Header>
                <Cell>{t('scout.candidate_id')}</Cell>
                <Cell>{t('candidate.age')}</Cell>
                <Cell>{t('candidate.candidate_visa_status')}</Cell>
                <Cell>{t('candidate.japanese_conversation_skills')}</Cell>
            </Header>
            {candidates?.map((candidate, index) => (
                <Item key={candidate.id}>
                    <Cell>{encodeCandidateId(candidate.id)}</Cell>
                    <Cell>{candidate.age ?? '-'}</Cell>
                    <Cell>
                        {candidate.visaStatus
                            ? t(`candidate.${candidate.visaStatus}`)
                            : '-'
                        }
                    </Cell>
                    <Cell>
                        {candidate.japaneseConversationSkills
                            ? t(`candidate.${candidate.japaneseConversationSkills}`)
                            : '-'
                        }
                    </Cell>
                </Item>
            ))}
        </Container>
    );
};

export default SelectedCandidateListTable;