import React, {
    ChangeEvent,
    FunctionComponent,
    HTMLAttributes,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from './Button';

type ImageUploaderProps = HTMLAttributes<HTMLDivElement> & {
    images?: Array<File|string>;
    accept?: string;
    onImagesChange?: (images: Array<File|string>) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Input = styled.input`
  display: none;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
  
  > * {
    margin-right: 30px;
    margin-bottom: 30px;
  }
`;

const Preview = styled.div`
  position: relative;
`

const Image = styled.img`
  width: 117px;
  height: 79px;
  object-fit: cover;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: center/cover no-repeat url('/images/icon-delete.svg');
  border: none;
  outline: none;
  transform: translate3d(50%, -50%, 0);
  
  &:hover {
    background-image: url('/images/icon-delete-hovered.svg');
  }
`;

const ImageUploader: FunctionComponent<ImageUploaderProps> = ({
    images,
    accept,
    onImagesChange,
}) => {
    const ref = useRef(null);
    const [urls, setUrls] = useState<string[]>([]);
    const { t } = useTranslation();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            images && onImagesChange && onImagesChange([
                ...images,
                event.target.files[0],
            ]);
            setUrls([
                ...urls,
                URL.createObjectURL(event.target.files[0]),
            ]);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ref && (ref.current! as HTMLInputElement).click()
    };

    const handleDelete = (index: number) => {
        if (images && onImagesChange) {
            const newImages = [...images];
            newImages.splice(index, 1);
            onImagesChange(newImages);
        }
        const newUrls = [...urls];
        newUrls.splice(index, 1);
        setUrls(newUrls);
    };

    useEffect(() => {
        images && setUrls(images.map((image) => (
            typeof image === 'string' ? image : URL.createObjectURL(image)
        )));
    }, [images]);

    return (
        <Container>
            <Input ref={ref} type="file" onChange={handleChange} accept={accept}/>
            <Button variant="tertiary" onClick={handleClick}>{t('core.select_file')}</Button>
            <PreviewContainer>
                {urls.map((url, index) => (
                    <Preview key={url}>
                        <Image src={url} />
                        <DeleteButton onClick={() => handleDelete(index)} />
                    </Preview>
                ))}
            </PreviewContainer>
        </Container>
    );
};

export default ImageUploader;