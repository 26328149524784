import {FunctionComponent, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import Page from "../../../core/components/Page";
import Button from "../../../core/components/Button";
import Column from "../../../core/components/Column";
import { useNavigate, useParams } from "react-router-dom";
import { useReducerContext } from "../../../core/contexts/ReducerContext";
import ACTIONS from "../../../core/constants/actions";
import { getProposal } from "../../apis/proposals";
import {SupportPlan} from "../../types";
import {updateProposalSupportPlans} from "../../apis/supportPlan";
import SupportPlansForm from "./SupportPlansForm";
import {SupportPlanExtractType} from "./types";
import {MAX_OTHER_TARGET_COUNT} from "./constants";

interface SupportPlansPageProps {}

const LocalPage = styled(Page)`
  padding: 40px 0 0 0;
  & > div {
    width: 1000px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

const PageHeaderContainer = styled(Column)`
  width: 100%;
  margin-bottom: 20px;
  gap: 40px;
`;

const InteractionContainer = styled(Column)`
  width: 100%;
  gap: 20px;
  font-size: 26px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const DraftButton = styled(Button).attrs({
  variant: 'secondary'
  })`
  min-width: 160px;
  font-weight: bold;
  gap: 10px;

  &:hover {
    path {
      fill: #444444; 
    }
  }
`;

const SupportPlansFormPage: FunctionComponent<
  SupportPlansPageProps
> = () => {
  const { proposalId } = useParams();
  const { dispatch } = useReducerContext();
  const navigate = useNavigate();
  const [supportPlan, setSupportPlan] = useState<SupportPlan>();
  const [otherTargetsDisplayCount, setOtherTargetsDisplayCount] = useState<number>(0);

  const onChangeSupportPlan = (val: Partial<SupportPlan>, isOtherTargets?: boolean) => {
    setSupportPlan((prevCondition) => {
      return prevCondition
        ? {
            ...prevCondition,
            ...val,
          }
        : prevCondition;
    });
  };

  const calcOtherTargetsDisplayCount = (supportPlan: SupportPlan) => {
    let displayCount = 0;
    for (let i = 1; i <= MAX_OTHER_TARGET_COUNT; i++) {
      if (supportPlan.targetNumberOfPeople > 0)
        displayCount = 1;

      if (
        supportPlan?.[`otherTarget_${i}FirstName` as SupportPlanExtractType<string>] ||
        supportPlan?.[`otherTarget_${i}LastName` as SupportPlanExtractType<string>] ||
        supportPlan?.[`otherTarget_${i}Gender` as SupportPlanExtractType<number>] ||
        supportPlan?.[`otherTarget_${i}DateOfBirth` as SupportPlanExtractType<string>] ||
        supportPlan?.[`otherTarget_${i}NationalityId` as SupportPlanExtractType<number>] ||
        supportPlan?.[`otherTarget_${i}NationalityOther` as SupportPlanExtractType<string>]
      ) {
        displayCount = i;
      }
    }
    setOtherTargetsDisplayCount(displayCount);
  }

  const addOtherTarget = () => {
    setOtherTargetsDisplayCount((prev) => prev + 1);
  }

  const deleteAllOtherTargets = () => {
    setOtherTargetsDisplayCount(0);
    setSupportPlan((prevCondition) => {
      return prevCondition
        ? {
          ...prevCondition,
          otherTarget_1FirstName: null,
          otherTarget_1LastName: null,
          otherTarget_1Gender: null,
          otherTarget_1DateOfBirth: null,
          otherTarget_1NationalityId: null,
          otherTarget_1NationalityOther: null,
          otherTarget_2FirstName: null,
          otherTarget_2LastName: null,
          otherTarget_2Gender: null,
          otherTarget_2DateOfBirth: null,
          otherTarget_2NationalityId: null,
          otherTarget_2NationalityOther: null,
          otherTarget_3FirstName: null,
          otherTarget_3LastName: null,
          otherTarget_3Gender: null,
          otherTarget_3DateOfBirth: null,
          otherTarget_3NationalityId: null,
          otherTarget_3NationalityOther: null,
          otherTarget_4FirstName: null,
          otherTarget_4LastName: null,
          otherTarget_4Gender: null,
          otherTarget_4DateOfBirth: null,
          otherTarget_4NationalityId: null,
          otherTarget_4NationalityOther: null,
          otherTarget_5FirstName: null,
          otherTarget_5LastName: null,
          otherTarget_5Gender: null,
          otherTarget_5DateOfBirth: null,
          otherTarget_5NationalityId: null,
          otherTarget_5NationalityOther: null,
          otherTarget_6FirstName: null,
          otherTarget_6LastName: null,
          otherTarget_6Gender: null,
          otherTarget_6DateOfBirth: null,
          otherTarget_6NationalityId: null,
          otherTarget_6NationalityOther: null,
          otherTarget_7FirstName: null,
          otherTarget_7LastName: null,
          otherTarget_7Gender: null,
          otherTarget_7DateOfBirth: null,
          otherTarget_7NationalityId: null,
          otherTarget_7NationalityOther: null,
          otherTarget_8FirstName: null,
          otherTarget_8LastName: null,
          otherTarget_8Gender: null,
          otherTarget_8DateOfBirth: null,
          otherTarget_8NationalityId: null,
          otherTarget_8NationalityOther: null,
          otherTarget_9FirstName: null,
          otherTarget_9LastName: null,
          otherTarget_9Gender: null,
          otherTarget_9DateOfBirth: null,
          otherTarget_9NationalityId: null,
          otherTarget_9NationalityOther: null,
          otherTarget_10FirstName: null,
          otherTarget_10LastName: null,
          otherTarget_10Gender: null,
          otherTarget_10DateOfBirth: null,
          otherTarget_10NationalityId: null,
          otherTarget_10NationalityOther: null,
        }
        : prevCondition;
    });
  }

  const deleteOtherTarget = (index: number) => {
    setSupportPlan((prevCondition) => {
      if (!prevCondition) {
        return prevCondition;
      }

      let updatedOtherTargets = {...prevCondition};

      for (let i = index + 1; i <= MAX_OTHER_TARGET_COUNT; i++) {
        if (i === MAX_OTHER_TARGET_COUNT) {
          return {
            ...updatedOtherTargets,
            [`otherTarget_${i}FirstName`]: null,
            [`otherTarget_${i}LastName`]: null,
            [`otherTarget_${i}Gender`]: null,
            [`otherTarget_${i}DateOfBirth`]: null,
            [`otherTarget_${i}NationalityId`]: null,
            [`otherTarget_${i}NationalityOther`]: null,
          }
        }
        updatedOtherTargets = {
          ...updatedOtherTargets,
          [`otherTarget_${i}FirstName`]: prevCondition[`otherTarget_${i + 1}FirstName` as SupportPlanExtractType<string>],
          [`otherTarget_${i}LastName`]: prevCondition[`otherTarget_${i + 1}LastName` as SupportPlanExtractType<string>],
          [`otherTarget_${i}Gender`]: prevCondition[`otherTarget_${i + 1}Gender` as SupportPlanExtractType<number>],
          [`otherTarget_${i}DateOfBirth`]: prevCondition[`otherTarget_${i + 1}DateOfBirth` as SupportPlanExtractType<string>],
          [`otherTarget_${i}NationalityId`]: prevCondition[`otherTarget_${i + 1}NationalityId` as SupportPlanExtractType<number>],
          [`otherTarget_${i}NationalityOther`]: prevCondition[`otherTarget_${i + 1}NationalityOther` as SupportPlanExtractType<string>],
        }
      }
    });
    setOtherTargetsDisplayCount((prev) => prev - 1);
  }

  const commonProps = useMemo(() => (
    { supportPlan,
       otherTargetsDisplayCount,
      onChangeSupportPlan,
      addOtherTarget,
      deleteAllOtherTargets,
      deleteOtherTarget
    }), [supportPlan, otherTargetsDisplayCount]);

  const onClickSave = async () => {
    if (!supportPlan) return;

    try {
      dispatch({ type: ACTIONS.START_LOADING });
      const updatedSp = await updateProposalSupportPlans(
        supportPlan.id,
        supportPlan
      );

      setSupportPlan(updatedSp);

      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "success",
          message: "保存しました",
        },
      });
    } catch {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: "保存に失敗しました",
        },
      });
    } finally {
      dispatch({ type: ACTIONS.STOP_LOADING });
    }
  };

  useEffect(() => {
    if (!proposalId) {
      navigate("/applications");
      return;
    }

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return "";
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    (async () => {
      try {
        dispatch({ type: ACTIONS.START_LOADING });
        const proposal = await getProposal(proposalId);
        const sp = proposal.supportPlan;
        if (sp) {
          setSupportPlan(sp);
          calcOtherTargetsDisplayCount(sp);
        }
      } finally {
        dispatch({ type: ACTIONS.STOP_LOADING });
      }
    })();

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <LocalPage>
      <ContentWrapper>
        <PageHeaderContainer>
          <InteractionContainer>
            1号特定技能外国人支援計画書
            <ActionButtonsContainer>
              <DraftButton onClick={onClickSave}>
                <span>下書き保存</span>
              </DraftButton>
            </ActionButtonsContainer>
          </InteractionContainer>
        </PageHeaderContainer>
          <SupportPlansForm
            {...commonProps}
          />
          <ActionButtonsContainer>
            <DraftButton onClick={onClickSave}>下書き保存</DraftButton>
          </ActionButtonsContainer>
      </ContentWrapper>
    </LocalPage>
  );
};

export default SupportPlansFormPage;
