import { FunctionComponent } from "react";
import { JobFormSectionProps } from "../type";
import {
  Column5,
  CurrencyInput,
  FieldCell,
  GridRow,
  GridRowsContainer,
  LabelCell,
  LocalNumberFieldSmall,
  LocalSelect,
  Note,
  RadioButtonsWrapper,
  Row5,
} from "../commonStyle";
import Label from "../../../../core/components/Label";
import Radio from "../../../../core/components/Radio";
import Text from "../../../../core/components/Text";
import { JobDormType } from "../../../enums/job";
import { COMMUTE_METHOD_ITEMS } from "../../../constants/options";
import styled from "styled-components";
import { useTranslation } from "react-i18next";


interface LivingEnvironmentSectionProps extends JobFormSectionProps {}

const CoveredExpensesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 12px;
  background-color: #f7f7f7;
  flex-grow: 0;
  width: max-content;
`;

const SingleHouseInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 12px;
  margin-top: 10px;
  background-color: #f7f7f7;
  width: 100%;
`;

const LivingEnvironmentSection: FunctionComponent<LivingEnvironmentSectionProps> = ({ 
  job, 
  onChange 
}) => {
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });
  const { t: tCore } = useTranslation('translation', { keyPrefix: 'core' });

  return (
    <>
      <GridRowsContainer>
        {/* 宿舎 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('dormitory')}</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio 
                value={""}
                checked={job?.dormitory === true}
                onValueChange={() => onChange?.({ dormitory: true })}
              >
                {tJob('applicable')}
              </Radio>
              <Radio 
                value={""}
                checked={job?.dormitory === false}
                onValueChange={() => onChange?.({ dormitory: false })}
              >
                {tJob('not_applicable')}
              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

        {/* 宿舎の種類 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('dormitory_type')}</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio 
                value={JobDormType.Apartment}
                checked={job?.dormitoryType === JobDormType.Apartment}
                onValueChange={value => onChange?.({ dormitoryType: value })}
              >
                {tJob('apartment')}
              </Radio>
              <Radio 
                value={JobDormType.SingleHouse}
                checked={job?.dormitoryType === JobDormType.SingleHouse}
                onValueChange={value => onChange?.({ dormitoryType: value })}
              >
                {tJob('single_house')}
              </Radio>
            </RadioButtonsWrapper>
            { job?.dormitoryType === JobDormType.SingleHouse &&
              <SingleHouseInfoContainer>
                <Note>寝室は何人用か入力してください。</Note>
                <LocalNumberFieldSmall 
                  placeholder="2"
                  value={job?.bedroomPersons ?? ""}
                  onValueChange={value => onChange?.({ bedroomPersons: value })}
                />
              </SingleHouseInfoContainer>
            }
          </FieldCell>
        </GridRow>

        {/* 職場までの通勤時間 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('commute_method_and_time')}</Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <Column5>
                <Note>{tJob('commute_method')}</Note>
                <LocalSelect 
                  placeholder={tCore('select')}
                  options={COMMUTE_METHOD_ITEMS}
                  value={job?.commuteMethod}
                  onValueChange={value => onChange?.({ commuteMethod: value })}
                />
              </Column5>
              <Column5>
                <Note>{tJob('total_commuting_time_minutes')}</Note>
                <LocalNumberFieldSmall 
                  placeholder="20" 
                  value={job?.commuteMinutes ?? ""}
                  onValueChange={value => onChange?.({ commuteMinutes: value })}
                />
              </Column5>
            </Row5>
          </FieldCell>
        </GridRow>

        {/* 家具・家電 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('furniture_appliances')}</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio 
                checked={job?.furnitureAppliances === true}
                onValueChange={() => onChange?.({ furnitureAppliances: true })}
              >
                {tJob('provided')}
              </Radio>
              <Radio
                checked={job?.furnitureAppliances === false}
                onValueChange={() => onChange?.({ furnitureAppliances: false })}
              >
                {tJob('not_provided')}
              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

        {/* 引っ越し交通費用 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('moving_expenses_transportation')}</Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>
                {tJob('moving_expenses_transportation_description')}
              </Note>
              <RadioButtonsWrapper>
                <Radio
                  checked={job?.transportationMovingExpensesCovered === true}
                  onValueChange={() => onChange?.({ transportationMovingExpensesCovered: true })}
                >
                  {tJob('covered_by_employer')}
                </Radio>
                <Radio
                  checked={job?.transportationMovingExpensesCovered === false}
                  onValueChange={() => onChange?.({ transportationMovingExpensesCovered: false })}
                >
                  {tJob('paid_by_employee')}
                </Radio>
              </RadioButtonsWrapper>
              { !!job?.transportationMovingExpensesCovered &&
                <CoveredExpensesContainer>
                  <Text>{tJob('support_allowance')}</Text>
                  <CurrencyInput
                    placeholder="0"
                    value={job?.transportationMovingExpensesMaximumSupportAllowance ?? ""}
                    onValueChange={value => onChange?.({ transportationMovingExpensesMaximumSupportAllowance: value })}
                    style={{ width: 126 }}
                  />
                </CoveredExpensesContainer>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 引越荷物配送費用 */}
        <GridRow>
          <LabelCell>
            <Label>{tJob('moving_expenses_baggage')}</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={job?.baggageDeliveryMovingExpensesCovered === true}
                onValueChange={() => onChange?.({ baggageDeliveryMovingExpensesCovered: true })}
              >
                {tJob('covered_by_employer')}
              </Radio>
              <Radio
                checked={job?.baggageDeliveryMovingExpensesCovered === false}
                onValueChange={() => onChange?.({ baggageDeliveryMovingExpensesCovered: false })}
              >
                {tJob('paid_by_employee')}
              </Radio>
            </RadioButtonsWrapper>
            { job?.baggageDeliveryMovingExpensesCovered &&
              <CoveredExpensesContainer>
                <Text>{tJob('support_allowance')}</Text>
                <CurrencyInput
                  placeholder="0"
                  value={job?.baggageDeliveryMovingExpensesMaximumSupportAllowance ?? ""}
                  onValueChange={value => onChange?.({ baggageDeliveryMovingExpensesMaximumSupportAllowance: value })}
                  style={{ width: 126 }}
                />
              </CoveredExpensesContainer>
            }
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default LivingEnvironmentSection;
