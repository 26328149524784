import { EmploymentOfferLetter } from "../../../core/types/api";
import { apiUtil } from "../../../core/utils";
import { EmploymentCondition } from "../../types";


export const getEmploymentOfferLetter = async (proposalId: string | number, token: string) => {
  const response = await apiUtil.get(
      `/public/proposals/${proposalId}/employment_offer?token=${token}`,
  );
  if (response.status >= 200 && response.status < 300) {
      return response.data as EmploymentOfferLetter;
  }
  throw new Error('API Error');
};


export const getEmploymentCondition = async (ecId: string | number, token: string) => {
  const response = await apiUtil.get(
      `/public/proposal_employment_conditions/${ecId}?token=${token}`,
  );
  if (response.status >= 200 && response.status < 300) {
      return response.data as EmploymentCondition;
  }
  throw new Error('API Error');
}