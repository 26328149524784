export const PROPOSAL_CANCELLATION_REASON_OPTIONS = [
    {
        label: 'proposal.reason_accepted_a_different_job',
        value: 'accepted_a_different_job',
    },
    {
        label: 'proposal.reason_wage_does_not_meet_expectations',
        value: 'wage_does_not_meet_expectations',
    },
    {
        label: 'proposal.reason_living_conditions_are_insufficient',
        value: 'living_conditions_are_insufficient',
    },
    {
        label: 'proposal.reason_location_is_too_far_away_from_the_city_center_urban_area',
        value: 'location_is_too_far_away_from_the_city_center_urban_area',
    },
    {
        label: 'proposal.reason_lack_of_holidays',
        value: 'lack_of_holidays',
    },
    {
        label: 'proposal.reason_other',
        value: 'other',
    },
];

export const EMPLOYER_INTERVIEW_CANCELLATION_REASON_OPTIONS = [
    {
        label: 'proposal.reason_conflicting_schedule',
        value: 'conflicting_schedule',
    },
    {
        label: 'proposal.reason_change_in_schedule',
        value: 'change_in_schedule',
    },
    {
        label: 'proposal.reason_other',
        value: 'other',
    },
];

export const PARTNER_INTERVIEW_CANCELLATION_REASON_OPTIONS = [
    {
        label: 'proposal.reason_change_in_work_schedule',
        value: 'change_in_work_schedule',
    },
    {
        label: 'proposal.reason_urgent_family_event',
        value: 'urgent_family_event',
    },
    {
        label: 'proposal.reason_other',
        value: 'other',
    },
];

export const INTERVIEW_DECLINE_REASON_OPTIONS = [
    {
        label: 'proposal.decline_proposal_reason.experience',
        value: 'experience',
    },
    {
        label: 'proposal.decline_proposal_reason.condition',
        value: 'condition',
    },
    {
        label: 'proposal.decline_proposal_reason.other',
        value: 'other',
    },
];

export const DOCUMENT_KINDS = {
    OFFER_LETTER: 'offer_letter',
    EMPLOYMENT_CONDITIONS: 'employment_conditions',
    OTHER: 'other',
};