import { ButtonHTMLAttributes, FunctionComponent, useEffect, useMemo, useState } from "react";
import Button from "../../../../core/components/Button";
import styled from "styled-components";
import { ReactComponent as Reload } from "../../../../assets/icon-reload.svg";
import { Row10, UnitText } from "../commonStyle";
import CurrencyField from "../../../../core/components/CurrencyField";
import { MOBILE_DEVICE } from "../../../../core/constants/styles";
import { useTranslation } from "react-i18next";

interface AmountCalcFieldProps extends ButtonHTMLAttributes<HTMLButtonElement>  {
  text?: string;
  sum?: number;
  sumInputs?: (number|null|undefined) []
  allowNegative?: boolean;
  onValueChange?: (value?: number) => void;
}

const LocalButton = styled(Button)`
  padding: 0 15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media ${MOBILE_DEVICE} {
    width: auto;
  }
`;

const LocalCurrencyField = styled(CurrencyField)`
  width: 180px;
  padding: 0 10px;
`;

const ReloadIcon = styled(Reload)`
  width: 12px;
  height: 12px;
  path {
    fill: #5F6368 !important;
  }
`;


const AmountCalcField: FunctionComponent<AmountCalcFieldProps> = ({ 
  text,
  sumInputs,
  sum,
  allowNegative,
  onValueChange
}) => {
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });
  const { t: tCore } = useTranslation('translation', { keyPrefix: 'core' });
  const [isDisabledAutoCalc, setIsDisabledAutoCalc] = useState<boolean>(false);
  const [currentSum, setCurrentSum] = useState<number>();
  const [manualSum, setManualSum] = useState<number>();
  const calculatedSum = useMemo(() => {
    const filtered = sumInputs?.filter(v => v);
    if (!filtered || filtered.length === 0)
      return;
    
    return filtered.reduce((a, b) => (a! + b!));
  }, [sumInputs]);


  useEffect(() => {
    if (isDisabledAutoCalc) {
      const newSum = sum ?? manualSum;
      newSum && onValueChange?.(newSum);
      setCurrentSum(newSum);
    } else if (calculatedSum) { 
      onValueChange?.(calculatedSum);
      setCurrentSum(calculatedSum);
    }
  }, [sum, isDisabledAutoCalc, calculatedSum, manualSum]);

  return (
    <Row10>
      <LocalCurrencyField
        allowNegative={allowNegative}
        placeholder="自動的に計算されます"
        value={currentSum}
        onValueChange={value => {
          setIsDisabledAutoCalc(true);
          setManualSum(value);
          onValueChange?.(value);
        }}
      />
      <UnitText>{tCore('units.yen')}</UnitText>
      <LocalButton 
        variant="inline"
        onClick={() => {
          setIsDisabledAutoCalc(false)
          setManualSum(manualSum)
        }}
      >
        <ReloadIcon 
            style={{ marginRight: 5 }}
        />
          { text ?? tJob('calculate_again') }
      </LocalButton>
    </Row10>
  )
}

export default AmountCalcField;