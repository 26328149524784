import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Row from '../../../core/components/Row';
import Section from '../../../core/components/Section';
import SectionHeading from '../../../core/components/SectionHeading';
import Select, { SelectOption } from '../../../core/components/Select';
import TextButton from '../../../core/components/TextButton';
import UploadButton from '../../../core/components/UploadButton';
import { AdditionalInformationType } from '../../types/api';
import { MOBILE_DEVICE } from '../../../core/constants/styles';

type Upload = {
    id?: number;
    type: string;
    file?: File | string;
    removed?: boolean;
};

type AdditionalInformationSectionProps = {
    types: AdditionalInformationType[];
    uploads: Upload[]
    onUploadsChange?: (uploads: Upload[]) => void;
};

const ImageButton = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 16px;
  object-fit: cover;
  cursor: pointer;
`;

const LocalRow = styled(Row)`
  align-items: center;
  width: 100%;
  
  > *:not(:first-child) {
    margin-left: 36px;
  }
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    align-items: flex-start;
    
    > *:not(:first-child) {
      margin-top: 10px;
      margin-left: 0;
    }
  }
`;

const AdditionalInformationSection: FunctionComponent<AdditionalInformationSectionProps> = ({
    types,
    uploads,
    onUploadsChange,
}) => {
    const [typeOptions, setTypeOptions] = useState<SelectOption[]>([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const typeOptions = types.map((type) => ({
            label: i18n.exists(`candidate.additional_information_types.${type.tkey}`)
                ? t(`candidate.additional_information_types.${type.tkey}`)
                : type.name,
            value: String(type.id),
        }));
        setTypeOptions(typeOptions);
    }, [types]);

    const handleTypeChange = (index: number, type: string) => {
        const newUploads = [...uploads];
        newUploads.splice(index, 1, {
            ...uploads[index],
            type,
        });
        onUploadsChange && onUploadsChange(newUploads);
    };

    const handleFileChange = (index: number, file?: File) => {
        const newUploads = [...uploads];
        newUploads.splice(index, 1, {
            ...uploads[index],
            file,
        });
        onUploadsChange && onUploadsChange(newUploads);
    };

    const handleUploadsAdd = () => {
        onUploadsChange && onUploadsChange([
            ...uploads,
            {
                type: '',
                file: undefined,
            },
        ]);
    };

    const handleUploadRemove = (index: number) => {
        const newUploads = [...uploads];
        newUploads.splice(index, 1, {
            ...uploads[index],
            removed: true,
        });
        onUploadsChange && onUploadsChange(newUploads);
    };

    return (
        <Section>
            <SectionHeading>{t('candidate.additional_information')}</SectionHeading>
            {uploads && uploads
                .map((upload, index) => (upload.removed
                    ? null
                    : (
                        <LocalRow key={index} center>
                            <Select
                                disabled={!!upload.id}
                                options={typeOptions}
                                value={upload.type}
                                onValueChange={(value) => handleTypeChange(index, value)}
                            />
                            <UploadButton
                                style={{ marginLeft: 36 }}
                                file={upload.file}
                                onFileChange={(file) => handleFileChange(index, file)}
                            />
                            <ImageButton
                                src="/images/icon-close.svg"
                                onClick={() => handleUploadRemove(index)}
                            />
                        </LocalRow>
                    )
                ))
                .filter(node => !!node)
            }
            <TextButton style={{ marginTop: 20 }} onClick={handleUploadsAdd}>
                <img style={{ marginRight: 5 }} alt="add" src="/images/icon-add.svg" />
                {t('candidate.add_more_files')}
            </TextButton>
        </Section>
    );
};

export default AdditionalInformationSection;
