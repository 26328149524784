import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from './Button';
import Column from './Column';
import Modal from './Modal';
import PageHeading from './PageHeading';
import Radio from './Radio';
import Text from './Text';
import TextInput from './TextInput';

interface UserInvitationModalProps {
    onSubmit?: (role: string, email: string) => void;
    onClose?: () => void;
}

const Container = styled.div`
  width: 528px;
  padding: 20px;
  background: #FFFFFF;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  > *:not(:first-child) {
    margin-left: 18px;
  }
`;

const RadioRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  
  > *:first-child {
    width: 80px;
  }
  
  > *:not(:first-child) {
    flex: 1;
    margin-left: 10px;
  }
`;

const Description = styled(Text)`
  font-size: 12px;
  color: #999999;
`;

const UserInvitationModal: FunctionComponent<UserInvitationModalProps> = ({
    onSubmit,
    onClose,
}) => {
    const [role, setRole] = useState('Admin');
    const [email, setEmail] = useState('');
    const { t } = useTranslation();

    return (
        <Modal open onClose={onClose}>
            <Container>
                <PageHeading>{t('core.invite_user')}</PageHeading>
                <Column style={{ marginTop: 20 }}>
                    <Text style={{ display: 'none' }}>
                        {t('core.role')}
                    </Text>

                    <Column style={{ display: 'none', marginTop: 10 }}>
                        <RadioRow>
                            <Radio
                                disabled
                                checked={role === 'User'}
                                value="User"
                                onValueChange={setRole}
                            >
                                {t('core.member')}
                            </Radio>
                            <Description>
                                {t('core.role_description_member')}
                            </Description>
                        </RadioRow>
                        <RadioRow style={{ marginTop: 10 }}>
                            <Radio
                                checked={role === 'Admin'}
                                value="Admin"
                                onValueChange={setRole}
                            >
                                {t('core.admin')}
                            </Radio>
                            <Description>
                                {t('core.role_description_admin')}
                            </Description>
                        </RadioRow>
                    </Column>

                    <Column style={{ width: '100%' }}>
                        <Text>{t('core.email')}</Text>
                        <TextInput
                            style={{ width: '100%', marginTop: 5 }}
                            value={email}
                            onTextChange={setEmail}
                        />
                    </Column>
                </Column>
                <ActionContainer style={{ marginTop: 40 }}>
                    <Button
                        disabled={!role || !email}
                        variant="primary"
                        onClick={() => onSubmit && onSubmit(role, email)}
                    >
                        {t('core.send_invitation')}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={onClose}
                    >
                        {t('core.send_cancel')}
                    </Button>
                </ActionContainer>
            </Container>
        </Modal>
    )
};

export default UserInvitationModal;