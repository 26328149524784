import React, { ChangeEvent, FocusEvent, InputHTMLAttributes, WheelEvent, useState } from 'react';
import styled from 'styled-components';
import { MOBILE_DEVICE } from '../constants/styles';
import { ReactComponent as EyeIcon } from '../../assets/icon-eye.svg';
import { ReactComponent as EyeSlashIcon } from '../../assets/icon-eye-slash.svg';
import Row from './Row';
import Column from './Column';

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
    error?: boolean;
    onTextChange?: (value: string) => void;
};

const Input = styled.input<TextInputProps>`
  min-height: 30px;
  padding: 0 4px;
  font-size: 14px;
  color: #444444;
  background: ${(props: TextInputProps) => props.error ? '#FFF0F0' : '#FFFFFF'};
  border: 1px solid ${(props: TextInputProps) => props.error ? '#E93232' : '#999999'};
  border-radius: 3px;
  box-sizing: border-box;
  outline: none;
  
  &[type=number] {
    text-align: right;
  }
  
  &:disabled {
    color: #999999;
    background: #D5D5D5;
  }
  
  &:focus {
    border-color: #444444;
  }
  
  &:invalid {
    background: #FFF0F0;
    border-color: #E93232;
  }
  
  &::placeholder {
    color: #999999;
  }
  
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::-ms-reveal {
    display: none
  }

  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const InputContainer = styled(Row)`
  width: 100%;
`;

const PasswordIconContainer = styled(Column)`
  cursor: pointer;
  height: 30px;
  width: 40px;
  border: 1px solid #8A8E94;
  border-left: none;
  align-items: center;
  justify-content: center;
  background-color: #EDEDED;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    ({
         onTextChange,
         ...props
     }, ref) => {
        const [showPassword, setShowPassword] = useState<boolean>(false);
        const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
            event.target.select();
        };

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            onTextChange && onTextChange(event.target.value);
        };

        const handleWheel = (event: WheelEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            target.blur();
            event.stopPropagation();
            setTimeout(() => {
                target.focus();
            }, 0);
        };

        if (props.type === 'password')
            return (
                <InputContainer>
                    <Input
                        ref={ref}
                        onFocus={handleFocus}
                        onChange={handleChange}
                        onWheel={handleWheel}
                        {...props}
                        type={showPassword
                          ? 'text' 
                          : 'password'}
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                    />
                    <PasswordIconContainer onClick={() => setShowPassword(!showPassword)}>
                        {showPassword
                            ? <EyeSlashIcon  />
                            : <EyeIcon />
                        }
                    </PasswordIconContainer>                  
                </InputContainer>
            )

        return (
            <Input
                ref={ref}
                onFocus={handleFocus}
                onChange={handleChange}
                onWheel={handleWheel}
                {...props}
            />
        );
    },
);

export default TextInput;