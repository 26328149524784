import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { MOBILE_DEVICE } from '../constants/styles';

type ButtonGroupProps = HTMLAttributes<HTMLDivElement> & {
    children?: ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  > *:not(:first-child) {
    margin-left: 32px;
  }
  
  @media ${MOBILE_DEVICE} {
    flex-direction: column;
    align-items: flex-start;

    > *:not(:first-child) {
      margin-top: 10px;
      margin-left: 0;
    }
  }
`;

const ButtonGroup: FunctionComponent<ButtonGroupProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default ButtonGroup;