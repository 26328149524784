import {
    FunctionComponent,
    HTMLAttributes,
    MutableRefObject,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Candidate } from '../../candidate/types/api';
import Checkbox from '../../core/components/Checkbox';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import { encodeCandidateId } from '../utils';
import { useJobPostCategory } from '../../core/hooks';

interface CandidateListTableProps extends HTMLAttributes<HTMLDivElement> {
    minimized?: boolean;
    candidates?: Candidate[];
    selectedIndexes?: number[];
    onItemClick?: (index: number) => void;
    onItemSelect?: (index: number) => void;
    onAllToggle?: () => void;
}

const Container = styled.div`
  width: 100%;
`;

const Item = styled.div`
  display: contents;
  
  &:hover {
    > * {
      background: #FFDFD1;
      border-top: 2px solid #FFC194;
      border-bottom: 2px solid #FFC194;
    }

    > *:first-child {
      border-left: 2px solid #FFC194;
    }

    > *:last-child {
      border-right: 2px solid #FFC194;
    }
  }

  > * {
    max-height: 40px;
    min-height: 40px;
    background: #FFFFFF;
    border-bottom: 1px solid #D5D5D5;
    cursor: pointer;
  }
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content auto;
  width: 100%;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  overflow: auto;

  > ${Item}:last-child {
    &:hover {
      > * {
        background: #FFDFD1;
        border-bottom: 2px solid #FFC194;
      }

      > *:first-child {
        border-bottom-left-radius: 5px;
      }

      > *:last-child {
        border-bottom-right-radius: 5px;
      }
    }

    > * {
      border-bottom: none;
    }
  }
`;

const Header = styled.div`
  display: contents;

  > * {
    position: sticky;
    top: 0;
    max-height: 40px;
    min-height: 40px;
    background: #E5E5E5;
    border-bottom: 1px solid #D5D5D5;
    z-index: 2;
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
`;

const CandidateListTable: FunctionComponent<CandidateListTableProps> = ({
    minimized,
    candidates,
    selectedIndexes,
    onItemClick,
    onItemSelect,
    onAllToggle,
    ...props
}) => {
    const { state } = useReducerContext();
    const jobPostCategory = useJobPostCategory();
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
    const allSelected = candidates?.every((candidate, index) => selectedIndexes?.includes(index));

    return (
        <Container ref={containerRef} {...props}>
            <Table style={{ maxHeight: containerRef.current?.clientHeight ?? 0 }}>
                <Header>
                    <Cell>
                        <Checkbox
                            checked={allSelected}
                            onClick={onAllToggle}
                        />
                    </Cell>
                    <Cell>{t('scout.candidate_id')}</Cell>
                    <Cell>{t('job.job_type')}</Cell>
                    <Cell>{t('candidate.nationality')}</Cell>
                    <Cell>{t('candidate.gender')}</Cell>
                    <Cell>{t('candidate.age')}</Cell>
                    <Cell>{t('candidate.japanese_conversation_skills')}</Cell>
                </Header>
                {candidates?.map((candidate, index) => (
                    <Item
                        key={candidate.id}
                        onClick={() => onItemClick?.(index)}
                    >
                        <Cell
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                onItemSelect?.(index);
                            }}
                        >
                            <Checkbox checked={selectedIndexes?.includes(index)} />
                        </Cell>
                        <Cell>{encodeCandidateId(candidate.id)}</Cell>
                        <Cell>
                          {jobPostCategory.getJobTypeName(candidate.jobTypeId) || '-'}
                        </Cell>
                        <Cell>
                            {candidate.nationalityId
                                ? state.nationalities[candidate.nationalityId]?.name
                                : '-'
                            }
                        </Cell>
                        <Cell>
                            {candidate.gender
                                ? t(`core.${candidate.gender}`)
                                : '-'
                            }
                        </Cell>
                        <Cell>{candidate.age ?? '-'}</Cell>
                        <Cell>
                            {candidate.japaneseConversationSkills
                                ? t(`candidate.${candidate.japaneseConversationSkills}`)
                                : '-'
                            }
                        </Cell>
                    </Item>
                ))}
            </Table>
        </Container>
    );
};

export default CandidateListTable;