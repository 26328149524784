import { Candidate } from '../candidate/types/api';

export const isCandidateProfileComplete = (profile: Candidate) => {
    return [
        profile.image,
        profile.enFirstName,
        profile.enLastName,
        profile.age,
        profile.gender,
        profile.nationalityId,
        profile.visaStatus,
        profile.currentAddress,
        profile.japaneseConversationSkills,
        profile.jlptLevel,
        profile.currentWorkHours,
        profile.nearestPossibleDate,
        profile.jobTypeId,
        profile.email
    ].every((item) => !!item);
};