import { LanguageCode } from "../../../core/enums/language";
import { EmploymentConditionTranslation } from "../../types";

export const getYear = (date?: string | null) => date ? new Date(date).getFullYear() : "";
export const getMonth = (date?: string | null) => date ? new Date(date).getMonth() + 1 : "";
export const getDay = (date?: string | null) => (date ? new Date(date).getDate() : "");
export const pad0ToMinutes = (minutes: number) => (minutes < 10 ? `0${minutes}` : minutes);
export const formatDecimalString = (decimalStr?: string) => {
  if (!decimalStr || isNaN(Number(decimalStr))) 
    return ""; 
    
  //if the decimal is 0, omit it (e.g. 1.0 -> 1)
  const decimal = Number(decimalStr);
  return decimal % 1 === 0 ? decimal.toFixed(0) : decimal.toFixed(1);
}
export const formatAmount = (amount?: number | string) => {
  if (!amount || isNaN(Number(amount))) return "";

  return Number(amount).toLocaleString();
};

export class EmploymentConditionTranslationHandler {
  private translations: EmploymentConditionTranslation[];
  private targetLanguageCode: string;

  constructor(
    translations?: EmploymentConditionTranslation[],
    targetLanguageCode?: LanguageCode
  ) {
    this.translations = translations || [];
    this.targetLanguageCode = targetLanguageCode || LanguageCode.Japanese;
  }

  getTranslation = (
    key: keyof Omit<
      EmploymentConditionTranslation,
      "id" | "language" | "createdAt" | "updatedAt"
    >,
    langCode?: string
  ): string => {
    const code = langCode || this.targetLanguageCode;
    const translation = this.translations.find((t) => t.language.code === code);

    return translation ? translation[key] : "";
  };

  get targetLangCode () { return this.targetLanguageCode }
}
