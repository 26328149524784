import { FunctionComponent, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Job } from '../../../job/types/api';
import JobListTable from '../../components/JobListTable';
import Button from '../../../core/components/Button';
import Page from '../../../core/components/Page';
import ACTIONS from '../../../core/constants/actions';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { getJobs } from '../../../job/apis/job';
import Column from '../../../core/components/Column';
import PageHeading from '../../../core/components/PageHeading';
import Text from '../../../core/components/Text';
import { JobState } from '../../../job/enums/job';

interface ScoutCreationPageProps {
}

const LocalPage = styled(Page)`
  padding: 0;
  > * {
    width: 100%;
    gap: 20px;
  }
`;

const LocalPageHeading = styled(PageHeading)`
  font-size: 20px;
  line-height: 29px;
  font-weight: 500;
`;

const Container = styled.div`
  padding: 20px 20px 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const JobContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 20px;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  background: #FFFFFF;
`;

const JobListContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0 20px 0 20px;
`;

const ScoutCreationPage: FunctionComponent<ScoutCreationPageProps> = () => {
    const { state, dispatch } = useReducerContext();
    const jobListContainerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
    const [jobs, setJobs] = useState<Job[]>([]);
    const [selectedJobIndex, setSelectedJobIndex] = useState(-1);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            dispatch({
                type: ACTIONS.START_LOADING,
                payload: {
                    message: t('job.fetching_jobs'),
                },
            });
            try {
                const jobs = await getJobs({
                    employerId: state.company?.employer?.id,
                    state: JobState.Published,
                });
                setJobs(jobs);
            } catch (e) {
                // TODO: error handling
            }
            dispatch({
                type: ACTIONS.STOP_LOADING,
            });
        })();
    }, []);

    return (
        <LocalPage>
            <Container>
                <LocalPageHeading>
                    {t('scout.which_job_for_scouting')}
                </LocalPageHeading>
                <JobContainer>
                    <Text>
                        {t('scout.select_job')}
                    </Text>
                    <JobListContainer ref={jobListContainerRef}>
                        <JobListTable
                            style={{
                                maxHeight: jobListContainerRef.current?.clientHeight ?? 0,
                            }}
                            jobs={jobs}
                            selectedIndex={selectedJobIndex}
                            onItemClick={setSelectedJobIndex}
                            onItemSelect={setSelectedJobIndex}
                        />
                    </JobListContainer>
                    <Column style={{ width: '100%' }}>
                        <Text style={{ fontSize: '12px', lineHeight: '17px', color: '#999999' }}>
                            {t('scout.scout_notes.first')}
                        </Text>
                        <Text style={{ fontSize: '12px', lineHeight: '17px', color: '#999999' }}>
                            {t('scout.scout_notes.second')}
                        </Text>
                        <Text style={{ fontSize: '12px', lineHeight: '17px', color: '#999999' }}>
                            {t('scout.scout_notes.third')}
                        </Text>
                    </Column>
                </JobContainer>
            </Container>
            <ButtonContainer>
                <Button
                    style={{ minWidth: 200 }}
                    disabled={selectedJobIndex < 0}
                    onClick={() => navigate(`/scouts/new/${jobs[selectedJobIndex].id}`)}
                >
                    {t('scout.proceed')}
                </Button>
            </ButtonContainer>
        </LocalPage>
    );
};

export default ScoutCreationPage;