import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

type GalleryProps = HTMLAttributes<HTMLDivElement> & {
    small?: boolean;
    images?: string[];
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  
  > *:not(:first-child) {
    margin-left: 10px;
  }
  
  img {
    width: 319px;
    height: 215px;
    border-radius: 5px;
    object-fit: cover;
  }
`;

const Gallery: FunctionComponent<GalleryProps> = ({
    small,
    images,
    ...props
}) => {
    return (
        <Container {...props}>
            {images && images.map((image) => (
                <img
                    style={{
                        width: small ? 193 : 319,
                        height: small ? 130 : 215,
                    }}
                    key={image}
                    src={image}
                />
            ))}
        </Container>
    );
};

export default Gallery;