import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { Container as Field } from './Field';
import { Container as CategoryHeading } from './CategoryHeading';
import { Container as Column } from './Column';
import { Container as Row } from './Row';
import { MOBILE_DEVICE } from '../constants/styles';

type SectionProps = HTMLAttributes<HTMLDivElement> & {
    children?: ReactNode;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 37px 57px 75px 58px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 5px;

  > ${CategoryHeading}:not(:first-child) {
    margin-top: 24px;
  }

  > ${Row}:not(:first-child) {
    margin-top: 20px;
  }

  > ${Column}:not(:first-child) {
    margin-top: 20px;
  }

  > ${Field}:not(:first-child) {
    margin-top: 20px;
  }
  
  @media ${MOBILE_DEVICE} {
    padding: 20px;
    border-radius: unset;
  }
`;

const Section: FunctionComponent<SectionProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default Section;