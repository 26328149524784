import { AddressInformation } from '../types/api';
import { apiUtil } from '../utils';

export const getAddressInformation = async (postalCode: string) => {
    const response = await apiUtil.get(
        '/postal_code_addresses/find_by_postal_code',
        {
            params: { postalCode }
        }
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as AddressInformation;
    }
    throw new Error('API Error');
};
