import { apiUtil } from '../utils';

export const sendInquiry = async (name: string, email: string, phoneNumber: string, company: string, detail: string) => {
    const response = await apiUtil.post(
        'misc/inquire',
        {
            name,
            email,
            phoneNumber,
            company,
            detail,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API error');
};