import { AnchorHTMLAttributes, FunctionComponent } from 'react';
import styled from 'styled-components';

import Text from './Text';
import { useTranslation } from 'react-i18next';

type DownloadButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
};

const Container = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding-right: 17px;
  padding-left: 12px;
  background: #F2F2F2;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  text-decoration: none;
`;

const Icon = styled.img`
  width: 12px;
`;

const DownloadButton: FunctionComponent<DownloadButtonProps> = ({
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Container download {...props}>
            <Icon alt="download" src="/images/icon-download.svg" />
            <Text style={{ marginLeft: '4px' }}>{t('core.download')}</Text>
        </Container>
    );
};

export default DownloadButton;