import { ChangeEvent, FunctionComponent, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components'

type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
    onValueChange?: (value: any) => void;
    children?: ReactNode;
    error?: boolean;
}

type ContainerProps = RadioProps & {
}

type ButtonProps = RadioProps & {
}

const Input = styled.input`
  display: none;
`

const Button = styled.span<ButtonProps>`
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 6px;
  background: ${(props: ButtonProps) => props.disabled ? '#D5D5D5' : '#FFFFFF'};
  border: 1px solid ${(props: ButtonProps) => props.error ? '#E93232' : '#999999'};
  border-radius: 50%;
  box-sizing: border-box;
`

const Container = styled.label<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: ${(props: ContainerProps) => props.disabled ? '#999999' : '#444444'};
  cursor: pointer;
  pointer-events: ${(props: ContainerProps) => props.disabled ? 'none' : 'auto'};
  
  ${Input}:checked ~ ${Button} {
    border-color: #FF730D;
    
    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 9px;
      height: 9px;
      background: #FF730D;
      border-radius: 50%;
    }
  }
  
  &:hover > ${Button} {
    background: #FFC194;
    border-color: #FF730D;
  }
`

const Radio: FunctionComponent<RadioProps> = ({
    disabled,
    onValueChange,
    children,
    error,
    ...props
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onValueChange && onValueChange(event.target.value);
    }

    return (
        <Container disabled={disabled}>
            <Input
                type="radio"
                disabled={disabled}
                onChange={handleChange}
                {...props}
            />
            <Button disabled={disabled} error={error}/>
            {children}
        </Container>
    )
}

export default Radio