import { FunctionComponent, MouseEvent, ReactNode, useRef } from 'react';
import styled from 'styled-components';

interface ModalProps {
    open?: boolean;
    children?: ReactNode;
    onClose?: () => void;
}

const Container = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.34);
  opacity: ${(props: ModalProps) => props.open ? 1 : 0};
  pointer-events: ${(props: ModalProps) => props.open ? 'auto' : 'none'};
  z-index: 10;
  transition: all 300ms;
`;

const Modal: FunctionComponent<ModalProps> = ({
    open,
    children,
    onClose,
}) => {
    const ref = useRef(null);
    const handleClick = (event: MouseEvent) => {
        if (event.target === ref.current && onClose) {
            onClose()
        }
    }

    return (
        <Container ref={ref} open={open} onClick={handleClick}>
            {open ? children : null}
        </Container>
    );
};

export default Modal;