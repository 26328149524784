import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Column5,  FlexRowColumn,  InputValue,  Item10,  Item20,  Item5,  Row,  Segment } from "../../commonStyles";
import { Section, Indent, ListBlock, ListBlockContent, SectionHeadingContainer, ListBlockIndex, ListBlocksContainer } from "./styles";
import { getMonth, getYear } from "../../util";
import { TemplateSectionProps } from "../../types";
import CheckMark from "../../components/CheckMark";
import { Trans, useTranslation } from "react-i18next";


interface OtherSectionProps extends TemplateSectionProps {}


const OtherSection: FunctionComponent<OtherSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang }
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;
  const [employeesPension, setEmployeesPension] = useState<boolean>();
  const [healthInsurance, setHealthInsurance] = useState<boolean>();
  const [employmentInsurance, setEmploymentInsurance] = useState<boolean>();
  const [accidentInsurance, setAccidentInsurance] = useState<boolean>();
  const [nationalPension, setNationalPension] = useState<boolean>();
  const [nationalHealthInsurance, setNationalHealthInsurance] = useState<boolean>();
  const [other, setOther] = useState<boolean>();
  const [otherDetails, setOtherDetails] = useState<string>("");
  const [otherDetailsTrans, setOtherDetailsTrans] = useState<string>("");
  const [medicalExamAtTheTimeOfHiring, setMedicalExamAtTheTimeOfHiring] = useState<string>("");
  const [firstPeriodicMeidcalExam, setFirstPeriodicMeidcalExam] = useState<string>("");
  const [secondAndLaterPeriodicMeidcalExam, setSecondAndLaterPeriodicMeidcalExam] = useState<string>("");
  const [secondAndLaterPeriodicMeidcalExamTrans, setSecondAndLaterPeriodicMeidcalExamTrans] = useState<string>("");
  const [departmentNameInJa, setDepartmentNameInJa] = useState<string>("");
  const [departmentNameInEn, setDepartmentNameInEn] = useState<string>("");
  const [contactPersonNameInJa, setContactPersonNameInJa] = useState<string>("");
  const [contactPersonNameInEn, setContactPersonNameInEn] = useState<string>("");
  const [contactDetails, setContactDetails] = useState<string>("");
  const [contactDetailsTrans, setContactDetailsTrans] = useState<string>("");

  const OtherDetail = ({ children }: { children?: ReactNode }) =>
    <Item5><InputValue>{children}</InputValue></Item5>;

  const MedicalExamAtHiringMonth = ({ children }: { children?: ReactNode }) =>
    medicalExamAtTheTimeOfHiring ? <Item10>{children}</Item10> : <></>;

  const MedicalExamAtHiringYear = ({ children }: { children?: ReactNode }) =>
    medicalExamAtTheTimeOfHiring ? <Item10>{children}</Item10> : <></>;

  const FirstPeriodicMedicalExamMonth = ({ children }: { children?: ReactNode }) =>
    firstPeriodicMeidcalExam ? <Item10>{children}</Item10> : <></>;

  const FirstPeriodicMedicalExamYear = ({ children }: { children?: ReactNode }) =>
    firstPeriodicMeidcalExam ? <Item10>{children}</Item10> : <></>;

  const SecondAndLaterPeriodcMedicalExam = ({ children }: { children?: ReactNode }) =>
    secondAndLaterPeriodicMeidcalExam ? <Item10>{children}</Item10> : <></>;


  useEffect(() => {

    if (!ec) 
      return;
    
    setEmployeesPension(ec.otherSocialInsuranceCoverageEmployeesPension);
    setHealthInsurance(ec.otherSocialInsuranceCoverageHealthInsurance);
    setEmploymentInsurance(ec.otherSocialInsuranceCoverageEmploymentInsurance);
    setAccidentInsurance(ec.otherSocialInsuranceCoverageAccidentInsurance);
    setNationalPension(ec.otherSocialInsuranceCoverageNationalPension);
    setNationalHealthInsurance(ec.otherSocialInsuranceCoverageNationalHealthInsurance);
    setOther(ec.otherSocialInsuranceCoverageOther);
    setOtherDetails(ec.otherSocialInsuranceCoverageOtherDetail);
    setOtherDetailsTrans(transHandler.getTranslation("otherSocialInsuranceCoverageOtherDetail"));
    setMedicalExamAtTheTimeOfHiring(ec.otherMedicalExaminationAtTheTimeOfHiring);
    setFirstPeriodicMeidcalExam(ec.otherFirstPeriodicMedicalExamination);
    setSecondAndLaterPeriodicMeidcalExam(ec.otherSecondAndSubsequentPeriodicMedicalExaminations);
    setSecondAndLaterPeriodicMeidcalExamTrans(
      transHandler.getTranslation("otherSecondAndSubsequentPeriodicMedicalExaminations")
    );
    setDepartmentNameInJa(ec.otherDepartmentConsultationEmploymentManagementJa);
    setDepartmentNameInEn(ec.otherDepartmentConsultationEmploymentManagementEn);
    setContactPersonNameInJa(ec.otherManagerConsultationEmploymentManagementJa);
    setContactPersonNameInEn(ec.otherManagerConsultationEmploymentManagementEn);
    setContactDetails(ec.otherContactConsultationEmploymentManagement);
    setContactDetailsTrans(transHandler.getTranslation("otherContactConsultationEmploymentManagement"));
  }, [ec]);

  return (
    <Section>
      <SectionHeadingContainer>
        <span>IX .</span> 
        <Segment>
          <span>その他</span><br/>
          <span>{tFixed("other_contain")}</span>
        </Segment>
      </SectionHeadingContainer>
      <Indent>
        <ListBlocksContainer>
          <ListBlock>
            <ListBlockIndex>1.</ListBlockIndex>
            <ListBlockContent>
              <Column5>
                <Row>
                  <Item5>社会保険の加入状況・労働保険の適用状況（</Item5>
                  <Item5>
                    <CheckMark
                      checked={employeesPension}
                    >
                      厚生年金
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={healthInsurance}
                    >
                      健康保険
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={employmentInsurance}
                    >
                      雇用保険
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={accidentInsurance}
                    >
                      労災保険
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={nationalPension}
                    >
                      国民年金
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={nationalHealthInsurance}
                    >
                      国民健康保険
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={other}
                    >
                      <Row>
                        <Item5>その他（</Item5>
                        <Item5>
                          <InputValue>{otherDetails}</InputValue>
                        </Item5>
                        <Item5>）</Item5>
                      </Row>
                      
                    </CheckMark>
                  </Item5>
                  <Item5>）</Item5>
                </Row>
                <Row>
                  <Item5>
                    {tFixed("social_and_labor_insurance_status")} (
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={employeesPension}
                    >
                      {tFixed("employees_pension")}
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={healthInsurance}
                    >
                      {tFixed("health_insurance")}
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={employmentInsurance}
                    >
                      {tFixed('employment_insurance')}
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={accidentInsurance}
                    >
                      {tFixed("accident_insurance")}
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={nationalPension}
                    >
                      {tFixed("national_pension")}
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={nationalHealthInsurance}
                    >
                      {tFixed("national_health_insurance")}
                    </CheckMark>
                  </Item5>
                  <Item5>
                    <CheckMark
                      checked={other}
                    >
                      <Row>
                        <Trans
                          i18nKey={getTKey("other_insurance")}
                          tOptions={tOptions}
                          values={{
                            detail: otherDetailsTrans
                          }}
                          components={{
                            seg: <Item5 />,
                            detail: <OtherDetail />
                          }}
                        />
                      </Row>
                    </CheckMark>
                  </Item5>
                  <Item5>)</Item5>
                </Row>
              </Column5>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>2.</ListBlockIndex>
            <ListBlockContent>
              <Column5>
                <Row>
                  <Item10>雇入れ時の健康診断（</Item10>
                  { medicalExamAtTheTimeOfHiring &&
                    <Item10>{getYear(medicalExamAtTheTimeOfHiring)}</Item10>
                  }
                  <Item10>年</Item10>
                  { medicalExamAtTheTimeOfHiring &&
                    <Item10>{getMonth(medicalExamAtTheTimeOfHiring)}</Item10>
                  }
                  <Item10>月</Item10>
                  <Item10>）</Item10>
                </Row>
                <Row>
                  <Trans
                    i18nKey={getTKey("health_check_at_hiring")}
                    tOptions={tOptions}
                    values={{
                      month: getMonth(medicalExamAtTheTimeOfHiring),
                      year: getYear(medicalExamAtTheTimeOfHiring)
                    }}
                    components={{
                      seg: <Item10 />,
                      month: <MedicalExamAtHiringMonth />,
                      year: <MedicalExamAtHiringYear />
                    }}
                  />
                </Row>
              </Column5>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>3.</ListBlockIndex>
            <ListBlockContent>
              <Column5>
                <Row>
                  <Item10>初回の定期健康診断（</Item10>
                  { firstPeriodicMeidcalExam &&
                    <Item10>{getYear(firstPeriodicMeidcalExam)}</Item10>
                  }
                  <Item10>年</Item10>
                  { firstPeriodicMeidcalExam &&
                    <Item10>{getMonth(firstPeriodicMeidcalExam)}</Item10>
                  }
                  <Item10>月</Item10>
                  <Item10>）（その後</Item10>
                  { secondAndLaterPeriodicMeidcalExam &&
                    <Item10>{secondAndLaterPeriodicMeidcalExam}</Item10>
                  }
                  <Item10>ごとに実施）</Item10>
                </Row>
                <Row>
                  <Trans
                    i18nKey={getTKey("first_regular_health_check")}
                    tOptions={tOptions}
                    values={{
                      month: getMonth(firstPeriodicMeidcalExam),
                      year: getYear(firstPeriodicMeidcalExam),
                      period: secondAndLaterPeriodicMeidcalExamTrans
                    }}
                    components={{
                      seg: <Item10 />,
                      month: <FirstPeriodicMedicalExamMonth />,
                      year: <FirstPeriodicMedicalExamYear />,
                      period: <SecondAndLaterPeriodcMedicalExam />
                    }}
                  />
                </Row>
              </Column5>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>4.</ListBlockIndex>
            <ListBlockContent>
              <Column5>
                <Column5>
                  <span>雇用管理の改善等に関する事項に係る相談窓口</span>
                  <FlexRowColumn>
                    <Item20>
                      <Item10>部署名</Item10>
                      <Item10>{departmentNameInJa}</Item10>
                    </Item20>
                    <Item20>
                      <Item10>担当者職氏名</Item10>
                      <Item10>{contactPersonNameInJa}</Item10>
                    </Item20>
                    <Item20>
                      <Item10>（連絡先</Item10>
                      <Item10>{contactDetails}</Item10>
                      <Item10>）</Item10>
                    </Item20>
                  </FlexRowColumn>
                </Column5>
                <Column5>
                  <span>{tFixed("employment_consultation_contact")}</span>
                  <FlexRowColumn>
                    <Item20>
                      <Item10>{tFixed("department_name")}</Item10>
                      <Item10>{departmentNameInEn}</Item10>
                    </Item20>
                    <Item20>
                      <Item10>{tFixed("contact_person_name")}</Item10>
                      <Item10>{contactPersonNameInEn}</Item10>
                    </Item20>
                    <Item20>
                      <Item10>（{tFixed("contact_details")}</Item10>
                      <Item10>{contactDetailsTrans}</Item10>
                      <Item10>）</Item10>
                    </Item20>
                  </FlexRowColumn>
                </Column5>
              </Column5>
            </ListBlockContent>
          </ListBlock>
          <ListBlock>
            <ListBlockIndex>5.</ListBlockIndex>
            <ListBlockContent>
              <Column5>
                <span>本契約終了後に乙が帰国するに当たり、乙が帰国旅費を負担することができないときは、甲が当該旅費を負担するとともに、帰国が円滑になされるよう必要な措置を講じることとする。</span>
                <span>{tFixed("repatriation_expenses_and_procedures")}</span>
              </Column5>
            </ListBlockContent>
          </ListBlock>
        </ListBlocksContainer>
      </Indent>
    </Section>
  )
}

export default OtherSection;