import { FunctionComponent } from "react";
import { JobFormSectionProps } from "../type";
import { Column5, CurrencyInput, FieldCell, GridRow, GridRowsContainer, HeadingContainer, LabelCell, LocalMultiSelect, LocalMultiTextInput, LocalNumberField, Note, RadioButtonsWrapper, Row10, SectionHeadingLarge, UnitText } from "../commonStyle";
import Label from "../../../../core/components/Label";
import Radio from "../../../../core/components/Radio";
import ImageUploader from "../../../../core/components/ImageUploader";
import { JobInterviewType } from "../../../enums/job";
import { SelectOption } from "../../../../core/components/Select";
import { isEmpty } from "../../../../core/utils";
import { JobPostImage } from "../../../types/jobPost";
import ErrorMessage from "../components/ErrorMessage";
import { Trans, useTranslation } from "react-i18next";

interface ResignationOtherSectionProps extends JobFormSectionProps {
  nationalityOptions: SelectOption[];
  jobPostImages?: JobPostImage[];
  onJobPostImagesChange?: (images: JobPostImage[]) => void;
}


const ResignationOtherSection: FunctionComponent<ResignationOtherSectionProps> = ({
  job,
  onChange,
  nationalityOptions,
  jobPostImages,
  onJobPostImagesChange
}) => {
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });
  const { t: tCore } = useTranslation('translation', { keyPrefix: 'core' });
  
  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>{tJob('resignation_and_other_matters')}</SectionHeadingLarge>
      </HeadingContainer>

     <GridRowsContainer>
        {/* 自己都合退職の手続き */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('procedure_for_voluntary_resignation')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>{tJob('note_on_resignation_procedure')}</Note>
              <Row10>
                <Trans
                  i18nKey={tJob('resignation_procedure_detail')}
                  components={{
                    procedure_input: (
                      <LocalNumberField
                        value={job?.ecResignationProcedureForResignationForPersonalReasons ?? ""}
                        onValueChange={value => onChange?.({ ecResignationProcedureForResignationForPersonalReasons: value })}
                        placeholder="14"
                        style={{ width: 80 }}
                      />),
                    text: <UnitText />
                  }}                
                />
              </Row10>
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 配属準備金 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('special_allowance_upon_hire')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>{tJob('special_allowance_upon_hire_description')}</Note>
              <Row10>
                <CurrencyInput
                  value={job?.specialAllowanceUponHire ?? ""}
                  onValueChange={val => onChange?.({ specialAllowanceUponHire: val })}
                  placeholder="0"
                  style={{ 
                    width: 80, 
                    textAlign: 'left'
                  }}
                />
                <UnitText>{tCore('units.yen')}</UnitText>
              </Row10>
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 現在働いている外国人の国籍 */}        
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('nationalities_of_currently_working_foreign_employees')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalMultiSelect 
                placeholder={tCore('select')}
                options={nationalityOptions}
                value={job?.currentNationalityIds?.map(id => id.toString())}
                onValueChange={value => onChange?.({ currentNationalityIds: value })}
              />
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 面談実地方法 */}
        <GridRow>
          <LabelCell>
            <Label required>
              {tJob('interview_method')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <RadioButtonsWrapper>
                <Radio
                  checked={job?.interviewMethod === JobInterviewType.Online}
                  value={JobInterviewType.Online}
                  onValueChange={value => onChange?.({ interviewMethod: value })}
                  error={isEmpty(job?.interviewMethod)}
                >
                  {tJob('online_interview')}
                </Radio>
                <Radio
                  checked={job?.interviewMethod === JobInterviewType.Offline}
                  value={JobInterviewType.Offline}
                  onValueChange={value => onChange?.({ interviewMethod: value })}
                  error={isEmpty(job?.interviewMethod)}
                >
                  {tJob('offline_interview')}
                </Radio>
              </RadioButtonsWrapper>
              { isEmpty(job?.interviewMethod) &&
                <ErrorMessage>{tCore('input_prompt.radio_button')}</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 面談可能な曜日と時間帯 */}
        <GridRow>
          <LabelCell>
            <Label required>
              {tJob('available_days_and_times_for_interview')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalMultiTextInput
                placeholder={tJob('available_days_and_times_for_interview_description')}
                value={job?.availableDaysAndTimesForInterviews ?? ""}
                onTextChange={value => onChange?.({ availableDaysAndTimesForInterviews: value })}
                required={isEmpty(job?.availableDaysAndTimesForInterviews)}
              />
              { isEmpty(job?.availableDaysAndTimesForInterviews) &&
                <ErrorMessage>{tCore('input_prompt.text_field')}</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 求人に関するその他補足 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('other_supplementary_information_about_this_job')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>{tJob('other_supplementary_information_about_this_job_description')}</Note>
              <LocalMultiTextInput 
                placeholder={tJob('other_supplementary_information_about_this_job_example')}
                value={job?.otherSupplementaryInformationAboutThisJob ?? ""}
                onTextChange={value => onChange?.({ otherSupplementaryInformationAboutThisJob: value })}
                style={{ minHeight: 157 }}
              />
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 求人票に掲載する画像 */}
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('images_to_attach_to_job_post')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>{tJob('images_to_attach_to_job_post_description')}</Note>
              <ImageUploader 
                images={jobPostImages}
                accept={"image/jpeg, image/png"}
                onImagesChange={onJobPostImagesChange}
              />
            </Column5>
          </FieldCell>
        </GridRow>

      </GridRowsContainer>
    </>
  )
}

export default ResignationOtherSection;