import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import InterviewSettings from './InterviewSettings';
import OrganizationSettings from './OrganizationSettings';
import OrganizationSettingsPanel from './OrganizationSettingsPanel';
import UserManagement from './UserManagement';
import DocumentsSettings from './DocumentsSettings/DocumentsSettings';
import JobOfferCertificate from './DocumentsSettings/EmploymentOffer';

type SettingsPageProps = {
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  padding: 40px;
  background: #FAF6F4;
  
  > *:not(:first-child) {
    margin-left: 30px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
`;

const SettingsPage: FunctionComponent<SettingsPageProps> = () => {
    return (
        <Container>
            <OrganizationSettingsPanel />
            <Content>
                <Routes>
                    <Route path="organization" element={<OrganizationSettings />} />
                    <Route path="users" element={<UserManagement />} />
                    <Route path="interview" element={<InterviewSettings />} />
                    <Route path="documents" element={<DocumentsSettings />} />
                    <Route path="documents/employment_offer" element={<JobOfferCertificate />} />
                </Routes>
            </Content>
        </Container>
    );
};

export default SettingsPage;