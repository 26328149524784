import React, {FunctionComponent} from "react";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import {
  CheckboxesRowWrapper,
  Column5,
  FieldCell, GridRow,
  LabelCell, LocalCheckBox, WordBreakLabel,
} from "../commonStyle";
import {isEmpty} from "../../../../core/utils";
import ErrorMessage from "./ErrorMessage";

interface MethodRowProps extends SupportPlanFormProps {
  firstCheckLabel: string;
  firstCheckPropertyName: SupportPlanExtractType<boolean>;
  secondCheckLabel: string;
  secondCheckPropertyName: SupportPlanExtractType<boolean>;
}

const MethodRow: FunctionComponent<MethodRowProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
    firstCheckLabel,
    firstCheckPropertyName,
    secondCheckLabel,
    secondCheckPropertyName,
}) => {
  return (
    <>
      <GridRow>
        <LabelCell>
          <WordBreakLabel required>実施方法</WordBreakLabel>
        </LabelCell>
        <FieldCell>
          <Column5>
            <CheckboxesRowWrapper>
              <LocalCheckBox
                checked={!!supportPlan?.[firstCheckPropertyName]}
                onValueChange={(val) => {
                  onChangeSupportPlan?.({ [firstCheckPropertyName]: val});
                }}
              >
                {firstCheckLabel}
              </LocalCheckBox>
              <LocalCheckBox
                checked={!!supportPlan?.[secondCheckPropertyName]}
                onValueChange={(val) => {
                  onChangeSupportPlan?.({ [secondCheckPropertyName]: val});
                }}
              >
                {secondCheckLabel}
              </LocalCheckBox>
            </CheckboxesRowWrapper>
            {(
                isEmpty(supportPlan?.[firstCheckPropertyName]) &&
                isEmpty(supportPlan?.[secondCheckPropertyName])
              ) &&
                <ErrorMessage>必ず選択してください</ErrorMessage>
            }
          </Column5>
        </FieldCell>
      </GridRow>
    </>
  );
};

export default MethodRow;