import { apiUtil } from '../core/utils';
import { Scout } from './types';

export const createScouts = async (jobId: number | string, candidateIds: number[] | string[]) => {
    const response = await apiUtil.post(
        '/scouts/bulk_create',
        {
            jobPostId: Number(jobId),
            candidateIds: candidateIds.map(candidateId => Number(candidateId)),
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API error');
};

export const getScouts = async (params = {}) => {
    const response = await apiUtil.get(
        '/scouts',
        {
            params,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Scout[];
    }
    throw new Error('API error');
};

export const markScoutAsRead = async (scoutId: number | string) => {
    const response = await apiUtil.patch(`/scouts/${scoutId}/read`);
    if (response.status >= 200 && response.status < 300) {
        return response.data as Scout;
    }
    throw new Error('API error');
};
