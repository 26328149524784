import { FunctionComponent } from "react";
import { JobFormSectionProps } from "../type";
import { useTranslation } from "react-i18next";
import { FieldCell, GridRow, GridRowsContainer, LabelCell, LocalNumberFieldSmall, Row10, UnitText } from "../commonStyle";
import Label from "../../../../core/components/Label";


interface ExtraWagesSectionProps extends JobFormSectionProps {}



const ExtraWagesSection: FunctionComponent<ExtraWagesSectionProps> = ({
  job,
  onChange
}) => {
  const { t: tJob } = useTranslation("translation", { keyPrefix: "job" });

  return (
    <>
      <GridRowsContainer>
        {/* 所定時間外（法定超月60時間以内） */}        
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('regular_hours_beyond_legal_limits_up_to_60_hours')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row10>
              <LocalNumberFieldSmall
                placeholder="25"
                value={job?.ecCompensationExtraWageRatesOvertimeAboveLegalLte_60Hs ?? ""}
                onValueChange={(val) => onChange?.({
                  ecCompensationExtraWageRatesOvertimeAboveLegalLte_60Hs: val
                })}
              />
              <UnitText>%</UnitText>
            </Row10>
          </FieldCell>
        </GridRow>

        {/* 所定時間外（法定超月60時間超） */}        
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('regular_hours_beyond_legal_li9mits_exceeding_60_hours')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row10>
              <LocalNumberFieldSmall
                placeholder="50"
                value={job?.ecCompensationExtraWageRatesOvertimeAboveLegalGt_60Hs ?? ""}
                onValueChange={(val) => onChange?.({
                  ecCompensationExtraWageRatesOvertimeAboveLegalGt_60Hs: val
                })}
              />
              <UnitText>%</UnitText>
            </Row10>
          </FieldCell>
        </GridRow>

        {/* 所定時間外（所定超） */}        
        <GridRow>
          <LabelCell>
            <Label>
                {tJob('regular_hours_beyond_standard_limits')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row10>
              <LocalNumberFieldSmall
                placeholder="25"
                value={job?.ecCompensationExtraWageRatesOvertimeAboveNormal ?? ""}
                onValueChange={(val) => onChange?.({
                  ecCompensationExtraWageRatesOvertimeAboveNormal: val
                })}
              />
              <UnitText>%</UnitText>
            </Row10>
          </FieldCell>
        </GridRow>

        {/* 休日（法定） */}        
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('holiday_work_legal')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row10>
              <LocalNumberFieldSmall
                placeholder="35"
                value={job?.ecCompensationExtraWageRatesHolidayLegal ?? ""}
                onValueChange={(val) => onChange?.({
                  ecCompensationExtraWageRatesHolidayLegal: val
                })}
              />
              <UnitText>%</UnitText>
            </Row10>
          </FieldCell>
        </GridRow>

        {/* 休日（法定外） */}        
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('holiday_work_non_legal')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row10>
              <LocalNumberFieldSmall
                placeholder="25"
                value={job?.ecCompensationExtraWageRatesHolidayOtherThanLegal ?? ""}
                onValueChange={(val) => onChange?.({
                  ecCompensationExtraWageRatesHolidayOtherThanLegal: val
                })}
              />
              <UnitText>%</UnitText>
            </Row10>
          </FieldCell>
        </GridRow>

        {/* 深夜 */}        
        <GridRow>
          <LabelCell>
            <Label>
              {tJob('late_night_hours')}
            </Label>
          </LabelCell>
          <FieldCell>
            <Row10>
              <LocalNumberFieldSmall
                placeholder="25"
                value={job?.ecCompensationExtraWageRatesMidnight ?? ""}
                onValueChange={(val) => onChange?.({
                  ecCompensationExtraWageRatesMidnight: val
                })}
              />
              <UnitText>%</UnitText>
            </Row10>
          </FieldCell>
        </GridRow>

      </GridRowsContainer>
    </>
  )
}

export default ExtraWagesSection;