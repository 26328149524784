import { FunctionComponent, useEffect, useState } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  SectionHeading,
  TextAndInputContainer,
  Description,
  ItemTextArea,
} from "../../commonStyles";
import DateField from "../../../../../core/components/DateField";
import MultiSelect from "../../../../../core/components/MultiSelect";
import { SOCIAL_INSURACE_COVERAGE_ITEMS } from "../../../../../job/constants/options";
import { FormBlockSectionProps } from "../../types";
import styled from "styled-components";

interface OtherSectionProps extends FormBlockSectionProps {}


const LocalItemTextArea = styled(ItemTextArea)`
  height: 44px;
`;


const OtherSection: FunctionComponent<OtherSectionProps> = ({
  employmentConditions: ec,
  onChange
}) => {
  const [socialInsuranceCoverage, setSocialInsuranceCoverage] = useState<string[]>([]);

  const onSocialInsuranceCoverageChange = (vals: string[]) => {
    const newSocialInsuranceCoverageOptions: (boolean | undefined) [] = 
      SOCIAL_INSURACE_COVERAGE_ITEMS.map(item => vals.includes(item.value));

    onChange?.({
      otherSocialInsuranceCoverageEmployeesPension: newSocialInsuranceCoverageOptions[0],
      otherSocialInsuranceCoverageHealthInsurance: newSocialInsuranceCoverageOptions[1],
      otherSocialInsuranceCoverageEmploymentInsurance: newSocialInsuranceCoverageOptions[2],
      otherSocialInsuranceCoverageAccidentInsurance: newSocialInsuranceCoverageOptions[3],
      otherSocialInsuranceCoverageNationalPension: newSocialInsuranceCoverageOptions[4],
      otherSocialInsuranceCoverageNationalHealthInsurance: newSocialInsuranceCoverageOptions[5],
      otherSocialInsuranceCoverageOther: newSocialInsuranceCoverageOptions[6]
    });

    setSocialInsuranceCoverage(vals);
  }

  useEffect(() => {
    if (!ec)
      return;  

    //The order matters. 
    //It must be the same as the order of SOCIAL_INSURACE_COVERAGE_ITEMS
    const socialInsuraceCoverageOptions: (boolean | undefined) [] = [
      ec?.otherSocialInsuranceCoverageEmployeesPension,
      ec?.otherSocialInsuranceCoverageHealthInsurance,
      ec?.otherSocialInsuranceCoverageEmploymentInsurance,
      ec?.otherSocialInsuranceCoverageAccidentInsurance,
      ec?.otherSocialInsuranceCoverageNationalPension,
      ec?.otherSocialInsuranceCoverageNationalHealthInsurance,
      ec?.otherSocialInsuranceCoverageOther
    ];

    const socialInsuranceCoverageItems = 
      socialInsuraceCoverageOptions
        .map((val, i) => val ? SOCIAL_INSURACE_COVERAGE_ITEMS[i].value : "")
        .filter(v => v);
    
    setSocialInsuranceCoverage(socialInsuranceCoverageItems);
  }, [
    ec?.otherSocialInsuranceCoverageEmployeesPension,
    ec?.otherSocialInsuranceCoverageHealthInsurance,
    ec?.otherSocialInsuranceCoverageEmploymentInsurance,
    ec?.otherSocialInsuranceCoverageAccidentInsurance,
    ec?.otherSocialInsuranceCoverageNationalPension,
    ec?.otherSocialInsuranceCoverageNationalHealthInsurance,
    ec?.otherSocialInsuranceCoverageOther
  ]);

  return (
    <FormSection>
      <SectionHeading>その他</SectionHeading>
      <FormRow>
        <InputContainer>
          <ItemLabel>社会保険等の加入状況</ItemLabel>
        </InputContainer>
        <InputContainer>
          <MultiSelect 
            value={socialInsuranceCoverage}
            onValueChange={onSocialInsuranceCoverageChange}
            options={SOCIAL_INSURACE_COVERAGE_ITEMS}
            placeholder="選択する(複数可)"
            style={{ width: 200 }}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <ItemLabel>社会保険等の加入状況（その他）</ItemLabel>
        </InputContainer>
        <InputContainer>
          <LocalItemTextArea 
            value={ec?.otherSocialInsuranceCoverageOtherDetail ?? ""}
            onTextChange={val => onChange?.({ otherSocialInsuranceCoverageOtherDetail: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <ItemLabel>雇入れ時の健康診断</ItemLabel>
        </InputContainer>
        <InputContainer>
          <DateField 
            value={ec?.otherMedicalExaminationAtTheTimeOfHiring ?? ""}
            onDateChange={val => onChange?.({ otherMedicalExaminationAtTheTimeOfHiring: val })}
            onlyYearAndMonth
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <ItemLabel>初回の定期健康診断</ItemLabel>
        </InputContainer>
        <InputContainer>
          <DateField 
            value={ec?.otherFirstPeriodicMedicalExamination ?? ""}
            onDateChange={val => onChange?.({ otherFirstPeriodicMedicalExamination: val })}
            onlyYearAndMonth
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <ItemLabel>2回目以降の定期健康診断</ItemLabel>
        </InputContainer>
        <TextAndInputContainer>
          <ItemTextInput 
              placeholder="1年"
              value={ec?.otherSecondAndSubsequentPeriodicMedicalExaminations ?? ""}
              onTextChange={val => onChange?.({ otherSecondAndSubsequentPeriodicMedicalExaminations: val })}
              style={{ width: 80, textAlign: "right" }} 
            />
          <span>ごとに実施</span>
        </TextAndInputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <ItemLabel>雇用管理の改善等に関する相談窓口（部署名）</ItemLabel>
        </InputContainer>
        <InputContainer>
          <LocalItemTextArea 
            value={ec?.otherDepartmentConsultationEmploymentManagementJa ?? ""}
            onTextChange={val => onChange?.({ otherDepartmentConsultationEmploymentManagementJa: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <ItemLabel>雇用管理の改善等に関する相談窓口（担当者職氏名）</ItemLabel>
        </InputContainer>
        <InputContainer>
          <LocalItemTextArea 
            value={ec?.otherManagerConsultationEmploymentManagementJa ?? ""}
            onTextChange={val => onChange?.({ otherManagerConsultationEmploymentManagementJa: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <ItemLabel>雇用管理の改善等に関する相談窓口（連絡先）</ItemLabel>
        </InputContainer>
        <InputContainer>
          <LocalItemTextArea 
            value={ec?.otherContactConsultationEmploymentManagement ?? ""}
            onTextChange={val => onChange?.({ otherContactConsultationEmploymentManagement: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <ItemLabel>雇用管理の改善等に関する相談窓口（部署名、英字）</ItemLabel>
        </InputContainer>
        <InputContainer>
          <LocalItemTextArea
              value={ec?.otherDepartmentConsultationEmploymentManagementEn ?? ""}
              onTextChange={val => onChange?.({ otherDepartmentConsultationEmploymentManagementEn: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <ItemLabel>雇用管理の改善等に関する相談窓口（担当者職氏名、英字）</ItemLabel>
        </InputContainer>
        <InputContainer>
          <LocalItemTextArea
              value={ec?.otherManagerConsultationEmploymentManagementEn ?? ""}
              onTextChange={val => onChange?.({ otherManagerConsultationEmploymentManagementEn: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <ItemLabel>帰国旅費の負担</ItemLabel>
        </InputContainer>
        <InputContainer>
          <Description>
            本契約終了後に乙が帰国するに当たり、乙が帰国旅費を負担することができないときは、甲が当該旅費を負担するとともに、帰国が円滑になされるよう必要な措置を講じることとする。
          </Description>
        </InputContainer>
      </FormRow>
      <FormRow>
        <InputContainer>
          <ItemLabel>就業規則確認方法</ItemLabel>
        </InputContainer>
        <InputContainer>
          <LocalItemTextArea 
            value={ec?.otherWorkRegulationsCheckMethod ?? ""}
            onTextChange={val => onChange?.({ otherWorkRegulationsCheckMethod: val })}
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default OtherSection;
