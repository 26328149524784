import { apiUtil } from "../../core/utils";
import { CandidateAIAnalysisResponse } from "../types/api";


export const analyzeCandidateDataByAI =  async <T extends {
  file: File;
  typeId: number;
}> (candidateId: number|string, params: T []) => {
  const data = new FormData();

  params.forEach((param, i) => {
    data.append('files[]', param.file);
    data.append('kinds[]', param.typeId.toString());
  });

  const response = await apiUtil.patch(`/candidates/${candidateId}/ai_analyze`, data);
  if (response.status >= 200 && response.status < 300) {
      return response.data as CandidateAIAnalysisResponse;
  }
  throw new Error('API error');
};