import React, {FunctionComponent} from "react";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import {
  LocalCheckBox,
  LocalTextInputGrow, Row0,
  Row10WithGrow,
  UnitText
} from "../commonStyle";

interface CheckBoxWithTextInputProps extends SupportPlanFormProps {
  checkPropertyName: SupportPlanExtractType<boolean>;
  textPropertyName: SupportPlanExtractType<string | null>;
  label: string;
  placeholder?: string;
  headBracket?: string;
  tailBracket?: string;
}

const CheckBoxWithTextInput: FunctionComponent<CheckBoxWithTextInputProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
    checkPropertyName,
    textPropertyName,
    label,
    placeholder,
    headBracket = "（",
    tailBracket = "）",
}) => {
  return (
    <Row0>
      <LocalCheckBox
        checked={!!supportPlan?.[checkPropertyName]}
        onValueChange={(val) => {
          onChangeSupportPlan?.({[checkPropertyName]: val});
        }}
      >
        {label}
      </LocalCheckBox>
      <Row10WithGrow>
        <UnitText>
          {headBracket}
        </UnitText>
        <LocalTextInputGrow
          placeholder={placeholder}
          value={supportPlan?.[textPropertyName] ?? ""}
          onTextChange={(val: string) => {
            onChangeSupportPlan?.({[textPropertyName]: val});
          }}
        />
        <UnitText>
          {tailBracket}
        </UnitText>
      </Row10WithGrow>
    </Row0>
  );
};

export default CheckBoxWithTextInput;