import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ProposalListTable from './ProposalListTable';
import SearchPanel from './SearchPanel';
import { getProposals } from '../../apis/proposals';
import { Proposal } from '../../types';
import { getCandidate, getCandidates } from '../../../candidate/apis/candidate';
import { Candidate } from '../../../candidate/types/api';
import Page from '../../../core/components/Page';
import ACTIONS from '../../../core/constants/actions';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import { Mapping } from '../../../core/types/common';
import { Job } from '../../../job/types/api';
import { getJobs } from '../../../job/apis/job';
import { MOBILE_DEVICE } from '../../../core/constants/styles';
import PageHeading from '../../../core/components/PageHeading';

interface ProposalListPageProps {
}

const LocalPage = styled(Page)`
  padding-top: 0;
  
  > * {
    width: 100%;
  }
`;

const LocalPageHeading = styled(PageHeading)`
  padding: 10px 20px;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  padding: 10px 17px 133px 17px;
  overflow: auto;
  
  @media ${MOBILE_DEVICE} {
    padding: 0;
  }
`;

const ProposalListPage: FunctionComponent<ProposalListPageProps> = () => {
    const { state, dispatch } = useReducerContext();
    const [jobs, setJobs] = useState<Mapping<Job>>({});
    const [candidates, setCandidates] = useState<Mapping<Candidate>>({});
    const [proposals, setProposals] = useState<Proposal[]>([]);
    const [status, setStatus] = useState('');
    const [sswCategory, setSswCategory] = useState('');
    const { isEmployer, isPartner, isCandidate } = state;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const search = async (keyword: string) => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('proposal.fetching_proposals'),
            },
        });
        try {
            let params;
            
            if (isEmployer)
                params = { employerId: state.company?.employer?.id };
            
            if (isPartner) 
                params = { partnerId: state.company?.partner?.id };

            if (isCandidate)
                params = { candidateId: state.candidate?.id };   
                
            //Fail-safe. This shouldn't happen
            if (!params)
                return;
           
            const candidates: Mapping<Candidate> = {};
            if (isCandidate) {
                const candidateId = state.candidate!.id;
                candidates[candidateId] = await getCandidate(candidateId);
            } else {
                for (const candidate of await getCandidates(params)) {
                    candidates[candidate.id] = candidate;
                }
            }
            const jobs: Mapping<Job> = {};
            for (const job of await getJobs(params)) {
                jobs[job.id] = job;
            }
            const response = await getProposals(params);
            const proposals = response
                .filter((item) => !keyword
                    || `${candidates[item.candidateId].enFirstName}${candidates[item.candidateId].enMiddleName ? ` ${candidates[item.candidateId].enMiddleName}` : ''} ${candidates[item.candidateId].enLastName}`.includes(keyword)
                    || jobs[item.jobPostId].name.includes(keyword)
                );
            setCandidates(candidates);
            setJobs(jobs);
            setProposals(proposals)
        } catch (e) {
            // TODO: error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleItemClick = (index: number) => {
        navigate(`/${state.isEmployer ? 'applications' : 'proposals'}/${proposals[index].id}`);
    };

    useEffect(() => {
        search('');
    }, []);

    return (
        <LocalPage>
            {state.isPartner
                ? (
                    <SearchPanel
                        status={status}
                        industry={sswCategory}
                        onStatusChange={setStatus}
                        onIndustryChange={setSswCategory}
                        onSearch={search}
                    />
                )
                : (
                    <LocalPageHeading>
                        {t('core.applications')}
                    </LocalPageHeading>
                )
            }
            <Content>
                <ProposalListTable
                    industries={state.industries}
                    jobTypes={state.jobTypes}
                    jobs={jobs}
                    candidates={candidates}
                    proposals={proposals}
                    onItemClick={handleItemClick}
                />
            </Content>
        </LocalPage>
    );
};

export default ProposalListPage;