import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { updateCompany } from '../../apis/company';
import Button from '../../components/Button';
import CategoryHeading from '../../components/CategoryHeading';
import Column from '../../components/Column';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import TextInput from '../../components/TextInput';
import ACTIONS from '../../constants/actions';
import { useReducerContext } from '../../contexts/ReducerContext';
import PostalCodeInput from '../../components/PostalCodeInput';
import Row from '../../components/Row';
import Field from '../../components/Field';
import Label from '../../components/Label';

interface OrganizationSettingsProps {
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
  gap: 10px;
`;

const SubCategoryHeading = styled(CategoryHeading)`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
`;

const LocalSectionHeading = styled(SectionHeading)`
   font-weight: 500;
   font-size: 32px;
   line-height: 46px;
   height: auto;
`;

const LocalTextInput = styled(TextInput)`
  width: 300px;
  margin-top: 4px;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 42px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 25px;
  object-fit: cover;
`

const Prompt = styled.div`
  margin-left: 6px;
  color: #40BF6E;
  font-size: 14px;
`

const OrganizationSettings: FunctionComponent<OrganizationSettingsProps> = () => {
    const { state, dispatch } = useReducerContext();
    const [name, setName] = useState(state.company!.name);
    const [postalCode, setPostalCode] = useState(state.company!.postalCode);
    const [prefecture, setPrefecture] = useState(state.company!.prefecture);
    const [cityWard, setCityWard] = useState(state.company!.cityWard);
    const [town, setTown] = useState(state.company!.town);
    const [addressNumber, setAddressNumber] = useState(state.company!.addressNumber);
    const [buildingName, setBuildingName] = useState(state.company!.buildingName);
    const [phoneNumber, setPhoneNumber] = useState(state.company!.phoneNumber);
    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    const handleSave = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: '設定を更新しています...',
            },
        });
        try {
            const company = await updateCompany(state.company!.id, {
                postalCode,
                prefecture,
                cityWard,
                town,
                addressNumber,
                buildingName,
                phoneNumber,
            });
            dispatch({
                type: ACTIONS.UPDATE_COMPANY,
                payload: {
                    company,
                },
            });
            setMessage('設定が保存されました')
        } catch (e) {
            // TODO: error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    return (
        <Container>
            <LocalSectionHeading>
                {t('core.settings.organization.title')}
            </LocalSectionHeading>
            <Section style={{ width: '100%', marginTop: 10 }}>
                <Column>
                    <SubCategoryHeading>
                        {t('core.company_name')}
                    </SubCategoryHeading>
                    <LocalTextInput
                        disabled
                        value={name}
                        onTextChange={setName}
                    />
                </Column>
                <Field>
                    <Label required>{t('core.postal_code')}</Label>
                    <PostalCodeInput
                        value={postalCode}
                        onTextChange={setPostalCode}
                        onPrefectureChange={setPrefecture}
                        onCityChange={setCityWard}
                        onTownChange={setTown}
                    />
                </Field>
                <Row>
                    <Field>
                        <Label>{t('core.prefecture')}</Label>
                        <TextInput
                            style={{ width: '170px' }}
                            value={prefecture}
                            onTextChange={setPrefecture}
                        />
                    </Field>
                    <Field style={{ marginLeft: '30px' }}>
                        <Label>{t('core.city')}</Label>
                        <TextInput
                            style={{ width: '170px' }}
                            value={cityWard}
                            onTextChange={setCityWard}
                        />
                    </Field>
                    <Field style={{ marginLeft: '30px' }}>
                        <Label>{t('core.town_name')}</Label>
                        <TextInput
                            style={{ width: '170px' }}
                            value={town}
                            onTextChange={setTown}
                        />
                    </Field>
                </Row>

                <Row>
                    <Field>
                        <Label>{t('core.locality')}</Label>
                        <TextInput
                            style={{ width: '170px' }}
                            value={addressNumber}
                            onTextChange={setAddressNumber}
                        />
                    </Field>
                    <Field style={{ marginLeft: '30px' }}>
                        <Label>{t('core.building_etc')}</Label>
                        <TextInput
                            style={{ width: '170px' }}
                            value={buildingName}
                            onTextChange={setBuildingName}
                        />
                    </Field>
                </Row>
                <Row>
                    <Field>
                        <Label>{t('core.phone_number')}</Label>
                        <LocalTextInput
                            value={phoneNumber}
                            onTextChange={setPhoneNumber}
                        />
                    </Field>
                </Row>
                <Action>
                    <Button onClick={handleSave}>{t("core.save")}</Button>
                    {message && (
                        <>
                            <Icon src="/images/icon-checked-circle.svg" />
                            <Prompt>{message}</Prompt>
                        </>
                    )}
                </Action>
            </Section>
        </Container>
    );
};

export default OrganizationSettings;