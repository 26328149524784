import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  LabelContainer,
  TallerFormRow,
  EmphasizedItemLabel,
  NoteInLabel,
  NoteOnSection,
  SectionNoteContainer,
  SectionHeadingLabel,
} from "../../commonStyles";
import CurrencyField from "../../../../../core/components/CurrencyField";
import Text from "../../../../../core/components/Text";
import { FormBlockSectionProps } from "../../types";

interface DeductionsSectionProps extends FormBlockSectionProps {}


const DeductionsSection: FunctionComponent<DeductionsSectionProps> = ({
  employmentConditions: ec,
  onChange
}) => {

  return (
    <FormSection>
      <FormRow>
        <LabelContainer>
          <SectionHeadingLabel>控除</SectionHeadingLabel>
        </LabelContainer>
        <SectionNoteContainer>
          <NoteOnSection>※概算額で可</NoteOnSection>
        </SectionNoteContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>税金</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField 
            placeholder="8600"
            value={ec?.deductionsTaxes}
            onValueChange={val => onChange?.({ deductionsTaxes: val })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>社会保険料</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField 
            placeholder="25000"
            value={ec?.deductionsSocialInsurancePremiums}
            onValueChange={val => onChange?.({ deductionsSocialInsurancePremiums: val })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>雇用保険料</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField 
            placeholder="1000"
            value={ec?.deductionsEmploymentInsurancePremiums}
            onValueChange={val => onChange?.({ deductionsEmploymentInsurancePremiums: val })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>食費</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField 
            placeholder="10000"
            value={ec?.deductionsFoodExpenses}
            onValueChange={val => onChange?.({ deductionsFoodExpenses: val })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>居住費</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField 
            placeholder="13000"
            value={ec?.deductionsHousingExpenses}
            onValueChange={val => onChange?.({ deductionsHousingExpenses: val })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他控除1（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="水道光熱費"  
            value={ec?.deductionsOtherDeductions_1Name ?? ""}
            onTextChange={val => onChange?.({ deductionsOtherDeductions_1Name: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他控除1（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField 
            placeholder="3000"
            value={ec?.deductionsOtherDeductions_1Amount}
            onValueChange={val => onChange?.({ deductionsOtherDeductions_1Amount: val })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他控除2（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
        <ItemTextInput 
            placeholder="水道光熱費"  
            value={ec?.deductionsOtherDeductions_2Name ?? ""}
            onTextChange={val => onChange?.({ deductionsOtherDeductions_2Name: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他控除2（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField 
            placeholder="3000"
            value={ec?.deductionsOtherDeductions_2Amount}
            onValueChange={val => onChange?.({ deductionsOtherDeductions_2Amount: val })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他控除3（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
              placeholder="水道光熱費"  
              value={ec?.deductionsOtherDeductions_3Name ?? ""}
              onTextChange={val => onChange?.({ deductionsOtherDeductions_3Name: val })}
            />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他控除3（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField 
            placeholder="3000"
            value={ec?.deductionsOtherDeductions_3Amount}
            onValueChange={val => onChange?.({ deductionsOtherDeductions_3Amount: val })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他控除4（名称）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
              placeholder="水道光熱費"  
              value={ec?.deductionsOtherDeductions_4Name ?? ""}
              onTextChange={val => onChange?.({ deductionsOtherDeductions_4Name: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>その他控除4（金額）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField 
            placeholder="3000"
            value={ec?.deductionsOtherDeductions_4Amount}
            onValueChange={val => onChange?.({ deductionsOtherDeductions_4Amount: val })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
      <TallerFormRow>
        <LabelContainer>
          <EmphasizedItemLabel>控除金額の合計</EmphasizedItemLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="72600"
            value={ec?.deductionsTotalDeductions}
            onValueChange={val => onChange?.({ deductionsTotalDeductions: val })}
          />
          <Text>円</Text>
        </InputContainer>
      </TallerFormRow>
      <FormRow>
        <LabelContainer>
          <EmphasizedItemLabel>手取り支給額</EmphasizedItemLabel>
          <NoteInLabel style={{ marginTop: 5 }}>
            支払額から控除額を引いた額（時間外労働の割増賃金等は除く）
          </NoteInLabel>
        </LabelContainer>
        <InputContainer>
          <CurrencyField
            placeholder="116400"
            value={ec?.takeHomeWage}
            onValueChange={val => onChange?.({ takeHomeWage: val })}
          />
          <Text>円</Text>
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default DeductionsSection;
