import { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Page from '../components/Page';
import styled from 'styled-components';
import PageHeading from '../components/PageHeading';
import { useTranslation } from 'react-i18next';
import Text from '../components/Text';
import Button from '../components/Button';
import Column from '../components/Column';
import { useReducerContext } from '../contexts/ReducerContext';
import ACTIONS from '../constants/actions';
import { sendConfirmationEmail } from '../apis/user';
import Section from '../components/Section';
import { MOBILE_DEVICE } from '../constants/styles';

interface EmailSentPageProps {
}

interface LocationState {
    email?: string;
}

const LocalPage = styled(Page)`
    > * {
      width: 500px;
    }

    @media ${MOBILE_DEVICE} {
      > * {
        width: 100%;
      }
  }
`;

const LocalSection = styled(Section)`
  padding: 50px 75px 67px 75px;
  gap: 20px;
  
  @media ${MOBILE_DEVICE} {
    padding: 20px;
    background: transparent;
    border: none;
  }
`;

const LocalColumn = styled(Column)`
  margin-top: 0 !important;
  width: 100%;
  gap: 5px;
`;

const LocalLink = styled(Link)`
  > * {
    color: #017698 !important;
  }
`;


const EmailSentPage: FunctionComponent<EmailSentPageProps> = () => {
    const { dispatch} = useReducerContext();
    const location = useLocation();
    const { t } = useTranslation();
    const state = location.state as LocationState;

    const handleSubmit = async () => {
        if (state?.email) {
            dispatch({
                type: ACTIONS.START_LOADING,
            });
            try {
                await sendConfirmationEmail(state?.email);
            } catch (e) {
            }
            dispatch({
                type: ACTIONS.STOP_LOADING,
            });
        }
    };

    return (
        <LocalPage>
            <LocalSection>
                <PageHeading>
                    {t('core.email_sent')}
                </PageHeading>
                <Text>
                    {t('core.email_sent_description', { email: state?.email })}
                </Text>
                <Text style={{ color: '#999999'}}>
                    {t('core.email_sent_note')}
                </Text>
                <LocalColumn style={{ gap: 10 }} center>
                    <Button onClick={handleSubmit}>
                        {t('core.resend_email')}
                    </Button>
                    <LocalLink to="/login">
                        <Text>
                            {t('core.go_login')}
                        </Text>
                    </LocalLink>
                </LocalColumn>
            </LocalSection>
        </LocalPage>
    );
};

export default EmailSentPage;