import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type StepContentProps = HTMLAttributes<HTMLDivElement> & {
    active?: boolean;
    children?: ReactNode;
};

type ContainerProps = {
    active?: boolean;
};

const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  padding-top: 22px;
  padding-bottom: 28px;
  padding-left: 49px;
  box-sizing: border-box;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 14px;
    width: 2px;
    background: #C4C4C4;
  }
`;

const StepContent: FunctionComponent<StepContentProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default StepContent;