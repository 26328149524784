import { FunctionComponent } from "react";
import {
  Column5, CustomLabel,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell, LocalNumberField,
  LocalPostCodeInput,
  Note, RadioButtonsWrapper, Row10,
  SectionHeadingLarge, UnitText, WordBreakLabel
} from "../commonStyle"
import { isEmpty } from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";
import {SupportPlanFormProps} from "../types";
import {ItemTextInput} from "../../EmploymentConditionsFormPage/commonStyles";
import {LocalTextInput, NoteInputWrapper} from "../../../../job/pages/JobFormPage/commonStyle";
import Radio from "../../../../core/components/Radio";
interface SupportOrganizationSupportSectionProps extends SupportPlanFormProps {
}

export const SupportOrganizationSupportSection: FunctionComponent<SupportOrganizationSupportSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
}) => {
  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>Ⅲ. 登録支援機関（支援情報）</SectionHeadingLarge>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 支援先事務所の郵便番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援先事務所の郵便番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>ハイフンを除いた半角数字で入力してください</Note>
              <LocalPostCodeInput
                value={supportPlan?.supportOrganizationSupportOfficePostalCode ?? ""}
                onTextChange={(val) => {
                  onChangeSupportPlan?.({ supportOrganizationSupportOfficePostalCode: val });
                }}
                onPostalCodeAddressIdChange={(val) =>
                  onChangeSupportPlan?.({ supportOrganizationSupportOfficePostalCodeAddressId: val })
                }
                onPrefectureChange={(val) => {
                  onChangeSupportPlan?.(
                    { supportOrganizationSupportOfficePrefecture: val }
                  );
                }}
                onCityChange={(val) => {
                  onChangeSupportPlan?.(
                    { supportOrganizationSupportOfficeCityWard: val }
                  );
                }}
                onTownChange={(val) => {
                  onChangeSupportPlan?.(
                    { supportOrganizationSupportOfficeTown: val }
                  );
                }}
                error={isEmpty(supportPlan?.supportOrganizationSupportOfficePostalCode)}
                suppressErrorMessage
              />
              {isEmpty(supportPlan?.supportOrganizationSupportOfficePostalCode) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の都道府県 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>支援先事務所の都道府県</CustomLabel>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>郵便番号から自動で入力されます</Note>
              <LocalTextInput
                readOnly
                disabled
                value={
                  supportPlan?.supportOrganizationSupportOfficePrefecture ?? ""
                }
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の市区 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>支援先事務所の市区</CustomLabel>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>郵便番号から自動で入力されます</Note>
              <LocalTextInput
                readOnly
                disabled
                value={
                  supportPlan?.supportOrganizationSupportOfficeCityWard ?? ""
                }
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の町村 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>支援先事務所の町村</CustomLabel>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>郵便番号から自動で入力されます</Note>
              <LocalTextInput
                readOnly
                disabled
                value={
                  supportPlan?.supportOrganizationSupportOfficeTown ?? ""
                }
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の番地 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援先事務所の番地</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="1-2-3"
                value={supportPlan?.supportOrganizationSupportOfficeAddressNumber ?? ""}
                onTextChange={val => onChangeSupportPlan?.({ supportOrganizationSupportOfficeAddressNumber: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportOfficeAddressNumber)}
              />
              {isEmpty(supportPlan?.supportOrganizationSupportOfficeAddressNumber) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の建物名等 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>支援先事務所の建物名等</CustomLabel>
          </LabelCell>
          <FieldCell>
            <ItemTextInput
              placeholder="サンプルビル 10F"
              value={supportPlan?.supportOrganizationSupportOfficeBuilding ?? ""}
              onTextChange={val => onChangeSupportPlan?.({ supportOrganizationSupportOfficeBuilding: val })}
            />
          </FieldCell>
        </GridRow>

        {/* 支援先事務所の電話番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援先事務所の電話番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="03-1234-5678"
                value={supportPlan?.supportOrganizationSupportOfficePhoneNumber ?? ""}
                onTextChange={val => onChangeSupportPlan?.({ supportOrganizationSupportOfficePhoneNumber: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportOfficePhoneNumber)}
              />
              {isEmpty(supportPlan?.supportOrganizationSupportOfficePhoneNumber) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援責任者の名字 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援責任者の名字</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="田村"
                value={supportPlan?.supportOrganizationSupportManagerLastName ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationSupportManagerLastName: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportManagerLastName)}
              >
              </ItemTextInput>
              {isEmpty(supportPlan?.supportOrganizationSupportManagerLastName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援責任者の名前 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援責任者の名前</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="秀介"
                value={supportPlan?.supportOrganizationSupportManagerFirstName ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationSupportManagerFirstName: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportManagerFirstName)}
              >
              </ItemTextInput>
              {isEmpty(supportPlan?.supportOrganizationSupportManagerFirstName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援責任者の名字ふりがな */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>支援責任者の名字<br/>ふりがな</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="たむら"
                value={supportPlan?.supportOrganizationSupportManagerLastNameFurigana ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationSupportManagerLastNameFurigana: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportManagerLastNameFurigana)}
              >
              </ItemTextInput>
              {isEmpty(supportPlan?.supportOrganizationSupportManagerLastNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援責任者の名前ふりがな */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>支援責任者の名前<br/>ふりがな</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="しゅうすけ"
                value={supportPlan?.supportOrganizationSupportManagerFirstNameFurigana ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationSupportManagerFirstNameFurigana: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportManagerFirstNameFurigana)}
              >
              </ItemTextInput>
              {isEmpty(supportPlan?.supportOrganizationSupportManagerFirstNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 役職 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>役職</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="（役職なし）"
                value={supportPlan?.supportOrganizationSupportManagerTitle ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationSupportManagerTitle: val })}
                error={isEmpty(supportPlan?.supportOrganizationSupportManagerTitle)}
              >
              </ItemTextInput>
              {isEmpty(supportPlan?.supportOrganizationSupportManagerTitle) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援を行っている1号特定技能外国人数 */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>支援を行っている1号特定技能外国人数</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Row10>
                <LocalNumberField
                  allowZero
                  placeholder="0"
                  step="1"
                  min="0"
                  value={supportPlan?.supportOrganizationSupportSpecificSkilledWorkerCount ?? 0}
                  error={isEmpty(supportPlan?.supportOrganizationSupportSpecificSkilledWorkerCount)}
                  onValueChange={(val: number | null) => {
                    onChangeSupportPlan?.({supportOrganizationSupportSpecificSkilledWorkerCount: Number(val)});
                  }}
                />
                <UnitText>人</UnitText>
              </Row10>
              {isEmpty(supportPlan?.supportOrganizationSupportSpecificSkilledWorkerCount) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援担当者数 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>支援担当者数</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Row10>
                <LocalNumberField
                  allowZero
                  placeholder="0"
                  step="1"
                  min="0"
                  value={supportPlan?.supportOrganizationSupportPersonCount ?? 0}
                  error={isEmpty(supportPlan?.supportOrganizationSupportPersonCount)}
                  onValueChange={(val: number | null) => {
                    onChangeSupportPlan?.({supportOrganizationSupportPersonCount: Number(val)});
                  }}
                />
                <UnitText>人</UnitText>
              </Row10>
              {isEmpty(supportPlan?.supportOrganizationSupportPersonCount) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援の適正性を担保していることの有無 */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>支援の適正性を担保していることの有無</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={
                  supportPlan?.supportOrganizationSupportEnsureProperty === true
                }
                onValueChange={() => {
                  onChangeSupportPlan?.({supportOrganizationSupportEnsureProperty: true});
                }}
                error={supportPlan?.supportOrganizationSupportEnsureProperty == null}
              >
                有
              </Radio>
              <Radio
                checked={
                  supportPlan?.supportOrganizationSupportEnsureProperty === false
                }
                onValueChange={() => {
                  onChangeSupportPlan?.({supportOrganizationSupportEnsureProperty: false});
                }}
                error={supportPlan?.supportOrganizationSupportEnsureProperty == null}
              >
                無
              </Radio>
            </RadioButtonsWrapper>
            {supportPlan?.supportOrganizationSupportEnsureProperty == null &&
                <ErrorMessage>選択してください</ErrorMessage>
            }
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default SupportOrganizationSupportSection;