import { AnchorHTMLAttributes, FunctionComponent } from 'react';
import styled from 'styled-components';

const GOOGLE_TRANSLATE_URL = 'http://translate.google.com';

interface GoogleTranslateLogoProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
}

const Container = styled.a`
`;

const GoogleTranslateLogo: FunctionComponent<GoogleTranslateLogoProps> = ({
    ...props
}) => {
    return (
        <Container {...props} href={GOOGLE_TRANSLATE_URL} target="_blank">
            <img alt="google-translate" src="/images/googleTranslate.svg" />
        </Container>
    )
};

export default GoogleTranslateLogo;