import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { ReactComponent as InfoIcon } from '../../assets/icon-info-message.svg';

type MessageProps = HTMLAttributes<HTMLDivElement> & {
    active?: boolean;
    center?: boolean;
    withIcon?: boolean;
    children?: ReactNode;
};

const Container = styled.div<MessageProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;
  color: #444444;
  background: ${(props: MessageProps) => props.active ? '#FFDFD1' : '#F2F2F2'};
  border-radius: 5px;
  text-align: ${(props: MessageProps) => props.center ? 'center' : 'start'};
    
  > img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

const Content = styled.div`
  width: 100%;
`;

const Message: FunctionComponent<MessageProps> = ({
    withIcon,
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            {withIcon && (
                <IconContainer>
                    <InfoIcon />
                </IconContainer>
            )}
            <Content>
                {children}
            </Content>
        </Container>
    );
};

export default Message;