import { apiUtil } from "../../core/utils";
import { JobDataAIAnalyzed } from "../types/api";

export const analyzeJobDataByAI = async (jobPostId: number|string, params: { file?: File | null,  url?: string; }) => {
  const data = new FormData();

  if (params.file)
    data.append('file', params.file);

  if (params.url)
    data.append('url', params.url);
  
  const response = await apiUtil.patch(`/job_posts/${jobPostId}/ai_analyze`, data);
  if (response.status >= 200 && response.status < 300) {
      return response.data as JobDataAIAnalyzed;
  }
  throw new Error('API error');
};