import { FunctionComponent } from "react";
import styled from "styled-components";
import EmployeeSection from "./employmentConditions/EmployeeSection";
import EmployerSection from "./employmentConditions/EmployerSection";
import ContractPeriodSection from "./employmentConditions/ContractPeriodSection";
import TypeOfWorkSection from "./employmentConditions/TypeOfWorkSection";
import WorkHoursSection from "./employmentConditions/WorkHoursSection";
import HolidaysSection from "./employmentConditions/HolidaysSection";
import VacationSection from "./employmentConditions/VacationSection";
import CompensationSection from "./employmentConditions/CompensationSection";
import ResignationSection from "./employmentConditions/ResignationSection";
import OtherSection from "./employmentConditions/OtherSection";
import PlacementOfEmploymentSection from "./employmentConditions/PlaceOfEmploymentSection";
import { FormBlockProps } from "../types";

interface EmploymentConditionsBlockProps extends FormBlockProps {}

const FormContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 0px 0px 30px 0px;
  border-radius: 5px;
  border: 0.5px solid #e5d5d5;
`;

const EmploymentConditionsBlock: FunctionComponent<EmploymentConditionsBlockProps> = ({
  ...props
}) => {
  return (
    <FormContainer>
      <EmployeeSection {...props}/>
      <EmployerSection {...props}/>
      <ContractPeriodSection {...props}/>
      <PlacementOfEmploymentSection {...props}/>
      <TypeOfWorkSection {...props}/>
      <WorkHoursSection {...props}/>
      
      <HolidaysSection {...props}/>
      <VacationSection {...props}/>
      
      <CompensationSection {...props}/>
      <ResignationSection {...props}/>
      <OtherSection {...props}/> 
    </FormContainer>
  );
};

export default EmploymentConditionsBlock;
