import React, {FunctionComponent} from "react";
import {SupportPlanExtractType, SupportPlanFormProps} from "../types";
import {
  CustomLabel, FieldCell, GridRow,
  LabelCell, LocalTextInputExtend,
} from "../commonStyle";

interface ScheduleDateReadOnlyRowProps extends SupportPlanFormProps {
  propertyName: SupportPlanExtractType<string>;
}

const ScheduleDateReadOnlyRow: FunctionComponent<ScheduleDateReadOnlyRowProps> = (
  {
    supportPlan,
    propertyName,
}) => {
  return (
    <GridRow>
      <LabelCell>
        <CustomLabel required>実施予定時期</CustomLabel>
      </LabelCell>
      <FieldCell>
        <LocalTextInputExtend
          readOnly
          value={supportPlan?.[propertyName] ?? ""}
        />
      </FieldCell>
    </GridRow>
  );
};

export default ScheduleDateReadOnlyRow;