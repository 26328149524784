import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Candidate } from '../../../candidate/types/api';
import ContactInformation from '../../components/ContactInformation';
import Text from '../../../core/components/Text';
import Row from '../../../core/components/Row';
import { useReducerContext } from '../../../core/contexts/ReducerContext';

interface ContactInformationSectionProps {
    candidate?: Candidate;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 730px;
  margin-top: 10px;
  padding: 20px 20px 60px 20px;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  background: #FFFFFF;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  color: #444444;
  background: #FFDFD1;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 30px;
  
  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const Warning = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 10px;
  border-radius: 5px;
  color: #E93232;
  background: #FFF0F0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  
  > img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    object-fit: cover;
  }
`;

const ContactInformationSection: FunctionComponent<ContactInformationSectionProps> = ({
    candidate,
}) => {
    const { state } = useReducerContext();
    const { t } = useTranslation();

    return (
        <Container>
            <Status>{t('proposal.candidate_hired')}</Status>
            <Block>
                <Text>
                    {t('proposal.contact_information_description')}
                </Text>
                {!candidate?.email && (
                    <Warning>
                        <img src="/images/icon-error.svg" />
                        <span>{t('proposal.set_email_warning')}</span>
                    </Warning>
                )}
                <Row style={{ justifyContent: 'space-between', width: '100%' }} center>
                    <Text style={{ fontWeight: 500 }}>
                        {t('candidate.contact_information')}
                    </Text>
                    <a
                        style={{ textDecorationColor: '#017698' }}
                        href={state.isCandidate
                            ? '/profile/edit'
                            : `/candidates/${candidate?.id}/edit`
                        }
                        target="_blank" 
                        rel="noreferrer"
                    >
                        <Text style={{ color: '#017698' }}>
                            {t('proposal.edit_candidate_profile')}
                        </Text>
                    </a>
                </Row>
                <ContactInformation
                    email={candidate?.email}
                    lineId={candidate?.lineId}
                    facebookProfileLink={candidate?.facebookProfileLink}
                />
            </Block>
        </Container>
    );
};

export default ContactInformationSection;