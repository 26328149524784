import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from './Button';
import Modal from './Modal';

interface ConfirmationModalProps {
    open?: boolean;
    message?: string;
    onCancel?: () => void;
    onConfirm?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  padding: 19px 19px;
  background-color: #FFFFFF;
  border-radius: 5px;
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font: normal normal normal 16px/27px Hiragino Kaku Gothic ProN;
  color: #2C2C2C;
  white-space: pre-wrap;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
  
  > *:not(:first-child) {
    margin-left: 24px;
  }
`

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
    open,
    message,
    onCancel,
    onConfirm,
}) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onCancel}>
            <Container>
                <Message>{message}</Message>
                <Buttons>
                    <Button variant="secondary" onClick={onCancel}>{t('core.not_confirm')}</Button>
                    <Button onClick={onConfirm}>{t('core.confirm')}</Button>
                </Buttons>
            </Container>
        </Modal>
    );
};

export default ConfirmationModal;